import { Tabs, Select } from "antd";
import Page from "./page";
import e1 from "../../../imgs/e1.png";
import e2 from "../../../imgs/e2.png";
import e3 from "../../../imgs/e3.png";
import e4 from "../../../imgs/e4.png";
import { Checkbox } from "antd";
import back5 from "../../../imgs/back5.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
const Listing = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  const pensionerId = useSelector((state) => state.pensionerId);
  const universalPenId = useSelector((state) => state.universalPenId);
  const allBenId = useSelector((state) => state.allBenId);
  const reset = useSelector((state) => state.reset);
  const penId = useSelector((state) => state.penId);
  const token = useSelector((state) => state.token);
  const [allB, setAllb] = useState([]);
  const [diss, setDiss] = useState(false);
  const [test, setTest] = useState(false);
  const [selfBen, setSelfBen] = useState(false);
  const items = [
    {
      key: "1",
      label: `All`,
      children: <Page />,
    },
    {
      key: "2",
      label: `Active`,
      children: <Page />,
    },
    {
      key: "3",
      label: `Inactive`,
      children: <Page />,
    },
  ];

  console.log(dataUser, "eee");
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [selfBen]);
  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://oap-api-prod.jabcomp.gy/v1/users/profile/${dataUser.data.user.id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {
  //       console.log(data, "ben");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const ownHandler = (e) => {
    console.log(e.target.checked, "deded111", allB);
    setSelfBen(!e.target.checked);
    const wow = allB.filter((item) => {
      return item.relationship === "Me";
    });
    let url;
    let url1;
    let body;
    if (dash === "/Dashboard/listing") {
      url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/toggle-self`;
      body = {
        programId: penId,
        isActive: e.target.checked,
      };
      // url1 = `https://oap-api-prod.jabcomp.gy/v1/beneficiary-info/change-self-status?programId=${penId}`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/toggle-self/by-staff?pensionerUserId=${pensionerId}`;
      body = {
        statusInfo: {
          programId: penId,
          isActive: e.target.checked,
        },
      };
      // url1 = `https://oap-api-prod.jabcomp.gy/v1/beneficiary-info/change-self-status/by-staff?programId=${penId}&pensionerUserId=${universalPenId}`;
    }

    axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        console.log(data, "21221");
        // setSelfBen(true);
        dispatch({ type: "RESET" });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    let wow;
    if (dash === "/EDashboard/listing") {
      console.log("ceefdds", allBenId);
      wow = allBenId;
    } else {
      wow = dataUser.data.user.id;
    }
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/users/profile/${wow}`, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        console.log("profile", data.data);
        dispatch({ type: "setData", num: data.data });
        dispatch({ type: "ISET", num: 12 });
        dispatch({ type: "SET", num: 0 });
        dispatch({ type: "BISET", num: 0 });
        dispatch({ type: "BRSET", num: 0 });
        dispatch({
          type: "penId",
          num: `${data.data.pensionPrograms[0].id}`,
        });

        // dispatch({ type: "setProfileData", num: data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (dash === "/EDashboard/listing") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/list-by-staff?pensionerUserId=${allBenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "allB");
          setAllb(data.data);
          let wow;
          wow = data.data.filter((item) => {
            return item.relationship === "Me";
          });
          if (wow.length != 0) {
            if (wow[0].isActive) {
              setSelfBen(true);
            } else {
              setSelfBen(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/list-by-pensioner`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "allB");
          setAllb(data.data);
          let wow;
          wow = data.data.filter((item) => {
            return item.relationship === "Me";
          });
          if (wow.length != 0) {
            if (wow[0].isActive) {
              setSelfBen(true);
            } else {
              setSelfBen(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [reset]);

  useEffect(() => {
    console.log(profileData, "1111111111");
    // if (profileData) {
    //   if (profileData.length != 0) {
    //     setDiss(profileData.user.isDisabled);
    //   }
    // }
  }, [profileData]);
  return (
    <div className="sm:p-10 sm:pl-[7rem] sm:pr-[7rem]">
      <div>
        <div
          className={`${
            location.pathname === "/Dashboard/listing" ? "mb-[4rem]" : ""
          } relative sm:flex-row flex-col flex justify-between sm:mt-0 mt-4`}
        >
          <div
            onClick={() => {
              if (dash === "/Dashboard/listing") {
                router("/Dashboard");
              } else {
                router("/EDashboard/profPage");
              }
            }}
            className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] sm:mt-3 left-[8%]"
          >
            <img className="w-[1.7rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold sm:text-[1.8rem] sm:ml-0 ml-[4rem] text-[1.2rem]">
            Beneficiaries
          </h2>
          <button
            onClick={() => {
              if (dash === "/EDashboard/listing") {
                router("/EDashboard/BeneficiaryQuestions");
              } else {
                router("/Dashboard/BeneficiaryQuestions");
              }
            }}
            className={` ${
              dataUser.data.user.status === "pending" || diss
                ? "pointer-events-none"
                : ""
            } text-white bg-[#57D38C] sm:pl-5 sm:pr-5 p-2 rounded-lg sm:mt-0 sm:ml-0 ml-4 mt-5 w-[15rem]`}
          >
            Create Beneficiary
          </button>
        </div>

        <div
          className={`sm:flex-row flex flex-col sm:ml-0 ml-[4rem] w-full sm:justify-between mt-[3rem] mb-8`}
        >
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Total Beneficiaries
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">6</p>
            </div>
            <div>
              <img className="w-[2.1rem] mt-4" src={e2} alt="" />
            </div>
          </div>
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Active Beneficiaries
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">3</p>
            </div>
            <div>
              <img className="w-[2.2rem] mt-4" src={e3} alt="" />
            </div>
          </div>
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Inactive Beneficiaries
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">2</p>
            </div>
            <div>
              <img className="w-[2rem] mt-5" src={e1} alt="" />
            </div>
          </div>
          <div className="w-[16rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Own Beneficiary
              </h2>
              <div className="mt-1">
                <Checkbox
                  checked={selfBen}
                  onChange={ownHandler}
                  className={"checkk3"}
                >
                  I am my own Beneficiary
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      {console.log(profileData, "wqwqwwqqqqqqqqqqq")}
      <div
        className={`relative  ${
          dataUser.data.user.status === "pending" || diss
            ? "pointer-events-none"
            : ""
        }`}
      >
        <Tabs defaultActiveKey="1" items={items} />
        {/* <select
          className="absolute top-2 right-5 bg-[#E8E8E8] sm:w-[12rem] w-[6.4rem] border-none rounded-lg pt-1 pb-1 pl-2 pr-5 cursor-pointer some-class focus:border-none"
          name=""
          defaultValue={"12"}
          id=""
        >
          <option value="12" disabled>
            Select Program
          </option>
          <option value="">Program1</option>
          <option value="">Program2</option>
        </select> */}
      </div>
    </div>
  );
};

export default Listing;
