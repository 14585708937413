import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import eye from "../../imgs/eyee.png";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import across from "../../imgs/across.png";

let personal = "";
let address = "";
let info = "";
let identification = "";
const Ww = () => {
  const option = useSelector((state) => state.option);
  const dataUser = useSelector((state) => state.dataUser);
  const router = useNavigate();
  const [prompt, setPrompt] = useState("");
  const [email, setEmail] = useState("");
  const [gid, setGid] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState("");
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [eOption, setEOption] = useState(false);
  const [pOption, setPOption] = useState(false);
  const [gOption, setGOption] = useState(true);
  const [word, setWord] = useState("Login with Phone");
  const [word1, setWord1] = useState("Login with Email");

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };
  console.log(`${process.env.REACT_APP_BASE_URL}`, "wwwwwwwwwwwwwwwwwwwww");
  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("w");
    if (email) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/login`, {
          password: password,
          email: email,
        })
        .then((data) => {
          console.log(data, "done");
          dispatch({ type: "tokenSet", num: data.data.tokens.access.token });
          localStorage.setItem("authen", "true");
          dispatch({ type: "authStateSet", num: "true" });
          if (data.data.user.roles[0] === "pensioner") {
            dispatch({ type: "PENSIONER" });
          } else {
            dispatch({ type: "EMPLOYEE" });
          }
          if (data.data.user.status === "rejected") {
            dispatch({
              type: "nameSet",
              num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
            });
            dispatch({
              type: "imageSet",
              num: `${data.data.user.person.image}`,
            });
            dispatch({
              type: "tokenSet",
              num: `${data.data.tokens.access.token}`,
            });
            dispatch({ type: "setUserData", num: data });
            if (data.data.user.roles[0] === "pensioner") {
              router("/Dashboard");
            } else if (data.data.user.roles[0] === "employee") {
              router("/EDashboard");
            }
          } else if (data.data.user.status === "approved") {
            dispatch({
              type: "nameSet",
              num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
            });
            dispatch({
              type: "imageSet",
              num: `${data.data.user.person.image}`,
            });
            dispatch({
              type: "tokenSet",
              num: `${data.data.tokens.access.token}`,
            });
            dispatch({ type: "setUserData", num: data });
            if (data.data.user.roles[0] === "pensioner") {
              router("/Dashboard");
            } else if (data.data.user.roles[0] === "employee") {
              router("/EDashboard");
            }
          } else {
            if (data.data.signupSteps.length === 0) {
              dispatch({ type: "RSET", num: 1 });
              dispatch({ type: "ISET", num: 2 });
            } else {
              personal = data.data.signupSteps.filter((item) => {
                return item.stepName === "personal";
              });
              address = data.data.signupSteps.filter((item) => {
                return item.stepName === "address";
              });
              if (option === "Employee") {
                info = data.data.signupSteps.filter((item) => {
                  return item.stepName === "employee-info";
                });
              } else {
                info = data.data.signupSteps.filter((item) => {
                  return item.stepName === "pensioner-info";
                });
              }

              identification = data.data.signupSteps.filter((item) => {
                return item.stepName === "identification";
              });
              if (
                personal.length != 0 &&
                address.length != 0 &&
                info.length != 0 &&
                identification.length != 0
              ) {
                if (data.data.user.applications === 0) {
                  dispatch({ type: "setUserData", num: data });

                  if (data.data.user.roles[0] === "pensioner") {
                    dispatch({ type: "data1Set", num: data.data.user.person });
                    dispatch({ type: "userDataSet", num: data.data.user });
                    dispatch({ type: "RSET", num: 1 });
                    dispatch({ type: "ISET", num: 19 });
                  }
                  // dispatch({ type: "data2Set", num: data });
                  dispatch({
                    type: "nameSet",
                    num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                  });
                  dispatch({
                    type: "imageSet",
                    num: `${data.data.user.person.image}`,
                  });
                  dispatch({
                    type: "tokenSet",
                    num: `${data.data.tokens.access.token}`,
                  });
                } else {
                  dispatch({ type: "setUserData", num: data });
                  dispatch({
                    type: "nameSet",
                    num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                  });
                  dispatch({
                    type: "imageSet",
                    num: `${data.data.user.person.image}`,
                  });
                  dispatch({
                    type: "tokenSet",
                    num: `${data.data.tokens.access.token}`,
                  });
                  if (data.data.user.roles[0] === "pensioner") {
                    router("/Dashboard");
                  } else if (data.data.user.roles[0] === "employee") {
                    router("/EDashboard");
                  }
                }
              } else if (personal.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 2 });
              } else if (
                address.length === 0 &&
                info.length === 0 &&
                identification.length === 0
              ) {
                console.log("hhh");
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 3 });
              } else if (
                info.length === 0 &&
                identification.length === 0 &&
                address.length != 0
              ) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 4 });
              } else if (
                identification.length === 0 &&
                info.length != 0 &&
                address != 0
              ) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 5 });
              }
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.message === "incorrect email or password") {
            setErr("Incorrect Email or Password");
          } else {
            setErr(err.response.data.message);
          }

          setShow(true);
          setIsLoading(false);
        });
    } else if (gid) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/login`, {
          password: password,
          gid: gid,
        })
        .then((data) => {
          console.log(data, "done");
          dispatch({ type: "tokenSet", num: data.data.tokens.access.token });
          localStorage.setItem("authen", "true");
          dispatch({ type: "authStateSet", num: "true" });
          if (data.data.user.roles[0] === "pensioner") {
            dispatch({ type: "PENSIONER" });
          } else {
            dispatch({ type: "EMPLOYEE" });
          }

          if (data.data.user.status === "rejected") {
            dispatch({
              type: "nameSet",
              num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
            });
            dispatch({
              type: "imageSet",
              num: `${data.data.user.person.image}`,
            });
            dispatch({
              type: "tokenSet",
              num: `${data.data.tokens.access.token}`,
            });
            dispatch({ type: "setUserData", num: data });
            if (data.data.user.roles[0] === "pensioner") {
              router("/Dashboard");
            } else if (data.data.user.roles[0] === "employee") {
              router("/EDashboard");
            }
          } else if (data.data.user.status === "approved") {
            dispatch({
              type: "nameSet",
              num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
            });
            dispatch({
              type: "imageSet",
              num: `${data.data.user.person.image}`,
            });
            dispatch({
              type: "tokenSet",
              num: `${data.data.tokens.access.token}`,
            });
            dispatch({ type: "setUserData", num: data });
            if (data.data.user.roles[0] === "pensioner") {
              router("/Dashboard");
            } else if (data.data.user.roles[0] === "employee") {
              router("/EDashboard");
            }
          } else {
            if (data.data.signupSteps.length === 0) {
              dispatch({ type: "RSET", num: 1 });
              dispatch({ type: "ISET", num: 2 });
            } else {
              personal = data.data.signupSteps.filter((item) => {
                return item.stepName === "personal";
              });
              address = data.data.signupSteps.filter((item) => {
                return item.stepName === "address";
              });
              if (option === "Employee") {
                info = data.data.signupSteps.filter((item) => {
                  return item.stepName === "employee-info";
                });
              } else {
                info = data.data.signupSteps.filter((item) => {
                  return item.stepName === "pensioner-info";
                });
              }
              identification = data.data.signupSteps.filter((item) => {
                return item.stepName === "identification";
              });
              if (
                personal.length != 0 &&
                address.length != 0 &&
                info.length != 0 &&
                identification.length != 0
              ) {
                if (data.data.user.applications === 0) {
                  dispatch({ type: "setUserData", num: data });

                  if (data.data.user.roles[0] === "pensioner") {
                    dispatch({ type: "data1Set", num: data.data.user.person });
                    dispatch({ type: "userDataSet", num: data.data.user });
                    dispatch({ type: "RSET", num: 1 });
                    dispatch({ type: "ISET", num: 19 });
                    console.log(data, "wqwqqwqww");
                  }
                  // dispatch({ type: "data2Set", num: data });
                  dispatch({
                    type: "nameSet",
                    num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                  });
                  dispatch({
                    type: "imageSet",
                    num: `${data.data.user.person.image}`,
                  });
                  dispatch({
                    type: "tokenSet",
                    num: `${data.data.tokens.access.token}`,
                  });
                } else {
                  dispatch({ type: "setUserData", num: data });
                  if (data.data.user.roles[0] === "pensioner") {
                    router("/Dashboard");
                  } else if (data.data.user.roles[0] === "employee") {
                    router("/EDashboard");
                  }
                  dispatch({
                    type: "nameSet",
                    num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                  });
                  dispatch({
                    type: "imageSet",
                    num: `${data.data.user.person.image}`,
                  });
                  dispatch({
                    type: "tokenSet",
                    num: `${data.data.tokens.access.token}`,
                  });
                }
              } else if (personal.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 2 });
              } else if (
                address.length === 0 &&
                info.length === 0 &&
                identification.length === 0
              ) {
                console.log("hhh");
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 3 });
              } else if (
                info.length === 0 &&
                identification.length === 0 &&
                address.length != 0
              ) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 4 });
              } else if (
                identification.length === 0 &&
                info.length != 0 &&
                address != 0
              ) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 5 });
              }
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);

          if (err.response.data.message === "incorrect email or password") {
            setErr("Incorrect GID or Password");
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
          setIsLoading(false);
        });
    } else if (prompt) {
      const wow = parsePhoneNumber(prompt);
      let countryCode = wow.countryCallingCode;
      let number = wow.nationalNumber;
      console.log(password, countryCode, number, "wwo");
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/login`, {
          password: password,
          countryCode: `+${countryCode}`,
          phoneNo: number,
        })
        .then((data) => {
          dispatch({ type: "tokenSet", num: data.data.tokens.access.token });
          localStorage.setItem("authen", "true");
          dispatch({ type: "authStateSet", num: "true" });
          dispatch({ type: "authStateSet", num: "true" });
          console.log(data, "6666666666");
          if (data.data.user.roles[0] === "pensioner") {
            dispatch({ type: "PENSIONER" });
          } else {
            dispatch({ type: "EMPLOYEE" });
          }
          if (data.data.user.status === "rejected") {
            dispatch({
              type: "nameSet",
              num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
            });
            dispatch({
              type: "imageSet",
              num: `${data.data.user.person.image}`,
            });
            dispatch({
              type: "tokenSet",
              num: `${data.data.tokens.access.token}`,
            });
            dispatch({ type: "setUserData", num: data });
            if (data.data.user.roles[0] === "pensioner") {
              router("/Dashboard");
            } else if (data.data.user.roles[0] === "employee") {
              router("/EDashboard");
            }
          } else if (data.data.user.status === "approved") {
            dispatch({
              type: "nameSet",
              num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
            });
            dispatch({
              type: "imageSet",
              num: `${data.data.user.person.image}`,
            });
            dispatch({
              type: "tokenSet",
              num: `${data.data.tokens.access.token}`,
            });
            dispatch({ type: "setUserData", num: data });
            if (data.data.user.roles[0] === "pensioner") {
              router("/Dashboard");
            } else if (data.data.user.roles[0] === "employee") {
              router("/EDashboard");
            }
          } else {
            console.log("pppppppp");
            if (data.data.signupSteps.length === 0) {
              dispatch({ type: "RSET", num: 1 });
              dispatch({ type: "ISET", num: 2 });
            } else {
              personal = data.data.signupSteps.filter((item) => {
                return item.stepName === "personal";
              });
              address = data.data.signupSteps.filter((item) => {
                return item.stepName === "address";
              });
              if (option === "Employee") {
                info = data.data.signupSteps.filter((item) => {
                  return item.stepName === "employee-info";
                });
              } else {
                info = data.data.signupSteps.filter((item) => {
                  return item.stepName === "pensioner-info";
                });
              }
              identification = data.data.signupSteps.filter((item) => {
                return item.stepName === "identification";
              });
              console.log(personal, "ll");
              console.log(address, "ll");
              console.log(info, "ll");
              console.log(identification, "ll");
              if (
                personal.length != 0 &&
                address.length != 0 &&
                info.length != 0 &&
                identification.length != 0
              ) {
                if (data.data.user.applications === 0) {
                  dispatch({ type: "setUserData", num: data });

                  if (data.data.user.roles[0] === "pensioner") {
                    dispatch({ type: "data1Set", num: data.data.user.person });
                    dispatch({ type: "userDataSet", num: data.data.user });
                    dispatch({ type: "RSET", num: 1 });
                    dispatch({ type: "ISET", num: 19 });
                  }
                  // dispatch({ type: "data2Set", num: data });
                  dispatch({
                    type: "nameSet",
                    num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                  });
                  dispatch({
                    type: "imageSet",
                    num: `${data.data.user.person.image}`,
                  });
                  dispatch({
                    type: "tokenSet",
                    num: `${data.data.tokens.access.token}`,
                  });
                } else {
                  dispatch({ type: "setUserData", num: data });
                  if (data.data.user.roles[0] === "pensioner") {
                    router("/Dashboard");
                  } else if (data.data.user.roles[0] === "employee") {
                    router("/EDashboard");
                  }
                  dispatch({
                    type: "nameSet",
                    num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                  });
                  dispatch({
                    type: "imageSet",
                    num: `${data.data.user.person.image}`,
                  });
                  dispatch({
                    type: "tokenSet",
                    num: `${data.data.tokens.access.token}`,
                  });
                }
              } else if (personal.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 2 });
              } else if (
                address.length === 0 &&
                info.length === 0 &&
                identification.length === 0
              ) {
                console.log("hhh");
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 3 });
              } else if (
                info.length === 0 &&
                identification.length === 0 &&
                address.length != 0
              ) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 4 });
              } else if (
                identification.length === 0 &&
                info.length != 0 &&
                address != 0
              ) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 5 });
              }
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.message === "incorrect email or password") {
            setErr("Incorrect Phone or Password");
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
          setIsLoading(false);
        });
    }
  };
  const passShowHandler = () => {
    const wow = document.getElementById("Pass11");
    if (wow.type === "password") {
      wow.type = "text";
    } else {
      wow.type = "password";
    }
  };

  useEffect(() => {
    if (option === "Employee") {
      setGOption(false);
      setEOption(true);
      setPOption(false);
    }
  }, []);
  const logHandler = (e) => {
    setEmail("");
    setGid("");
    if (e === "Login with Email") {
      setEOption(true);
      setPOption(false);
      setGOption(false);
      setWord("Login with Phone");
      setWord1("Login with GID");
    } else if (e === "Login with Phone") {
      setPOption(true);
      setEOption(false);
      setGOption(false);
      setWord("Login with Email");
      setWord1("Login with GID");
    } else if (e === "Login with GID") {
      setGOption(true);
      setPOption(false);
      setEOption(false);
      setWord("Login with Phone");
      setWord1("Login with Email");
    }
  };
  return (
    <div>
      <div className="w-full">
        <div className="text-center sm:mt-[3rem] mt-[2rem] flex flex-col items-center">
          <h2 className="font-medium text-[1.6rem] text-[#2c5281] mb-1">
            Login
          </h2>
          <p className="sm:w-[60%] w-[80%] text-[#596F96] text-[0.9rem]">
            Use the approved credentials provided by the Ministry of Human
            Services and Social Security (MHSSS) to access information on Old
            Age Pension applications, status, and services via your account.
          </p>
        </div>
        <div className=" flex items-center justify-center sm:mt-[4rem] mt-[2rem]">
          <form onSubmit={submitHandler} className="mt-4 sm:w-[40%] w-[80%]">
            <div className={`mb-6 ${eOption ? "" : "hidden"}`}>
              <label
                htmlFor="input1"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Email
              </label>
              <input
                className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
                type="text"
                placeholder="Email"
                name="input1"
                value={email}
                required={eOption ? true : false}
                onChange={handleChange(setEmail)}
                id=""
              />
            </div>
            {/* <p
              className={`${
                option === "Employee" ? "hidden" : ""
              } text-center font-bold text-[#596F96]`}
            >
              OR
            </p> */}
            <div
              className={`${
                gOption && option === "Pensioner" ? "" : "hidden"
              } flex flex-col`}
            >
              <label
                htmlFor="input2"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                GID
              </label>
              <input
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                type="text"
                name="input2"
                placeholder="GID"
                autocomplete="off"
                id=""
                value={gid}
                required={gOption && option ? true : false}
                onChange={handleChange(setGid)}
              />
            </div>
            <div className={`${pOption ? "" : "hidden"}`}>
              <label
                htmlFor="input5"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Phone
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GY"
                // value={prompt}
                onChange={handleChange(setPrompt, "tt")}
                international
                countryCallingCodeEditable={false}
                required={option || pOption === "Employee" ? true : false}
                className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow .PhoneInputCountrySelect"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="input4"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Password
              </label>
              <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type="password"
                  placeholder="Password"
                  id="Pass11"
                  onChange={handleChange(setPassword)}
                  value={password}
                  required
                  className="w-[100%]"
                ></input>
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={passShowHandler}
                  src={eye}
                  alt=""
                />
              </div>
            </div>
            <div
              onClick={() => dispatch({ type: "BRSET", num: 3 })}
              className="flex justify-end hover:underline cursor-pointer"
            >
              <p className="text-[0.8rem] font-semibold">Forgot Password?</p>
            </div>
            <button
              type="submit"
              className="relative justify-center flex w-[100%] cursor-pointer mt-10 bg-[#57D38C] pt-2 pb-2 pl-[4rem] pr-[4rem] text-white rounded-xl"
            >
              <Spin
                style={{ display: `${isLoading ? "" : "none"}` }}
                indicator={antIcon}
              />
              <span className="ml-3">Login</span>
              <div
                className={`w-[100%] h-[100%] ${
                  isLoading ? "" : "hidden"
                } absolute top-0 bg-slate-400 opacity-40 rounded-2xl`}
              ></div>
            </button>
            <div className="flex items-center mt-4 sm:ml-14 ml-5 mb-4 w-[100%]">
              <div className="w-[36%] h-[1px] bg-[#adb4c5]"></div>
              <p className="ml-2 mr-2 text-[#adb4c5]">or</p>
              <div className="w-[36%] h-[1px] bg-[#adb4c5]"></div>
            </div>
            <button
              onClick={() => logHandler(word)}
              type="button"
              className="w-[100%] cursor-pointer border-[1px] font-medium text-[#57D38C] border-[#57D38C] pt-2 pb-2 pl-[4rem] pr-[4rem] rounded-xl"
            >
              {word}
            </button>
            <button
              onClick={() => logHandler(word1)}
              type="button"
              className={`${
                option === "Employee" ? "hidden" : ""
              } w-[100%] mt-4 cursor-pointer border-[1px] font-medium text-[#57D38C] border-[#57D38C] pt-2 pb-2 pl-[4rem] pr-[4rem] rounded-xl`}
            >
              {word1}
            </button>
          </form>
          <div
            className={`${
              show ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
          >
            <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
              <div
                className="absolute top-[-11%] left-[42%]"
                onClick={() => setShow(false)}
              >
                <img src={across} alt="" />
              </div>
              <p className="text-center mt-[3rem] mb-5">{err}</p>
              <button
                onClick={() => setShow(false)}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ww;
