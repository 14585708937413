import { Tabs, Input, DatePicker } from "antd";

import e1 from "../../../../imgs/e1.png";
import e2 from "../../../../imgs/e2.png";
import e3 from "../../../../imgs/e3.png";
import e4 from "../../../../imgs/e4.png";
import down from "../../../../imgs/downn.png";

import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";

import Paget from "./paget";
import { useDispatch, useSelector } from "react-redux";
let wow;
let set;
const Listingh = () => {
  const router = useNavigate();
  const [show, setShow] = useState(false);
  const [test, setTest] = useState(false);
  const gidFilter = useSelector((state) => state.gidFilter);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const items = [
    {
      key: "1",
      label: `All`,
      children: <Paget />,
    },
    {
      key: "2",
      label: `Active`,
      children: <Paget />,
    },
    {
      key: "3",
      label: `Pending`,
      children: <Paget />,
    },
  ];

  const showHandler = (check) => {
    if (check === "apply") {
      dispatch({ type: "phoneFilterSet", num: wow });
    } else if (check === "cancel") {
      wow = "";
      dispatch({ type: "phoneFilterSet", num: "" });
    }
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const changeHandler = (e) => {
    console.log(e.target.value);
    dispatch({ type: "gidFilterSet", num: e.target.value });
  };
  const change1Handler = (event, f) => {
    console.log(event, "ee", f);
    if (event === null) {
      wow = "";
    } else {
      set = event;
      wow = f;
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  };
  return (
    <div className="sm:p-10 sm:pl-[7rem] sm:pr-[7rem]">
      <div>
        <div className="relative flex justify-between items-center mt-4">
          <div
            onClick={() => router("/EDashboard")}
            className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
          >
            <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold sm:text-[1.8rem] sm:ml-0 ml-[2rem] text-[1.2rem]">
            Pensioners
          </h2>
          <button
            onClick={() => {
              dispatch({ type: "ISET", num: 0 });
              dispatch({ type: "PENSIONER" });
              router("/EDashboard/addPensioner");
            }}
            className={`${
              dataUser.data.user.status === "pending"
                ? "pointer-events-none"
                : ""
            } text-white bg-[#57D38C] pl-5 pr-5 pt-2 pb-2 rounded-lg `}
          >
            Create Pensioner
          </button>
        </div>
        <div className="sm:flex-row  flex-col sm:ml-0 flex ml-[4rem] w-full sm:justify-between mt-[3rem] mb-8">
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between sm:mb-0 mb-4">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Total Pensioners
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">8</p>
            </div>
            <div>
              <img className="w-[2.1rem] mt-4" src={e2} alt="" />
            </div>
          </div>
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between sm:mb-0 mb-4">
            <div>
              <h2 className="text-[0.73rem] font-semibold text-[#797979]">
                Active Pensioners
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">5</p>
            </div>
            <div>
              <img className="w-[2.2rem] mt-4" src={e3} alt="" />
            </div>
          </div>
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between sm:mb-0 mb-4">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Inactive Pensioners
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">4</p>
            </div>
            <div>
              <img className="w-[2rem] mt-5" src={e1} alt="" />
            </div>
          </div>
          {/* <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between sm:mb-0 mb-4">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Pension
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">$600</p>
            </div>
            <div>
              <img className="w-[2.5rem] mt-4" src={e4} alt="" />
            </div>
          </div> */}
        </div>
      </div>

      <div
        className={`${
          dataUser.data.user.status === "pending" ? "pointer-events-none" : ""
        } relative sm:mt-0 mt-[4rem]`}
      >
        <Tabs defaultActiveKey="1" items={items} />
        <div className="absolute sm:top-1 sm:right-0 top-[-2.5rem] w-[100%] sm:w-[55%] flex justify-between">
          <Input
            size="large"
            placeholder="Search by gid/email/lastName"
            prefix={<SearchOutlined />}
            onChange={changeHandler}
            className="Search"
            style={{ width: "100%" }}
          />
          <div
            onClick={showHandler}
            className="border w-[8rem] h-[2.2rem] rounded-lg ml-2 cursor-pointer flex justify-between items-center p-1 pl-2 pr-2"
          >
            <p>Sort By</p>
            <div>
              <img className="scale-[0.7]" src={down} alt="" />
            </div>
          </div>
          <div
            className={`${
              show ? "" : "hidden"
            } absolute right-[-10%] top-14 w-[18rem] h-[11.5rem] bg-white shadow-2xl rounded-xl p-4`}
          >
            <h2 className=" font-semibold text-[1.2rem] mb-2">Filters</h2>
            <div className="mt-5">
              <DatePicker
                value={wow !== "" ? set : null}
                onChange={(e, f) => change1Handler(e, f)}
              />
            </div>
            <div className="mt-4">
              <button
                onClick={() => showHandler("apply")}
                className={`lg:text-[1.1rem] bg-gradient-to-tr bg-[#57D38C] text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
              >
                Apply
              </button>
              <button
                onClick={() => showHandler("cancel")}
                className={`lg:text-[1.1rem] bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listingh;
