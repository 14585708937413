import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import pdf from "../../imgs/edit.png";
import bs1 from "../../imgs/bs1.png";
import bs3 from "../../imgs/bs3.png";
import cross from "../../imgs/cross.png";
import { Checkbox } from "antd";
import { Dropdown } from "antd";
import { useLocation } from "react-router-dom";

const AllDocs = () => {
  const location = useLocation();
  const dash = location.pathname;
  const dataUser = useSelector((state) => state.dataUser);
  const universalPenId = useSelector((state) => state.universalPenId);
  const [allDocs, setAllDocs] = useState([]);
  const [show1, setShow1] = useState(false);
  const [test, setTest] = useState(false);
  const [docName, setDocName] = useState("");
  const [docValue, setDocValue] = useState("");
  const [expDate, setExpDate] = useState("");
  const [issueDate, setIsseuDate] = useState("");
  const [imageFront, setImageFront] = useState("");
  const [imageBack, setImageBack] = useState("");
  const [chak, setChak] = useState(false);
  const [globIndex, setGlobIndex] = useState(0);

  useEffect(() => {
    if (dash === "/Dashboard/additionalDocs") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/official-docs/my-official-docs`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "qwqw");
          setAllDocs(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/official-docs?pensionerUserId=${universalPenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "qwqw");
          setAllDocs(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const items = [
    {
      label: "View ",
      key: "1",
      icon: <img style={{ width: "1.5rem" }} src={bs1} />,
    },
    {
      label: "Delete ",
      key: "4",
      icon: (
        <img style={{ width: "1.1rem", marginLeft: "0.28rem" }} src={bs3} />
      ),
    },
  ];

  const onClick = ({ key }) => {
    if (key === "1") {
      setShow1(true);
      setDocName(allDocs[globIndex].docIDName);
      setDocValue(allDocs[globIndex].docIDValue);
      setIsseuDate(allDocs[globIndex].issuedDate);
      setExpDate(allDocs[globIndex].expiryDate);
      setChak(allDocs[globIndex].isPrivate);
      if (allDocs[globIndex].officialdocimages.length != 0) {
        const wow = allDocs[globIndex].officialdocimages.filter((item) => {
          return item.type === "front image";
        });
        const wow1 = allDocs[globIndex].officialdocimages.filter((item) => {
          return item.type === "back image";
        });
        if (wow.length != 0) {
          const body3 = [wow[0].imageUrl];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body3,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkl22222222l");
              setImageFront(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (wow1.length != 0) {
          const body4 = [wow1[0].imageUrl];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body4,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "rrrrrr");
              setImageBack(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  };
  const onClick2 = (e) => {
    setGlobIndex(e);
  };

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [show1]);
  return (
    <div className="lg:pl-[12rem] lg:pr-[12rem] mb-[5rem]">
      <div>
        {allDocs.map((item, index) => {
          return (
            <div>
              <ul className="w-[85%] flex mt-[1rem] justify-between items-center rounded-md bg-[#E8E8E8] hover:bg-[#cbcbcb] pl-[1rem] pr-[1rem] pt-3 pb-3 mb-3">
                <div>
                  <img className="w-[4rem]" src={pdf} alt="" />
                </div>
                <a
                  className="hidden"
                  id="randomLink"
                  //   href={`${item.url}`}
                  target="_blank"
                >
                  Random Link
                </a>
                <div className="flex">
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold">
                    {item.docIDName}
                  </h2>
                  <li className="relative flex items-center ml-4">
                    <Dropdown
                      menu={{
                        items,
                        onClick,
                      }}
                      trigger={["click"]}
                      onClick={() => onClick2(index)}
                    >
                      <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                        <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                        <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                        <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                      </div>
                    </Dropdown>
                  </li>
                </div>
              </ul>
            </div>
          );
        })}
      </div>
      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[40rem] pb-6 pt-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-3 right-4 cursor-pointer"
            onClick={() => {
              setShow1(false);
              setDocName("");
              setDocValue("");
              setExpDate("");
              setIsseuDate("");
              setImageBack("");
              setImageFront("");
            }}
          >
            <img className="scale-[0.8]" src={cross} />
          </div>
          <div className="flex flex-col w-[80%]">
            <label
              htmlFor="input4"
              className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
            >
              Document Name
            </label>
            <input
              type="text"
              name=""
              value={docName}
              placeholder=" Document Name"
              className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
            />
          </div>
          <div className="flex flex-col w-[80%]">
            <label
              htmlFor="input4"
              className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
            >
              Document #
            </label>
            <input
              type="text"
              name=""
              value={docValue}
              placeholder="Document #"
              className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
            />
          </div>
          <div className="flex w-[80%]">
            <div className="flex flex-col w-[100%]">
              <label
                htmlFor="input4"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Issue Date
              </label>
              <input
                type="text"
                name=""
                value={issueDate}
                placeholder="Issue Date"
                className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
            </div>
            <div className="flex flex-col ml-8 w-[100%]">
              <label
                htmlFor="input4"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Expiry Date
              </label>
              <input
                type="text"
                name=""
                value={expDate}
                placeholder="Expiry Date"
                className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
            </div>
          </div>
          <div className="flex w-[80%]">
            <div className={`${imageFront === "" ? "hidden" : ""}`}>
              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Image Front
              </label>
              <img src={imageFront} className="w-[14.5rem] h-[7rem]"></img>
            </div>
            <div className={`ml-8 ${imageBack === "" ? "hidden" : ""}`}>
              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Image Back
              </label>
              <img src={imageBack} className="w-[14.5rem] h-[7rem]"></img>
            </div>
          </div>
          <div className="mt-4 w-[80%] flex justify-start">
            <Checkbox checked={chak} className={"checkk"}>
              For office use only
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllDocs;
