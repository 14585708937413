import { Tabs, Input } from "antd";

import e1 from "../../../../imgs/e1.png";
import e2 from "../../../../imgs/e2.png";
import e3 from "../../../../imgs/e3.png";
import e4 from "../../../../imgs/e4.png";
import down from "../../../../imgs/downn.png";

import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Pagea from "./pagea";
import { useState } from "react";
const Listinga = () => {
  const router = useNavigate();
  const [show, setShow] = useState(false);
  const items = [
    {
      key: "1",
      label: `All`,
      children: <Pagea />,
    },
    {
      key: "2",
      label: `Active`,
      children: <Pagea />,
    },
    {
      key: "3",
      label: `Pending`,
      children: <Pagea />,
    },
  ];
  const showHandler = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  return (
    <div className="sm:p-10 sm:pl-[7rem] sm:pr-[7rem] pl-4 pr-4">
      <div>
        <div className="relative flex justify-between items-center mt-[1rem] ">
          <div
            onClick={() => router("/EDashboard/Pensioner_HistoryP")}
            className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] "
          >
            <img className="sm:w-[1.7rem] w-[1.5rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold sm:text-[1.8rem] text-[1.2rem] ml-8">
            Applications
          </h2>
        </div>
        <div className="sm:flex-row flex flex-col sm:ml-0 ml-[4rem] w-full sm:justify-between mt-[3rem] mb-8">
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between sm:mb-0 mb-4">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Total Applications
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">8</p>
            </div>
            <div>
              <img className="w-[2.1rem] mt-4" src={e2} alt="" />
            </div>
          </div>
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between sm:mb-0 mb-4">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Active Applications
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">5</p>
            </div>
            <div>
              <img className="w-[2.2rem] mt-4" src={e3} alt="" />
            </div>
          </div>
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between sm:mb-0 mb-4">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Pending Applications
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">4</p>
            </div>
            <div>
              <img className="w-[2rem] mt-5" src={e1} alt="" />
            </div>
          </div>
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between sm:mb-0 mb-4">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Pension
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">$600</p>
            </div>
            <div>
              <img className="w-[2.5rem] mt-4" src={e4} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="relative sm:mt-0 mt-[4rem]">
        <Tabs defaultActiveKey="1" items={items} />
        <div className="absolute sm:top-1 sm:right-0 top-[-2.5rem] w-[100%] sm:w-[55%] flex justify-between">
          <Input
            size="large"
            placeholder="Search by name"
            prefix={<SearchOutlined />}
            className="Search"
            style={{ width: "100%" }}
          />
          <div
            onClick={showHandler}
            className="border w-[8rem] h-[2.2rem] rounded-lg ml-2 cursor-pointer flex justify-between items-center p-1 pl-2 pr-2"
          >
            <p>Sort By</p>
            <div>
              <img className="scale-[0.7]" src={down} alt="" />
            </div>
          </div>
          <div
            className={`${
              show ? "" : "hidden"
            } absolute right-[-10%] top-14 w-[18rem] h-[13rem] bg-white shadow-2xl rounded-xl p-2`}
          >
            <h2 className="text-center font-semibold text-[1.2rem] mb-2">
              Filters
            </h2>
            <select
              className="mb-2 bg-[#E8E8E8] w-[100%] rounded-lg pt-1 pb-1 pl-2 pr-5 cursor-pointer some-class"
              name=""
              defaultValue={"12"}
              id=""
            >
              <option value="12" disabled>
                Filter 1
              </option>
              <option value="">Program1</option>
              <option value="">Program2</option>
            </select>
            <select
              className="mb-2 bg-[#E8E8E8] w-[100%] rounded-lg pt-1 pb-1 pl-2 pr-5 cursor-pointer some-class"
              name=""
              defaultValue={"12"}
              id=""
            >
              <option value="12" disabled>
                Filter 2
              </option>
              <option value="">Program1</option>
              <option value="">Program2</option>
            </select>
            <select
              className="mb-2 bg-[#E8E8E8] w-[100%] rounded-lg pt-1 pb-1 pl-2 pr-5 cursor-pointer some-class"
              name=""
              defaultValue={"12"}
              id=""
            >
              <option value="12" disabled>
                Filter 3
              </option>
              <option value="">Program1</option>
              <option value="">Program2</option>
            </select>
            <select
              className="mb-2 bg-[#E8E8E8] w-[100%] rounded-lg pt-1 pb-1 pl-2 pr-5 cursor-pointer some-class"
              name=""
              defaultValue={"12"}
              id=""
            >
              <option value="12" disabled>
                Filter 3
              </option>
              <option value="">Program1</option>
              <option value="">Program2</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listinga;
