import { Select, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import across from "../../../../imgs/across.png";
import { LoadingOutlined } from "@ant-design/icons";
import plusp from "../../../../imgs/plusp.png";
import { Checkbox } from "antd";
let data;
let officeData;
let homeData;
let allAddresses = [
  {
    isCurrent: true,
    regionNo: "",
    regionName: "",
    address: "",
    type: "home",
    lot: "",
    city: "",
    ward: "",
  },
];
// let allAddressList = [
//   {
//     value: "Address 1",
//     label: "Address 1",
//   },
// ];
const Location1 = () => {
  const profileData = useSelector((state) => state.profileData);
  const universalPenId = useSelector((state) => state.universalPenId);
  const dataUser = useSelector((state) => state.dataUser);
  const benDone = useSelector((state) => state.benDone);
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const [regionNo, setRegionNo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [regionName, setRegionName] = useState("");
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [type, setType] = useState("home");
  const [lot, setLot] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState(null);
  const [ward, setWard] = useState(null);
  const [allRegions, setAllRegions] = useState("");
  const [allCities, setAllCities] = useState("");
  const [allWards, setAllWards] = useState("");
  const [initial, setInitial] = useState(true);
  const [initial1, setInitial1] = useState(true);
  const [initial2, setInitial2] = useState(true);
  const [initial4, setInitial4] = useState(true);
  const [test, setTest] = useState(false);
  const [showIndex, setShowIndex] = useState(0);
  const [status, setStatus] = useState("");
  const [roles, setRoles] = useState("");
  const [newAddress, setNewAddress] = useState(false);
  const [allAddressList, setAllAddressList] = useState("");
  const [allDis, setAllDis] = useState(false);

  useEffect(() => {
    return () => {
      allAddresses = [
        {
          isCurrent: true,
          regionNo: "",
          regionName: "",
          address: "",
          type: "home",
          lot: "",
          city: "",
          ward: "",
        },
      ];

      setAllAddressList("");
    };
  }, []);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data, regionNo, ward, city]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  useEffect(() => {
    let wow1;
    if (
      dash === "/EDashboard/PensionerAccount" ||
      dash === "/EDashboard/BeneficiaryAccount"
    ) {
      wow1 = `${process.env.REACT_APP_BASE_URL}/v1/addresses/users/${universalPenId}`;
      if (dash === "/EDashboard/BeneficiaryAccount") {
        wow1 = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/addresses?beneficiaryUserId=${universalPenId}`;
      }
    } else {
      wow1 = `${process.env.REACT_APP_BASE_URL}/v1/addresses`;
    }

    axios
      .get(wow1, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        console.log(data, "3333");
        let wow2 = "Yes";
        data.data.map((item, index) => {
          if (item.type === "office" && index === 0) {
            wow2 = "No";
          }
          console.log(allAddresses, index, "dedee");
          if (item.type === "home") {
            console.log(wow2, "222222222222222222222222");
            if (wow2 === "Yes") {
              allAddresses.push({
                isCurrent: true,
                regionNo: "",
                regionName: "",
                address: "",
                type: "home",
                lot: "",
                city: "",
                ward: "",
              });
              allAddresses[index].regionNo = item.regionNo;
              allAddresses[index].regionName = item.regionName;
              allAddresses[index].address = item.address;
              allAddresses[index].type = item.type;
              allAddresses[index].lot = item.lot;
              allAddresses[index].city = item.city;
              allAddresses[index].ward = item.ward;
              allAddresses[index].isCurrent = item.isCurrent;
              if (item.isCurrent) {
                setShowIndex(index);
              }
            } else if (wow2 === "No") {
              allAddresses.push({
                isCurrent: true,
                regionNo: "",
                regionName: "",
                address: "",
                type: "home",
                lot: "",
                city: "",
                ward: "",
              });
              allAddresses[index - 1].regionNo = item.regionNo;
              allAddresses[index - 1].regionName = item.regionName;
              allAddresses[index - 1].address = item.address;
              allAddresses[index - 1].type = item.type;
              allAddresses[index - 1].lot = item.lot;
              allAddresses[index - 1].city = item.city;
              allAddresses[index - 1].ward = item.ward;
              allAddresses[index - 1].isCurrent = item.isCurrent;
              if (item.isCurrent) {
                setShowIndex(index - 1);
              }
            }
          } else if (item.type === "office") {
            officeData = item;
          }
        });
        if (
          allAddresses[allAddresses.length - 1].regionNo === "" &&
          allAddresses[allAddresses.length - 1].regionName === ""
        ) {
          allAddresses.pop();
        }
        console.log(allAddresses, "999999999999", allAddressList);

        let wow = allAddresses.map((item, index) => {
          return {
            value: `Address ${index + 1}`,
            label: `Address ${index + 1}`,
          };
        });
        setAllAddressList(wow);
      })
      .catch((err) => console.log(err));
    if (profileData.person) {
      console.log(profileData, "dedqdqdq1111111111");
      if (profileData.person.addresses.length >= 1) {
        data = profileData.person.addresses;
        officeData = data.filter((item) => {
          return item.type === "office";
        });
        homeData = data.filter((item) => {
          return item.type === "home";
        });

        console.log(homeData, "wweeddssffgg");

        console.log("callacl", officeData, homeData);
      }
    }
  }, [profileData]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/gy-addresses`)
      .then((data) => {
        console.log(data.data.data, "get");
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllRegions(wow);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}`
        )
        .then((data) => {
          console.log(data.data.data, "get2");
          setCity(null);
          setAllCities("");
          setAllWards("");
          setRegionName("");
          setWard(null);
          setRegionName(data.data.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [regionNo]);
  useEffect(() => {
    if (initial1) {
      setInitial1(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}`
        )
        .then((data) => {
          console.log(data.data.data, "get3");
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllCities(wow);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [regionName]);
  useEffect(() => {
    if (initial2) {
      setInitial2(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}&city=${city}`
        )
        .then((data) => {
          console.log(data.data.data, "get3");
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllWards(wow);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [city]);

  const handleChange = (setChange, check) => (event) => {
    if (check === "tt" || check === "tn") {
      console.log(event);
      setChange(event);
    } else {
      console.log(event.target.value);
      setChange(event.target.value);
    }
  };

  const updateHandler = () => {
    setIsLoading(true);
    console.log(regionNo);
    console.log(regionName);
    console.log(ward);
    console.log(city);
    console.log(lot);
    console.log(address);
    if (
      dash === "/Dashboard/BeneficiaryAccount" ||
      dash === "/EDashboard/BeneficiaryAccount"
    ) {
      console.log(profileData, "11111111111111");
      let wow;
      if (dash === "/Dashboard/BeneficiaryAccount") {
        wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/addresses?beneficiaryUserId=${profileData.beneficiaryUser.id}`;
      } else {
        wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/addresses/update-by-staff?beneficiaryUserId=${profileData.beneficiaryUser.id}/update-by-staff`;
      }
      axios
        .put(
          wow,
          {
            addresses: [
              {
                isCurrent: true,
                regionNo,
                regionName,
                address,
                type: "home",
                lot,
                city,
                ward,
              },
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "eer");
          dispatch({ type: "RESET" });
          setIsLoading(false);
          allAddresses = [
            {
              isCurrent: false,
              regionNo: "",
              regionName: "",
              address: "",
              type: "home",
              lot: "",
              city: "",
              ward: "",
            },
          ];
          setAllAddressList("");
          setLot("");
          setRegionName("");
          setRegionNo("");
          setAddress("");
          setWard(null);
          setCity(null);
          setNewAddress(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      if (dash != "/EDashboard/PensionerAccount") {
        // allAddresses.map((item) => {
        //   item.isCurrent = false;
        // });
        // allAddresses.push({
        //   isCurrent: true,
        //   regionNo,
        //   regionName,
        //   address,
        //   type: "home",
        //   lot,
        //   city,
        //   ward,
        // });
        console.log(allAddresses, "qqaaq");
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/addresses`,
            [
              {
                isCurrent: true,
                regionNo,
                regionName,
                address,
                type: "home",
                lot,
                city,
                ward,
              },
            ],
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "eer");
            dispatch({ type: "RESET" });
            setIsLoading(false);
            allAddresses = [
              {
                isCurrent: false,
                regionNo: "",
                regionName: "",
                address: "",
                type: "home",
                lot: "",
                city: "",
                ward: "",
              },
            ];
            setAllAddressList("");
            setLot("");
            setRegionName("");
            setRegionNo("");
            setAddress("");
            setWard(null);
            setCity(null);
            setNewAddress(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/addresses/update-by-staff?userId=${profileData.user.id}`,
            {
              addresses: [
                {
                  isCurrent: true,
                  regionNo,
                  regionName,
                  address,
                  type: "home",
                  lot,
                  city,
                  ward,
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "eer");
            dispatch({ type: "RESET" });
            setIsLoading(false);
            allAddresses = [
              {
                isCurrent: false,
                regionNo: "",
                regionName: "",
                address: "",
                type: "home",
                lot: "",
                city: "",
                ward: "",
              },
            ];
            setAllAddressList("");
            setLot("");
            setRegionName("");
            setRegionNo("");
            setAddress("");
            setWard(null);
            setCity(null);
            setNewAddress(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      }
    }
  };

  const addressHandler = () => {
    if (!newAddress) {
      setNewAddress(true);
    } else {
      setNewAddress(false);
    }
  };

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [allAddressList]);

  useEffect(() => {
    if (dash === "/Dashboard/PensionerAccount") {
      if (dataUser.data.user.status === "approved") {
        setAllDis(false);
      } else {
        setAllDis(true);
      }
    } else if (dash === "/EDashboard/Account") {
      if (dataUser.data.user.status === "approved") {
        setAllDis(false);
      } else {
        setAllDis(true);
      }
    } else if (dash === "/EDashboard/PensionerAccount") {
      if (profileData.user.isDisabled) {
        setAllDis(true);
      }
    }

    if (benDone === "Done") {
      setStatus("Ben");
      setRoles("Ben");
    } else {
      setStatus(profileData.user.status);
      setRoles(profileData.user.roles[0]);
    }
  });
  const addChangeHandler = (e) => {
    console.log(e[8]);
    setShowIndex(Number(e[8]) - 1);
  };
  return (
    <div>
      {console.log(data, "eee")}
      {allAddresses[showIndex] && allAddressList && (
        <div>
          <div className="flex justify-start">
            <Select
              placeholder="Addresses"
              optionFilterProp="children"
              className="wow"
              style={{
                marginBottom: "1.2rem",
                width: "30%",
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              defaultValue={allAddressList[showIndex]}
              onChange={addChangeHandler}
              // value={allAddressList[showIndex]}
              options={allAddressList}
            />
          </div>
          <h2 className="mb-4 text-center text-[1.5rem] font-bold mt-4">
            {console.log(profileData, "2wwdfewds")}
            {roles === "Employee" ? "Home Address" : "Address"}
          </h2>
          <div
            className={`${
              dash === "/Dashboard/PensionerAccount" ? "hidden" : ""
            }`}
          >
            <h2 className="mb-4 text-[1.1rem] font-semibold">Type:</h2>
            <Select
              placeholder="Type"
              optionFilterProp="children"
              className="wow"
              style={{ marginBottom: "1.2rem", pointerEvents: "none" }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              value={{
                value: `${allAddresses[showIndex].type}`,
                label: `${allAddresses[showIndex].type}`,
              }}
              options={[
                {
                  value: "home",
                  label: "Home",
                },
              ]}
            />
          </div>
          <h2 className="mb-4 text-[1.1rem] font-semibold">Lot #:</h2>
          <input
            type="text"
            name=""
            id=""
            placeholder="Lot #"
            value={allAddresses[showIndex].lot}
            className={`pointer-events-none lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Address:</h2>
          <textarea
            type="text"
            name=""
            id=""
            placeholder="Address"
            value={allAddresses[showIndex].address}
            className={`pointer-events-none lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />

          <h2 className="mb-4 text-[1.1rem] font-semibold">Region No:</h2>

          <Select
            placeholder="Region No"
            optionFilterProp="children"
            className="wow"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `none`,
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            value={{
              value: `${allAddresses[showIndex].regionNo}`,
              label: `${allAddresses[showIndex].regionNo}`,
            }}
            options={allRegions}
          />

          <h2 className="mb-4 text-[1.1rem] font-semibold"> Region Name:</h2>

          <input
            type="text"
            name=""
            value={allAddresses[showIndex].regionName}
            placeholder="Region Name"
            className="lg:text-[1.1rem] w-[100%] bg-white pointer-events-none border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />

          <h2 className="mb-4 text-[1.1rem] font-semibold">Town/City:</h2>
          {console.log(city, "222222", ward)}
          <Select
            placeholder="Town/City"
            optionFilterProp="children"
            className="wow"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `none`,
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            value={{
              value: `${allAddresses[showIndex].city}`,
              label: `${allAddresses[showIndex].city}`,
            }}
            size="middle"
            options={allCities}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Ward/Village:</h2>
          <Select
            placeholder="Ward/Village"
            optionFilterProp="children"
            className="wow"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `none`,
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            value={{
              value: `${allAddresses[showIndex].ward}`,
              label: `${allAddresses[showIndex].ward}`,
            }}
            options={allWards}
          />
          <div className="ml-4">
            {console.log(allAddresses[showIndex], "vvvimp")}
            <Checkbox
              checked={allAddresses[showIndex].isCurrent}
              className={"checkk"}
              style={{ pointerEvents: "none" }}
            >
              Is current address?
            </Checkbox>
          </div>
        </div>
      )}
      <div
        className={`${
          allDis ? "pointer-events-none" : ""
        } flex justify-center items-center mt-4`}
        onClick={addressHandler}
      >
        <div className="flex justify-center items-center cursor-pointer">
          <div>
            <img
              className={`w-[1.2rem] h-[1.2rem] mr-2 ${
                newAddress ? "hidden" : ""
              }`}
              src={plusp}
              alt=""
            />
          </div>
          <p
            className={`${
              newAddress ? "text-[red] underline" : "text-[#18B871]"
            }`}
          >
            {newAddress ? "Cancel" : "Add Address"}
          </p>
        </div>
      </div>
      <div className={`${newAddress ? "" : "hidden"}`}>
        <h2 className="mb-4 text-center text-[1.5rem] font-bold mt-4">
          New Address
        </h2>
        <div
          className={`${
            dash === "/Dashboard/PensionerAccount" ? "hidden" : ""
          }`}
        >
          <h2 className="mb-4 text-[1.1rem] font-semibold">Type:</h2>
          <Select
            placeholder="Type"
            optionFilterProp="children"
            className="wow"
            style={{ marginBottom: "1.2rem" }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            value={{
              value: `${type}`,
              label: `${type}`,
            }}
            options={[
              {
                value: "home",
                label: "Home",
              },
            ]}
          />
        </div>
        <h2 className="mb-4 text-[1.1rem] font-semibold">Lot #:</h2>
        <input
          type="text"
          name=""
          id=""
          placeholder="Lot #"
          onChange={handleChange(setLot)}
          value={lot}
          className={` lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">Address:</h2>
        <textarea
          type="text"
          name=""
          id=""
          placeholder="Address"
          onChange={handleChange(setAddress)}
          value={address}
          className={` lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        />

        <h2 className="mb-4 text-[1.1rem] font-semibold">Region No:</h2>

        <Select
          placeholder="Region No"
          optionFilterProp="children"
          className="wow"
          style={{
            marginBottom: "1.2rem",
            // pointerEvents: `none`,
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          onChange={handleChange(setRegionNo, "tn")}
          // defaultValue={{
          //   value: `${regionNo}`,
          //   label: `${regionNo}`,
          // }}
          options={allRegions}
        />

        <h2 className="mb-4 text-[1.1rem] font-semibold"> Region Name:</h2>

        <input
          type="text"
          name=""
          onChange={handleChange(setRegionName, "tt")}
          value={regionName}
          placeholder="Region Name"
          className="lg:text-[1.1rem] w-[100%] bg-white pointer-events-none border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />

        <h2 className="mb-4 text-[1.1rem] font-semibold">Town/City:</h2>

        <Select
          placeholder="Town/City"
          optionFilterProp="children"
          className="wow"
          style={{
            marginBottom: "1.2rem",
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onChange={handleChange(setCity, "tt")}
          value={city}
          size="middle"
          options={allCities}
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">Ward/Village:</h2>
        <Select
          placeholder="Ward/Village"
          optionFilterProp="children"
          className="wow"
          style={{
            marginBottom: "1.2rem",
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          value={ward}
          onChange={handleChange(setWard, "tt")}
          options={allWards}
        />
      </div>
      {officeData && (
        <div>
          {officeData.length != 0 && (
            <div>
              <h2 className="mb-4 mt-[3rem] text-center text-[1.5rem] font-bold">
                Office Address
              </h2>
              <h2 className="mb-4 text-[1.1rem] font-semibold">Type:</h2>
              <Select
                placeholder="Type"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.2rem", pointerEvents: "none" }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                value={{
                  value: `${officeData.type}`,
                  label: `${officeData.type}`,
                }}
                options={[
                  {
                    value: "home",
                    label: "Home",
                  },
                ]}
              />
              <h2 className="mb-4 text-[1.1rem] font-semibold">Lot #:</h2>
              <input
                type="text"
                name=""
                id=""
                placeholder="Lot #"
                onChange={handleChange(setLot)}
                value={officeData.lot}
                className={`pointer-events-none lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
              />
              <h2 className="mb-4 text-[1.1rem] font-semibold">Address:</h2>
              <textarea
                type="text"
                name=""
                id=""
                placeholder="Address"
                onChange={handleChange(setAddress)}
                value={officeData.address}
                className={`pointer-events-none lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
              />

              <h2 className="mb-4 text-[1.1rem] font-semibold">Region No:</h2>
              <Select
                placeholder="Region No"
                optionFilterProp="children"
                className="wow"
                style={{
                  marginBottom: "1.2rem",
                  pointerEvents: "none",
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                onChange={handleChange(setRegionNo, "tn")}
                defaultValue={{
                  value: `Region ${officeData.regionNo}`,
                  label: `Region ${officeData.regionNo}`,
                }}
                options={allRegions}
              />
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                {" "}
                Region Name:
              </h2>

              <input
                type="text"
                name=""
                onChange={handleChange(setRegionName, "tt")}
                value={officeData.regionName}
                placeholder="Region Name"
                className="lg:text-[1.1rem] w-[100%] bg-white pointer-events-none border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />

              <h2 className="mb-4 text-[1.1rem] font-semibold">Town/City:</h2>
              <Select
                placeholder="Town/City"
                optionFilterProp="children"
                className="wow"
                style={{
                  marginBottom: "1.2rem",
                  pointerEvents: "none",
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={handleChange(setCity, "tt")}
                value={officeData.city}
                size="middle"
                options={allCities}
              />
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Ward/Village:
              </h2>
              <Select
                placeholder="Ward/Village"
                optionFilterProp="children"
                className="wow"
                style={{
                  marginBottom: "1.2rem",
                  pointerEvents: "none",
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                value={officeData.ward}
                onChange={handleChange(setWard, "tt")}
                options={allWards}
              />
            </div>
          )}
        </div>
      )}

      <div
        className={`${
          dataUser.data.user.roles[0] === "employee" &&
          dataUser.data.user.status === "pending"
            ? "hidden"
            : ""
        }`}
      >
        <button
          onClick={updateHandler}
          className={`${
            status === "pending" && dash === "/Dashboard/PensionerAccount"
              ? "hidden"
              : ""
          } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          <Spin
            style={{ display: `${isLoading ? "" : "none"}` }}
            indicator={antIcon}
          />
          <span className="ml-3">Save</span>
          <div
            className={`w-[100%] h-[100%] ${
              isLoading ? "" : "hidden"
            } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
          ></div>
        </button>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Location1;
