import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Pensioner from "../../imgs/pensioner.png";
import Employee from "../../imgs/employee.png";
import Merchant from "../../imgs/merchant.png";

import events from "../../imgs/events.png";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import axios from "axios";
import eye from "../../imgs/eyee.png";
import across from "../../imgs/across.png";
import Ww from "./ww";
import Register from "../Register/Register";
import FirstR from "../Register/stepsR/FirstR";
import ForgotPassword from "../Forgot/ForgotPassword";
import Ww2 from "./ww2";

let Show;
const Login = () => {
  const dispatch = useDispatch();
  const option = useSelector((state) => state.option);
  const router = useNavigate();
  const brcurrent = useSelector((state) => state.brcurrent);

  const [prompt, setPrompt] = useState("");
  const [email, setEmail] = useState("");
  const [gid, setGid] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState("");
  const [err, setErr] = useState("");

  Show = [Ww2, FirstR, Ww, ForgotPassword];

  const Dis = Show[brcurrent];
  const takeHandler = () => {};

  const passShowHandler = () => {
    const wow = document.getElementById("Pass11");
    if (wow.type === "password") {
      wow.type = "text";
    } else {
      wow.type = "password";
    }
  };
  return (
    // bg-[#57D38C]
    <div>
      <div
        className={` ${
          brcurrent === 3 ? "hidden" : ""
        } w-[100%] sm:h-[3.8rem] h-[3rem] flex bg-[#EDEFFD] text-[0.95rem] rounded-tl-2xl rounded-tr-2xl`}
      >
        <div
          onClick={() => dispatch({ type: "BRSET", num: 0 })}
          className={`cursor-pointer ${
            brcurrent === 0 || brcurrent === 2
              ? " bg-gradient-to-tr bg-[#57D38C]"
              : ""
          } flex rounded-tl-[2.5rem] w-[50%] items-center justify-center rounded-tr-[5rem] rounded-br-[5rem]`}
        >
          <div
            className={`w-5 h-5 ${
              brcurrent === 0 || brcurrent === 2
                ? "bg-white text-[lightblue]"
                : "bg-[#BDC7E8] text-white"
            }  rounded-full flex justify-center items-center`}
          >
            <p className=" text-[0.7rem]">1</p>
          </div>
          <p
            className={`ml-2 ${
              brcurrent === 0 || brcurrent === 2
                ? "text-white"
                : "text-[#BDC7E8]"
            } `}
          >
            Login
          </p>
        </div>
        <div
          onClick={() => dispatch({ type: "BRSET", num: 1 })}
          className={` ${
            brcurrent === 1
              ? "bg-gradient-to-tr bg-[#57D38C] rounded-bl-[5rem] rounded-tl-[5rem]"
              : ""
          } cursor-pointer rounded-tr-[2.6rem] flex w-[50%] items-center justify-center`}
        >
          <div
            className={`w-5 h-5 ${
              brcurrent === 1
                ? "bg-white text-[lightblue]"
                : "bg-[#BDC7E8] text-white"
            } rounded-full flex justify-center items-center`}
          >
            <p className="text-[0.7rem]">2</p>
          </div>
          <p
            className={`${
              brcurrent === 1 ? "text-white" : "text-[#BDC7E8"
            } ml-2 `}
          >
            Register
          </p>
        </div>
      </div>
      <div
        className={`${
          brcurrent === 3 ? "" : "hidden"
        } w-[100%] h-[3.8rem] flex bg-[#EDEFFD] text-[0.95rem] rounded-tl-2xl rounded-tr-2xl`}
      >
        <div
          onClick={() => dispatch({ type: "BRSET", num: 0 })}
          className={`cursor-pointer ${
            brcurrent === 0 || brcurrent === 2
              ? " bg-gradient-to-tr bg-[#57D38C]"
              : ""
          } flex rounded-tl-[2.5rem] w-[50%] items-center justify-center rounded-tr-[5rem] rounded-br-[5rem]`}
        >
          <div
            className={`w-5 h-5 ${
              brcurrent === 0 || brcurrent === 2
                ? "bg-white text-[lightblue]"
                : "bg-[#BDC7E8] text-white"
            }  rounded-full flex justify-center items-center`}
          >
            <p className=" text-[0.7rem]">1</p>
          </div>
          <p
            className={`ml-2 ${
              brcurrent === 0 || brcurrent === 2
                ? "text-white"
                : "text-[#BDC7E8]"
            } `}
          >
            Login
          </p>
        </div>
        <div
          onClick={() => dispatch({ type: "BRSET", num: 1 })}
          className={` ${
            brcurrent === 3
              ? "bg-gradient-to-tr bg-[#57D38C] rounded-bl-[5rem] rounded-tl-[5rem]"
              : ""
          } cursor-pointer rounded-tr-[2.6rem] flex w-[50%] items-center justify-center`}
        >
          <div
            className={`w-5 h-5 ${
              brcurrent === 3
                ? "bg-white text-[lightblue]"
                : "bg-[#BDC7E8] text-white"
            } rounded-full flex justify-center items-center`}
          >
            <p className="text-[0.7rem]">2</p>
          </div>
          <p
            className={`${
              brcurrent === 3 ? "text-white" : "text-[#BDC7E8"
            } ml-2 `}
          >
            Reset Password
          </p>
        </div>
      </div>
      <Dis />
    </div>
  );
};

export default Login;
