import Bregistration from "./parts/Bregistration";
import Bidentification from "./parts/Bidentification";
import profile from "../../imgs/profile.png";
import profile1 from "../../imgs/prof.png";
import pdf from "../../imgs/pdff.png";
import { useState } from "react";
import back5 from "../../imgs/back5.png";
import { useLocation, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
const Baccount = () => {
  const router = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const benDone = useSelector((state) => state.benDone);
  const universalPenId = useSelector((state) => state.universalPenId);
  const penId = useSelector((state) => state.penId);
  const reset = useSelector((state) => state.reset);
  const token = useSelector((state) => state.token);
  const dash = location.pathname;
  const [wow, setWow] = useState(0);
  const Show = [Bregistration, Bidentification];
  const Dis = Show[wow];

  useEffect(() => {
    return () => {
      dispatch({ type: "benDone", num: "" });
    };
  }, []);
  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  console.log(benDone, "dwwwwwwwwww");

  useEffect(() => {
    console.log(universalPenId, "111111111111");

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/users/profile?beneficiaryUserId=${universalPenId}&programId=${penId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "ben");
        dispatch({ type: "setProfileData", num: data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [universalPenId, reset]);
  const items1 = [
    {
      label: "June 28th, 2023",
      key: "5",
      icon: <DownloadOutlined className="some" />,
    },
    {
      label: "June 24th, 2023",
      key: "6",
      icon: <DownloadOutlined className="some" />,
    },
    {
      label: "April 5th, 2022",
      key: "7",
      icon: <DownloadOutlined className="some" />,
    },
  ];
  return (
    <div className="pt-[5rem] pb-[5rem] sm:pl-[12rem] sm:pr-[12rem] pr-2 pl-2">
      <div className="relative flex items-center mb-[3rem] justify-between">
        <div
          onClick={() => {
            if (dash === "/EDashboard/BeneficiaryAccount") {
              router("/EDashboard/listing");
            } else {
              router("/Dashboard/listing");
            }
          }}
          className="absolute top-[1] cursor-pointer sm:left-[-4%] left-1"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <div className="font-bold sm:text-[1.8rem] text-[1.2rem] sm:ml-0 ml-[2rem]">
          Edit Beneficiary
        </div>
        <div className="flex">
          <Dropdown
            menu={{
              items: items1,
            }}
            placement="bottomRight"
            arrow
          >
            <div className="mr-4 cursor-pointer">
              <img className="w-[2.5rem] h-[2.5rem]" src={pdf} alt="" />
            </div>
          </Dropdown>
          <button
            onClick={() => router("/EDashboard/PensionerAccount")}
            className={`text-white bg-[#57D38C] pl-5 pr-5 pt-2 pb-2 rounded-lg ${
              dash === "/Dashboard/BeneficiaryAccount" ? "hidden" : ""
            } `}
          >
            Pensioner Account
          </button>
        </div>
      </div>
      <div className="lg:mb-[4rem] flex mb-[2rem]">
        <div className="cursor-pointer" onClick={() => changeHandler("R")}>
          <h2
            className={`lg:text-[1.1rem] ${wow === 0 ? "font-semibold" : ""} `}
          >
            Registration Details
          </h2>
          <div
            className={`lg:w-[9.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
              wow === 0 ? "bg-[#18B871]" : "bg-[#E8E8E8]"
            } `}
          ></div>
        </div>

        <div className="cursor-pointer" onClick={() => changeHandler("I")}>
          <h2
            className={`lg:text-[1.1rem] ml-6 ${
              wow === 1 ? "font-semibold" : ""
            } `}
          >
            Identification Details
          </h2>
          <div
            className={`lg:w-[9.5] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${
              wow === 1 ? "bg-[#18B871]" : "bg-[#E8E8E8]"
            }  `}
          ></div>
        </div>
      </div>
      <div>
        <Dis />
      </div>
    </div>
  );
};

export default Baccount;
