import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
let data;
const LoginCredentials = () => {
  useEffect(() => {
    return () => {
      data = null;
    };
  }, []);
  const [test, setTest] = useState(false);
  const profileData = useSelector((state) => state.profileData);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data]);
  useEffect(() => {
    if (profileData) {
      if (profileData.beneficiaryUser != null) {
        console.log("called", profileData);
        data = profileData.beneficiaryUser;
        if (!test) {
          setTest(true);
        } else {
          setTest(false);
        }
      }
    }
  }, [profileData]);
  console.log(profileData, "2222222222222222222222", data);
  return (
    <div>
      {data && (
        <div>
          <h2 className="mb-4 text-center text-[1.5rem] font-bold">
            Login Credentials
          </h2>
          {console.log(profileData, "eee", data)}
          <h2 className="mb-4 text-[1.1rem] font-semibold">Relationship:</h2>
          {profileData.beneficiaryInfo && (
            <input
              type="text"
              name=""
              id=""
              placeholder="Relationship"
              value={profileData.beneficiaryInfo.relationship}
              className={`lg:mb-5  lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
            />
          )}
          <h2 className="mb-4 text-[1.1rem] font-semibold">Mobile:</h2>
          <input
            type="text"
            name=""
            id=""
            placeholder="Mobile"
            value={`${
              data.phoneNo === null ? "" : `${data.countryCode} ${data.phoneNo}`
            } `}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Email:</h2>
          <input
            type="text"
            name=""
            id=""
            placeholder="Email"
            value={data.email}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">GID:</h2>
          <input
            type="text"
            name=""
            id=""
            placeholder="GID"
            value={data.gid}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          {/* <h2 className="mb-4 text-[1.1rem] font-semibold">Password:</h2>
          <input
            type="text"
            name=""
            id=""
            placeholder="Password"
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          /> */}
        </div>
      )}
    </div>
  );
};

export default LoginCredentials;
