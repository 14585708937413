import prof from "../../../imgs/prof.png";
import { Dropdown, Pagination, Popover } from "antd";
import bs1 from "../../../imgs/bs1.png";
import bs2 from "../../../imgs/bs2.png";
import bs3 from "../../../imgs/bs3.png";
import bs4 from "../../../imgs/change.png";
import { useDispatch, useSelector } from "react-redux";
import def from "../../../imgs/default.png";
import def1 from "../../../imgs/default1.png";
import def2 from "../../../imgs/default2.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import across from "../../../imgs/across.png";
import { Switch } from "antd";
const Page = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const dataUser = useSelector((state) => state.dataUser);
  const allBenId = useSelector((state) => state.allBenId);
  const pensionerName = useSelector((state) => state.pensionerName);
  const penId = useSelector((state) => state.penId);
  const reset = useSelector((state) => state.reset);
  const [allB, setAllb] = useState();
  const [buffer, setBuffer] = useState("");
  const [buffer1, setBuffer1] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showx, setShowx] = useState(false);
  const [err, setErr] = useState("");
  const [able, setAble] = useState(false);
  const [dis, setDis] = useState();
  const [test, setTest] = useState(false);
  const router = useNavigate();
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }

  const items = [
    {
      label: "View ",
      key: "1",
      icon: <img style={{ width: "1.5rem" }} src={bs1} />,
    },
    {
      label: "Change Status",
      key: "2",
      icon: <img style={{ width: "1.2rem", marginLeft: "0.1rem" }} src={bs4} />,
    },
    {
      label: "Change Default",
      key: "5",
      icon: (
        <img style={{ width: "1.2rem", marginLeft: "0.1rem" }} src={def2} />
      ),
    },
    {
      label: "Edit ",
      key: "3",
      icon: <img style={{ width: "1.1rem", marginLeft: "0.3rem" }} src={bs2} />,
    },
    {
      label: "Delete ",
      key: "4",
      icon: (
        <img style={{ width: "1.1rem", marginLeft: "0.28rem" }} src={bs3} />
      ),
    },
  ];

  const onClick = ({ key }) => {
    if (key === "3") {
      dispatch({ type: "benDone", num: "Done" });
      dispatch({ type: "penId", num: buffer1 });
      dispatch({ type: "universalPenSet", num: buffer });
      dispatch({ type: "whichViewSet", num: "beneficiary" });
      if (location.pathname === "/EDashboard/listing") {
        router("/EDashboard/BeneficiaryAccount");
      } else if (location.pathname === "/Dashboard/listing") {
        router("/Dashboard/BeneficiaryAccount");
      }
    } else if (key === "1") {
      dispatch({ type: "benDone", num: "Done" });
      dispatch({ type: "penId", num: buffer1 });
      dispatch({ type: "universalPenSet", num: buffer });
      dispatch({ type: "whichViewSet", num: "beneficiary" });
      if (location.pathname === "/EDashboard/PensionL") {
        router("/EDashboard/cardView");
      } else if (location.pathname === "/Dashboard/listing") {
        router("/Dashboard/cardView");
      } else if (dash === "/EDashboard/listing") {
        router("/EDashboard/cardView");
      }
    } else if (key === "2") {
      setShow(true);
    } else if (key === "5") {
      setShow1(true);
    }
  };

  const onClick2 = (e, pen, aa, tt) => {
    setBuffer(e);
    setBuffer1(pen);
    setAble(aa);
    setDis(tt);
  };

  useEffect(() => {
    if (dash === "/EDashboard/listing") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/list-by-staff?pensionerUserId=${allBenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "allB");
          setAllb(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/list-by-pensioner`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "allB");
          setAllb(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [reset]);

  // overflow-x-scroll block whitespace-nowrap min-w-[42rem]

  const ableHandler = (e) => {
    console.log(e);
    let wow;
    let body;
    if (dash === "/Dashboard/listing") {
      body = {
        programId: penId,
        isActive: e,
      };
      wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/change-status?beneficiaryUserId=${buffer}`;
    } else {
      body = {
        statusInfo: {
          programId: penId,
          isActive: e,
        },
      };
      wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/change-status/by-staff?beneficiaryUserId=${buffer}`;
    }
    axios
      .patch(wow, body, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        console.log(data, "wqwqw");
        setAble(e);
        dispatch({ type: "RESET" });
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (test) {
      setTest(false);
    } else {
      setTest(true);
    }
  }, [able, dis]);
  return (
    <div>
      <div className="w-[100%] overflow-x-auto">
        <ul className="grid  grid-cols-[4.5rem_5rem_9.5rem_9.5rem_8rem_5rem_8.5rem_7rem_7rem_4rem] pl-[1rem] mb-2 mt-5">
          <li className="font-semibold mr-2">Line No.</li>
          <li className="font-semibold">ID</li>
          <li className="font-semibold">Beneficiary Name</li>
          <li className="font-semibold">Relationship</li>
          <li className="font-semibold">Pensioner</li>
          <li className="font-semibold">GID</li>
          <li className="font-semibold">Monthly Transfer</li>
          <li className="font-semibold">Status</li>
          <li className="font-semibold">Created</li>
          <li className="font-semibold">Action</li>
        </ul>
        {console.log(allB, "dedwfsasad")}
        {allB && (
          <div>
            {/* 2xl:grid-cols-[8%_6%_15%_13%_13%_6%_14%_10%_10%_10%] */}
            {allB.map((item, index) => {
              return (
                <ul
                  className={`${
                    item.relationship === "Me" ? "hidden" : ""
                  } relative rounded-md grid content-center  grid-cols-[4rem_5rem_9.5rem_9.5rem_8rem_6rem_8rem_7rem_7rem_4rem] w-fit  bg-[#E8E8E8] hover:bg-[#cbcbcb] pl-[1rem] pt-2 pb-2 mb-3`}
                >
                  <li className="relative  flex items-center">
                    <p className="ml-1">{index + 1}.</p>
                    <p
                      className={`absolute right-[0] mr-2  font-semibold rounded-lg   ${
                        item.isDefault ? "" : "hidden"
                      } cursor-pointer`}
                    >
                      <Popover
                        content="Default Beneficiary"
                        placement="bottomLeft"
                      >
                        <img
                          src={def2}
                          className="w-[1.7rem] h-[1.7rem]"
                          alt=""
                        />
                      </Popover>
                    </p>
                  </li>
                  <Popover content={item.id} placement="bottomLeft">
                    <li className="relative flex items-center ml-2">
                      <p>{item.id.substring(0, 5)}..</p>
                    </li>
                  </Popover>

                  <li className="flex items-center">
                    {item.beneficiaryuser && (
                      <div>
                        <p className="ml-2">
                          {item.beneficiaryuser.beneficiaryperson != null
                            ? `${item.beneficiaryuser.beneficiaryperson.firstName} ${item.beneficiaryuser.beneficiaryperson.lastName}`
                            : "---"}
                        </p>
                      </div>
                    )}
                  </li>
                  <li className="flex items-center">
                    <div className="border-none text-[#F7542E] pl-4 bg-[white] p-1  pr-5 rounded-md font-semibold focus:border-none cursor-pointer">
                      {item.relationship}
                    </div>
                  </li>

                  <li className="flex items-center ml-1">
                    {dash === "/EDashboard/listing"
                      ? `${pensionerName}`
                      : `${dataUser.data.user.person.firstName} ${dataUser.data.user.person.lastName}`}
                  </li>

                  {item.beneficiaryuser && (
                    <div>
                      <li className="font-semibold ml-2">
                        {item.beneficiaryuser.gid}
                      </li>
                    </div>
                  )}
                  <li className="flex items-center ml-1">
                    {item.percentage === null
                      ? item.amount === null
                        ? "---"
                        : `$${item.amount}`
                      : `${item.percentage}%`}
                  </li>
                  <li className="flex items-center">
                    <Popover
                      content={`${index === 3 ? "Deceased" : ""}`}
                      placement="bottomLeft"
                    >
                      {item.beneficiaryuser && (
                        <div>
                          <div
                            className={`border-none ${
                              item.beneficiaryuser.status === "pending" &&
                              item.isActive
                                ? "bg-[#2F84F1]"
                                : !item.isActive
                                ? "bg-[#D64242]"
                                : " bg-[#57D38C]"
                            }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer`}
                          >
                            {!item.isActive ? "Disabled" : "Active"}
                          </div>
                        </div>
                      )}
                    </Popover>
                  </li>
                  <li className="flex items-center">June 8, 2023</li>
                  <li className={`relative flex items-center`}>
                    <Dropdown
                      menu={{
                        items,
                        onClick,
                      }}
                      trigger={["click"]}
                      onClick={() =>
                        onClick2(
                          item.beneficiaryuserId,
                          item.pensionprogram.id,
                          item.isActive,
                          item.isDefault
                        )
                      }
                    >
                      <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                        <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                        <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                        <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                      </div>
                    </Dropdown>
                  </li>
                </ul>
              );
            })}
          </div>
        )}
      </div>
      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <p className="text-center text-[1.1rem] mt-[1rem] mb-5">
            {dis
              ? "Are you sure do you want to unmark this beneficiary as Default?"
              : "Are you sure do you want to mark this beneficiary as Default?"}
          </p>
          <div className="flex">
            <button
              onClick={() => setShow1(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                console.log(dis);
                const wow = !dis;
                let Url;
                if (dash === "/EDashboard/listing") {
                  Url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/default/toggle/by-staff?beneficiaryUserId=${buffer}`;
                } else {
                  Url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/default/toggle?beneficiaryUserId=${buffer}`;
                }

                axios
                  .post(
                    Url,
                    {
                      programId: buffer1,
                      isDefault: wow,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                      },
                    }
                  )
                  .then((data) => {
                    console.log(data, "21");
                    dispatch({ type: "RESET" });
                    setShow1(false);
                  })
                  .catch((err) => {
                    console.log(err);
                    setShowx(true);
                    setErr(err.response.data.message);
                  });
              }}
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#57d38c] w-[60%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <p className="text-center text-[1.1rem] mt-[1rem] mb-5">
            Are you sure do you want to {able ? "Disable" : "Enable"} this
            beneficiary?
          </p>
          <div className="mb-4 mt-2">
            <Switch
              size="large"
              style={{ backgroundColor: able ? "#57d38c" : "#c3c6c9" }}
              checked={able}
              onChange={ableHandler}
            />
          </div>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Cancel
          </button>
        </div>
      </div>
      <div
        className={`${
          showx ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[200]`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShowx(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShowx(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Page;
