import real1 from "../../../imgs/real1.png";
import real2 from "../../../imgs/real2.png";
import real4 from "../../../imgs/real4.png";
import reals from "../../../imgs/reals.png";
import oapm from "../../../imgs/oapm.png";
import eve from "../../../imgs/eve.png";
import prof from "../../../imgs/prof.png";
import ee from "../../../imgs/ee.png";
import gro from "../../../imgs/gro.png";
import effect from "../../../imgs/effect.png";
import employeee from "../../../imgs/employeee.png";
import Popup1 from "../../Popup/popup1";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
const EMainDash = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.isLogged);
  const dataUser = useSelector((state) => state.dataUser);
  const router = useNavigate();
  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://oap-api-prod.jabcomp.gy/v1/users/profile/${dataUser.data.user.id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {
  //       console.log("profile", data.data);
  //       dispatch({ type: "setProfileData", num: data.data });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  return (
    <div className="">
      {console.log(dataUser, "defda")}
      <div className="mt-[4rem] flex justify-center text-[white]">
        <div className="flex justify-center items-center flex-col pb-[3rem]">
          <div className="flex">
            <div
              className={`grid ${
                dataUser.data.user.email === "iiabdullahii.khan@gmail.com"
                  ? "lg:grid-cols-6"
                  : "lg:grid-cols-5"
              }  lg:gap-x-3 gap-y-5 grid-cols-2 gap-x-3`}
            >
              <div
                onClick={() => {
                  if (dataUser.data.user.status[0] != "pending") {
                    dispatch({ type: "PENSIONER_HISTORY" });
                    router("/EDashboard/allEmployees");
                  }
                }}
                className={`cursor-pointer ${
                  dataUser.data.user.email === "iiabdullahii.khan@gmail.com"
                    ? ""
                    : "hidden"
                }`}
              >
                <div className="relative overflow-hidden font-bold  shadow-2xl text-[1rem] sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] rounded-2xl">
                  <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                    <div className="flex justify-between">
                      <div className=" flex items-center">
                        <div className="ml-3">
                          <p className="text-[1rem] font-bold text-black">
                            Employees
                          </p>
                          <div className="flex items-center">
                            <img src={effect} className="scale-[0.7]" alt="" />
                            <p className="text-[0.9rem] font-normal text-black">
                              OAP
                            </p>
                          </div>
                        </div>
                        <p className="h-[1.5rem] right-2 absolute bg-[#F7542E] text-white text-[0.7rem] font-medium rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                          Active
                        </p>
                      </div>
                    </div>
                    <div className="overflow-hidden rounded-2xl h-[9rem]">
                      <img
                        className="rounded-2xl w-full h-[9rem]"
                        src={employeee}
                        alt=""
                      />
                    </div>
                    <div className="bg-[#57D38C]/[0.9]  rounded-2xl p-2 mt-2">
                      <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                        View the list of all of the employees that are
                        registered with MHSSS
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  // dispatch({ type: "PENSIONER_HISTORY" });
                  if (dataUser.data.user.status[0] != "pending") {
                    router("/EDashboard/Pensioner_HistoryP");
                  }
                }}
                className="cursor-pointer"
              >
                <div className="relative overflow-hidden font-bold  shadow-2xl text-[1rem] sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] rounded-2xl">
                  <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                    <div className="flex justify-between">
                      <div className=" flex items-center">
                        <div className="ml-3">
                          <p className="text-[1rem] font-bold text-black">
                            Pensioners
                          </p>
                          <div className="flex items-center">
                            <img src={effect} className="scale-[0.7]" alt="" />
                            <p className="text-[0.9rem] font-normal text-black">
                              OAP
                            </p>
                          </div>
                        </div>
                        <p className="h-[1.5rem] right-2 absolute bg-[#F7542E] text-white text-[0.7rem] font-medium rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                          Active
                        </p>
                      </div>
                    </div>
                    <div className="overflow-hidden rounded-2xl h-[9rem]">
                      <img
                        className="rounded-2xl w-full h-[9rem]"
                        src={reals}
                        alt=""
                      />
                    </div>
                    <div className="bg-[#57D38C]/[0.9]  rounded-2xl p-2 mt-2">
                      <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                        View the list of all of the pensioners that are
                        registered with MHSSS
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => alert("Coming Soon")}
              >
                <div className="overflow-hidden relative font-bold text-[1rem]  flex justify-center items-center  shadow-2xl  sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] rounded-2xl">
                  <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                    <div className="flex justify-between">
                      <div className=" flex items-center">
                        <div className="ml-3">
                          <p className="text-[1rem] font-bold text-black">
                            Merchants
                          </p>
                          <div className="flex items-center ">
                            <img src={effect} className="scale-[0.7]" alt="" />
                            <p className="text-[0.9rem] font-normal text-black">
                              OAP
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium mt-3 rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                        Active
                      </p>
                    </div>
                    <div className="overflow-hidden rounded-2xl h-[8rem] flex justify-center">
                      <img
                        className="rounded-2xl object-cover w-[13rem] h-[8rem]"
                        src={real2}
                        alt=""
                      />
                    </div>
                    <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2 mt-2">
                      <p className="text-[0.71rem] font-medium">
                        View merchants near you for receiving your benefits, pay
                        bills and purchase grocery
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => alert("Coming Soon")}
              >
                <div className="overflow-hidden relative font-bold text-[1rem] shadow-2xl  sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] rounded-2xl flex justify-center items-center">
                  <div className="sm:p-4 p-2 w-full h-full   relative  rounded-2xl flex flex-col justify-between">
                    <div className="flex justify-between ">
                      <div className=" flex items-center">
                        {/* <div className="w-[2.5rem] h-[2.5rem]">
                              <img className="object-cover" src={gro} alt="" />
                            </div> */}
                        <div className="ml-2">
                          <div className="flex justify-between items-center">
                            <p className="text-[1rem] font-bold text-black">
                              General
                            </p>
                            <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                              Active
                            </p>
                          </div>

                          <p className="text-[1rem] font-bold text-black w-[10rem]">
                            Register Office
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="overflow-hidden flex justify-center items-center rounded-2xl h-[9rem]">
                      <img
                        className="rounded-2xl w-[8.5rem] h-[8.5rem] mb-3"
                        src={gro}
                        alt=""
                      />
                    </div>
                    <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2 mt-2">
                      <p className="text-[0.71rem] font-medium">
                        Updated records for deceased members of the Social
                        Programs
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="cursor-pointer"
                onClick={() => {
                  alert("Coming Soon");
                }}
              >
                <div className="overflow-hidden relative font-bold text-[1rem] flex justify-center items-center shadow-2xl sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] mb-5 rounded-2xl">
                  <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                    <div className="flex justify-between">
                      <div className=" flex items-center">
                        <div className="ml-3">
                          <p className="text-[1rem] font-bold text-black">
                            OAP Money
                          </p>
                          <div className="flex items-center ">
                            <img src={effect} className="scale-[0.7]" alt="" />
                            <p className="text-[0.9rem] font-normal text-black">
                              OAP
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium mt-3 rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                        Active
                      </p>
                    </div>
                    <div className="overflow-hidden  rounded-2xl h-[13rem] mt-3">
                      <img
                        className="rounded-2xl w-full mr-[10rem]"
                        src={oapm}
                        alt=""
                      />
                    </div>
                    <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2">
                      <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                        Use your QR Code for purchases. The history will be
                        recorded in your profile
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  alert("Coming Soon");
                }}
              >
                <div className="overflow-hidden relative font-bold text-[1rem] flex justify-center items-center shadow-2xl sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] mb-5 rounded-2xl">
                  <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                    <div className="flex justify-between">
                      <div className=" flex items-center">
                        <div className="ml-3">
                          <p className="text-[1rem] font-bold text-black">
                            Events
                          </p>
                          <div className="flex items-center ">
                            <img src={effect} className="scale-[0.7]" alt="" />
                            <p className="text-[0.9rem] font-normal text-black">
                              OAP
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium mt-3 rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                        Active
                      </p>
                    </div>
                    <div className="overflow-hidden  rounded-2xl h-[13rem] mt-3">
                      <img
                        className="rounded-2xl w-full mr-[10rem]"
                        src={eve}
                        alt=""
                      />
                    </div>
                    <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2">
                      <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                        The announments will be for public or private (Ministry)
                        for scheduling events and distributions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div
        className={`${
          dataUser.data.user.status === "approved" ? "hidden" : ""
        }`}
      >
        <div className={`${show ? "hidden" : ""}`}>
          <Popup1 />
        </div>
      </div>
    </div>
  );
};

export default EMainDash;
