import { useEffect, useState } from "react";
import plusp from "../../imgs/plusp.png";
import { Spin } from "antd";
import trash from "../../imgs/trash.png";
import axios from "axios";
import cross from "../../imgs/cross.png";
import { useDispatch, useSelector } from "react-redux";
import across from "../../imgs/across.png";

let file;
const EmpRegisterPol = () => {
  const [display4, setDisplay4] = useState("");
  const [isLoading4, setIsLoading4] = useState("");
  const [show, setShow] = useState(false);
  const [err, setErr] = useState(false);
  const [test, setTest] = useState(false);
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const polId = useSelector((state) => state.polId);
  const polName = useSelector((state) => state.polName);
  const polStatus = useSelector((state) => state.polStatus);

  const imageHandler = (setState) => async (e) => {
    console.log(polId, "233333333");
    file = e.target.files[0];
    console.log(file);
    const base641 = await convertToBase64(file);
    console.log(base641, "eee", setState);
    setDisplay4(base641);
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  useEffect(() => {
    console.log(display4, "111");
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [display4]);

  const uploadHandler = (e) => {
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    console.log(polName, "2222", polStatus);
    if (polStatus === "no-pol") {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/employee-register-pol?userId=${polId}&name=${polName}`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
        .then((data) => {
          console.log(data, "ww");
          if (data.data.faceDetection === false) {
            setErr("Sorry the picture did not match");
            setShow(true);
          } else {
            dispatch({ type: "pol" });
            setDisplay4("");
          }
        })
        .catch((err) => {
          console.log(err);
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/verify-pol?userId=${polId}`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
        .then((data) => {
          console.log(data, "ww");
          if (!data.data.faceDetection) {
            setErr("Sorry the picture did not match");
            setShow(true);
          } else {
            dispatch({ type: "pol" });
            setDisplay4("");
          }
        })
        .catch((err) => {
          console.log(err);
          setErr(err.response.data.message);
          setShow(true);
        });
    }
  };
  return (
    <div className=" fixed w-[100%] h-[100%] z-[50] flex justify-center items-center top-0 left-0 bg-slate-400 bg-opacity-[40%]">
      <form
        onSubmit={uploadHandler}
        className="relative sm:pl-[6rem] sm:pr-[6rem] pl-[2rem] pr-[2rem] pt-5 pb-5 rounded-2xl bg-white flex flex-col justify-center items-center"
      >
        <div
          className="absolute top-3 right-4"
          onClick={() => {
            setDisplay4("");
            dispatch({ type: "pol" });
          }}
        >
          <img className="scale-[0.8]" src={cross} />
        </div>
        <h2 className=" text-[1.3rem] font-bold mb-6">POL Image</h2>
        <label
          htmlFor="special-input4"
          className={`cursor-pointer relative w-[18rem]`}
        >
          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
            <div>
              <img className="w-[1.2rem]" src={plusp} alt="" />
            </div>
            <p className="text-[#9CA6C2] ml-2">POL Image</p>
          </div>
          <input
            type="file"
            className="hidden"
            id="special-input4"
            accept="image/png, image/gif, image/jpeg"
            onInput={imageHandler(setDisplay4)}
          />
        </label>
        {console.log(display4, "weeeeeee")}
        <div className={`relative mb-4 ${display4 === "" ? "hidden" : ""}`}>
          <img className="w-[18rem] h-[10rem]" src={display4} alt="" />
          <div
            className={`absolute ${
              isLoading4 ? "" : "hidden"
            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
          >
            <Spin size="large" />
          </div>
          <div
            className="absolute bottom-0 right-0 cursor-pointer bg-white"
            onClick={() => {
              setDisplay4("");
              const wow = document.getElementById("special-input4");
              wow.value = "";
            }}
          >
            <img className="w-[2rem]" src={trash} alt="" />
          </div>
        </div>
        <div className="flex">
          <button
            type="submit"
            className="relative justify-center ml-2 flex w-[100%] cursor-pointer bg-[#57D38C] font-semibold pt-2 pb-2 pl-[4rem] pr-[4rem] text-white rounded-xl"
          >
            <span className="ml-3">Upload</span>
          </button>
        </div>
      </form>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmpRegisterPol;
