import { useEffect, useState } from "react";
import plusp from "../../imgs/plusp.png";
import { Spin } from "antd";
import trash from "../../imgs/trash.png";
import axios from "axios";
import cross from "../../imgs/cross.png";
import { useDispatch, useSelector } from "react-redux";
import across from "../../imgs/across.png";
import Webcam from "react-webcam";
import { useRef } from "react";

let file;
const EmpRegisterPol1 = () => {
  const [display4, setDisplay4] = useState("");
  const [isLoading4, setIsLoading4] = useState("");
  const [show, setShow] = useState(false);
  const [err, setErr] = useState(false);
  const [test, setTest] = useState(false);
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const cam = useSelector((state) => state.cam);
  const polId = useSelector((state) => state.polId);
  const polName = useSelector((state) => state.polName);
  const polStatus = useSelector((state) => state.polStatus);
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);

  const imageHandler = (setState) => async (e) => {
    console.log(polId, "233333333");
    file = e.target.files[0];
    console.log(file);
    const base641 = await convertToBase64(file);
    console.log(base641, "eee", setState);
    setDisplay4(base641);
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  useEffect(() => {
    console.log(display4, "111");
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [display4]);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const uploadHandler = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    console.log(polName, "2222", polStatus);
    if (polStatus === "no-pol") {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/employee-register-pol?userId=${polId}&name=${polName}`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
        .then((data) => {
          console.log(data, "ww");
          if (data.data.faceDetection === false) {
            setErr("Sorry the picture did not match");
            setShow(true);
          } else {
            dispatch({ type: "pol1" });
            setDisplay4("");
          }
        })
        .catch((err) => {
          console.log(err);
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/verify-pol?userId=${polId}`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
        .then((data) => {
          console.log(data, "ww");
          if (!data.data.faceDetection) {
            setErr("Sorry the picture did not match");
            setShow(true);
          } else {
            dispatch({ type: "pol1" });
            setDisplay4("");
          }
        })
        .catch((err) => {
          console.log(err);
          setErr(err.response.data.message);
          setShow(true);
        });
    }
  };
  const captureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // file = await (await fetch(imageSrc)).blob();
    // console.log(imageSrc, "22222222", file);
    file = dataURLtoFile("data:text/plain;base64,aGVsbG8=", "hello.txt");
    console.log(imageSrc, "22222222", file);
    setCapturedImage(imageSrc);
  };
  const [webcamOpen, setWebcamOpen] = useState(true);
  return (
    <div className=" fixed w-[100%] h-[100%] z-[50] flex justify-center items-center top-0 left-0 bg-slate-400 bg-opacity-[40%]">
      <form
        onSubmit={uploadHandler}
        className="relative sm:pl-[6rem] sm:pr-[6rem] pl-[2rem] pr-[2rem] pt-5 pb-5 rounded-2xl bg-white flex flex-col justify-center items-center"
      >
        <div
          className="absolute top-3 right-4"
          onClick={() => {
            dispatch({ type: "pol1" });
            setDisplay4("");
          }}
        >
          <img className="scale-[0.8]" src={cross} />
        </div>
        <h2 className=" text-[1.3rem] font-bold mb-6">POL Image</h2>
        {cam && (
          <div className="w-[100%] h-[100%] absolute top-0 left-0 flex justify-center items-center bg-slate-400 bg-opacity-[40%] z-[100]">
            <div className="relative w-[35rem] h-[25rem] bg-white p-6 rounded-2xl flex flex-col justify-center items-center">
              <h2 className="font-semibold mb-4 text-[1.5rem]">
                {capturedImage === null ? " Capture Image" : "Upload Image"}
              </h2>
              <div
                onClick={() => {
                  setCapturedImage(null);
                  dispatch({ type: "pol1" });
                }}
                className="absolute top-3 right-3"
              >
                <img className="scale-[0.7] cursor-pointer" src={cross} />
              </div>
              {capturedImage === null && (
                <div className="w-[20rem] h-[15rem]">
                  <Webcam ref={webcamRef} />
                </div>
              )}
              {capturedImage != null && (
                <div className="relative w-[20rem] h-[15rem]">
                  <img src={capturedImage} />
                  <div
                    className="absolute bottom-0 right-0 cursor-pointer bg-white"
                    onClick={() => setCapturedImage(null)}
                  >
                    <img className="w-[3rem]" src={trash} alt="" />
                  </div>
                </div>
              )}
              <button
                onClick={() => {
                  if (capturedImage === null) {
                    captureImage();
                  } else {
                    uploadHandler();
                  }
                }}
                type="button"
                className={`lg:text-[1.1rem] bg-gradient-to-tr mt-5 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[20rem]`}
              >
                {capturedImage === null ? "Capture" : "Upload"}
              </button>
            </div>
          </div>
        )}
        <div className="flex">
          <button className="relative justify-center flex w-[100%] cursor-pointer bg-[#57D38C] font-semibold pt-2 pb-2 pl-[4rem] pr-[4rem] text-white rounded-xl">
            <span className="ml-3">Capture </span>
          </button>
        </div>
      </form>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmpRegisterPol1;
