import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import React from "react";
import Initial from "./pages/initial";
import Dashboard from "./pages/dashboard";
import ApplicationPage from "./pages/ApplicationPage";
import BeneficiaryPage from "./pages/BeneficiaryPage";
import ListingPage from "./pages/listingPage";

import "./App.css";
import EDashboard from "./pages/Edashoard";
import PensionL from "./pages/PensionL";
import BeneficiaryP from "./pages/BeneficiaryP";
import ApplicationsP from "./pages/ApplicationsP";
import PensionP from "./pages/PensionP";
import Account from "./pages/Account";
import AboutPage from "./pages/About";
import BAccount from "./pages/BAccount";
import PAccount from "./pages/PAccount";
import AAccount from "./pages/AAccount";
import ViewPage from "./pages/ViewPage";
import PViewPage from "./pages/PViewCard";
import CardPage from "./pages/cardPage";
import CardBackPage from "./pages/cardBackPage";
import Transactions from "./pages/Transactions";
import ContactPage from "./pages/ContactPage";
import PolPage from "./pages/polPage";
import StatusPage from "./pages/StatusPage";
import MapsPage from "./pages/mapsPage";
import Protected from "./components/protectedRoutes";
import { useSelector } from "react-redux";
import AddPensionerPage from "./pages/AddPensionerPage";
import DistriutionsPage from "./pages/DistributionsPage";
import AllApplications from "./pages/AllApplications";
import ProfPage from "./pages/ProfPage";
import PowerBiPage from "./pages/PowerBi";
import AditionalDocuments from "./pages/AditionalDocuments";
import AllEmployee from "./pages/AllEmployeePages";
import AdminPanel from "./pages/adminPanel";
localStorage.setItem("authen", "false");

function App() {
  const authState = useSelector((state) => state.authState);
  return (
    <div>
      <Routes>
        <Route path="/" element={<Initial />} exact></Route>
        <Route
          path="/dashboard"
          element={
            <Protected isSignedIn={authState}>
              <Dashboard />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/Edashboard"
          element={
            <Protected isSignedIn={authState}>
              <EDashboard />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/dashboard/listing"
          element={
            <Protected isSignedIn={authState}>
              <ListingPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/listing"
          element={
            <Protected isSignedIn={authState}>
              <ListingPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/PensionL"
          element={
            <Protected isSignedIn={authState}>
              <PensionL />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/BeneficiaryP"
          element={
            <Protected isSignedIn={authState}>
              <BeneficiaryP />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/ApplicationP"
          element={
            <Protected isSignedIn={authState}>
              <ApplicationsP />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/Pensioner_HistoryP"
          element={
            <Protected isSignedIn={authState}>
              <PensionP />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/dashboard/application"
          element={
            <Protected isSignedIn={authState}>
              <ApplicationPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/application"
          element={
            <Protected isSignedIn={authState}>
              <ApplicationPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/dashboard/BeneficiaryQuestions"
          element={
            <Protected isSignedIn={authState}>
              <BeneficiaryPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/BeneficiaryQuestions"
          element={
            <Protected isSignedIn={authState}>
              <BeneficiaryPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/Account"
          element={
            <Protected isSignedIn={authState}>
              <Account />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/BeneficiaryAccount"
          element={
            <Protected isSignedIn={authState}>
              <BAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/BeneficiaryAccount"
          element={
            <Protected isSignedIn={authState}>
              <BAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/PensionerAccount"
          element={
            <Protected isSignedIn={authState}>
              <PAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/PensionerAccount"
          element={
            <Protected isSignedIn={authState}>
              <PAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/PensionerApplication"
          element={
            <Protected isSignedIn={authState}>
              <AAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/About"
          element={
            <Protected isSignedIn={authState}>
              <AboutPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/About"
          element={
            <Protected isSignedIn={authState}>
              <AboutPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/cardView"
          element={
            <Protected isSignedIn={authState}>
              <ViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/cardView"
          element={
            <Protected isSignedIn={authState}>
              <ViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route path="/card" element={<CardPage />} exact></Route>
        <Route path="/cardBack" element={<CardBackPage />} exact></Route>
        <Route
          path="/Dashboard/transaction_history"
          element={
            <Protected isSignedIn={authState}>
              <Transactions />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/PcardView"
          element={
            <Protected isSignedIn={authState}>
              <PViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/contact"
          element={
            <Protected isSignedIn={authState}>
              <ContactPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/contact"
          element={
            <Protected isSignedIn={authState}>
              <ContactPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/polList"
          element={
            <Protected isSignedIn={authState}>
              <PolPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/statusPage"
          element={
            <Protected isSignedIn={authState}>
              <StatusPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/statusPage"
          element={
            <Protected isSignedIn={authState}>
              <StatusPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/addPensioner"
          element={
            <Protected isSignedIn={authState}>
              <AddPensionerPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/distributionsPage"
          element={
            <Protected isSignedIn={authState}>
              <DistriutionsPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/allApplications"
          element={
            <Protected isSignedIn={authState}>
              <AllApplications />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/allApplications"
          element={
            <Protected isSignedIn={authState}>
              <AllApplications />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/profPage"
          element={
            <Protected isSignedIn={authState}>
              <ProfPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/additionalDocs"
          element={
            <Protected isSignedIn={authState}>
              <AditionalDocuments />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/additionalDocs"
          element={
            <Protected isSignedIn={authState}>
              <AditionalDocuments />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/allEmployees"
          element={
            <Protected isSignedIn={authState}>
              <AllEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route path="/Maps" element={<MapsPage />} exact></Route>
        <Route
          path="/EDashboard/PowerBi"
          element={<PowerBiPage />}
          exact
        ></Route>
        <Route
          path="/EDashboard/adminPanel"
          element={
            <Protected isSignedIn={authState}>
              <AdminPanel />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/adminPanel"
          element={
            <Protected isSignedIn={authState}>
              <AdminPanel />
            </Protected>
          }
          exact
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
