import prof from "../../../../imgs/prof.png";
import { Dropdown, Pagination, Popover } from "antd";
import bs1 from "../../../../imgs/bs1.png";
import bs2 from "../../../../imgs/bs2.png";
import bs3 from "../../../../imgs/bs3.png";
import bs4 from "../../../../imgs/change.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
const Pagea = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const option2 = useSelector((state) => state.option2);
  const dataUser = useSelector((state) => state.dataUser);
  const universalPenId = useSelector((state) => state.universalPenId);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/pensioner-recent?pensionerUserId=${universalPenId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "dede");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }
  const showHandler = (index) => {
    const wow = document.getElementById(`some${index}`);
    if (wow.classList.contains("hide-action")) {
      wow.classList.add("show-action");
      wow.classList.remove("hide-action");
    } else {
      wow.classList.remove("show-action");
      wow.classList.add("hide-action");
    }
  };
  const items = [
    {
      label: "View ",
      key: "1",
      icon: <img style={{ width: "1.5rem" }} src={bs1} />,
    },
    {
      label: "Change Default ",
      key: "2",
      icon: <img style={{ width: "1.2rem", marginLeft: "0.1rem" }} src={bs4} />,
    },
    {
      label: "Edit ",
      key: "3",
      icon: <img style={{ width: "1.1rem", marginLeft: "0.3rem" }} src={bs2} />,
    },
    {
      label: "Delete ",
      key: "4",
      icon: (
        <img style={{ width: "1.1rem", marginLeft: "0.28rem" }} src={bs3} />
      ),
    },
  ];
  const onClick = ({ key }) => {
    if (key === "3") {
      router("/EDashboard/PensionerApplication");
    }
  };
  return (
    <div className="w-[100%] overflow-x-auto overflow-y-hidden">
      <ul className="grid grid-cols-[4rem_8rem_8rem_8.5rem_8.8rem_9.3rem_8.5rem_8rem_3rem] pl-[1rem] mb-2 mt-5">
        <li className="font-semibold">ID</li>
        <li className="font-semibold">Application</li>
        <li className="font-semibold">GID</li>
        <li className="font-semibold">Booklet Number</li>
        <li className="font-semibold ml-3">Phone</li>
        <li className="font-semibold">Email</li>
        <li className="font-semibold">Status</li>
        <li className="font-semibold">Created</li>
        <li className="font-semibold">Action</li>
      </ul>
      {wow.map((item, index) => {
        return (
          <ul
            className={`relative rounded-md grid cursor-pointer content-center w-fit grid-cols-[4rem_8rem_8rem_8.5rem_8.8rem_9.3rem_8.5rem_8rem_3rem] bg-[#E8E8E8] hover:bg-[#cbcbcb] pl-[1rem] pt-2 pb-2 mb-3`}
          >
            <li className="flex items-center ml-1">{index + 1}.</li>
            <li className="relative flex items-center ml-2">
              <p>121 091</p>
            </li>

            <li className="flex items-center">
              {/* <div>
                <img className="w-[2.5rem]" src={prof} alt="" />
              </div> */}
              {/* <p className="ml-2">Marcus John</p> */}
              <div>123456</div>
            </li>
            <li className="flex items-center">
              <div className="border-none bg-[white] text-[#F7542E] p-1 pl-5 pr-5 rounded-md font-semibold focus:border-none cursor-pointer">
                145352
              </div>
            </li>
            <li className="flex items-center ml-2">03359290606</li>
            <li className="flex items-center ml-1">marcus@gmail.com</li>
            <li className="flex items-center">
              <Popover
                content={`${index === 4 ? "In-Process" : ""}`}
                placement="bottomLeft"
              >
                <div
                  className={`border-none  ${
                    index === 2
                      ? "bg-[#d64242]"
                      : index === 4
                      ? "bg-[#2F84F1]"
                      : "bg-[#57D38C]"
                  } p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer`}
                >
                  {index === 2
                    ? "Rejected"
                    : index === 4
                    ? "Pending"
                    : "Approved"}
                </div>
              </Popover>
            </li>
            <li className="flex items-center">June 8, 2023</li>
            <li className="relative flex items-center">
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
                trigger={["click"]}
              >
                <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                </div>
              </Dropdown>
            </li>
          </ul>
        );
      })}
      <div className="flex justify-end mt-7">
        <Pagination defaultCurrent={1} total={80} showSizeChanger={false} />
      </div>
    </div>
  );
};

export default Pagea;
