import { useLocation, useNavigate } from "react-router-dom";
import AdditionalDocs from "../components/ExtraDocs/AdditionalDocs";
import Nav from "../components/Nav/nav";
import back5 from "../imgs/back5.png";
import AllDocs from "../components/ExtraDocs/AllDocs";
import { useState } from "react";
import { useEffect } from "react";
import qwqw from "../imgs/qwqw.png";
const AditionalDocuments = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const [wow, setWow] = useState(0);
  const Show = [AdditionalDocs, AllDocs];
  const Dis = Show[wow];

  useEffect(() => {
    if (dash === "/Dashboard/additionalDocs") {
      setWow(1);
    }
  }, []);
  return (
    <div>
      <Nav />
      <div className="relative flex justify-between items-center mt-[3rem] mb-[3rem] ml-[10rem] mr-[10rem]">
        <div
          onClick={() => {
            if (dash === "/Dashboard/additionalDocs") {
              router("/Dashboard");
            } else {
              router("/EDashboard/profPage");
            }
          }}
          className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
        >
          <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
        </div>
        <h2 className="font-bold sm:text-[1.8rem] sm:ml-0 ml-[2rem] text-[1.2rem]">
          Documents
        </h2>
        <button
          onClick={() => {
            // dispatch({ type: "ISET", num: 0 });
            // dispatch({ type: "PENSIONER" });
            // router("/EDashboard/addPensioner");
            if (wow === 0) {
              setWow(1);
            } else {
              setWow(0);
            }
          }}
          className={`${
            dash === "/Dashboard/additionalDocs" ? "hidden" : ""
          } text-white bg-[#57D38C] pl-5 pr-5 pt-2 pb-2 rounded-lg cursor-pointer`}
        >
          {wow === 1 ? "Add Documents" : "All Documents"}
        </button>
      </div>
      <Dis />
      <div className="fixed bottom-[8%] right-[3%] bg-[#25D366] w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
        <img className=" w-[3.5rem]" src={qwqw} alt="" />
      </div>
    </div>
  );
};

export default AditionalDocuments;
