import { useLocation } from "react-router-dom";
import { Select } from "antd";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import eye from "../../../imgs/eyee.png";
import across from "../../../imgs/across.png";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const FirstB = () => {
  const option = useSelector((state) => state.option);
  const penId = useSelector((state) => state.penId);
  const token = useSelector((state) => state.token);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const [prompt, setPrompt] = useState("");
  // const [countryCode, setCountryCode] = useState("");
  // const [phoneNo, setPhoneNo] = useState("");
  const [relationship, setRelationship] = useState("");
  const [gid, setGid] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState("");
  const dash = location.pathname;

  const handleChange = (setState, check) => (event) => {
    console.log("ca");
    if (check === "tt") {
      setState(event);
    } else {
      setState(event.target.value);
    }
  };
  const nextHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let body;
    if (email && prompt === "") {
      body = {
        relationship,
        programId: `${penId}`,
        email: email,
        gid,
        password: password,
      };
    } else if (prompt && email === "") {
      const wow = parsePhoneNumber(prompt);
      let countryCode = wow.countryCallingCode;
      let number = wow.nationalNumber;
      body = {
        relationship,
        programId: `${penId}`,
        gid,
        countryCode: `+${countryCode}`,
        phoneNo: number,
        password: password,
      };
    } else if (email && prompt) {
      const wow = parsePhoneNumber(prompt);
      let countryCode = wow.countryCallingCode;
      let number = wow.nationalNumber;
      body = {
        relationship,
        programId: `${penId}`,
        countryCode: `+${countryCode}`,
        phoneNo: number,
        email: email,
        gid,
        password: password,
      };
    } else {
      body = {
        relationship,
        programId: `${penId}`,
        gid: gid,
        password: password,
      };
    }
    console.log(body, "qqqqqqqqqqqqqqqqqq");
    if (dash === "/EDashboard/BeneficiaryQuestions") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/users/signup-by-staff`,
          { accountInfo: body },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "first");
          dispatch({
            type: "benId",
            num: `${data.data.id}`,
          });
          setIsLoading(false);
          if (
            dash === "/Dashboard/BeneficiaryQuestions" ||
            dash === "/EDashboard/BeneficiaryQuestions"
          ) {
            dispatch({ type: "BRSET", num: 1 });
            console.log("called");
          } else {
            dispatch({ type: "IINCREMENT" });
          }

          // dispatch({ type: "veri2" });
        })
        .catch((err) => {
          console.log(err);
          if (
            err.response.data.message ===
            '"relationship" must be one of [Me, Husband, Wife, Father, Mother, Son, Daughter, Brother, Sister, Legal guardian]'
          ) {
            setErr("Relationship is a required field");
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
          setIsLoading(false);
        });
    } else {
      console.log(penId, "---------------------");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/users/signup`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "first");
          dispatch({
            type: "benId",
            num: `${data.data.id}`,
          });
          setIsLoading(false);
          if (dash === "/Dashboard/BeneficiaryQuestions") {
            dispatch({ type: "BRSET", num: 1 });
            console.log("called");
          } else {
            dispatch({ type: "IINCREMENT" });
          }

          // dispatch({ type: "veri2" });
        })
        .catch((err) => {
          console.log(err);
          if (
            err.response.data.message ===
            '"relationship" must be one of [Me, Husband, Wife, Father, Mother, Son, Daughter, Brother, Sister, Legal guardian]'
          ) {
            setErr("Relationship is a required field");
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
          setIsLoading(false);
        });
    }
  };
  const passShowHandler = () => {
    const wow = document.getElementById("Pass11");
    if (wow.type === "password") {
      wow.type = "text";
    } else {
      wow.type = "password";
    }
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()} action="" className=" mt-10">
        <div className="flex justify-center items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div>
              <div className="">
                <label
                  htmlFor="input5"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Relation
                </label>
                <Select
                  placeholder="Relation"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1.2rem" }}
                  onChange={handleChange(setRelationship, "tt")}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={[
                    {
                      value: "Brother",
                      label: "Brother",
                    },
                    {
                      value: "Daughter",
                      label: "Daughter",
                    },
                    {
                      value: "Father",
                      label: "Father",
                    },
                    {
                      value: "Husband",
                      label: "Husband",
                    },
                    {
                      value: "Legal guardian",
                      label: "Legal Guardian",
                    },
                    {
                      value: "Mother",
                      label: "Mother",
                    },
                    {
                      value: "Sister",
                      label: "Sister",
                    },
                    {
                      value: "Son",
                      label: "Son",
                    },
                    {
                      value: "Wife",
                      label: "Wife",
                    },
                  ]}
                />
              </div>
              <div className="">
                <label
                  htmlFor="input5"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Phone Number
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="GY"
                  value={prompt}
                  onChange={handleChange(setPrompt, "tt")}
                  international
                  countryCallingCodeEditable={false}
                  required={option === "Employee" ? true : false}
                  className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                />
              </div>
              <div className="">
                <label
                  htmlFor="input5"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Email
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Email"
                  onChange={handleChange(setEmail)}
                  value={email}
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
            <div>
              <div className="">
                <label
                  htmlFor="input5"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  GID *
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="GID"
                  onChange={handleChange(setGid)}
                  value={gid}
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="w-full">
                <div className="mt-4">
                  <label
                    htmlFor="input4"
                    className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                  >
                    Password
                  </label>
                  <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                    <input
                      type="password"
                      placeholder="Password"
                      className="w-[100%]"
                      id="Pass11"
                      onChange={handleChange(setPassword)}
                      value={password}
                      required
                    ></input>
                    <img
                      className="w-[1.6rem] cursor-pointer"
                      onClick={passShowHandler}
                      src={eye}
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex sm:justify-end justify-center w-[100%]">
                  <button
                    onClick={nextHandler}
                    className="relative justify-center flex cursor-pointer mt-10 bg-[#57D38C] pt-2 pb-2 pl-[2rem] pr-[2rem] text-white rounded-xl"
                  >
                    <Spin
                      style={{
                        display: `${isLoading ? "" : "none"}`,
                        marginRight: "1rem",
                      }}
                      indicator={antIcon}
                    />
                    <span>Next</span>
                    <div
                      className={`w-[100%] h-[100%] ${
                        isLoading ? "" : "hidden"
                      } absolute top-0 bg-slate-400 opacity-40 rounded-2xl`}
                    ></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstB;
