import React from "react";
import { Navigate } from "react-router-dom";
function Protected({ isSignedIn, children }) {
  console.log(isSignedIn, "www");
  if (isSignedIn != "true") {
    console.log("iside");
    return <Navigate to="/" replace />;
  }
  return children;
}
export default Protected;
