import { Steps } from "antd";
import { useEffect, useState } from "react";
import First from "./stepsR/FirstR";
import Second from "./stepsR/SecondR";
import Third from "./stepsR/ThirdR";
import Fourth from "./stepsR/FourthR";
import Fifth from "./stepsR/FifthR";

import EmployeeInfo from "../Employee/employeeInfo";
import CompanyInfo from "../Merchant/companyInfo";
import { useDispatch, useSelector } from "react-redux";
import MiddleP from "./stepsR/middleP";
import FirstR from "./stepsR/FirstR";
import SecondR from "./stepsR/SecondR";
import ThirdR from "./stepsR/ThirdR";
import Mid from "./stepsR/mid";
import FirstI from "../Identification/stepsI/FirstI";
import SecondI from "../Identification/stepsI/SecondI";
import ThirdI from "../Identification/stepsI/ThirdI";
import FourthI from "../Identification/stepsI/FourthI";
import AddBenificiary from "../Beneficiary/AddBenificiary";
import FirstB from "../Beneficiary/stepsBR/FirstBR";
import FifthB from "../Beneficiary/stepsBR/FifthBR";
import Application from "../Application/application";
import Awaited from "../Awaited/Awaited";
import FourthR from "./stepsR/FourthR";
import Banking1 from "../Identification/stepsI/Banking";

let Show;
let steps;
Show = [
  FirstR,
  SecondR,
  Mid,
  Third,
  Fifth,
  FirstI,
  SecondI,
  ThirdI,
  FourthI,
  Banking1,
  AddBenificiary,
  FirstB,
  Mid,
  ThirdR,
  Fifth,
  FirstI,
  SecondI,
  ThirdI,
  FifthB,
  Application,
  Awaited,
];
const Register = () => {
  const option = useSelector((state) => state.option);
  useEffect(() => {
    console.log(option, "www");
    if (option === "Employee") {
      Show = [
        FirstR,
        SecondR,
        Mid,
        FourthR,
        EmployeeInfo,
        FirstI,
        SecondI,
        ThirdI,
        Awaited,
      ];
    }
  }, []);

  const [wow, setWow] = useState(1);
  const [word, setWord] = useState("Your Registration");
  const [mainWord, setMainWord] = useState(
    "The Ministry of Human Services and Social Security offers this free online service to Guyanese nationwide that are sixty-five (65) years plus and living in Guyana."
  );
  const [test, setTest] = useState(false);
  const icurrent = useSelector((state) => state.icurrent);
  const acurrent = useSelector((state) => state.acurrent);

  const Dis = Show[icurrent];
  useEffect(() => {
    console.log(icurrent);
    if (icurrent > 4 && icurrent < 8) {
      console.log("1");
      setWord("Your Identification");
    } else if (icurrent === 8) {
      setWord("OAP Details");
      if (option != "Pensioner") {
        setWord("Request Sent");
        setMainWord(
          " Your Employee account is setup and details forwarded to MHSSS administrator for review and approval. It might take some time for management to go through all the steps of your profile. Thanks for your patience and cooperation."
        );
      }
    } else if (icurrent === 9) {
      setWord("OAP Financial");
    } else if (icurrent === 10) {
      console.log("2");
      setWord("Add Beneficiary");
    } else if (icurrent > 9 && icurrent <= 14) {
      console.log("3");
      setWord("Beneficiary Registration");
    } else if (icurrent > 14 && icurrent < 18) {
      setWord("Beneficiary Identification");
    } else if (icurrent === 20) {
      setWord("Request Sent");
      if (option === "Pensioner") {
        setMainWord(
          "Your account is sent to MHSSS for approval. It might take some time for the management to go through all the steps of reviewing your account. Thank you for your patience."
        );
      }
    } else if (icurrent === 18) {
      setWord("Witnesses");
    } else if (icurrent === 19 && acurrent != 6) {
      setWord("Your Application");
    } else if (icurrent === 19 && acurrent === 6) {
      setWord("OAP Financial");
    } else {
      console.log("4");
      setWord("Your Registration");
    }

    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [icurrent, acurrent]);
  return (
    <div>
      <div
        className={`${
          option === "Pensioner" ? "" : "hidden"
        } w-[100%] sm:h-[3.8rem] h-[3rem] flex bg-[#EDEFFD] text-[0.95rem] rounded-tl-2xl rounded-tr-2xl`}
      >
        <div
          className={`flex ${
            icurrent <= 9 ? "bg-gradient-to-tr bg-[#57D38C]" : ""
          } rounded-tl-[2.8rem] w-[33%] items-center justify-center rounded-tr-[5rem] rounded-br-[5rem]`}
        >
          <div
            className={`w-5 sm:flex hidden h-5 ${
              icurrent <= 9 ? "bg-white" : "bg-[#BDC7E8]"
            }   rounded-full flex justify-center items-center`}
          >
            <p
              className={` ${
                icurrent <= 9 ? "text-[lightblue]" : "text-white"
              } text-[0.7rem]`}
            >
              1
            </p>
          </div>
          <p
            className={`ml-2 sm:text-[1rem] text-[0.85rem] ${
              icurrent <= 9 ? "text-white" : "text-[#BDC7E8]"
            }  `}
          >
            {icurrent > 4 ? "Identification" : "Registration"}
          </p>
        </div>
        <div
          className={`${
            icurrent > 9 && icurrent <= 18
              ? "bg-gradient-to-tr bg-[#57D38C]"
              : ""
          } flex w-[33%] rounded-[5rem] items-center justify-center`}
        >
          <div
            className={`w-5 sm:flex hidden h-5 ${
              icurrent > 9 && icurrent <= 18 ? "bg-white" : "  bg-[#BDC7E8]"
            }  rounded-full flex justify-center items-center`}
          >
            <p
              className={`text-[0.7rem] ${
                icurrent > 9 && icurrent <= 18
                  ? "text-[lightblue] "
                  : " text-white"
              } `}
            >
              2
            </p>
          </div>
          <p
            className={`ml-2 sm:text-[1rem] text-[0.85rem] ${
              icurrent > 9 && icurrent <= 18 ? "text-white" : "text-[#BDC7E8]"
            } `}
          >
            Beneficiary
          </p>
        </div>
        <div
          className={`flex ${
            icurrent === 19 ? "bg-gradient-to-tr bg-[#57D38C]" : ""
          } w-[34%] rounded-tl-[5rem] rounded-bl-[5rem] items-center justify-center rounded-tr-[2.9rem]`}
        >
          <div
            className={`w-5 sm:flex hidden h-5  ${
              icurrent === 19 ? "bg-white" : "bg-[#BDC7E8]"
            } rounded-full flex justify-center items-center`}
          >
            <p
              className={` ${
                icurrent === 19 ? "text-[lightblue]" : "text-white"
              } text-[0.7rem]`}
            >
              3
            </p>
          </div>
          <p
            className={`ml-2 sm:text-[1rem] text-[0.85rem] ${
              icurrent === 19 ? "text-white" : "text-[#BDC7E8]"
            } `}
          >
            Application
          </p>
        </div>
      </div>
      <div
        className={`${
          option === "Employee" ? "" : "hidden"
        } w-[100%] h-[3.8rem] flex bg-[#EDEFFD] text-[0.95rem] rounded-tl-2xl rounded-tr-2xl`}
      >
        <div
          className={`flex ${
            icurrent <= 4 ? "bg-gradient-to-tr bg-[#57D38C] " : ""
          } rounded-tl-[2.8rem] w-[50%] items-center justify-center rounded-tr-[5rem] rounded-br-[5rem]`}
        >
          <div
            className={`w-5 h-5 ${
              icurrent <= 4 ? "bg-white" : "bg-[#BDC7E8]"
            }   rounded-full flex justify-center items-center`}
          >
            <p
              className={` ${
                icurrent <= 4 ? "text-[lightblue]" : "text-white"
              } text-[0.7rem]`}
            >
              1
            </p>
          </div>
          <p
            className={`ml-2 ${
              icurrent <= 4 ? " text-white" : " text-[#BDC7E8]"
            }  `}
          >
            Registration
          </p>
        </div>
        <div
          className={`${
            icurrent > 4 && icurrent <= 17
              ? "bg-gradient-to-tr bg-[#57D38C]"
              : ""
          } flex w-[50%] rounded-tl-[5rem] rounded-bl-[5rem] items-center justify-center`}
        >
          <div
            className={`w-5 h-5 ${
              icurrent > 4 && icurrent <= 17 ? "bg-white" : "  bg-[#BDC7E8]"
            }  rounded-full flex justify-center items-center`}
          >
            <p
              className={`text-[0.7rem] ${
                icurrent > 4 && icurrent <= 17
                  ? "text-[lightblue] "
                  : " text-white"
              } `}
            >
              2
            </p>
          </div>
          <p
            className={`ml-2 ${
              icurrent > 4 && icurrent <= 17 ? "text-white" : "text-[#BDC7E8]"
            } `}
          >
            Identification
          </p>
        </div>
      </div>
      <div className="w-full">
        <div className="text-center sm:mt-[3rem] mt-[2rem] flex flex-col items-center">
          <p className="text-[#BDC7E8] mb-1">Step {icurrent}</p>
          <h2 className="font-medium text-[1.6rem] text-[#2c5281] mb-1">
            {word}
          </h2>
          <p className="sm:w-[60%] w-[80%] text-[#596F96] text-[0.9rem]">
            {mainWord}

            {/* {icurrent === 20
              ? "Your account is sent to MHSSS for approval. It might take some time for the management to go through all the steps of reviewing your account. Thank you for your patience."
              : "The Ministry of Human Services and Social Security offers this free online service to Guyanese nationwide that are sixty-five (65) years plus and living in Guyana."} */}
          </p>
          <p className="font-normal text-[1rem]"></p>
        </div>
        <div className="w-full mt-5">
          <Dis />
        </div>
      </div>
    </div>
  );
};

export default Register;
