import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import placeholder from "../../../../imgs/placeholder.png";
import plusp from "../../../../imgs/plusp.png";
import trash from "../../../../imgs/trash.png";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import across from "../../../../imgs/across.png";
import cross from "../../../../imgs/cross.png";
import { LoadingOutlined } from "@ant-design/icons";
let data;
let file;
const Identification2 = () => {
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const token = useSelector((state) => state.token);
  const benDone = useSelector((state) => state.benDone);
  const [signature, setSignature] = useState(null);
  const [leftThumb, setLeftThumb] = useState(null);
  const [rightThumb, setRightThumb] = useState(null);
  const [rightIndex, setRightIndex] = useState(null);
  const [leftIndex, setLeftIndex] = useState(null);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingx, setIsLoadingx] = useState(false);
  const [show, setShow] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [iShow1, setIShow1] = useState(false);
  const [iShow2, setIShow2] = useState(false);
  const [iShow3, setIShow3] = useState(false);
  const [iShow4, setIShow4] = useState(false);
  const [allDis, setAllDis] = useState(false);
  const [err, setErr] = useState("");
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [display3, setDisplay3] = useState(false);
  const [display4, setDisplay4] = useState(false);
  const [images, setImages] = useState();
  const [status, setStatus] = useState("");
  const [test, setTest] = useState(false);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  console.log(profileData, "watch");
  useEffect(() => {
    console.log(profileData, "www");
    if (profileData.pensionerInfo || profileData.beneficiaryInfo) {
      if (
        dash === "/Dashboard/BeneficiaryAccount" ||
        dash === "/EDashboard/BeneficiaryAccount"
      ) {
        data = profileData.beneficiaryInfo;
      } else {
        data = profileData.pensionerInfo;
      }

      console.log(data, "see111");
      const body1 = [data.signature];
      setSignature(data.signature);
      if (data.signature) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
            {
              fileNames: body1,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "llkll");
            setDisplay(data.data[0].url);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      const body2 = [data.leftThumb];
      setLeftThumb(data.leftThumb);
      if (data.leftThumb) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
            {
              fileNames: body2,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "llkll");
            setDisplay1(data.data[0].url);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      const body3 = [data.rightThumb];
      setRightThumb(data.rightThumb);
      console.log(data, "dedededeff1111111122222222");
      if (data.rightThumb) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
            {
              fileNames: body3,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "llkll");

            setDisplay2(data.data[0].url);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      const body4 = [data.rightIndex];
      setRightIndex(data.rightIndex);
      if (data.rightIndex) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
            {
              fileNames: body4,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "llkll");

            setDisplay4(data.data[0].url);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      const body5 = [data.leftIndex];
      setLeftIndex(data.leftIndex);
      if (data.leftIndex) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
            {
              fileNames: body5,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "llkll");

            setDisplay3(data.data[0].url);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [profileData]);

  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    console.log(file);
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log(data, "ww");
        setUrl(data.data.url);
        load(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const updateHandler = () => {
    setIsLoadingx(true);
    console.log(signature);
    console.log(leftThumb);
    console.log(rightThumb);
    console.log(leftIndex);
    console.log(rightIndex);
    if (
      dash === "/Dashboard/BeneficiaryAccount" ||
      dash === "/EDashboard/BeneficiaryAccount"
    ) {
      let wow;
      if (dash === "/Dashboard/BeneficiaryAccount") {
        wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/${profileData.beneficiaryInfo.id}`;
      } else {
        wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/${profileData.beneficiaryInfo.id}/update-by-staff`;
      }
      axios
        .put(
          wow,
          {
            beneficiaryInfo: {
              signature,
              leftThumb,
              rightThumb,
              leftIndex,
              rightIndex,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "eeee");
          dispatch({ type: "RESET" });
          setIsLoadingx(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingx(false);
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      if (dash != "/EDashboard/PensionerAccount") {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info`,
            {
              signature,
              leftThumb,
              rightThumb,
              leftIndex,
              rightIndex,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "eeee");
            dispatch({ type: "RESET" });
            setIsLoadingx(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoadingx(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      } else {
        console.log(profileData, "swdwdw");
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info/${profileData.pensionerInfo.id}/update-by-staff`,
            {
              pensionerInfo: {
                signature,
                leftThumb,
                rightThumb,
                leftIndex,
                rightIndex,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "eeee");
            dispatch({ type: "RESET" });
            setIsLoadingx(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      }
    }
  };
  useEffect(() => {
    if (dash === "/Dashboard/PensionerAccount") {
      setAllDis(true);
    } else {
      setAllDis(false);
      if (benDone != "Done") {
        if (profileData.user.isDisabled) {
          setAllDis(true);
        }
      }
    }
    if (benDone != "Done") {
      setStatus(profileData.user.status);
    }
  }, []);
  return (
    <div>
      {data && (
        <div>
          <div>
            <h2 className="mb-4 text-center text-[1.5rem] font-bold mt-4">
              Identification
            </h2>
            {data && (
              <div
                className={`${
                  dash === "/Dashboard/BeneficiaryAccount" ||
                  dash === "/EDashboard/BeneficiaryAccount"
                    ? ""
                    : "hidden"
                }`}
              >
                <h2 className="mb-4 text-[1.1rem] font-semibold">
                  Percentage Amount:
                </h2>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder={"Monthly Transfer"}
                  value={
                    data.percentage === null
                      ? data.amount === null
                        ? ""
                        : `$${data.amount}`
                      : `${data.percentage}%`
                  }
                  className={`${
                    status === "pending" ? "pointer-events-none" : ""
                  } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                />
              </div>
            )}
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">Signature:</h2>
              <label
                htmlFor="special-input"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4 ||
                  allDis
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Signature</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(setDisplay, setSignature, setIsLoading)}
                />
              </label>
              <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <div
                  className={`${
                    iShow ? "" : "hidden"
                  } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      onClick={() => setIShow(false)}
                      alt=""
                    />
                    <img className="w-[45rem] h-[28rem]" src={display} alt="" />
                  </div>
                </div>
                {/* <div
                className="absolute bottom-0 right-0 cursor-pointer bg-white"
                onClick={() => setDisplay("")}
              >
                <img className="w-[3rem]" src={trash} alt="" />
              </div> */}
              </div>
              {/* <div>
              <img
                className="max-w-[25rem] max-h-[15rem] mb-4"
                src={signature}
              />
            </div> */}
            </div>
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Thumb Print Left:
              </h2>
              <label
                htmlFor="special-input1"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4 ||
                  allDis
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Thumb Print Left</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input1"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay1,
                    setLeftThumb,
                    setIsLoading1
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow1(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display1}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading1 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                {/* <div
                className="absolute bottom-0 right-0 cursor-pointer bg-white"
                onClick={() => setDisplay1("")}
              >
                <img className="w-[3rem]" src={trash} alt="" />
              </div> */}
              </div>
              <div
                className={`${
                  iShow1 ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow1(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display1} alt="" />
                </div>
              </div>
              {/* <div>
              <img
                className="max-w-[25rem] max-h-[15rem] mb-4"
                src={leftThumb}
              />
            </div> */}
            </div>
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Thumb Print Right:
              </h2>
              <label
                htmlFor="special-input2"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4 ||
                  allDis
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Thumb Print Right</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input2"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay2,
                    setRightThumb,
                    setIsLoading2
                  )}
                />
              </label>
              {console.log(display2, "111111111111111")}
              <div className={`relative mb-4 ${display2 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow2(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display2}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading2 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <div
                  className={`${
                    iShow2 ? "" : "hidden"
                  } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      onClick={() => setIShow2(false)}
                      alt=""
                    />
                    <img
                      className="w-[45rem] h-[28rem]"
                      src={display2}
                      alt=""
                    />
                  </div>
                </div>
                {/* <div
                className="absolute bottom-0 right-0 cursor-pointer bg-white"
                onClick={() => setDisplay2("")}
              >
                <img className="w-[3rem]" src={trash} alt="" />
              </div> */}
              </div>
              {/* <div>
              <img
                className="max-w-[25rem] max-h-[15rem] mb-4"
                src={rightThumb}
              />
            </div> */}
            </div>
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Index Print Left:
              </h2>
              <label
                htmlFor="special-input3"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4 ||
                  allDis
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Index Print Left</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input3"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay3,
                    setLeftIndex,
                    setIsLoading3
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display3 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow3(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display3}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading3 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <div
                  className={`${
                    iShow3 ? "" : "hidden"
                  } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      onClick={() => setIShow3(false)}
                      alt=""
                    />
                    <img
                      className="w-[45rem] h-[28rem]"
                      src={display3}
                      alt=""
                    />
                  </div>
                </div>
                {/* <div
                className="absolute bottom-0 right-0 cursor-pointer bg-white"
                onClick={() => setDisplay3("")}
              >
                <img className="w-[3rem]" src={trash} alt="" />
              </div> */}
              </div>
              {/* <div>
              <img
                className="max-w-[25rem] max-h-[15rem] mb-4"
                src={leftIndex}
              />
            </div> */}
            </div>
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Index Print Right:
              </h2>
              <label
                htmlFor="special-input4"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4 ||
                  allDis
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Index Print Right</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input4"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay4,
                    setRightIndex,
                    setIsLoading4
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display4 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow4(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display4}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading4 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <div
                  className={`${
                    iShow4 ? "" : "hidden"
                  } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      onClick={() => setIShow4(false)}
                      alt=""
                    />
                    <img
                      className="w-[45rem] h-[28rem]"
                      src={display4}
                      alt=""
                    />
                  </div>
                </div>
                {/* <div
                className="absolute bottom-0 right-0 cursor-pointer bg-white"
                onClick={() => setDisplay4("")}
              >
                <img className="w-[3rem]" src={trash} alt="" />
              </div> */}
              </div>
              {/* <div>
              <img
                className="max-w-[25rem] max-h-[15rem] mb-4"
                src={rightIndex}
              />
            </div> */}
            </div>
          </div>

          <div
            className={`${
              dataUser.data.user.roles[0] === "employee" &&
              dataUser.data.user.status === "pending"
                ? "hidden"
                : ""
            }`}
          >
            <button
              onClick={updateHandler}
              className={`${
                allDis ? "hidden" : ""
              } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              <Spin
                style={{ display: `${isLoadingx ? "" : "none"}` }}
                indicator={antIcon}
              />
              <span className="ml-3">Save</span>
              <div
                className={`w-[100%] h-[100%] ${
                  isLoadingx ? "" : "hidden"
                } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
              ></div>
            </button>
          </div>
          <div
            className={`${
              show ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
          >
            <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
              <div
                className="absolute top-[-11%] left-[42%]"
                onClick={() => setShow(false)}
              >
                <img src={across} alt="" />
              </div>
              <p className="text-center mt-[3rem] mb-5">{err}</p>
              <button
                onClick={() => setShow(false)}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      <div className={`${data ? "hidden" : ""}`}>No Data</div>
    </div>
  );
};

export default Identification2;
