import placeholder from "../../../imgs/placeholder.png";
import p1 from "../../../imgs/p1.png";
import p2 from "../../../imgs/p2.png";
import p3 from "../../../imgs/p3.png";
import p4 from "../../../imgs/p4.png";
import axios from "axios";
import back5 from "../../../imgs/back5.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

let loginData;
let personData;
let locationData;
let infoData;
let witness1;
let witness2;
const RegistrationP = () => {
  const profileData = useSelector((state) => state.profileData);
  const dispatch = useDispatch();
  const reset = useSelector((state) => state.reset);
  const dataUser = useSelector((state) => state.dataUser);
  const benDone = useSelector((state) => state.benDone);
  const location = useLocation();
  const dash = location.pathname;
  const [signature, setSignature] = useState(placeholder);
  const [leftThumb, setLeftThumb] = useState(placeholder);
  const [rightThumb, setRightThumb] = useState(placeholder);
  const [rightIndex, setRightIndex] = useState(placeholder);
  const [leftIndex, setLeftIndex] = useState(placeholder);
  const [wit1Img, setWit1Img] = useState(placeholder);
  const [wit2Img, setWit2Img] = useState(placeholder);
  const [test, setTest] = useState(true);
  useEffect(() => {
    return () => {
      loginData = null;
      personData = null;
      locationData = null;
      infoData = null;
    };
  }, []);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [loginData, personData, locationData, infoData, witness1]);
  useEffect(() => {
    console.log(profileData, "ddded");
    if (benDone === "Done") {
      if (profileData.beneficiaryUser) {
        loginData = profileData.beneficiaryUser;
      }
    } else {
      if (profileData.user) {
        loginData = profileData.user;
      }
    }

    if (benDone === "Done") {
      if (profileData.beneficiaryPerson) {
        personData = profileData.beneficiaryPerson;
        if (profileData.beneficiaryPerson.beneficiaryaddresses.length >= 1) {
          locationData = profileData.beneficiaryPerson.beneficiaryaddresses;
        }
      }
    } else {
      if (profileData.person) {
        personData = profileData.person;
        if (profileData.person.addresses.length >= 1) {
          locationData = profileData.person.addresses;
        }
      }
    }

    if (profileData.pensionerInfo || profileData.beneficiaryInfo) {
      if (dash === "/Dashboard/cardView" || dash === "/EDashboard/cardView") {
        infoData = profileData.beneficiaryInfo;
      } else {
        infoData = profileData.pensionerInfo;
      }
      console.log(profileData, "wwqaqs", infoData);
      if (infoData) {
        if (infoData.signature != null) {
          const body1 = [infoData.signature];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setSignature(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (infoData.leftThumb != null) {
          const body2 = [infoData.leftThumb];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body2,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setLeftThumb(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (infoData.rightThumb != null) {
          const body3 = [infoData.rightThumb];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body3,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setRightThumb(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (infoData.rightIndex != null) {
          const body4 = [infoData.rightIndex];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body4,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setRightIndex(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (infoData.leftIndex != null) {
          const body5 = [infoData.leftIndex];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body5,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setLeftIndex(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
    console.log(profileData, "jjjjjjjjj");
    if (profileData.beneficiaryUser) {
      if (profileData.witnesses) {
        if (profileData.witnesses.length != 0) {
          witness1 = profileData.witnesses[0];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: [profileData.witnesses[0].signature],
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setWit1Img(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        if (profileData.witnesses.length > 1) {
          witness2 = profileData.witnesses[1];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: [profileData.witnesses[1].signature],
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setWit2Img(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }

    console.log(infoData);
  }, [profileData, reset]);

  useEffect(() => {
    if (test) {
      setTest(false);
    } else {
      setTest(true);
    }
  }, [profileData]);
  return (
    <div className=" mt-[1rem] sm:ml-[0rem] ml-[2rem]">
      <div className="flex">
        <div className="sm:w-[55rem] w-[90%] bg-[#e0e5ec]  rounded-xl p-2 mb-[3rem]">
          {personData && (
            <div className="mb-4 bg-white w-full sm:h-[8.5rem] rounded-lg flex  sm:flex-row flex-col">
              <div className="sm:w-[9.5rem] sm:h-full h-[5rem] w-[100%] bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
                <img className="w-[2rem]" src={p4} alt="" />
              </div>
              <div className="p-2 flex sm:flex-row flex-col pt-3">
                <div className="flex justify-between">
                  <div className="sm:ml-[0.8rem]">
                    <div>
                      <p className="text-[0.9rem] font-bold">Title</p>
                      <p className="text-[0.9rem]">{personData.title}</p>
                    </div>
                    <div className="mt-6 mb-4">
                      <p className="text-[0.9rem] font-bold">DOB</p>
                      <p className="text-[0.9rem]">{personData.dateOfBirth}</p>
                    </div>
                  </div>
                  <div className="sm:ml-[2rem]">
                    <div className="mb-4">
                      <p className="text-[0.9rem] font-bold">First Name</p>
                      <p className="text-[0.9rem]">{personData.firstName}</p>
                    </div>

                    <div className="mt-6">
                      <p className="text-[0.9rem] font-bold">Gender</p>
                      <p className="text-[0.9rem]">{personData.gender}</p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="sm:ml-[2rem]">
                    <div>
                      <p className="text-[0.9rem] font-bold">Middle Name</p>
                      <p className="text-[0.9rem]">
                        {personData.middleName === null
                          ? "---"
                          : `${personData.middleName}`}
                      </p>
                    </div>
                    <div className="mt-6">
                      <p className="text-[0.9rem] font-bold">Union Status</p>
                      <p className="text-[0.9rem]">
                        {personData.unionStatus === null
                          ? "---"
                          : `${personData.unionStatus}`}
                      </p>
                    </div>
                  </div>
                  <div className="sm:ml-[2rem]">
                    <div>
                      <p className="text-[0.9rem] font-bold">Last Name</p>
                      <p className="text-[1.1rem] font-semibold">
                        {personData.lastName}
                      </p>
                    </div>
                    <div className="mt-5">
                      <p className="text-[0.9rem] font-bold">Ethnicity</p>
                      <p className="text-[0.9rem]">
                        {personData.ethnicity === null
                          ? "---"
                          : `${personData.ethnicity}`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sm:ml-[2rem]">
                  <div className="mb-4">
                    <p className="text-[0.9rem] font-bold">Call Name</p>
                    <p className="text-[0.9rem]">
                      {personData.callName === null
                        ? "---"
                        : `${personData.callName}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {locationData && (
            <div className="mt-4 bg-white sm:w-full w-[100%] sm:h-[10rem] rounded-lg flex sm:flex-row flex-col">
              <div className="sm:w-[11.5rem] sm:h-full h-[5rem] w-[100%] bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
                <img className="w-[2rem]" src={p3} alt="" />
              </div>
              <div className="p-2 flex pt-3 w-full">
                <div className="sm:ml-[0.8rem] w-[100%]">
                  <div className="mb-4 flex sm:flex-row flex-col-reverse justify-between">
                    <div className=" max-w-[8rem]">
                      <p className="text-[0.9rem] font-bold">Lot</p>
                      <p className="text-[0.9rem]">{locationData[0].lot}</p>
                    </div>
                    <div className="text-right">
                      <p className="text-[0.9rem] font-bold">Address</p>
                      <p className="text-[0.9rem]">{locationData[0].address}</p>
                    </div>
                  </div>
                  <div className="flex mb-4 sm:flex-row flex-col justify-between ">
                    <div className="flex justify-between">
                      <div className="">
                        <p className="text-[0.9rem] font-bold">Region No</p>
                        <p className="text-[0.9rem]">
                          {locationData[0].regionNo}
                        </p>
                      </div>
                      <div className="sm:ml-[4rem] ">
                        <p className="text-[0.9rem] font-bold">Region Name</p>
                        <p className="text-[0.9rem] sm:max-w-[100%] max-w-[3rem]">
                          {locationData[0].regionName}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="sm:ml-[2rem]">
                        <p className="text-[0.9rem] font-bold">City/Town</p>
                        <p className="text-[0.9rem]">{locationData[0].city}</p>
                      </div>
                      <div className="sm:ml-[4rem]">
                        <p className="text-[0.9rem] font-bold">Village/Ward</p>
                        <p className="text-[0.9rem]">{locationData[0].ward}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {loginData && (
            <div className="mt-4 bg-white w-full sm:h-[8rem] rounded-lg flex sm:flex-row flex-col">
              <div className="sm:w-[9.5rem] sm:h-full h-[5rem] bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
                <img className="w-[2rem]" src={p2} alt="" />
              </div>
              <div className="p-2 flex pt-3">
                <div className="ml-[0.8rem]">
                  <div>
                    <p className="text-[0.9rem] font-bold">GID</p>
                    <p className="text-[0.9rem]">{loginData.gid}</p>
                  </div>
                </div>

                <div className="ml-[4.5rem]">
                  <div className="mb-4">
                    <p className="text-[0.9rem] font-bold">Email</p>
                    <p className="text-[0.9rem]">
                      {loginData.email === null ? "---" : `${loginData.email}`}
                    </p>
                  </div>
                </div>
                <div className="ml-[1.8rem]">
                  <div>
                    <p className="text-[0.9rem] font-bold">Phone</p>
                    <p className="text-[0.9rem]">
                      {loginData.phoneNo === null
                        ? "---"
                        : `${loginData.countryCode}${loginData.phoneNo}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {infoData && (
            <div className="mt-4 bg-white w-full sm:h-[17.5rem] rounded-lg flex sm:flex-row flex-col">
              <div className="sm:w-[9.5rem] sm:h-full h-[5rem]  bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
                <img className="w-[2rem]" src={p1} alt="" />
              </div>
              <div className="p-2 flex sm:flex-row flex-col pt-3">
                <div className="flex">
                  <div className="ml-[0.8rem]">
                    <div
                      className={`mb-4 ${
                        leftThumb === placeholder ? "hidden" : ""
                      }`}
                    >
                      <p className="text-[0.9rem] font-bold">Thumb Left</p>
                      <div className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]">
                        <img
                          className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]"
                          src={leftThumb}
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className={` ${
                        leftIndex === placeholder ? "hidden" : ""
                      }`}
                    >
                      <p className="text-[0.9rem] font-bold">Index Left</p>
                      <div className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]">
                        <img
                          className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]"
                          src={leftIndex}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ml-[2rem]">
                    <div
                      className={`mb-4 ${
                        rightThumb === placeholder ? "hidden" : ""
                      }`}
                    >
                      <p className="text-[0.9rem] font-bold">Thumb Right</p>
                      <div className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]">
                        <img
                          className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]"
                          src={rightThumb}
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className={`mb-4 mr-[2rem] ${
                        rightIndex === placeholder ? "hidden" : ""
                      }`}
                    >
                      <p className="text-[0.9rem] font-bold">Index Right</p>
                      <div className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]">
                        <img
                          className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]"
                          src={rightIndex}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div
                    className={`mb-4 mr-[2rem] ${
                      signature === placeholder ? "hidden" : ""
                    }`}
                  >
                    <p className="text-[0.9rem] font-bold">Signature</p>
                    <div className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]">
                      <img
                        className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]"
                        src={signature}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* ${
                profileData.beneficiaryUser.roles[0] === "beneficiary"
                  ? ""
                  : "hidden"
              } */}
          {witness1 && benDone === "Done" && (
            <div className={` mt-4 bg-white w-full h-[9rem] rounded-lg flex`}>
              <div className="w-[9.5rem] h-full bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
                <img className="w-[2rem]" src={p1} alt="" />
              </div>
              <div className="p-2 flex pt-3">
                <div className="ml-[0.8rem] flex">
                  <div className="mb-4">
                    <p className="text-[0.9rem] font-bold">Witness 1 Name</p>
                    <p className="text-[0.9rem]">{witness1.name}</p>
                  </div>
                  <div className="ml-[2rem]">
                    <p className="text-[0.9rem] font-bold">ID</p>
                    <p className="text-[0.9rem]">{witness1.govtID}</p>
                  </div>
                  <div className="ml-[2rem]">
                    <p className="text-[0.9rem] font-bold">Signature</p>
                    <div className="w-[9rem] h-[6rem]">
                      <img className="w-[9rem] h-[6rem]" src={wit1Img} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {witness2 && benDone === "Done" && (
            <div className={`  mt-4 bg-white w-full h-[9rem] rounded-lg flex`}>
              <div className="w-[9.5rem] h-full bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
                <img className="w-[2rem]" src={p1} alt="" />
              </div>
              <div className="p-2 flex pt-3">
                <div className="ml-[0.8rem] flex">
                  <div className="mb-4">
                    <p className="text-[0.9rem] font-bold">Witness 2 Name</p>
                    <p className="text-[0.9rem]">{witness2.name}</p>
                  </div>
                  <div className="ml-[2rem]">
                    <p className="text-[0.9rem] font-bold">ID</p>
                    <p className="text-[0.9rem]">{witness2.govtID}</p>
                  </div>
                  <div className="ml-[2rem]">
                    <p className="text-[0.9rem] font-bold">Signature</p>
                    <div className="w-[9rem] h-[6rem]">
                      <img className="w-[9rem] h-[6rem]" src={wit2Img} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationP;
