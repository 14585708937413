import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import placeholder from "../../../../imgs/placeholder.png";
import plusp from "../../../../imgs/plusp.png";
import { Spin, DatePicker, Select } from "antd";
import { useMemo } from "react";
import countryList from "react-select-country-list";
import logo1 from "../../../../imgs/logo12.png";
import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";
let data;
let birthData = [];
let drivData = [];
let file;
let w1;
let w2;
const Part2 = () => {
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const benDone = useSelector((state) => state.benDone);
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const [test, setTest] = useState(true);
  const options1 = useMemo(() => countryList().getData(), []);
  const [birthId, setBirthId] = useState("");
  const [birthIssued, setBirthIssued] = useState(null);
  const [birthFront, setBirthFront] = useState("");
  const [drivId, setDrivId] = useState("");
  const [drivExp, setDrivExp] = useState(null);
  const [drivCountry, setDrivCountry] = useState(null);
  const [drivFront, setDrivFront] = useState("");
  const [drivBack, setDrivBack] = useState("");

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoadingx, setIsLoadingx] = useState(false);
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const [display2, setDisplay2] = useState("");
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [allDis, setAllDIs] = useState(false);
  const [roles, setRoles] = useState("");

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {
    if (benDone != "Done") {
      setRoles(profileData.user.roles[0]);
    }
  }, []);
  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    console.log(file, "see");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        setUrl(data.data.url);
        load(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  useEffect(() => {
    if (
      (benDone != "Done" && profileData.verificationDocs) ||
      (benDone === "Done" && profileData.beneficiaryVerificationDocs)
    ) {
      if (benDone === "Done") {
        data = profileData.beneficiaryVerificationDocs;
      } else {
        data = profileData.verificationDocs;
      }

      birthData = data.filter((item) => {
        return item.type === "birth certificate";
      });
      console.log(birthData, "ee");
      if (birthData.length != 0) {
        setBirthId(birthData[0].docIDValue);
        setBirthIssued(birthData[0].issuedDate);
      }

      drivData = data.filter((item) => {
        return item.type === "driver license";
      });
      if (drivData.length != 0) {
        setDrivId(drivData[0].docIDValue);
        setDrivExp(drivData[0].expiryDate);

        if (drivData[0].countryIssued.length > 2) {
          setDrivCountry(drivData[0].countryIssued);
          w1 = countryList().getValue(drivData[0].countryIssued);
          w2 = drivData[0].countryIssued;
        } else {
          setDrivCountry(countryList().getLabel(drivData[0].countryIssued));
          w2 = countryList().getLabel(drivData[0].countryIssued);
          w1 = drivData[0].countryIssued;
        }
      }

      if (birthData.length != 0) {
        if (
          (benDone != "Done" && birthData[0].docimages.length != 0) ||
          (benDone === "Done" && birthData[0].beneficiarydocimages.length != 0)
        ) {
          let wow11;

          if (benDone === "Done") {
            wow11 = birthData[0].beneficiarydocimages.filter((item) => {
              return item.type === "front image";
            });
          } else {
            wow11 = birthData[0].docimages.filter((item) => {
              return item.type === "front image";
            });
          }

          const body1 = [wow11[0].imageUrl];
          setBirthFront(wow11[0].imageUrl);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setDisplay(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      if (drivData.length != 0) {
        if (
          (benDone != "Done" && drivData[0].docimages.length != 0) ||
          (benDone === "Done" && drivData[0].beneficiarydocimages.length != 0)
        ) {
          let wow11;
          if (benDone === "Done") {
            wow11 = drivData[0].beneficiarydocimages.filter((item) => {
              return item.type === "front image";
            });
          } else {
            wow11 = drivData[0].docimages.filter((item) => {
              return item.type === "front image";
            });
          }

          console.log(wow11, "11111111111111111111111111");
          const body1 = [wow11[0].imageUrl];
          setDrivFront(wow11[0].imageUrl);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");

              setDisplay1(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
          if (
            (benDone != "Done" && drivData[0].docimages.length > 1) ||
            (benDone === "Done" && drivData[0].beneficiarydocimages.length > 1)
          ) {
            let wow11;
            if (benDone === "Done") {
              wow11 = drivData[0].beneficiarydocimages.filter((item) => {
                return item.type === "back image";
              });
            } else {
              wow11 = drivData[0].docimages.filter((item) => {
                return item.type === "back image";
              });
            }

            if (wow11.length != 0) {
              const body2 = [wow11[0].imageUrl];
              setDrivBack(wow11[0].imageUrl);
              axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
                  {
                    fileNames: body2,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                    },
                  }
                )
                .then((data) => {
                  console.log(data, "llkll");

                  setDisplay2(data.data[0].url);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        }
      }
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
    console.log(birthData, "edewded", drivData);
  }, [profileData]);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [birthData, drivData, display, display1, display2]);
  const handleChange = (setState, check) => (event) => {
    if (check === "tg") {
      setState(event);
    } else if (check === "tgf") {
      let wow;
      if (event.$M <= 9) {
        if (event.$D <= 9) {
          wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
        }
      } else {
        if (event.$D <= 9) {
          wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
        }
      }
      setState(wow);
    } else {
      setState(event.target.value);
    }
  };

  const updateHandler = () => {
    setIsLoadingx(true);
    console.log("called", birthIssued);
    const body = [];
    if (drivId || drivCountry || drivExp || drivFront != "" || drivBack != "") {
      const drivData = {
        type: "driver license",
        docIDName: "drivers license no",
        docIDValue: `${drivId}`,
        countryIssued: `${drivCountry}`,
        expiryDate: `${drivExp}`,
        docImages: [],
      };
      if (drivFront != "") {
        drivData.docImages[0] = {
          type: "front image",
          imageUrl: `${drivFront}`,
        };
      }
      if (drivBack != "") {
        drivData.docImages[1] = {
          type: "back image",
          imageUrl: `${drivBack}`,
        };
      }
      body.push(drivData);
    }
    if (birthId || birthId || birthFront != "") {
      const birthData = {
        type: "birth certificate",
        docIDName: "birth cert",
        docIDValue: `${birthId}`,
        issuedDate: `${birthIssued}`,
        docImages: [],
      };
      if (birthFront != "") {
        birthData.docImages[0] = {
          type: "front image",
          imageUrl: `${birthFront}`,
        };
      }
      body.push(birthData);
    }

    console.log(body);
    if (
      dash === "/Dashboard/BeneficiaryAccount" ||
      dash === "/EDashboard/BeneficiaryAccount"
    ) {
      let wow;
      if (dash === "/Dashboard/BeneficiaryAccount") {
        wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/verification-docs?beneficiaryUserId=${profileData.beneficiaryUser.id}`;
      } else {
        wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/verification-docs/update-by-staff?beneficiaryUserId=${profileData.beneficiaryUser.id}`;
      }
      axios
        .put(
          wow,
          {
            verificationDocs: body,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "222");
          dispatch({ type: "RESET" });
          setIsLoadingx(false);
        })
        .catch((err) => {
          console.log(err);
          if (
            err.response.data.message === '"expiryDate" must be a valid date'
          ) {
            setErr("Please verify if expiration date is available on card");
          } else {
            setErr(err.response.data.message);
          }
          setIsLoadingx(false);
          setShow(true);
        });
    } else {
      if (dash != "/EDashboard/PensionerAccount") {
        axios
          .put(`${process.env.REACT_APP_BASE_URL}/v1/verification-docs`, body, {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          })
          .then((data) => {
            console.log(data, "222");
            dispatch({ type: "RESET" });
            setIsLoadingx(false);
          })
          .catch((err) => {
            console.log(err);

            if (
              err.response.data.message === '"expiryDate" must be a valid date'
            ) {
              setErr("Please verify if expiration date is available on card");
            } else {
              setErr(err.response.data.message);
            }
            setIsLoadingx(false);
            setShow(true);
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/verification-docs/update-by-staff?userId=${profileData.user.id}`,
            { verificationDocs: body },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "222");
            dispatch({ type: "RESET" });
            setIsLoadingx(false);
          })
          .catch((err) => {
            console.log(err);
            if (
              err.response.data.message === '"expiryDate" must be a valid date'
            ) {
              setErr("Please verify if expiration date is available on card");
            } else {
              setErr(err.response.data.message);
            }
            setShow(true);
            setIsLoadingx(false);
          });
      }
    }
  };

  useEffect(() => {
    if (benDone != "Done") {
      if (
        dash === "/EDashboard/PensionerAccount" &&
        profileData.user.isDisabled
      ) {
        setAllDIs(true);
      } else if (
        profileData.user.status === "pending" &&
        dash === "/Dashboard/PensionerAccount"
      ) {
        setAllDIs(true);
      }
    }
  }, []);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [w1, w2]);

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }

  const date = getCurrentDate();
  const disabledBeforeDate = moment(
    `${date.props.children[0]}-${date.props.children[2]}-${date.props.children[4]}`
  );
  const disabledDate = (current) => {
    return current && current < disabledBeforeDate;
  };
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };
  return (
    <div>
      <div className={`${roles === "employee" ? "hidden" : ""}`}>
        <h2 className="mb-4 text-center text-[1.5rem] font-bold">
          Birth Certificate
        </h2>
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
          Birth Certificate #:
        </h2>
        <input
          type="text"
          name=""
          id=""
          onChange={handleChange(setBirthId)}
          value={birthId}
          placeholder="Birth Certificate #"
          className={`${
            allDis ? "pointer-events-none" : "pointer-events-auto"
          } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        />
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>Date Issued:</h2>

        {birthIssued != null && (
          <DatePicker
            placeholder="Expiration Date"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${allDis ? "none" : "auto"}`,
            }}
            defaultValue={dayjs(`${birthIssued}`, "YYYY-MM-DD")}
            onChange={handleChange(setBirthIssued, "tgf")}
            disabledDate={disabledDate1}
            className="date-class"
          />
        )}
        <DatePicker
          placeholder="Expiration Date"
          style={{
            marginBottom: "1.2rem",
            pointerEvents: `${allDis ? "none" : "auto"}`,
            display: `${birthIssued === null ? "" : "none"}`,
          }}
          onChange={handleChange(setBirthIssued, "tgf")}
          disabledDate={disabledDate1}
          className="date-class"
        />
        {/* <input
          type="text"
          name=""
          id=""
          placeholder="Date Issued"
          value={birthIssued}
          className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        /> */}
        <div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
            Image Front:
          </h2>
          <label
            htmlFor="special-input"
            className={`${
              isLoading1 || isLoading2 || isLoading3 || allDis
                ? "pointer-events-none"
                : ""
                ? "pointer-events-none"
                : ""
            } cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <img className="w-[1.2rem]" src={plusp} alt="" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Front</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay, setBirthFront, setIsLoading3)}
            />
          </label>
          <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
            <div
              className={`absolute ${
                isLoading3 ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div>
            <img className="w-[25rem] h-[13rem]" src={display} alt="" />
            {/* <div
              className="absolute bottom-0 right-0 bg-white cursor-pointer"
              onClick={() => setDisplay("")}
            >
              <img className="w-[3rem]" src={trash} alt="" />
            </div> */}
          </div>
          {/* <div>
            <img className="w-[25rem] h-[13rem] mb-4" src={birthFront} />
          </div> */}
        </div>
      </div>

      <div>
        <h2 className="mb-4 text-center text-[1.5rem] font-bold">
          Driver's License
        </h2>
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
          Driver's License #:
        </h2>
        <input
          type="text"
          name=""
          id=""
          placeholder="Driver's License #"
          onChange={handleChange(setDrivId)}
          value={drivId}
          className={`${
            allDis ? "pointer-events-none" : "pointer-events-auto"
          } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        />
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
          Expiration Date:
        </h2>
        {drivExp != null && (
          <DatePicker
            placeholder="Expiration Date"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${allDis ? "none" : "auto"}`,
            }}
            defaultValue={dayjs(`${drivExp}`, "YYYY-MM-DD")}
            // disabledDate={disabledDate}
            onChange={handleChange(setDrivExp, "tgf")}
            disabledDate={disabledDate}
            className="date-class"
          />
        )}
        <DatePicker
          placeholder="Expiration Date"
          style={{
            marginBottom: "1.2rem",
            pointerEvents: `${allDis ? "none" : "auto"}`,
            display: `${drivExp === null ? "" : "none"}`,
          }}
          onChange={handleChange(setDrivExp, "tgf")}
          disabledDate={disabledDate}
          className="date-class"
        />
        {/* <input
          type="text"
          name=""
          id=""
          placeholder="Expiration Date"
          value={drivExp}
          className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        /> */}
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
          Country Issued:
        </h2>
        {console.log(w1, w2, drivCountry)}
        {w1 && w2 && (
          <Select
            placeholder="Country Issued"
            optionFilterProp="children"
            className="wow"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${allDis ? "none" : "auto"}`,
              display: `${drivCountry === null ? "none" : ""}`,
            }}
            defaultValue={{
              value: `${w1}`,
              label: `${w2}`,
            }}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            options={options1}
            onChange={handleChange(setDrivCountry, "tg")}
          />
        )}
        <Select
          placeholder="Country Issued"
          optionFilterProp="children"
          className="wow"
          style={{
            marginBottom: "1.2rem",
            pointerEvents: `${allDis ? "none" : "auto"}`,
            display: `${drivCountry != null ? "none" : ""}`,
          }}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          options={options1}
          onChange={handleChange(setDrivCountry, "tg")}
        />
        <div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
            Image Front:
          </h2>
          <label
            htmlFor="special-input1"
            className={`${
              isLoading1 || isLoading2 || isLoading3 || allDis
                ? "pointer-events-none"
                : ""
                ? "pointer-events-none"
                : ""
            } cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <img className="w-[1.2rem]" src={plusp} alt="" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Front</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input1"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay1, setDrivFront, setIsLoading1)}
            />
          </label>
          <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
            {console.log(display1, "dedededded")}
            <img className="w-[25rem] h-[13rem]" src={display1} alt="" />
            <div
              className={`absolute ${
                isLoading1 ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div>
            {/* <div
              className="absolute bottom-0 right-0 bg-white cursor-pointer"
              onClick={() => setDisplay1("")}
            >
              <img className="w-[3rem]" src={trash} alt="" />
            </div> */}
          </div>
          {/* <div>
            <img className="w-[25rem] h-[13rem] mb-4" src={drivFront} />
          </div> */}
        </div>
        <div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>Image Back:</h2>
          <label
            htmlFor="special-input2"
            className={`${
              isLoading1 || isLoading2 || isLoading3 || allDis
                ? "pointer-events-none"
                : ""
                ? "pointer-events-none"
                : ""
            } cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <img className="w-[1.2rem]" src={plusp} alt="" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Back</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input2"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay2, setDrivBack, setIsLoading2)}
            />
          </label>
          <div className={`relative mb-4 ${display2 ? "" : "hidden"}`}>
            <div
              className={`absolute ${
                isLoading2 ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div>
            <img className="w-[25rem] h-[13rem]" src={display2} alt="" />
            {/* <div
              className="absolute bottom-0 right-0 bg-white cursor-pointer"
              onClick={() => setDisplay2("")}
            >
              <img className="w-[3rem]" src={trash} alt="" />
            </div> */}
          </div>
          {/* <div>
            <img className="w-[25rem] h-[13rem] mb-4" src={drivBack} />
          </div> */}
        </div>
      </div>
      <div
        className={`${
          dataUser.data.user.roles[0] === "employee" &&
          dataUser.data.user.status === "pending"
            ? "hidden"
            : ""
        }`}
      >
        <button
          onClick={updateHandler}
          className={`${
            allDis ? "hidden" : ""
          } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          <Spin
            style={{ display: `${isLoadingx ? "" : "none"}` }}
            indicator={antIcon}
          />
          <span className="ml-3">Save</span>
          <div
            className={`w-[100%] h-[100%] ${
              isLoadingx ? "" : "hidden"
            } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
          ></div>
        </button>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" />
          </div>
          <p className="text-center mt-[1rem] mb-5">{err}</p>

          <button
            type="submit"
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#57D38C] w-[60%]"
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default Part2;
