import back5 from "../../imgs/back5.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, DatePicker, Dropdown } from "antd";
import pdf from "../../imgs/pdff.png";
import { DownloadOutlined } from "@ant-design/icons";
import { useState } from "react";
import Nationality from "./parts/nationality";
import Residance from "./parts/residance";
import General from "./parts/general";
import Acceptance from "./parts/acceptance";
const Aaccount = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const items1 = [
    {
      label: "June 28th, 2023",
      key: "5",
      icon: <DownloadOutlined className="some" />,
    },
    {
      label: "June 24th, 2023",
      key: "6",
      icon: <DownloadOutlined className="some" />,
    },
    {
      label: "April 5th, 2022",
      key: "7",
      icon: <DownloadOutlined className="some" />,
    },
  ];
  const [wow, setWow] = useState(0);
  const Show = [Nationality, Residance, General, Acceptance];
  const Dis = Show[wow];
  return (
    <div className="pt-[5rem] pb-[5rem] pl-[12rem] pr-[12rem] relative">
      <div className="relative flex items-center mb-[3rem] justify-between">
        <div
          onClick={() => router("/EDashboard/ApplicationP")}
          className="absolute top-[1] cursor-pointer left-[-4%]"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <div className="font-bold text-[1.8rem]">Pensioner Application</div>
        <div className="flex">
          <Dropdown
            menu={{
              items: items1,
            }}
            placement="bottomRight"
            arrow
          >
            <div className="mr-4 cursor-pointer">
              <img className="w-[2.5rem] h-[2.5rem]" src={pdf} alt="" />
            </div>
          </Dropdown>
          <button
            onClick={() => router("/EDashboard/PensionerAccount")}
            className="text-white bg-[#57D38C] pl-5 pr-5 pt-2 pb-2 rounded-lg "
          >
            Pensioner Account
          </button>
        </div>
      </div>
      <div className="flex mt-[5rem] mb-[5rem]">
        <button
          onClick={() => setWow(0)}
          className={` ${
            wow === 0 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          }  p-2 rounded-xl mr-2  w-[7rem] relative`}
        >
          Nationality
          <div
            className={`${
              wow === 0 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-37%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(1)}
          className={`${
            wow === 1 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl mr-2 w-[7rem] relative`}
        >
          Residence
          <div
            className={`${
              wow === 1 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-37%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(2)}
          className={`${
            wow === 2 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl mr-2 w-[7rem] relative`}
        >
          General
          <div
            className={`${
              wow === 2 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-37%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(3)}
          className={`${
            wow === 3 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl mr-2 w-[7rem] relative`}
        >
          Acceptance
          <div
            className={`${
              wow === 3 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-37%]`}
          ></div>
        </button>
      </div>
      <Dis />
    </div>
  );
};

export default Aaccount;
