import { useState } from "react";
import LoginCredentials1 from "./tabsR/loginCredentials";
import PersonalDetails1 from "./tabsR/personalDetails";
import Location1 from "./tabsR/location";
import Identification2 from "./tabsR/identification";
import PensionBookDetails from "./PensionBook";
import Banking from "./BankingDetails";

const Pregistration = () => {
  const [wow, setWow] = useState(0);
  const Show = [
    LoginCredentials1,
    PersonalDetails1,
    Location1,
    Identification2,
    PensionBookDetails,
    Banking,
  ];
  const Dis = Show[wow];
  return (
    <div className="">
      <div className="flex flex-wrap mt-8 mb-[5rem]">
        <button
          onClick={() => setWow(0)}
          className={` ${
            wow === 0 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          }  p-2 pl-4 pr-4 rounded-xl mr-2  min-w-[5.5rem] relative text-center`}
        >
          <div className=" text-center w-[100%]">Login</div>
          <div
            className={`${
              wow === 0 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(1)}
          className={`${
            wow === 1 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 pl-4 pr-4  rounded-xl mr-2 min-w-[5.5rem] relative text-center`}
        >
          <div className=" text-center w-[100%]">Personal</div>
          <div
            className={`${
              wow === 1 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(2)}
          className={`${
            wow === 2 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 pl-4 pr-4  rounded-xl mr-2 min-w-[5.5rem] relative text-center`}
        >
          <div className=" text-center w-[100%]">Location</div>
          <div
            className={`${
              wow === 2 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(3)}
          className={`${
            wow === 3 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 pl-4 pr-4  rounded-xl mr-2 min-w-[5.5rem] relative text-center sm:mt-0 mt-8`}
        >
          <div className="text-center w-[100%]">Identity</div>
          <div
            className={`${
              wow === 3 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>
        <button
          onClick={() => setWow(4)}
          className={`${
            wow === 4 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 pl-4 pr-4  rounded-xl mr-2 min-w-[5.5rem] relative text-center sm:mt-0 mt-8`}
        >
          <div className="text-center w-[100%]">Pension Book</div>
          <div
            className={`${
              wow === 4 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>
        <div className="">
          <button
            onClick={() => setWow(5)}
            className={`${
              wow === 5 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
            } flex p-2 pl-4 pr-4  rounded-xl mr-2 min-w-[5.5rem] relative text-center sm:mt-0 mt-8`}
          >
            <div className="text-center w-[100%]">Banking</div>
            <div
              className={`${
                wow === 5 ? "" : "hidden"
              } arrow-down absolute right-[35%] bottom-[-38%]`}
            ></div>
          </button>
        </div>
      </div>
      <Dis />
    </div>
  );
};

export default Pregistration;
