import Eregistration from "./Parts/Eregistration";
import Eidentification from "./Parts/Eidentification";
import { useState, useEffect } from "react";
import back5 from "../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Eaccount = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileData);
  const [wow, setWow] = useState(0);
  const dataUser = useSelector((state) => state.dataUser);
  const Show = [Eregistration, Eidentification];
  const Dis = Show[wow];
  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  useEffect(() => {
    // console.log(universalPenId, "eee");
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/profile/${dataUser.data.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        console.log("profile", data.data);
        dispatch({ type: "setProfileData", num: data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="pt-[5rem] pb-[5rem] sm:pl-[12rem] sm:pr-[12rem] pl-2 pr-2">
      {profileData && (
        <div>
          <div className="relative flex items-center mb-[3rem] sm:ml-0 ml-7">
            <div
              onClick={() => router("/EDashboard")}
              className="absolute top-[1] cursor-pointer sm:left-[-4%] left-[-8%]"
            >
              <img className="sm:w-[1.7rem] w-[1.4rem]" src={back5} alt="" />
            </div>
            <div className="font-bold sm:text-[1.8rem] text-[1.4rem]">
              {dataUser.data.user.email === "iiabdullahii.khan@gmail.com"
                ? "Admin Account"
                : "Employee Account"}
            </div>
          </div>
          <div className="lg:mb-[4rem] flex mb-[2rem]">
            <div className="cursor-pointer" onClick={() => changeHandler("R")}>
              <h2
                className={`lg:text-[1.1rem] ${
                  wow === 0 ? "font-semibold" : ""
                } `}
              >
                Registration Details
              </h2>
              <div
                className={`lg:w-[9.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
                  wow === 0 ? "bg-[#18B871]" : "bg-[#E8E8E8]"
                } `}
              ></div>
            </div>

            <div className="cursor-pointer" onClick={() => changeHandler("I")}>
              <h2
                className={`lg:text-[1.1rem] ml-6 ${
                  wow === 1 ? "font-semibold" : ""
                } `}
              >
                Identification Details
              </h2>
              <div
                className={`lg:w-[9.5] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${
                  wow === 1 ? "bg-[#18B871]" : "bg-[#E8E8E8]"
                }  `}
              ></div>
            </div>
          </div>
          <div>
            <Dis />
          </div>
        </div>
      )}
    </div>
  );
};

export default Eaccount;
