import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Checkbox, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import trash from "../../../imgs/trash.png";
import { DatePicker } from "antd";
import plusp from "../../../imgs/plusp.png";
import { Spin } from "antd";
import axios, { all } from "axios";

let firstQ = "";
let secondQ = "";
let file = "";
let question1 = null;
let question1sub1 = null;
let question1sub2 = null;
let question1sub3 = null;
let question1sub4 = null;
let question1sub5 = null;
let question1sub6 = null;
let question1sub7 = null;
let question1sub8 = null;
let question1sub9 = null;
const Mid = () => {
  const [initial, setInitial] = useState(true);
  const dispatch = useDispatch();
  const appQuestions = useSelector((state) => state.appQuestions);
  const applicationAnswers = useSelector((state) => state.applicationAnswers);
  const dataUser = useSelector((state) => state.dataUser);
  const token = useSelector((state) => state.token);
  const [display, setDisplay] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkBuffer, setCheckBuffer] = useState("");
  const [allLocations, setAllLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const Question9 = useSelector((state) => state.Question9);
  const location = useLocation();
  const dash = location.pathname;
  const [test, setTest] = useState(false);
  const [allDis, setAllDis] = useState(true);

  const nextHandler = () => {
    if (question1 != null) {
      Question9.push(question1);
    }
    if (question1sub1 != null) {
      Question9.push(question1sub1);
    }
    if (question1sub2 != null) {
      Question9.push(question1sub2);
    }
    if (question1sub3 != null) {
      Question9.push(question1sub3);
    }
    if (question1sub4 != null) {
      Question9.push(question1sub4);
    }
    if (question1sub5 != null) {
      Question9.push(question1sub5);
    }
    if (question1sub6 != null) {
      Question9.push(question1sub6);
    }
    if (question1sub7 != null) {
      Question9.push(question1sub7);
    }
    if (question1sub8 != null) {
      Question9.push(question1sub8);
    }
    if (question1sub9 != null) {
      Question9.push(question1sub9);
    }
    if (Question9.length != 0) {
      Question9.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    dispatch({ type: "AINCREMENT" });
  };
  // useEffect(() => {
  //   console.log(applicationAnswers);
  //   return () => {
  //     // applicationAnswers.push(question1);
  //     // applicationAnswers.push(question1sub1);
  //     // applicationAnswers.push(question1sub2);
  //     // applicationAnswers.push(question1sub3);
  //     // applicationAnswers.push(question1sub4);
  //     // applicationAnswers.push(question1sub5);
  //     // applicationAnswers.push(question1sub6);
  //     // applicationAnswers.push(question1sub7);
  //     // applicationAnswers.push(question1sub8);
  //     // applicationAnswers.push(question1sub9);

  //     // question1 = {};
  //     // question1sub1 = {};
  //     // question1sub2 = {};
  //     // question1sub3 = {};
  //     // question1sub4 = {};
  //     // question1sub5 = {};
  //     // question1sub6 = {};
  //     // question1sub7 = {};
  //     // question1sub8 = {};
  //     // question1sub9 = {};
  //   };
  // }, []);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [firstQ, secondQ]);
  const changeHandler = (item, index) => {
    setCheckBuffer(item);
    question1 = {
      appquestionId: firstQ[0].id,
      type: firstQ[0].type,
    };
    question1.answers = [item];
  };

  const changeAllHandler = (e, id, type, question) => {
    console.log(e, id, type, question, "ssdf");
    if (question === "Region") {
      question1sub2 = {
        appquestionId: id,
        type: type,
        answer: [e.target.value],
      };
    } else if (question === "Region Name") {
      question1sub3 = {
        appquestionId: id,
        type: type,
        answer: [e.target.value],
      };
    } else if (question === "Lot #") {
      question1sub4 = {
        appquestionId: id,
        type: type,
        answer: [e.target.value],
      };
    } else if (question === "Street") {
      question1sub5 = {
        appquestionId: id,
        type: type,
        answer: [e.target.value],
      };
    } else if (question === "City") {
      question1sub6 = {
        appquestionId: id,
        type: type,
        answer: [e.target.value],
      };
    } else if (question === "Village") {
      question1sub7 = {
        appquestionId: id,
        type: type,
        answer: [e.target.value],
      };
    } else if (question === "Phone") {
      question1sub8 = {
        appquestionId: id,
        type: type,
        answer: [e.target.value],
      };
    } else if (question === "Category") {
      question1sub9 = {
        appquestionId: id,
        type: type,
        answer: [e.target.value],
      };
    }
  };

  const imageHandler = (setState) => async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  useEffect(() => {
    if (appQuestions) {
      firstQ = appQuestions.filter((item) => {
        return item.order === 9;
      });

      // question1sub2 = {
      //   appquestionId: firstQ[0].id,
      //   type: firstQ[0].type,
      // }
    }
    console.log(firstQ);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/shutin-locations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        console.log(data, "swsw");
        const wow = data.data;
        console.log(wow, "2222");
        wow.push({ name: "Other" });
        setAllLocations(wow);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [appQuestions]);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [allLocations]);
  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/shutin-location-address?shutInLocationId=${selectedLocation}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          console.log("address", data);
          const wow = document.getElementById("wowRegion");
          const wow1 = document.getElementById("wowRegion Name");
          const wow2 = document.getElementById("wowLot #");
          const wow3 = document.getElementById("wowCity");
          const wow4 = document.getElementById("wowStreet");
          const wow5 = document.getElementById("wowVillage");
          const wow6 = document.getElementById("wowPhone");
          const wow7 = document.getElementById("wowCategory");
          wow.value = data.data.regionNo;
          wow1.value = data.data.regionName;
          wow2.value = data.data.lot;
          wow3.value = data.data.city;
          wow4.value = data.data.street;
          wow5.value = data.data.village;
          wow6.value = data.data.phone;
          wow7.value = data.data.category;

          if (data.data.regionNo === null) {
            question1sub2 = {
              appquestionId: firstQ[0].childrenQuestions[1].id,
              type: firstQ[0].childrenQuestions[1].type,
              answers: [""],
            };
          } else {
            question1sub2 = {
              appquestionId: firstQ[0].childrenQuestions[1].id,
              type: firstQ[0].childrenQuestions[1].type,
              answers: [data.data.regionNo],
            };
          }

          if (data.data.regionName === null) {
            question1sub3 = {
              appquestionId: firstQ[0].childrenQuestions[2].id,
              type: firstQ[0].childrenQuestions[2].type,
              answers: [""],
            };
          } else {
            question1sub3 = {
              appquestionId: firstQ[0].childrenQuestions[2].id,
              type: firstQ[0].childrenQuestions[2].type,
              answers: [data.data.regionName],
            };
          }

          if (data.data.lot === null) {
            question1sub4 = {
              appquestionId: firstQ[0].childrenQuestions[3].id,
              type: firstQ[0].childrenQuestions[3].type,
              answers: [""],
            };
          } else {
            question1sub4 = {
              appquestionId: firstQ[0].childrenQuestions[3].id,
              type: firstQ[0].childrenQuestions[3].type,
              answers: [data.data.lot],
            };
          }

          if (data.data.city === null) {
            question1sub5 = {
              appquestionId: firstQ[0].childrenQuestions[4].id,
              type: firstQ[0].childrenQuestions[4].type,
              answers: [""],
            };
          } else {
            question1sub5 = {
              appquestionId: firstQ[0].childrenQuestions[4].id,
              type: firstQ[0].childrenQuestions[4].type,
              answers: [data.data.city],
            };
          }

          if (data.data.street === null) {
            question1sub6 = {
              appquestionId: firstQ[0].childrenQuestions[5].id,
              type: firstQ[0].childrenQuestions[5].type,
              answers: [""],
            };
          } else {
            question1sub6 = {
              appquestionId: firstQ[0].childrenQuestions[5].id,
              type: firstQ[0].childrenQuestions[5].type,
              answers: [data.data.street],
            };
          }

          if (data.data.village === null) {
            question1sub7 = {
              appquestionId: firstQ[0].childrenQuestions[6].id,
              type: firstQ[0].childrenQuestions[6].type,
              answers: [""],
            };
          } else {
            question1sub7 = {
              appquestionId: firstQ[0].childrenQuestions[6].id,
              type: firstQ[0].childrenQuestions[6].type,
              answers: [data.data.village],
            };
          }

          if (data.data.phone === null) {
            question1sub8 = {
              appquestionId: firstQ[0].childrenQuestions[7].id,
              type: firstQ[0].childrenQuestions[7].type,
              answers: [""],
            };
          } else {
            question1sub8 = {
              appquestionId: firstQ[0].childrenQuestions[7].id,
              type: firstQ[0].childrenQuestions[7].type,
              answers: [data.data.phone],
            };
          }

          if (data.data.category === null) {
            question1sub9 = {
              appquestionId: firstQ[0].childrenQuestions[8].id,
              type: firstQ[0].childrenQuestions[8].type,
              answers: [""],
            };
          } else {
            question1sub9 = {
              appquestionId: firstQ[0].childrenQuestions[8].id,
              type: firstQ[0].childrenQuestions[8].type,
              answers: [data.data.category],
            };
          }

          if (!test) {
            setTest(true);
          } else {
            setTest(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedLocation]);
  return (
    <div>
      <div className="w-full min-h-[25rem] pb-5 pt-5 bg-[#F0F0F0] flex flex-col justify-center sm:pl-10 sm:pr-10 pl-4 pr-4 rounded-2xl">
        <form>
          {firstQ != "" && (
            <div>
              <div>
                <h2 className={`mb-4 font-semibold`}>
                  {firstQ[0].questionText}
                </h2>
              </div>
              <div>
                {firstQ[0].choices.map((item, index) => {
                  return (
                    <div>
                      <Checkbox
                        className={`${
                          dash === "/Dashboard/application"
                            ? "checkk1"
                            : "checkk"
                        }`}
                        checked={checkBuffer === item ? true : false}
                        onClick={() => changeHandler(item, index)}
                      >
                        {item}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
              <div>
                {firstQ[0].childrenQuestions.length != 0 && (
                  <h2
                    className={`${
                      checkBuffer ===
                      firstQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                        ? ""
                        : "hidden"
                    } mb-4 lg:text-[1.1rem] font-semibold mt-2`}
                  >
                    {firstQ[0].childrenQuestions[0].wrapInHeading}
                  </h2>
                )}
                {firstQ[0].childrenQuestions.map((item, index) => {
                  return (
                    <div
                      className={`${
                        checkBuffer === item.showMeIfParentAnswerIs
                          ? ""
                          : "hidden"
                      }`}
                    >
                      <input
                        className={`${item.type === "text" ? "" : "hidden"} ${
                          allDis ? "pointer-events-none" : ""
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        placeholder={`${item.questionText}`}
                        onInput={(e) => {
                          changeAllHandler(
                            e,
                            item.id,
                            item.type,
                            item.questionText
                          );
                        }}
                        id={`wow${item.questionText}`}
                        key={`${item.id}`}
                      ></input>
                      {/* <input
                      className={`${
                        item.type === "number" ? "" : "hidden"
                      } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                      placeholder={`${item.questionText}`}
                    ></input> */}
                      {allLocations && (
                        <div
                          className={`${
                            item.type === "dropdown" ? "" : "hidden"
                          }`}
                        >
                          <Select
                            onChange={(value, r) => {
                              if (r.children === "Other") {
                                setAllDis(false);
                              } else {
                                setAllDis(true);
                                setSelectedLocation(value);
                              }
                              question1sub7 = {
                                appquestionId: item.id,
                                type: item.type,
                                answers: [r.children],
                              };
                            }}
                            // value={option}
                            placeholder={`${item.questionText}`}
                            className="mb-2 mt-2 bg-[#F7F7F7] wow"
                          >
                            {console.log(allLocations, "qwqw11")}
                            {allLocations.map((option) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div
                        className={`${item.type === "date" ? "" : "hidden"}`}
                      >
                        <DatePicker
                          style={{ marginBottom: "1.2rem" }}
                          className="date-class"
                          placeholder={`${item.questionText}`}
                        />
                      </div>
                      <div
                        className={`${item.type === "image" ? "" : "hidden"}`}
                      >
                        <label
                          htmlFor="special-input1"
                          className={` cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <img className="w-[1.2rem]" src={plusp} alt="" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">
                              {item.questionText}
                            </p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            id="special-input1"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(setDisplay)}
                          />
                        </label>
                        <div
                          className={`relative mb-4 ${display ? "" : "hidden"}`}
                        >
                          <img
                            className="w-[25rem] h-[13rem]"
                            src={display}
                            alt=""
                          />
                          <div
                            className={`absolute ${
                              isLoading ? "" : "hidden"
                            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                          >
                            <Spin size="large" />
                          </div>
                          <div
                            className="absolute bottom-0 right-0 cursor-pointer bg-white"
                            onClick={() => setDisplay("")}
                          >
                            <img className="w-[3rem]" src={trash} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </form>
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={nextHandler}
          className={`lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Mid;
