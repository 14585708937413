import BeneficiaryI from "../components/Beneficiary/BeneficiaryI";
import BenificiaryR from "../components/Beneficiary/BeneficiaryR";
import Nav from "../components/Nav/nav";
import back5 from "../imgs/back5.png";
import qwqw from "../imgs/qwqw.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddBenificiary from "../components/Beneficiary/AddBenificiary";
import { useEffect, useState } from "react";
let Show = [AddBenificiary, BenificiaryR, BeneficiaryI];
const BeneficiaryPage = () => {
  const router = useNavigate();
  const wowt = useSelector((state) => state.wowt);
  const [test, setTest] = useState(false);
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const Dis = Show[wowt];

  useEffect(() => {
    if (dash === "/EDashboard/BeneficiaryQuestions") {
      console.log("dddedcalled");
      Show = [BenificiaryR, BeneficiaryI];
    } else {
      Show = [BenificiaryR, BeneficiaryI];
    }
  }, []);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [Show]);
  return (
    <div>
      <Nav />
      <div className="relative flex justify-start pl-[10rem] pr-[20rem] items-center text-[1.8rem] font-bold mb-[5rem]">
        <div
          onClick={() => {
            dispatch({ type: "SET", num: 0 });
            dispatch({ type: "BRSET", num: 0 });
            dispatch({ type: "BISET", num: 0 });
            if (dash === "/EDashboard/BeneficiaryQuestions") {
              router("/EDashboard/listing");
            } else {
              router("/Dashboard/listing");
            }
          }}
          className="cursor-pointer pt-[5rem] mr-5"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <h2 className="pt-[5rem]">
          {wowt === 0
            ? "Beneficiary Registration"
            : "Beneficiary Identification"}
        </h2>
      </div>
      <div className=" mb-[5rem]">
        <Dis />
      </div>
      <div className="fixed bottom-[8%] right-[3%] bg-[#25D366] w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
        <img className=" w-[3.5rem]" src={qwqw} alt="" />
      </div>
    </div>
  );
};

export default BeneficiaryPage;
