import real1 from "../../imgs/real1.png";
import real2 from "../../imgs/real2.png";
import real4 from "../../imgs/real4.png";
import reals from "../../imgs/reals.png";
import prof from "../../imgs/prof.png";
import ee from "../../imgs/ee.png";
import gro from "../../imgs/gro.png";
import effect from "../../imgs/effect.png";
import Popup1 from "../../components/Popup/popup1";
import oapm from "../../imgs/oapm.png";
import eve from "../../imgs/eve.png";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";

const MainDash = () => {
  const show = useSelector((state) => state.isLogged);
  const router = useNavigate();
  const dispatch = useDispatch("");
  const token = useSelector((state) => state.token);
  const dataUser = useSelector((state) => state.dataUser);

  return (
    <div className="">
      <div className="mt-[4rem] flex justify-center text-[white]">
        <div className="flex justify-center items-center flex-col pb-[3rem]">
          <div className="flex">
            <div className="grid xl:grid-cols-3 lg:grid-cols-2 lg:gap-x-3 gap-y-5 grid-cols-1">
              <div className="flex justify-center items-center">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    if (dataUser.data.user.status === "rejected") {
                      dispatch({ type: "SIGN_IN" });
                    } else {
                      router("/Dashboard/listing");
                    }
                  }}
                >
                  <div className="overflow-hidden relative font-bold text-[1rem] flex justify-center items-center shadow-2xl sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] mb-5 rounded-2xl">
                    <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                      <div className="flex justify-between">
                        <div className=" flex items-center">
                          <div className="ml-3">
                            <p className="sm:text-[1rem] font-bold text-black">
                              Beneficiary
                            </p>
                            <div className="flex items-center ">
                              <img
                                src={effect}
                                className="scale-[0.7]"
                                alt=""
                              />
                              <p className="text-[0.9rem] font-normal text-black">
                                OAP
                              </p>
                            </div>
                          </div>
                        </div>
                        <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium mt-3 rounded-lg sm:inline-block hidden pt-1 pb-1 sm:pl-4 sm:pr-4  pl-1 pr-1">
                          Active
                        </p>
                      </div>
                      <div className="overflow-hidden  rounded-2xl h-[13rem] mt-3">
                        <img
                          className="rounded-2xl w-full mr-[10rem]"
                          src={real1}
                          alt=""
                        />
                      </div>
                      <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2">
                        <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                          View edit or add a beneficiary. To manage your
                          benefits
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    if (dataUser.data.user.status === "rejected") {
                      dispatch({ type: "SIGN_IN" });
                    } else {
                      router("/Dashboard/allApplications");
                    }
                  }}
                >
                  <div className="overflow-hidden relative font-bold text-[1rem] bg-white shadow-2xl ml-4 sm:h-[22rem] sm:w-[13rem] w-[10rem] h-[18rem] rounded-2xl flex justify-center items-center">
                    <div className="sm:p-4 p-2 w-full h-full   relative  rounded-2xl flex flex-col justify-between">
                      <div className="flex justify-between">
                        <div className=" flex items-center">
                          <div className="ml-3">
                            <p className="text-[1rem] font-bold text-black">
                              Applications
                            </p>
                            <div className="flex items-center ">
                              <img
                                src={effect}
                                className="scale-[0.7]"
                                alt=""
                              />
                              <p className="text-[0.9rem] font-normal text-black">
                                OAP
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="sm:absolute hidden right-[-1%] tosm:p-4 p-2">
                          <img src={ee} className="w-[7rem]" alt="" />
                        </div>
                      </div>

                      <div className="overflow-hidden  rounded-2xl h-[12rem] mt-6">
                        <img
                          className="rounded-2xl w-full"
                          src={real4}
                          alt=""
                        />
                      </div>
                      <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2">
                        <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                          Click to complete the application for approval and
                          your benefits
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col   justify-center items-center">
                <div className="flex">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      if (dataUser.data.user.status === "rejected") {
                        dispatch({ type: "SIGN_IN" });
                      } else {
                        router("/Dashboard/transaction_history");
                      }
                    }}
                  >
                    <div className="relative overflow-hidden font-bold  shadow-2xl text-[1rem] sm:h-[15.5rem] sm:w-[13rem] w-[10rem] h-[18rem] rounded-2xl">
                      <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                        <div className="flex justify-between">
                          <div className=" flex items-center">
                            <div className="ml-3">
                              <p className="text-[1rem] font-bold text-black">
                                Pension History
                              </p>
                              <div className="flex items-center">
                                <img
                                  src={effect}
                                  className="scale-[0.7]"
                                  alt=""
                                />
                                <p className="text-[0.9rem] font-normal text-black">
                                  OAP
                                </p>
                              </div>
                            </div>
                            <p className="h-[1.4rem] right-2 top-[16%] sm:absolute sm:inline-block hidden bg-[#F7542E] text-white text-[0.7rem] font-medium rounded-lg  pt-1 pb-1 pl-3 pr-3 ">
                              Active
                            </p>
                          </div>
                        </div>
                        <div className="overflow-hidden rounded-2xl h-[9rem]">
                          <img
                            className="rounded-2xl w-full h-[9rem]"
                            src={reals}
                            alt=""
                          />
                        </div>
                        <div className="bg-[#57D38C]/[0.9]  rounded-2xl p-2 mt-2">
                          <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                            View the history of your benefits and transactions
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => alert("Coming Soon")}
                  >
                    <div className="overflow-hidden relative font-bold text-[1rem] ml-4 flex justify-center items-center  shadow-2xl  sm:h-[15.5rem] sm:w-[13rem] w-[10rem] h-[18rem] rounded-2xl">
                      <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                        <div className="flex justify-between">
                          <div className=" flex items-center">
                            <div className="ml-3">
                              <p className="text-[1rem] font-bold text-black">
                                Merchants
                              </p>
                              <div className="flex items-center ">
                                <img
                                  src={effect}
                                  className="scale-[0.7]"
                                  alt=""
                                />
                                <p className="text-[0.9rem] font-normal text-black">
                                  OAP
                                </p>
                              </div>
                            </div>
                          </div>
                          <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium mt-3 rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                            Active
                          </p>
                        </div>
                        <div className="overflow-hidden rounded-2xl h-[8rem] flex justify-center">
                          <img
                            className="rounded-2xl object-cover w-[13rem] h-[8rem]"
                            src={real2}
                            alt=""
                          />
                        </div>
                        <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2 mt-2">
                          <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                            View merchants near you for receiving your benefits,
                            pay bills and purchase grocery
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => alert("Coming Soon")}
                >
                  <div className="overflow-hidden relative font-bold text-[1rem] bg-[#57D38C]/[0.9] shadow-2xl mt-4 sm:h-[5rem] sm:w-[27rem] w-[22rem] rounded-2xl flex justify-center items-center">
                    <div className=" p-2 w-full h-full   relative  rounded-2xl flex flex-col justify-between">
                      <div className="flex justify-between ">
                        <div className=" flex items-center">
                          <div className="w-[2.5rem] h-[2.5rem]">
                            <img className="object-cover" src={gro} alt="" />
                          </div>
                          <div className="ml-2">
                            <p className="text-[1rem] font-bold text-white">
                              General Register Office
                            </p>
                          </div>
                        </div>
                        <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium mt-3 rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                          Non-Active
                        </p>
                      </div>
                      <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                        Updated records for deceased members of the Social
                        Programs
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    alert("Coming Soon");
                  }}
                >
                  <div className="overflow-hidden relative font-bold text-[1rem] flex justify-center items-center shadow-2xl sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] mb-5 rounded-2xl">
                    <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                      <div className="flex justify-between">
                        <div className=" flex items-center">
                          <div className="ml-3">
                            <p className="text-[1rem] font-bold text-black">
                              OAP Money
                            </p>
                            <div className="flex items-center ">
                              <img
                                src={effect}
                                className="scale-[0.7]"
                                alt=""
                              />
                              <p className="text-[0.9rem] font-normal text-black">
                                OAP
                              </p>
                            </div>
                          </div>
                        </div>
                        <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium mt-3 rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                          Active
                        </p>
                      </div>
                      <div className="overflow-hidden  rounded-2xl h-[13rem] mt-3">
                        <img
                          className="rounded-2xl w-full mr-[10rem]"
                          src={oapm}
                          alt=""
                        />
                      </div>
                      <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2">
                        <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                          Use your QR Code for purchases. The history will be
                          recorded in your profile
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    alert("Coming Soon");
                  }}
                >
                  <div className="overflow-hidden ml-4 relative font-bold text-[1rem] flex justify-center items-center shadow-2xl sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] mb-5 rounded-2xl">
                    <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                      <div className="flex justify-between">
                        <div className=" flex items-center">
                          <div className="ml-3">
                            <p className="text-[1rem] font-bold text-black">
                              Events
                            </p>
                            <div className="flex items-center ">
                              <img
                                src={effect}
                                className="scale-[0.7]"
                                alt=""
                              />
                              <p className="text-[0.9rem] font-normal text-black">
                                OAP
                              </p>
                            </div>
                          </div>
                        </div>
                        <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium mt-3 rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                          Active
                        </p>
                      </div>
                      <div className="overflow-hidden rounded-2xl h-[13rem] mt-3">
                        <img
                          className="rounded-2xl sm:w-full w-[7rem] mr-[10rem]"
                          src={eve}
                          alt=""
                        />
                      </div>
                      <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2">
                        <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                          The announments will be for public or private
                          (Ministry) for scheduling events and distributions
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div
        className={`${
          dataUser.data.user.status === "approved" ? "hidden" : ""
        }`}
      >
        <div className={`${show ? "hidden" : ""}`}>
          <Popup1 />
        </div>
      </div>
    </div>
  );
};

export default MainDash;
