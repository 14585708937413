import { Select, DatePicker } from "antd";
import { useState } from "react";
import Part1 from "./tabsI/part1";
import Part2 from "./tabsI/part2";
import Part3 from "./tabsI/part3";
import { useSelector } from "react-redux";

const Bidentification = () => {
  const [wow, setWow] = useState(0);
  const profileData = useSelector((state) => state.profileData);
  const benDone = useSelector((state) => state.benDone);
  const Show = [Part1, Part2, Part3];
  const Dis = Show[wow];
  console.log(profileData, "de");
  return (
    <div className="">
      {((benDone != "Done" && profileData.verificationDocs) ||
        (benDone === "Done" && profileData.beneficiaryVerificationDocs)) && (
        <div>
          <div className="flex mb-8">
            <button
              onClick={() => setWow(0)}
              className={` ${
                wow === 0 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
              }  p-2 rounded-xl mr-2  pl-4 pr-4 min-w-[5.5rem] relative`}
            >
              IDs
              <div
                className={`${
                  wow === 0 ? "" : "hidden"
                } arrow-down absolute right-[35%] bottom-[-38%]`}
              ></div>
            </button>
            <button
              onClick={() => setWow(1)}
              className={`${
                wow === 1 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
              } flex p-2 rounded-xl mr-2 pl-4 pr-4 min-w-[5.5rem] relative`}
            >
              Certificates
              <div
                className={`${
                  wow === 1 ? "" : "hidden"
                } arrow-down absolute right-[35%] bottom-[-38%]`}
              ></div>
            </button>

            <button
              onClick={() => setWow(2)}
              className={`${
                wow === 2 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
              } flex p-2 rounded-xl mr-2 pl-4 pr-4  relative`}
            >
              Tax IDs
              <div
                className={`${
                  wow === 2 ? "" : "hidden"
                } arrow-down absolute right-[35%] bottom-[-38%]`}
              ></div>
            </button>
          </div>
          <Dis />
        </div>
      )}
    </div>
  );
};

export default Bidentification;
