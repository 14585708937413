import { Select, DatePicker } from "antd";
import { useState } from "react";
import Part1 from "../../BAccount/parts/tabsI/part1";
import Part2 from "../../BAccount/parts/tabsI/part2";
import Part3 from "../../BAccount/parts/tabsI/part3";
import placeholder from "../../../imgs/placeholder.png";
import { useSelector } from "react-redux";

const Pidentification = () => {
  const [wow, setWow] = useState(0);
  const profileData = useSelector((state) => state.profileData);
  const Show = [Part1, Part2, Part3];
  const Dis = Show[wow];
  return (
    <div className="">
      {profileData.verificationDocs && (
        <div>
          <div className="flex mt-8 mb-[5rem]">
            <button
              onClick={() => setWow(0)}
              className={` ${
                wow === 0 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
              }  p-2 rounded-xl mr-2  pl-4 pr-4 min-w-[5.5rem] relative`}
            >
              <div className=" text-center w-[100%]">IDs</div>
              <div
                className={`${
                  wow === 0 ? "" : "hidden"
                } arrow-down absolute right-[35%] bottom-[-38%]`}
              ></div>
            </button>
            <button
              onClick={() => setWow(1)}
              className={`${
                wow === 1 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
              } flex p-2 rounded-xl mr-2 pl-4 pr-4 min-w-[5.5rem] relative`}
            >
              <div className=" text-center w-[100%]">Certificates</div>
              <div
                className={`${
                  wow === 1 ? "" : "hidden"
                } arrow-down absolute right-[35%] bottom-[-38%]`}
              ></div>
            </button>
            <button
              onClick={() => setWow(2)}
              className={`${
                wow === 2 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
              } flex p-2 rounded-xl mr-2 pl-4 pr-4  relative`}
            >
              Tax IDs
              <div
                className={`${
                  wow === 2 ? "" : "hidden"
                } arrow-down absolute right-[35%] bottom-[-38%]`}
              ></div>
            </button>
          </div>
          <Dis />
        </div>
      )}
    </div>
  );
};

export default Pidentification;
