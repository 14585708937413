import { useEffect } from "react";
import prof from "../../../imgs/prof.png";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
const Pre = () => {
  const namePerson = useSelector((state) => state.namePerson);
  const imagePerson = useSelector((state) => state.imagePerson);
  const [test, setTest] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [namePerson, imagePerson]);
  return (
    <div>
      <div className="w-full h-[25rem] bg-[#F0F0F0] flex flex-col items-center justify-center pl-10 pr-10 rounded-2xl">
        <div className="w-[5rem] h-[5rem] rounded-full">
          <img
            className="w-[5rem] h-[5rem] rounded-full border-black border-2"
            src={imagePerson}
            alt=""
          />
        </div>
        <p className="font-bold mt-2">Hello {namePerson}</p>
        <p className="text-center mt-3 text-[1rem]">
          We need some information for the Old Age Pension Program. Please click
          on next to provide all the information.
        </p>
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={() => dispatch({ type: "AINCREMENT" })}
          className={`lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pre;
