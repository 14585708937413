import { DatePicker, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import plusp from "../../../imgs/plusp.png";
import { useState, useMemo } from "react";
import axios from "axios";
import countryList from "react-select-country-list";
import { useLocation } from "react-router";
import trash from "../../../imgs/trash.png";
import { Spin } from "antd";
import across from "../../../imgs/across.png";
import moment from "moment";
import logo1 from "../../../imgs/logo12.png";
import cross from "../../../imgs/cross.png";

let file;
const SecondI = () => {
  const location = useLocation();
  const benId = useSelector((state) => state.benId);
  const dataUser = useSelector((state) => state.dataUser);
  const popData = useSelector((state) => state.popData);
  const icurrent = useSelector((state) => state.icurrent);
  const dash = location.pathname;
  const token = useSelector((state) => state.token);
  const wowt = useSelector((state) => state.wowt);
  const dispatch = useDispatch();
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const [display2, setDisplay2] = useState("");
  const option = useSelector((state) => state.option);

  const [birthNo, setBirthNo] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthFront, setBirthFront] = useState("");

  const [dirvNo, setDrivNo] = useState("");
  const [drivExp, setDrivExp] = useState("");
  const [dirvCountry, setDrivCountry] = useState("");
  const [drivFront, setDrivFront] = useState("");
  const [drivBack, setDrivBack] = useState("");
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [show, setShow] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [iShow1, setIShow1] = useState(false);
  const [iShow2, setIShow2] = useState(false);
  const [err, setErr] = useState("");

  const [value, setValue] = useState(null);
  const options1 = useMemo(() => countryList().getData(), []);

  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    if (file === undefined) {
      setIsLoading1(false);
      setIsLoading2(false);
      setIsLoading3(false);
    } else {
      const base641 = await convertToBase64(file);
      setState(base641);
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      console.log(file, "see");
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          setUrl(data.data.url);
          load(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const nextHandler = (e) => {
    e.preventDefault();
    const body = [];
    if (dirvNo || dirvCountry || drivExp || drivFront != "" || drivBack != "") {
      const drivData = {
        type: "driver license",
        docIDName: "drivers license no",
        docIDValue: `${dirvNo}`,
        countryIssued: `${dirvCountry}`,
        expiryDate: `${drivExp}`,
        docImages: [],
      };
      if (drivFront != "") {
        drivData.docImages[0] = {
          type: "front image",
          imageUrl: `${drivFront}`,
        };
      }
      if (drivBack != "") {
        drivData.docImages[1] = {
          type: "front image",
          imageUrl: `${drivBack}`,
        };
      }
      body.push(drivData);
    }
    if (birthNo || birthDate || birthFront != "") {
      const birthData = {
        type: "birth certificate",
        docIDName: "birth cert",
        docIDValue: `${birthNo}`,
        issuedDate: `${birthDate}`,
        docImages: [],
      };
      if (birthFront != "") {
        birthData.docImages[0] = {
          type: "front image",
          imageUrl: `${birthFront}`,
        };
      }
      body.push(birthData);
    }
    if (icurrent <= 9) {
      if (dash === "/EDashboard/addPensioner") {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/verification-docs/add-by-staff?verificationDocUserId=${popData.id}`,
            { verificationDocs: body },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "oo");
            dispatch({ type: "IdDoneSet", num: "Yes" });
            dispatch({ type: "IINCREMENT" });
          })
          .catch((err) => {
            console.log(err);
            if (
              err.response.data.message === '"expiryDate" must be a valid date'
            ) {
              setErr("Please verify if expiration date is available on card");
            } else {
              setErr(err.response.data.message);
            }
            setShow(true);
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/verification-docs`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "oo");
            dispatch({ type: "IdDoneSet", num: "Yes" });
            dispatch({ type: "IINCREMENT" });
          })
          .catch((err) => {
            console.log(err);
            if (
              err.response.data.message === '"expiryDate" must be a valid date'
            ) {
              setErr("Please verify if expiration date is available on card");
            } else {
              setErr(err.response.data.message);
            }
            setShow(true);
          });
      }
    } else {
      if (dash === "/EDashboard/BeneficiaryQuestions") {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/verification-docs/add-by-staff?beneficiaryUserId=${benId}`,
            { verificationDocs: body },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "change");
            dispatch({ type: "IdDoneSet", num: "Yes" });
            dispatch({ type: "BISET", num: 2 });
          })
          .catch((err) => {
            console.log(err);
            if (
              err.response.data.message === '"expiryDate" must be a valid date'
            ) {
              setErr("Please verify if expiration date is available on card");
            } else {
              setErr(err.response.data.message);
            }
            setShow(true);
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/verification-docs?beneficiaryUserId=${benId}`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "change");
            dispatch({ type: "IdDoneSet", num: "Yes" });
            if (dash === "/Dashboard/BeneficiaryQuestions") {
              dispatch({ type: "BISET", num: 2 });
              console.log("called");
            } else {
              dispatch({ type: "IINCREMENT" });
            }
          })
          .catch((err) => {
            console.log(err);
            if (
              err.response.data.message === '"expiryDate" must be a valid date'
            ) {
              setErr("Please verify if expiration date is available on card");
            } else {
              setErr(err.response.data.message);
            }
            setShow(true);
          });
      }
    }

    if (body.length === 0) {
      if (
        dash === "/Dashboard/BeneficiaryQuestions" ||
        dash === "/EDashboard/BeneficiaryQuestions"
      ) {
        dispatch({ type: "BISET", num: 2 });
        console.log("called");
      } else {
        dispatch({ type: "IINCREMENT" });
      }
    }
  };

  const handleChange = (setState, check) => (event) => {
    if (check === "tg") {
      setState(event);
    } else if (check === "tgf") {
      let wow;
      if (event.$M <= 9) {
        if (event.$D <= 9) {
          wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
        }
      } else {
        if (event.$D <= 9) {
          wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
        }
      }
      setState(wow);
    } else {
      setState(event.target.value);
    }
  };
  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }

  const date = getCurrentDate();
  const disabledBeforeDate = moment(
    `${date.props.children[0]}-${date.props.children[2]}-${date.props.children[4]}`
  );
  const disabledDate = (current) => {
    return current && current < disabledBeforeDate;
  };
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };
  const country1Handler = (e, target) => {
    setDrivCountry(target.label);
  };
  return (
    <div>
      <form onSubmit={nextHandler} action="" className=" mt-10">
        <div className="flex justify-center items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Driver's License #
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Driver's License #"
                  onChange={handleChange(setDrivNo)}
                  value={dirvNo}
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Expiration Date
                </label>
                <DatePicker
                  placeholder="Expiration Date"
                  disabledDate={disabledDate}
                  style={{ marginBottom: "1.2rem" }}
                  className="date-class"
                  onChange={handleChange(setDrivExp, "tgf")}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Country Issued
                </label>
                <Select
                  placeholder="Country Issued"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1.2rem" }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={options1}
                  onChange={(e, de) => country1Handler(e, de)}
                  // onChange={handleChange(setDrivCountry, "tg")}
                />
              </div>

              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Image Front
              </h2>
              <label
                htmlFor="special-input1"
                className={`${
                  isLoading1 || isLoading2 || isLoading3
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Image Front</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input1"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay1,
                    setDrivFront,
                    setIsLoading1
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display1}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading1 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <div
                  className="absolute bottom-0 right-0 bg-white cursor-pointer"
                  onClick={() => {
                    setDisplay1("");
                    setDrivFront("");
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    const wow = document.getElementById("special-input1");
                    wow.value = "";
                  }}
                >
                  <img className="w-[3rem]" src={trash} alt="" />
                </div>
              </div>
              <div
                className={`${
                  iShow ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display1} alt="" />
                </div>
              </div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Image Back
              </h2>
              <label
                htmlFor="special-input2"
                className={`${
                  isLoading1 || isLoading2 || isLoading3
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Image Back</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input2"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay2,
                    setDrivBack,
                    setIsLoading2
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display2 ? "" : "hidden"}`}>
                <div
                  className={`absolute ${
                    isLoading2 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <img
                  onClick={() => {
                    setIShow1(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display2}
                  alt=""
                />
                <div
                  className="absolute bottom-0 right-0 bg-white cursor-pointer"
                  onClick={() => {
                    setDisplay2("");
                    setDrivBack("");
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    const wow = document.getElementById("special-input2");
                    wow.value = "";
                  }}
                >
                  <img className="w-[3rem]" src={trash} alt="" />
                </div>
              </div>
            </div>
            <div
              className={`${
                iShow1 ? "" : "hidden"
              } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
            >
              <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                <img
                  className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                  src={cross}
                  onClick={() => setIShow1(false)}
                  alt=""
                />
                <img className="w-[45rem] h-[28rem]" src={display2} alt="" />
              </div>
            </div>
            <div>
              <div className={`${option === "Pensioner" ? "" : "hidden"}`}>
                <div className="flex flex-col">
                  <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Birth Certificate #
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    onChange={handleChange(setBirthNo)}
                    value={birthNo}
                    placeholder="Birth Certificate #"
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Date Issued
                  </label>
                  <DatePicker
                    placeholder="Date Issued"
                    disabledDate={disabledDate1}
                    onChange={handleChange(setBirthDate, "tgf")}
                    style={{ marginBottom: "1.2rem" }}
                    className="date-class"
                  />
                </div>

                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Image Front
                </h2>
                <label
                  htmlFor="special-input"
                  className={`${
                    isLoading1 || isLoading2 || isLoading3
                      ? "pointer-events-none"
                      : ""
                  } cursor-pointer relative`}
                >
                  <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                    <div>
                      <img className="w-[1.2rem]" src={plusp} alt="" />
                    </div>
                    <p className="text-[#9CA6C2] ml-2">Image Front</p>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    id="special-input"
                    accept="image/png, image/gif, image/jpeg"
                    onInput={imageHandler(
                      setDisplay,
                      setBirthFront,
                      setIsLoading3
                    )}
                  />
                </label>
                <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
                  <div
                    className={`absolute ${
                      isLoading3 ? "" : "hidden"
                    } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                  >
                    <Spin size="large" />
                  </div>
                  <img
                    onClick={() => {
                      setIShow2(true);
                    }}
                    className="w-[25rem] h-[13rem]"
                    src={display}
                    alt=""
                  />
                  <div
                    className="absolute bottom-0 right-0 bg-white cursor-pointer"
                    onClick={() => {
                      setDisplay("");
                      setBirthFront("");
                      setIsLoading1(false);
                      setIsLoading2(false);
                      setIsLoading3(false);
                      const wow = document.getElementById("special-input");
                      wow.value = "";
                    }}
                  >
                    <img className="w-[3rem]" src={trash} alt="" />
                  </div>
                </div>
                <div
                  className={`${
                    iShow2 ? "" : "hidden"
                  } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      onClick={() => setIShow2(false)}
                      alt=""
                    />
                    <img className="w-[45rem] h-[28rem]" src={display} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={` lg:mt-[3rem] flex mt-4 items-center sm:justify-end  justify-center sm:w-[85%] `}
        >
          {/* <button
            onClick={() => dispatch({ type: "IDECREMENT" })}
            className={`lg:text-[1.1rem] bg-[#EDEFFD] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button> */}
          <button
            type="submit"
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Next
          </button>
        </div>
      </form>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" />
          </div>
          <p className="text-center mt-[1rem] mb-5">{err}</p>

          <button
            type="submit"
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#57D38C] w-[60%]"
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecondI;
