import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
let data;
const LoginCredentials1 = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);

  const [test, setTest] = useState(false);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data]);
  useEffect(() => {
    console.log(profileData, "wss1122w");
    if (profileData.user) {
      data = profileData.user;
      if (!test) {
        setTest(true);
      } else {
        setTest(false);
      }
    }
  }, [profileData]);
  return (
    <div>
      {data && (
        <div>
          <h2 className="mb-4 text-center text-[1.5rem] font-bold">
            Login Credentials
          </h2>
          <h2 className="mb-4 text-[1.1rem] font-semibold">Mobile:</h2>
          {console.log(data, "222222")}
          <input
            type="email"
            required
            name=""
            id=""
            placeholder={"Phone"}
            value={
              data.phoneNo === null ? "" : `${data.countryCode} ${data.phoneNo}`
            }
            className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4"
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Email:</h2>
          <input
            type="email"
            required
            name=""
            id=""
            value={data.email}
            placeholder={"Email"}
            className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 pointer-events-none"
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">GID:</h2>
          <input
            type="text"
            name=""
            id=""
            value={data.gid}
            placeholder={"GID"}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 pointer-events-none`}
          />
        </div>
      )}
      {/* <button
        className={`lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
      >
        Save
      </button> */}
    </div>
  );
};

export default LoginCredentials1;
