import address from "../../imgs/address.png";
import mail from "../../imgs/mail.png";
import call from "../../imgs/call.png";
import back5 from "../../imgs/back5.png";
import { useLocation, useNavigate } from "react-router-dom";
const Contact = () => {
  const router = useNavigate();
  const location = useLocation();
  return (
    <div className="w-full bg-[#F5F4F0]">
      <div className="sm:ml-[6rem] ml-[1rem]  flex items-center">
        <div
          onClick={() => {
            if (location.pathname === "/EDashboard/contact") {
              router("/EDashboard");
            } else {
              router("/Dashboard");
            }
          }}
          className="mt-[2.8rem] mr-2 cursor-pointer"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <h2 className="text-[1.8rem] font-bold pt-[3rem]">Contact Us</h2>
      </div>
      <div className="flex sm:flex-row flex-col items-center justify-between pl-[5rem] pr-[7rem] mt-[7rem]">
        <div className="w-[20rem] flex flex-col items-center justify-start mb-[6rem]">
          <div>
            <img src={call} alt="" />
          </div>
          <p className="font-semibold mt-4 text-[1.1rem] mb-5">By Phone</p>
          <p className="text-[#9B9A9D] font-medium">Tel:</p>
          <p className="text-[#9B9A9D] font-medium">+592-225-7112</p>
          <p className="text-[0.7rem] font-medium mt-4">
            (Monday to Friday 9am to 4pm CST)
          </p>
        </div>
        <div className="w-[20rem] flex flex-col items-center justify-start mb-[4rem]">
          <div>
            <img src={mail} alt="" />
          </div>
          <p className="font-semibold mt-4 text-[1.1rem] mb-5">By Email</p>
          <p className="text-[#9B9A9D] font-medium text-center mb-2">
            Just send us your questions and concerns at the email provided below
          </p>
          <a href="mailto:info@mhsss.gov.gy">
            <button className="bg-[white] w-[13rem] text-center pt-3 pb-3 mt-4 font-medium rounded-xl">
              info@mhsss.gov.gy
            </button>
          </a>
        </div>
        <div className="w-[20rem] flex flex-col items-center justify-start mb-[4rem]">
          <div>
            <img src={address} alt="" />
          </div>
          <p className="font-semibold mt-4 text-[1.1rem] mb-5">Location</p>
          {/* <p>Ministry of Human Services and Social Security (MHSSS)</p> */}
          <p className="text-[#9B9A9D] font-medium text-center mb-2">
            357 Lamaha & East Streets, South Cummingsburg, Georgetwon
          </p>
          <a
            href="https://www.google.com/maps/search/?api=1&query=1 Water St, Georgetown, Guyana"
            target="_blank"
          >
            <button className="bg-[white] w-[13rem] text-center pt-3 pb-3 mt-4 font-medium rounded-xl">
              View
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
