import test from "../imgs/test11.jpg";
import logo1 from "../imgs/logo12.png";
import Flow from "../components/Flow";
import ForgotPassword from "../components/Forgot/ForgotPassword";
import Popup from "../components/Popup/popup";
import Awaited from "../components/Awaited/Awaited";
import { useDispatch, useSelector } from "react-redux";
import Vpopup from "../components/Popup/Vpopup";
import Vppopup from "../components/Popup/Vppopup";
import w1 from "../imgs/w1.png";
import w2 from "../imgs/w2.png";
import special from "../imgs/w2.png";
import final from "../imgs/final22.png";
import final2 from "../imgs/final33.png";
import final3 from "../imgs/final44.png";
import final4 from "../imgs/final55.png";

import logo from "../imgs/impLogo.png";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { useState } from "react";
import Pensioner from "../imgs/pensioner.png";
import Employee from "../imgs/employee.png";
import Merchant from "../imgs/merchant.png";
import check from "../imgs/checkkk.png";
import events from "../imgs/events.png";
import Register from "../components/Register/Register";
import Login from "../components/Login/login";
import FirstR from "../components/Register/stepsR/FirstR";
import SecondR from "../components/Register/stepsR/SecondR";
import Group from "../imgs/Group 1.png";
import Popup1 from "../components/Popup/popup1";
import { useEffect } from "react";

const Initial = () => {
  const icurrent = useSelector((state) => state.icurrent);
  const option = useSelector((state) => state.option);
  const rcurrent = useSelector((state) => state.rcurrent);
  const wowx = useSelector((state) => state.wowx);
  const Show1 = [Login, Register];
  const Dis1 = Show1[rcurrent];
  const dispatch = useDispatch();
  const [pc, setPc] = useState(true);
  const [ec, setEc] = useState(false);
  const [mc, setMc] = useState(false);

  const optHandler = (e) => {
    if (e === "Pensioner") {
      dispatch({ type: "PENSIONER" });
      setPc(true);
      setEc(false);
      setMc(false);
    }
    if (e === "Employee") {
      dispatch({ type: "EMPLOYEE" });
      setPc(false);
      setEc(true);
      setMc(false);
    }
    if (e === "Merchant") {
      dispatch({ type: "MERCHANT" });
      setPc(false);
      setEc(false);
      setMc(true);
    }
  };
  function topFunction() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  return (
    //#6A5ADB

    <div>
      <div className=" w-full bg-[#ebfff3]">
        <div className="flex items-center justify-between sm:mb-[0rem] mb-[0.5rem]">
          <div
            className="pt-2 mb-1 cursor-pointer"
            onClick={() => {
              dispatch({ type: "PENSIONER" });
              dispatch({ type: "RSET", num: 0 });
              dispatch({ type: "ISET", num: 1 });
              dispatch({ type: "ASET", num: 0 });
              dispatch({ type: "BRSET", num: 0 });
              dispatch({ type: "IdDoneSet", num: "" });
            }}
          >
            <img
              className="sm:w-[5.5rem] sm:ml-[5rem] w-[4rem] ml-[2rem]"
              src={logo}
              alt=""
            />
          </div>
          <p className="sm:text-[2rem] sm:mr-[4rem] mr-[1rem] text-[1.4rem] mt-[0.5rem] font-bold">
            <span className="">Old Age&nbsp;</span>
            <span className="text-[#57D38C]">Pension</span>
          </p>
          <p className="mr-[4rem] text-white"></p>
        </div>
        <div className="w-[100%] flex justify-center items-center">
          <div
            className={`bg-white border-white sm:min-h-[30rem]  sm:pb-[5rem] pb-[2rem] overflow-hidden w-[90%] mb-[5rem] shadow-2xl rounded-2xl rounded-tl-2xl`}
          >
            <Dis1 />
          </div>
        </div>
      </div>
      <Popup />
      <Vpopup />
      <Vppopup />
    </div>
  );
};

export default Initial;
