import BeneficiaryI from "../components/Beneficiary/BeneficiaryI";
import BenificiaryR from "../components/Beneficiary/BeneficiaryR";
import Nav from "../components/Nav/nav";
import back5 from "../imgs/back5.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddBenificiary from "../components/Beneficiary/AddBenificiary";
import { useEffect, useState } from "react";
import { Select } from "antd";
import axios from "axios";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import across from "../imgs/across.png";
import pdf from "../imgs/pdf.png";
import qwqw from "../imgs/qwqw.png";
import { Input } from "antd";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

let Show = [AddBenificiary, BenificiaryR, BeneficiaryI];

const DistriutionsPage = () => {
  const router = useNavigate();
  const universalPenId = useSelector((state) => state.universalPenId);
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const [test, setTest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [allData, setAllData] = useState();
  const [selectedData, setSelectedData] = useState();
  const [err, setErr] = useState("");
  const [err2, setErr2] = useState("");
  const [show1, setShow1] = useState(false);
  const [once, setOnce] = useState(true);
  const [allRegions, setAllRegions] = useState([]);
  const [allLocationCodes, setAllLocationCodes] = useState([]);
  const [allRData, setAllRData] = useState([]);
  const [allLData, setAllLData] = useState([]);
  const [regionName, setRegionName] = useState("");
  const [regionNo, setRegionNo] = useState("");
  const [regionId, setRegionId] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const [centerId, setCenterId] = useState("");
  const [allCenterId, setAllCenterId] = useState([]);
  const [allCenterData, setAllCenterData] = useState([]);
  const [centerName, setCenterName] = useState("");
  const [coverage, setCoverage] = useState("");
  const [coverageId, setCoverageId] = useState("");
  const [coverageIdR, setCoverageIdR] = useState("");
  const [coverageData, setCoverageData] = useState([]);
  const [allCoverageId, setAllCoverageId] = useState([]);
  const [already, setAlready] = useState(false);
  const [distributionlocationaddressId, setDistributionlocationId] =
    useState("");

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  useEffect(() => {
    return () => {
      setErr2("");
    };
  }, []);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [items]);
  console.log(err2, "swdwsa");

  useEffect(() => {
    if (once) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/distribution-coverages/search?search=ab`
        )
        .then((data) => {
          console.log(data, "2121");
          setOnce(false);
          let wow = [];
          data.data.rows.map((ww, index) => {
            wow.push({
              value: index,
              label: `${ww.coverage}`,
            });
          });

          setItems(wow);
          setAllData(data.data.rows);
          console.log(items, "221222222222222");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/distribution-info?pensionerUserId=${universalPenId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "121321");
        setAlready(true);
        setCoverage(data.data.distributioncoverage.coverage);
        setRegionName(
          data.data.distributioncoverage.distributioncenter.distributionlocation
            .distributionregion.regionName
        );
        setRegionNo(
          data.data.distributioncoverage.distributioncenter.distributionlocation
            .distributionregion.regionNo
        );
        setLocationName(
          data.data.distributioncoverage.distributioncenter.distributionlocation
            .locationName
        );
        setLocationCode(
          data.data.distributioncoverage.distributioncenter.distributionlocation
            .locationCode
        );
        setCenterName(
          data.data.distributioncoverage.distributioncenter.centerName
        );
        setCoverageIdR(data.data.distributioncoverage.coverage);
        setCenterId(
          data.data.distributioncoverage.distributioncenter.centerCode
        );
        setErr2("done");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message === "pensionerInfo not found") {
          setErr(err.response.data.message);
          setShow1(true);
        }
        setErr2(err.response.data.message);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/distribution-regions`)
      .then((data) => {
        console.log(data, "dede");
        setAllRData(data.data);
        const wow = data.data.map((item) => {
          return {
            label: `${item.regionNo}`,
            value: `${item.regionNo}`,
          };
        });
        setAllRegions(wow);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateHandler = (e) => {
    e.preventDefault();
    if (selectedData.length === 0) {
      setErr("Please select a coverage");
      setShow1(true);
    } else {
      setIsLoading(true);
      console.log(distributionlocationaddressId, "deded");
      if (already) {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/distribution-info?pensionerUserId=${universalPenId}`,
            {
              distributioncoverageId: `${distributionlocationaddressId}`,
              consumerInfo: {
                ip: "d",
                location: "d",
              },
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "dedoeoe oy putttttttttt");
            setIsLoading(false);
            // router("/EDashboard/Pensioner_HistoryP");
          })
          .catch((err) => {
            console.log(err);
            setErr(err.response.data.message);
            setShow1(true);
            setIsLoading(false);
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/distribution-info?pensionerUserId=${universalPenId}`,
            {
              distributioncoverageId: `${distributionlocationaddressId}`,
              consumerInfo: {
                ip: "d",
                location: "d",
              },
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "dedoeoe oy");
            setIsLoading(false);
            // router("/EDashboard/Pensioner_HistoryP");
          })
          .catch((err) => {
            console.log(err);
            setErr(err.response.data.message);
            setShow1(true);
            setIsLoading(false);
          });
      }
    }
  };
  return (
    <div>
      <Nav />

      <div className="relative flex justify-between items-center px-[10rem] mt-[5rem]">
        <div className="flex items-center">
          <div
            onClick={() => {
              router("/EDashboard/profPage");
            }}
            // className="absolute top-[2.8] cursor-pointer sm:left-[20%] left-[3%]"
          >
            <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold ml-[1rem] sm:text-[1.8rem]   text-[1.2rem]">
            Distribution Details
          </h2>
        </div>
        <a
          href="https://oap-api-staging.jabcomp.gy/public/office-distribution-locations-list_v15-1.pdf"
          target="_blank"
          className="cursor-pointer"
        >
          <img className="w-[3rem]" src={pdf} alt="" />
        </a>
      </div>
      <div
        className={`flex justify-center items-center mt-[3rem] mb-[5rem] ${
          profileData.user.isDisabled ? "pointer-events-none" : ""
        }`}
      >
        <form
          onSubmit={updateHandler}
          className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-[70%]"
        >
          <div>
            <div className="flex flex-col">
              <label
                htmlFor="input4"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Coverage
              </label>
              <Select
                placeholder=" Coverage"
                onChange={(e, w) => {
                  console.log(e, w, "choice");
                  if (e) {
                    const wow = allData.filter((item) => {
                      return w.label === item.coverage;
                    });
                    console.log(wow, "111111");
                    setSelectedData(wow);
                    setCoverage(wow[0].coverage);
                    setRegionNo(
                      wow[0].distributioncenter.distributionlocation
                        .distributionregion.regionNo
                    );
                    setRegionName(
                      wow[0].distributioncenter.distributionlocation
                        .distributionregion.regionName
                    );
                    setLocationCode(
                      wow[0].distributioncenter.distributionlocation
                        .locationCode
                    );
                    setLocationName(
                      wow[0].distributioncenter.distributionlocation
                        .locationName
                    );
                    setCenterId(wow[0].distributioncenter.centerCode);
                    setCenterName(wow[0].distributioncenter.centerName);
                    setDistributionlocationId(wow[0].id);
                  }
                }}
                allowClear
                onClear={() => {
                  axios
                    .get(
                      `${process.env.REACT_APP_BASE_URL}/v1/distribution-coverages/search?search=ab`
                    )
                    .then((data) => {
                      console.log(data, "2121");
                      let wow = [];
                      data.data.rows.map((ww, index) => {
                        wow.push({
                          value: index,
                          label: `${ww.coverage}`,
                        });
                      });
                      setItems(wow);

                      setAllData(data.data.rows);
                      setCoverage("");
                      setSelectedData([]);
                      setRegionNo("");
                      setRegionName("");
                      setLocationCode("");
                      setLocationName("");
                      setCenterId("");
                      setCenterName("");
                      console.log(items, "000000000000");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
                onSearch={(e) => {
                  console.log(e, "1212");
                  if (e) {
                    axios
                      .get(
                        `${process.env.REACT_APP_BASE_URL}/v1/distribution-coverages/search?search=${e}`
                      )
                      .then((data) => {
                        console.log(data, "2121");
                        let wow = [];
                        data.data.rows.map((ww, index) => {
                          wow.push({
                            value: index,
                            label: `${ww.coverage}`,
                          });
                        });
                        setItems(wow);
                        setAllData(data.data.rows);
                        console.log(items, "000000000000");
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                }}
                value={coverage}
                showSearch
                optionFilterProp="children"
                style={{ marginBottom: "0.5rem" }}
                className="wow"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                options={items}
              />

              <label
                htmlFor="input4"
                className="text-[#596F96] mt-[1rem] text-[0.88rem] font-semibold mb-2"
              >
                Region No
              </label>
              <input
                type="text"
                name=""
                value={regionNo}
                placeholder="Region Name"
                className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="input4"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Region Name
              </label>
              <input
                type="text"
                name=""
                value={regionName}
                placeholder="Region Name"
                className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="input4"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Location Code
              </label>
              <input
                type="text"
                name=""
                value={locationCode}
                placeholder="Location Code"
                className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="input4"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Location Name
              </label>
              <input
                type="text"
                name=""
                value={locationName}
                placeholder="Region Name"
                className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-col">
              <label
                htmlFor="input4"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Center Code
              </label>
              <input
                type="text"
                name=""
                value={centerId}
                placeholder=" Center ID"
                className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="input4"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Center Name
              </label>
              <input
                type="text"
                name=""
                placeholder=" Center Name"
                value={centerName}
                className={`  lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
            </div>
          </div>
          <div
          // className={`${
          //   err2 === "distributionInfo not found" ? "" : "hidden"
          // }`}
          >
            <button
              type="submit"
              className={`relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              <Spin
                style={{ display: `${isLoading ? "" : "none"}` }}
                indicator={antIcon}
              />
              <span className={`${isLoading ? "ml-3" : ""}`}>Save</span>
              <div
                className={`w-[100%] h-[100%] ${
                  isLoading ? "" : "hidden"
                } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
              ></div>
            </button>
            <button
              type="button"
              onClick={() => {
                setRegionId("");
                setRegionName("");
                setAllLData("");
                setAllLocationCodes("");
                setLocationName("");
                setLocationId("");
                setCenterId("");
                setCoverageIdR("");
                setAllCoverageId([]);
                setCoverage("");
                setCenterName("");
                setRegionNo("");
                setLocationCode("");
              }}
              className={`lg:text-[1.1rem] bg-[#EDEFFD] text-black rounded-[0.5rem] p-2  pr-8 pl-8 ml-4`}
            >
              Cancel
            </button>
          </div>
        </form>
        <div
          className={`${
            show1 ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div
              className="absolute top-[-11%] left-[42%]"
              onClick={() => setShow1(false)}
            >
              <img src={across} alt="" />
            </div>
            <p className="text-center mt-[3rem] mb-5">{err}</p>
            <button
              onClick={() => setShow1(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
      <div className="fixed bottom-[8%] right-[3%] bg-[#25D366] w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
        <img className=" w-[3.5rem]" src={qwqw} alt="" />
      </div>
    </div>
  );
};

export default DistriutionsPage;
