import Paccount from "../components/PAccount/Paccount";
import Nav from "../components/Nav/nav";
import qwqw from "../imgs/qwqw.png";

const PAccount = () => {
  return (
    <div>
      <Nav />
      <Paccount />
      <div className="fixed bottom-[8%] right-[3%] bg-[#25D366] w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
        <img className=" w-[3.5rem]" src={qwqw} alt="" />
      </div>
    </div>
  );
};

export default PAccount;
