import plusp from "../../../imgs/plusp.png";
import { Spin } from "antd";
import { Select } from "antd";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import across from "../../../imgs/across.png";
import cross from "../../../imgs/cross.png";
import { useLocation } from "react-router-dom";

let file;
const PensionBookDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const dispatch = useDispatch();
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const [bookletNo1, setBookletNo1] = useState("");
  const [bookletNo2, setBookletNo2] = useState("");
  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [wow, setWow] = useState(0);
  const [iShow, setIShow] = useState(false);
  const [iShow1, setIShow1] = useState(false);
  const [test, setTest] = useState(false);
  const [present1, setPresent1] = useState(false);
  const [present2, setPresent2] = useState(false);

  const [allDis1, setAllDis1] = useState(false);
  const [allDis2, setAllDis2] = useState(false);
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const location = useLocation();
  const dash = location.pathname;
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const imageHandler = (setState, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        console.log(data, "ww");
        if (wow === 0) {
          setImageUrl1(data.data.url);
        } else {
          setImageUrl2(data.data.url);
        }
        load(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const updateHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let body = {};
    let outerBody = {};

    if (wow === 0) {
      body = {
        bookletNo: `${bookletNo1}`,
        frontImg: `${imageUrl1}`,
        year: `2023`,
      };
    } else {
      body = {
        bookletNo: `${bookletNo2}`,
        frontImg: `${imageUrl2}`,
        year: `2024`,
      };
    }

    if (dash === "/EDashboard/PensionerAccount") {
      let wowBoy;
      let win1 = "";
      if (wow === 0) {
        wowBoy = url1;
        if (present1) {
          outerBody = { pensionBook: body };
          win1 = "put";
          console.log("11111111111111");
        } else {
          outerBody = { bookInfo: body };
          win1 = "post";
          console.log("2222222222222222");
        }
      } else {
        wowBoy = url2;
        if (present2) {
          outerBody = { pensionBook: body };
          win1 = "put";
        } else {
          outerBody = { bookInfo: body };
          win1 = "post";
        }
      }

      console.log(win1, "sseee");
      if (win1 === "put") {
        axios
          .put(`${wowBoy}`, outerBody, {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          })
          .then((data) => {
            console.log(data, "wssws11");
            dispatch({ type: "RESET" });
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setErr(err.response.data.message);
            setShow(true);
            setIsLoading(false);
          });
      } else {
        axios
          .post(`${wowBoy}`, outerBody, {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          })
          .then((data) => {
            console.log(data, "wssws11");
            dispatch({ type: "RESET" });
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setErr(err.response.data.message);
            setShow(true);
            setIsLoading(false);
          });
      }
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/pension-books`, body, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          console.log(data, "wssws");
          dispatch({ type: "RESET" });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setErr(err.response.data.message);
          setShow(true);
          setIsLoading(false);
        });
    }
  };

  const yearChangeHandler = (e) => {
    console.log(e);
    if (e === "2023") {
      setWow(0);
    } else {
      setWow(1);
    }
  };

  useEffect(() => {
    console.log(profileData, "ded");
    if (profileData.pensionerInfo) {
      if (
        profileData.pensionBooks.length === 0 &&
        dash === "/Dashboard/PensionerAccount"
      ) {
        setAllDis1(false);
        setAllDis2(false);
        console.log("caled");
      } else {
        const wow1 = profileData.pensionBooks.filter((item) => {
          return item.year === "2023";
        });
        const wow2 = profileData.pensionBooks.filter((item) => {
          return item.year === "2024";
        });
        if (
          dash === "/EDashboard/PensionerAccount" &&
          profileData.user.isDisabled
        ) {
          console.log("caleddddddddd");
          setAllDis1(true);
          setAllDis2(true);
        }
        if (wow1.length != 0) {
          if (dash != "/EDashboard/PensionerAccount") {
            setAllDis1(true);
          } else if (
            dash === "/EDashboard/PensionerAccount" &&
            profileData.user.isDisabled
          ) {
            console.log("caleddddddddd");
            setAllDis1(true);
            setAllDis2(true);
          }
          setBookletNo1(wow1[0].bookletNo);
          const body4 = [wow1[0].frontImg];
          setImageUrl1(wow1[0].frontImg);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body4,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setDisplay(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
          console.log(wow1, "444444444444444444");
          setUrl1(
            `${process.env.REACT_APP_BASE_URL}/v1/pension-books/${wow1[0].id}/update-by-staff`
          );
          setPresent1(true);
        } else {
          setUrl1(
            `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${profileData.user.id}`
          );
          setPresent1(false);
        }
        if (wow2.length != 0) {
          if (dash != "/EDashboard/PensionerAccount") {
            setAllDis2(true);
          } else if (
            dash === "/EDashboard/PensionerAccount" &&
            profileData.user.isDisabled
          ) {
            console.log("caleddddddddd");
            setAllDis1(true);
            setAllDis2(true);
          }
          setBookletNo2(wow2[0].bookletNo);
          const body4 = [wow2[0].frontImg];
          setImageUrl2(wow2[0].frontImg);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body4,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setDisplay1(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
          setUrl2(
            `${process.env.REACT_APP_BASE_URL}/v1/pension-books/${wow2[0].id}/update-by-staff`
          );
          setPresent2(true);
        } else {
          setUrl2(
            `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${profileData.user.id}`
          );
          setPresent2(false);
        }
      }
    }
  }, []);
  let Key = ["1", "2"];
  return (
    <div>
      <h2 className="mb-4 text-center text-[1.5rem] font-bold">
        Pension Books
      </h2>
      <div className="flex justify-start">
        <Select
          placeholder="Year"
          optionFilterProp="children"
          className="wow"
          style={{
            marginBottom: "1.2rem",
            width: "15%",
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          defaultValue={{
            label: "2023",
            value: "2023",
          }}
          onChange={yearChangeHandler}
          // value={allAddressList[showIndex]}
          options={[
            {
              label: "2023",
              value: "2023",
            },
            {
              label: "2024",
              value: "2024",
            },
          ]}
        />
      </div>
      <form onSubmit={updateHandler}>
        <div>
          <h2 className="mb-4 text-[1.1rem] font-semibold">
            Current Booklet #:
          </h2>
          <input
            type="text"
            name=""
            id=""
            placeholder={"Current Booklet #"}
            onChange={(e) => {
              setBookletNo1(e.target.value);
            }}
            value={bookletNo1}
            className={`${wow === 0 ? "" : "hidden"} ${
              allDis1 ? "pointer-events-none" : ""
            } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          <input
            type="text"
            name=""
            id=""
            onChange={(e) => {
              setBookletNo2(e.target.value);
            }}
            value={bookletNo2}
            placeholder={"Current Booklet #"}
            className={`${wow === 0 ? "hidden" : ""} ${
              allDis2 ? "pointer-events-none" : ""
            } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Image Front:</h2>
          <div className={`${wow === 0 ? "" : "hidden"}`}>
            <label
              htmlFor="special-input4"
              className={`${
                allDis1 ? "pointer-events-none" : ""
              } cursor-pointer relative`}
            >
              <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                <div>
                  <img className="w-[1.2rem]" src={plusp} alt="" />
                </div>
                <p className="text-[#9CA6C2] ml-2">Image Front</p>
              </div>
              <input
                type="file"
                className="hidden"
                id="special-input4"
                accept="image/png, image/gif, image/jpeg"
                onInput={imageHandler(setDisplay, setIsLoading1)}
              />
            </label>
            <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
              <img
                onClick={() => {
                  setIShow(true);
                }}
                className="w-[25rem] h-[13rem]"
                src={display}
                alt=""
              />
              <div
                className={`absolute ${
                  isLoading1 ? "" : "hidden"
                } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
              >
                <Spin size="large" />
              </div>
            </div>
            <div
              className={`${
                iShow ? "" : "hidden"
              } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
            >
              <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                <img
                  className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                  src={cross}
                  onClick={() => setIShow(false)}
                  alt=""
                />
                <img className="w-[45rem] h-[28rem]" src={display} alt="" />
              </div>
            </div>
          </div>
          <div className={`${wow === 0 ? "hidden" : ""}`}>
            <label
              htmlFor="special-input5"
              className={`${
                allDis2 ? "pointer-events-none" : ""
              } cursor-pointer relative`}
            >
              <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                <div>
                  <img className="w-[1.2rem]" src={plusp} alt="" />
                </div>
                <p className="text-[#9CA6C2] ml-2">Image Front</p>
              </div>
              <input
                type="file"
                className="hidden"
                id="special-input5"
                accept="image/png, image/gif, image/jpeg"
                onInput={imageHandler(setDisplay1, setIsLoading2)}
              />
            </label>
            <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
              <img
                onClick={() => {
                  setIShow1(true);
                }}
                className="w-[25rem] h-[13rem]"
                src={display1}
                alt=""
              />
              <div
                className={`absolute ${
                  isLoading2 ? "" : "hidden"
                } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
              >
                <Spin size="large" />
              </div>
            </div>
            <div
              className={`${
                iShow1 ? "" : "hidden"
              } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
            >
              <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                <img
                  className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                  src={cross}
                  onClick={() => setIShow1(false)}
                  alt=""
                />
                <img className="w-[45rem] h-[28rem]" src={display1} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            dataUser.data.user.roles[0] === "employee" &&
            dataUser.data.user.status === "pending"
              ? "hidden"
              : ""
          }`}
        >
          <div
            className={`${
              profileData.user.status === "pending" &&
              dash === "/Dashboard/PensionerAccount"
                ? "hidden"
                : ""
            }`}
          >
            <button
              type="submit"
              className={`${allDis1 ? "hidden" : ""} ${
                wow === 1 ? "hidden" : ""
              } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              <Spin
                style={{ display: `${isLoading ? "" : "none"}` }}
                indicator={antIcon}
              />
              <span className="ml-3">Save</span>
              <div
                className={`w-[100%] h-[100%] ${
                  isLoading ? "" : "hidden"
                } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
              ></div>
            </button>
          </div>
          <div
            className={`${
              profileData.user.status === "pending" &&
              dash === "/Dashboard/PensionerAccount"
                ? "hidden"
                : ""
            }`}
          >
            <button
              type="submit"
              className={`${allDis2 ? "hidden" : ""}  ${
                wow === 0 ? "hidden" : ""
              } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              <Spin
                style={{ display: `${isLoading ? "" : "none"}` }}
                indicator={antIcon}
              />
              <span className="ml-3">Save</span>
              <div
                className={`w-[100%] h-[100%] ${
                  isLoading ? "" : "hidden"
                } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
              ></div>
            </button>
          </div>
        </div>
      </form>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default PensionBookDetails;
