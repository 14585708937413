import prof from "../../../../imgs/prof.png";
import { Dropdown, Pagination, Popover } from "antd";
import bs1 from "../../../../imgs/bs1.png";
import bs2 from "../../../../imgs/bs2.png";
import bs3 from "../../../../imgs/bs3.png";
import bs4 from "../../../../imgs/change.png";
import form from "../../../../imgs/form.png";
import perso from "../../../../imgs/perso.png";
import dis from "../../../../imgs/dis.png";
import p4 from "../../../../imgs/p4.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import across from "../../../../imgs/across.png";

const Paget = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const gidFilter = useSelector((state) => state.gidFilter);
  const emailFilter = useSelector((state) => state.emailFilter);
  const phoneFilter = useSelector((state) => state.phoneFilter);
  const [allData, setAllData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [buffer, setBuffer] = useState("");
  const [checkPerson, setCheckPerson] = useState("");
  const [page, setPage] = useState(1);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users?role=pensioner${
          gidFilter === "" ? "" : `&search=${gidFilter}`
        }${phoneFilter === "" ? "" : `&dateOfBirth=${phoneFilter}`}${[
          gidFilter != "" || phoneFilter != ""
            ? ""
            : `&limit=6&page=${page}&sortBy=createdAt:desc`,
        ]}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "all");
        setAllData(data.data.rows);
        setTotalPages(data.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, gidFilter, phoneFilter]);
  const router = useNavigate();
  const dispatch = useDispatch();
  const option2 = useSelector((state) => state.option2);
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }
  const items = [
    {
      label: "View ",
      key: "1",
      icon: <img style={{ width: "1.5rem" }} src={bs1} />,
    },
    {
      label: "Edit ",
      key: "3",
      icon: <img style={{ width: "1.1rem", marginLeft: "0.3rem" }} src={bs2} />,
    },
    {
      label: "Beneficiary Details",
      key: "2",
      icon: (
        <img style={{ width: "1.2rem", marginLeft: "0.1rem" }} src={perso} />
      ),
    },
    {
      label: "Application Details",
      key: "6",
      icon: (
        <img style={{ width: "1.2rem", marginLeft: "0.1rem" }} src={form} />
      ),
    },
    {
      label: "Distribution Details",
      key: "5",
      icon: <img style={{ width: "1.2rem", marginLeft: "0.1rem" }} src={dis} />,
    },

    {
      label: "Delete ",
      key: "4",
      icon: (
        <img style={{ width: "1.1rem", marginLeft: "0.28rem" }} src={bs3} />
      ),
    },
  ];

  const onClick2 = (e, person) => {
    console.log(e, ";lllll;");
    setBuffer(e);
  };
  const pageHandler = (e) => {
    console.log(e);
    setPage(e);
  };
  return (
    <div className="w-[100%] overflow-x-auto overflow-y-hidden">
      <ul className=" 2xl:grid-cols-[5%_8%_14%_14%_8%_9%_12%_9%_8%_10%] grid grid-cols-[2.5rem_6rem_8rem_7rem_5rem_8rem_9rem_8rem_8.5rem_6rem] pl-[1rem] mb-2 mt-5">
        <li className="font-semibold">ID</li>
        <li className="font-semibold">Pensioner</li>
        <li className="font-semibold">Name</li>
        <li className="font-semibold">GID</li>
        <li className="font-semibold">Gender</li>
        <li className="font-semibold">Booklet Number</li>
        <li className="font-semibold ml-3">Phone</li>
        <li className="font-semibold">Date of Birth</li>
        <li className="font-semibold">Status</li>
        <li className="font-semibold">Created At</li>
        {/* <li className="font-semibold">Action</li> */}
      </ul>
      {allData.map((item, index) => {
        return (
          <ul
            onClick={() => {
              router("/EDashboard/profPage");
              dispatch({ type: "universalPenSet", num: item.id });
            }}
            className="relative rounded-md w-fit 2xl:w-[95%] 2xl:grid-cols-[5%_10%_14%_15%_10%_8%_13%_9%_9%_10%] grid cursor-pointer content-center grid-cols-[2.5rem_6rem_8rem_7rem_5rem_8rem_9rem_8rem_8.5rem_6rem] bg-[#E8E8E8] hover:bg-[#cbcbcb] pl-[1rem] pt-2 pb-2 mb-3"
          >
            <li className="flex items-center ml-1">{index + 1}.</li>
            <Popover content={item.uniqueId} placement="bottomLeft">
              <li className="relative flex items-center ml-2">
                <p>{item.uniqueId.substring(0, 5)}..</p>
              </li>
            </Popover>
            {item.person != null && (
              <li className="flex items-center">
                <div>
                  {item.person.firstName} {item.person.lastName}
                </div>
              </li>
            )}
            <li
              className={`flex items-center ${
                item.person === null ? "" : "hidden"
              }`}
            >
              <div>---</div>
            </li>
            <li className="flex items-center">
              <div>{item.gid}</div>
            </li>
            <li className="flex items-center">
              <div>
                {item.person === null ? "----" : `${item.person.gender}`}
              </div>
            </li>

            <li className="flex items-center">
              <div className="border-none bg-[white] text-[#F7542E] font-semibold p-1 pl-5 pr-5 rounded-md  focus:border-none cursor-pointer">
                145352
              </div>
            </li>
            <li className="flex items-center ml-2">
              {item.phoneNo === null ? "----" : `${item.phoneNo}`}
            </li>
            {item.person != null && (
              <li className="flex items-center">
                <div>{item.person.dateOfBirth}</div>
              </li>
            )}
            <li
              className={`flex items-center ${
                item.person === null ? "" : "hidden"
              }`}
            >
              <div>---</div>
            </li>
            <li className="flex items-center">
              {/* <Popover
                content={`${
                  index === 3 ? "Deceased" : index === 1 ? "In-Process" : ""
                }`}
                placement="bottomLeft"
              >
                <div
                  className={`border-none ${
                    index === 3 || index === 5
                      ? "bg-[#d64242]"
                      : index === 1
                      ? "bg-[#2F84F1]"
                      : "bg-[#57D38C]"
                  }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer`}
                >
                  {index === 3 || index === 5 ? "In-Active" : "Active"}
                </div>
              </Popover> */}
              <Popover
                content={`${item.status === "pending" ? "In-Process" : ""}`}
                placement="bottomLeft"
              >
                <div
                  className={`border-none ${
                    item.status === "pending" && !item.isDisabled
                      ? "bg-[#2F84F1]"
                      : item.isDisabled || item.status === "rejected"
                      ? "bg-[#D64242]"
                      : " bg-[#57D38C]"
                  }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer`}
                >
                  {item.isDisabled ? "Disabled" : item.status}
                </div>
              </Popover>
            </li>
            {console.log(item, "ddsw")}
            <Popover
              content={`${item.status === "pending" ? "In-Process" : ""}`}
              placement="bottomLeft"
            >
              <li className="flex items-center">
                {item.createdAt.substring(0, 10)}
              </li>
            </Popover>
            {/* <li className="relative flex items-center">
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
                onClick={() => onClick2(item.id, item.person)}
                trigger={["click"]}
              >
                <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                </div>
              </Dropdown>
            </li> */}
          </ul>
        );
      })}
      <div className="flex justify-end mt-7">
        <Pagination
          defaultCurrent={1}
          total={totalPages * 10}
          showSizeChanger={false}
          onChange={pageHandler}
        />
      </div>
    </div>
  );
};

export default Paget;
