import Listing from "../components/Dash/Listing/listing";
import Listingt from "../components/Dash/Test/Listing/listingt";
import Nav from "../components/Nav/nav";
import Popup1 from "../components/Popup/popup1";
import { useSelector } from "react-redux";
import qwqw from "../imgs/qwqw.png";
const PensionL = () => {
  const show = useSelector((state) => state.isLogged);
  return (
    <div>
      <Nav />
      <Listingt />
      <div className={`${show ? "hidden" : ""}`}>
        <Popup1 />
      </div>
      <div className="fixed bottom-[8%] right-[3%] bg-[#25D366] w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
        <img className=" w-[3.5rem]" src={qwqw} alt="" />
      </div>
    </div>
  );
};

export default PensionL;
