export const optionReducer = (state = "Pensioner", action) => {
  switch (action.type) {
    case "PENSIONER":
      return "Pensioner";
    case "EMPLOYEE":
      return "Employee";
    case "MERCHANT":
      return "Merchant";
    default:
      return state;
  }
};
export const option2Reducer = (state = "", action) => {
  switch (action.type) {
    case "BENEFICIARY":
      return "Beneficiary";
    case "APPLICATION":
      return "Application";
    case "PENSIONER_HISTORY":
      return "Pensioner_History";
    default:
      return state;
  }
};

export const gidFilterReducer = (state = "", action) => {
  switch (action.type) {
    case "gidFilterSet":
      return action.num;
    default:
      return state;
  }
};
export const phoneFilterReducer = (state = "", action) => {
  switch (action.type) {
    case "phoneFilterSet":
      return action.num;
    default:
      return state;
  }
};
export const emailFilterReducer = (state = "", action) => {
  switch (action.type) {
    case "emailFilterSet":
      return action.num;
    default:
      return state;
  }
};
export const universalPenReducer = (state = "", action) => {
  switch (action.type) {
    case "universalPenSet":
      return action.num;
    default:
      return state;
  }
};
