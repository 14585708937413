export const rcurrentReducer = (state = 0, action) => {
  switch (action.type) {
    case "RINCREMENT":
      return state + 1;
    case "RDECREMENT":
      return state - 1;
    case "RSET":
      return action.num;
    default:
      return state;
  }
};
export const icurrentReducer = (state = 1, action) => {
  switch (action.type) {
    case "IINCREMENT":
      return state + 1;
    case "IDECREMENT":
      return state - 1;
    case "ISET":
      return action.num;
    default:
      return state;
  }
};
export const brcurrentReducer = (state = 0, action) => {
  switch (action.type) {
    case "BRINCREMENT":
      return state + 1;
    case "BRDECREMENT":
      return state - 1;
    case "BRSET":
      return action.num;
    default:
      return state;
  }
};
export const bicurrentReducer = (state = 0, action) => {
  switch (action.type) {
    case "BIINCREMENT":
      return state + 1;
    case "BIDECREMENT":
      return state - 1;
    case "BISET":
      return action.num;
    default:
      return state;
  }
};
export const acurrentReducer = (state = 0, action) => {
  switch (action.type) {
    case "AINCREMENT":
      return state + 1;
    case "ADECREMENT":
      return state - 1;
    case "ASET":
      return action.num;
    default:
      return state;
  }
};
