import logo1 from "../../imgs/logo12.png";
import prof from "../../imgs/prof.png";

import L1 from "../../imgs/L1.png";
import L2 from "../../imgs/L2.png";
import L3 from "../../imgs/L3.png";
import L4 from "../../imgs/L4.png";
import trash from "../../imgs/trash.png";
import cam1 from "../../imgs/cam1.png";
import Webcam from "react-webcam";
import cross from "../../imgs/cross.png";
import across from "../../imgs/across.png";
import { Dropdown, Space, Popover } from "antd";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import eye from "../../imgs/eyee.png";
import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  CompassOutlined,
  SnippetsOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EmpRegisterPol from "../Pol/empRegisterPol";
import axios from "axios";
import { useEffect, useState, useRef } from "react";

let items = [];
let file;
const Nav = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const pol = useSelector((state) => state.pol);
  const profileData = useSelector((state) => state.profileData);
  const universalPenId = useSelector((state) => state.universalPenId);
  const [test, setTest] = useState(false);
  const [err, setErr] = useState(false);
  const [show, setShow] = useState(false);
  const [showx, setShowx] = useState(false);
  const [show1, setShow1] = useState(false);
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);

  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  useEffect(() => {
    console.log(dataUser, "1111111");
    if (dataUser.data.user.roles[0] === "employee") {
      items = [
        {
          label: "Profile ",
          key: "1",
          icon: <UserOutlined className="some" />,
        },
        {
          label: "Account Status ",
          key: "3",
          icon: <CompassOutlined className="some" />,
        },
        {
          label: "Live Verification ",
          key: "4",
          icon: <LaptopOutlined className="some" />,
        },
        {
          label: "Reports",
          key: "5",
          icon: <SnippetsOutlined className="some" />,
        },
        {
          label: "Logout",
          key: "2",
          icon: <LogoutOutlined className="some" />,
        },
      ];
    } else {
      items = [
        {
          label: "Profile ",
          key: "1",
          icon: <UserOutlined className="some" />,
        },
        {
          label: "Account Status ",
          key: "3",
          icon: <CompassOutlined className="some" />,
        },
        {
          label: "Documents",
          key: "10",
          icon: <SnippetsOutlined className="some" />,
        },
        {
          label: "Logout",
          key: "2",
          icon: <LogoutOutlined className="some" />,
        },
      ];
    }

    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, []);

  const items1 = [
    {
      label: "English",
      key: "5",
    },
    {
      label: "Spanish",
      key: "6",
    },
    {
      label: "Dutch",
      key: "7",
    },
    {
      label: "Portuguese",
      key: "8",
    },
  ];
  const items2 = [
    {
      label: "Capture Image",
      key: "9",
    },
    // {
    //   label: "Upload Image",
    //   key: "10",
    // },
  ];

  const onClick1 = ({ key }) => {
    console.log("call");
    if (key === "10") {
      dispatch({ type: "pol" });
    } else if (key === "9") {
      setWebcamOpen(!webcamOpen);
    }
  };
  const onClick = ({ key }) => {
    if (key === "2") {
      console.log(dataUser.data.tokens.refresh.token);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/logout`, {
          refreshToken: `${dataUser.data.tokens.refresh.token}`,
        })
        .then((data) => {
          console.log(data, "ww");
          localStorage.setItem("authen", "true");
          dispatch({ type: "authStateSet", num: "true" });
          dispatch({ type: "ISET", num: "1" });
          dispatch({ type: "BRSET", num: 0 });
          router("/");
        })
        .catch((err) => {
          console.log(err);
          setErr(err.response.data.message);
          setShow(true);
        });
    } else if (key === "1") {
      if (location.pathname[1] === "E") {
        dispatch({ type: "universalPenSet", num: dataUser.data.user.id });
        dispatch({ type: "Employee" });
        router("/EDashboard/Account");
        dispatch({ type: "PENSIONER" });
        dispatch({ type: "editSet", num: "editEmployee" });
      } else if (location.pathname[1] === "D") {
        dispatch({ type: "universalPenSet", num: dataUser.data.user.id });
        router("/Dashboard/PensionerAccount");
        dispatch({ type: "editSet", num: "editPensioner" });
      }
    } else if (key === "4") {
      if (dataUser.data.user.status[0] != "pending") {
        router("/EDashboard/polList");
      }
    } else if (key === "3") {
      if (location.pathname[1] === "D") {
        router("/Dashboard/statusPage");
      } else if (location.pathname[1] === "E") {
        router("/EDashboard/statusPage");
      }
    } else if (key === "5") {
      router("/EDashboard/PowerBi");
    } else if (key === "10") {
      router("/Dashboard/additionalDocs");
    }
  };

  useEffect(() => {
    console.log(universalPenId, "kkkl");
  }, [universalPenId]);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const captureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // file = await (await fetch(imageSrc)).blob();
    // console.log(imageSrc, "22222222", file);
    file = dataURLtoFile("data:text/plain;base64,aGVsbG8=", "hello.txt");
    console.log(imageSrc, "22222222", file);
    setCapturedImage(imageSrc);
  };
  const [webcamOpen, setWebcamOpen] = useState(false);

  const uploadHandler = (e) => {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    console.log(dataUser, "3333333", file);
    if (dataUser.data.user.polStatus === "no-pol") {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/face-image?name=${dataUser.data.user.person.firstName} ${dataUser.data.user.person.lastName}`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
        .then((data) => {
          console.log(data, "ww");
        })
        .catch((err) => {
          console.log(err);
          if (
            err.response.data.message === "Failed to register proof of life"
          ) {
            setErr(
              "The quality of your webcam quality is not up to the mark. Please upgrade your webcam quality or use the mobile application for POL. "
            );
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
        });
    } else if (dataUser.data.user.polStatus === "expired-pol") {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/detect-image`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
        .then((data) => {
          console.log(data, "ww");
        })
        .catch((err) => {
          console.log(err);
          if (
            err.response.data.message === "Failed to register proof of life"
          ) {
            setErr(
              "The quality of your webcam quality is not up to the mark. Please upgrade your webcam quality or use the mobile application for POL. "
            );
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
        });
    }
  };
  // console.log(location.pathname[1]);
  return (
    <div>
      <nav className="shadow-md w-full h-[16vh] flex sm:pl-[6rem] sm:pr-[6rem] pl-6 pr-6 justify-between items-center">
        {webcamOpen && (
          <div className="w-[100%] h-[100%] absolute top-0 left-0 flex justify-center items-center bg-slate-400 bg-opacity-[40%] z-[100]">
            <div className="relative w-[35rem] h-[25rem] bg-white p-6 rounded-2xl flex flex-col justify-center items-center">
              <h2 className="font-semibold mb-4 text-[1.5rem]">
                {capturedImage === null ? " Capture Image" : "Upload Image"}
              </h2>
              <div
                onClick={() => {
                  setCapturedImage(null);
                  setWebcamOpen(false);
                }}
                className="absolute top-3 right-3"
              >
                <img className="scale-[0.7] cursor-pointer" src={cross} />
              </div>
              {capturedImage === null && (
                <div className="w-[20rem] h-[15rem]">
                  <Webcam ref={webcamRef} />
                </div>
              )}
              {capturedImage != null && (
                <div className="relative w-[20rem] h-[15rem]">
                  <img src={capturedImage} />
                  <div
                    className="absolute bottom-0 right-0 cursor-pointer bg-white"
                    onClick={() => setCapturedImage(null)}
                  >
                    <img className="w-[3rem]" src={trash} alt="" />
                  </div>
                </div>
              )}
              <button
                onClick={() => {
                  if (capturedImage === null) {
                    captureImage();
                  } else {
                    uploadHandler();
                  }
                }}
                className={`lg:text-[1.1rem] bg-gradient-to-tr mt-5 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[20rem]`}
              >
                {capturedImage === null ? "Capture" : "Upload"}
              </button>
            </div>
          </div>
        )}

        <div
          onClick={() => {
            if (location.pathname[1] === "E") {
              router("/EDashboard");
            } else {
              router("/Dashboard");
            }
          }}
          className="cursor-pointer"
        >
          <img className="w-[4.5rem]" src={logo1} alt="" />
        </div>
        {console.log(dataUser, "dedwvefwd")}
        <div className="flex justify-center items-center">
          <li
            className={`text-[0rem] animate-bounce font-bold mr-4 cursor-pointer sm:hidden ${
              dataUser.data.user.roles[0] === "pensioner" &&
              dataUser.data.user.status === "approved"
                ? ""
                : "hidden"
            }`}
          >
            <Dropdown
              menu={{
                items: items2,
                onClick: onClick1,
              }}
              placement="bottomRight"
              arrow
            >
              <img src={cam1} className="w-[3rem]" alt="" />
            </Dropdown>
          </li>
          <div
            onClick={() => setShowx(true)}
            className="w-[2rem] h-[1.2rem] cursor-pointer flex flex-col justify-between sm:hidden"
          >
            <div className="w-full h-[2px] bg-black"></div>
            <div className="w-full h-[2px] bg-black"></div>
            <div className="w-full h-[2px] bg-black"></div>
          </div>
        </div>
        <ul className="sm:flex items-center hidden">
          <li
            className="text-[1.1rem] font-bold cursor-pointer"
            onClick={() => {
              if (location.pathname[1] === "E") {
                router("/EDashboard");
              } else {
                router("/Dashboard");
              }
            }}
          >
            Home
          </li>
          <li
            className="text-[1.1rem] font-bold cursor-pointer ml-6"
            onClick={() => {
              if (
                location.pathname === "/Dashboard" ||
                location.pathname === "/EDashboard"
              ) {
                setShow1(true);
              }
            }}
          >
            Admin
          </li>
          <li
            className="text-[1.1rem] font-bold ml-6 cursor-pointer"
            onClick={() => {
              if (location.pathname[1] === "E") {
                router("/EDashboard/About");
              } else {
                router("/Dashboard/About");
              }
            }}
          >
            About
          </li>
          <li
            onClick={() => {
              if (location.pathname[1] === "E") {
                router("/EDashboard/contact");
              } else {
                router("/Dashboard/contact");
              }
            }}
            className="text-[1.1rem] font-bold ml-6 cursor-pointer"
          >
            Contact
          </li>

          <li className="text-[1.1rem] font-bold ml-6 cursor-pointer">
            <Dropdown
              menu={{
                items: items1,
              }}
              placement="bottomRight"
              arrow
            >
              <img src={L3} className="w-[1.8rem]" alt="" />
            </Dropdown>
          </li>
          <li
            className={`text-[1.1rem] animate-bounce font-bold ml-6 cursor-pointer ${
              dataUser.data.user.roles[0] === "pensioner" &&
              dataUser.data.user.status === "approved"
                ? ""
                : "hidden"
            }`}
          >
            <Dropdown
              menu={{
                items: items2,
                onClick: onClick1,
              }}
              placement="bottomRight"
              arrow
            >
              <img src={cam1} className="w-[3rem]" alt="" />
            </Dropdown>
          </li>
          <li className="ml-6">
            <Dropdown
              menu={{
                items,
                onClick,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {dataUser.data.user.person && (
                    <h2 style={{ fontWeight: "700" }}>
                      {dataUser.data.user.person.firstName}&nbsp;
                      {dataUser.data.user.person.lastName}
                    </h2>
                  )}
                  <h2
                    style={{
                      fontWeight: "700",
                      display: `${dataUser.data.user.person ? "none" : ""}`,
                    }}
                  >
                    John Doe
                  </h2>
                  <DownOutlined className="some1" />
                </Space>
              </a>
            </Dropdown>
          </li>
          <li className="ml-3 relative">
            <img
              src={dataUser.data.user.person.image}
              className="w-[3.3rem] h-[3.3rem] rounded-full ml-3"
              alt=""
            />
            <p
              className={`absolute top-[95%] ${
                dataUser.data.user.email === "iiabdullahii.khan@gmail.com"
                  ? "left-[20%]"
                  : "left-[10%]"
              }  text-[0.9rem] font-bold`}
            >
              {dataUser.data.user.roles[0] === "employee"
                ? dataUser.data.user.email === "iiabdullahii.khan@gmail.com"
                  ? "Admin"
                  : "Employee"
                : "Pensioner"}
            </p>
          </li>
        </ul>
        <div
          className={`${
            show ? "" : "hidden"
          } w-[100%] h-[100%] fixed z-[200] top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div
              className="absolute top-[-11%] left-[42%]"
              onClick={() => setShow(false)}
            >
              <img src={across} alt="" />
            </div>
            <p className="text-center mt-[3rem] mb-5">{err}</p>
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Ok
            </button>
          </div>
        </div>
        <div
          className={`${
            show1 ? "" : "hidden"
          } w-[100%] h-[100%] fixed z-[200] top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[30rem] pb-6 flex items-center pl-[2.5rem] pr-[2.5rem] p-3 bg-white flex-col">
            <p className="text-center text-[1.4rem] mt-[3rem] mb-5 font-semibold text-[#57D38C]">
              Admin Credentials
            </p>
            <div className="w-[100%]">
              <label
                htmlFor="input5"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Phone
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GY"
                international
                countryCallingCodeEditable={false}
                className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow .PhoneInputCountrySelect"
              />
            </div>
            <div className={`mb-6 w-[100%]`}>
              <label
                htmlFor="input1"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Email
              </label>
              <input
                className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
                type="text"
                placeholder="Email"
                name="input1"
                id=""
              />
            </div>
            <div className="flex flex-col w-[100%]">
              <label
                htmlFor="input4"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Password
              </label>
              <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type="password"
                  placeholder="Password"
                  id="Pass11"
                  className="w-[100%]"
                ></input>
                <img
                  className="w-[1.6rem] cursor-pointer"
                  // onClick={passShowHandler}
                  src={eye}
                  alt=""
                />
              </div>
            </div>
            <button
              onClick={() => {
                if (location.pathname[1] === "E") {
                  setShow1(false);
                  router("/EDashboard/adminPanel");
                } else {
                  setShow1(false);
                  router("/Dashboard/adminPanel");
                }
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#57D38C] w-[60%]"
            >
              Login
            </button>
          </div>
        </div>
      </nav>
      {pol && dataUser.data.user.roles[0] === "pensioner" && (
        <div
          className={`${
            pol && dataUser.data.user.roles[0] === "pensioner" ? "" : "hidden"
          }`}
        >
          <EmpRegisterPol />
        </div>
      )}
      <div
        className={`w-[100%] h-[100%] bg-white fixed top-0 z-[300] ${
          showx ? "" : "hidden"
        }`}
      >
        <div className="w-full flex justify-between mt-4 pr-4 pl-4">
          <div className="flex items-center">
            <div className="">
              <img
                className="rounded-full w-[4rem] h-[4rem] border-2 border-[#20B574]"
                src={dataUser.data.user.person.image}
              />
            </div>
            <p className="bg-[#CBD5E1] pt-1 pb-1 pl-3 pr-3 rounded-xl ml-2">{`${dataUser.data.user.person.title} ${dataUser.data.user.person.firstName} ${dataUser.data.user.person.lastName}`}</p>
          </div>
          <div
            onClick={() => setShowx(false)}
            className="w-[2rem] h-[1.2rem] mt-6 cursor-pointer flex flex-col justify-between sm:hidden"
          >
            <div className="w-full h-[2px] bg-black"></div>
            <div className="w-full h-[2px] bg-black"></div>
            <div className="w-full h-[2px] bg-black"></div>
          </div>
        </div>
        <div>
          <ul className=" items-center mt-12">
            {/* <div className=" bg-[#CBD5E1] p-3 ml-2 mr-2 mb-2">
              <p>
                <span className="font-semibold">Role:</span>{" "}
                {dataUser.data.user.roles[0]}
              </p>
            </div> */}
            <li
              className="text-[1.1rem] font-bold cursor-pointer bg-[#EBFFF3] p-3 ml-2 mr-2"
              onClick={() => {
                if (location.pathname[1] === "E") {
                  router("/EDashboard");
                } else {
                  router("/Dashboard");
                }
                setShowx(false);
              }}
            >
              Home
            </li>
            <li
              className="text-[1.1rem] font-bold cursor-pointer bg-[#EBFFF3] p-3 ml-2 mr-2"
              onClick={() => {
                if (
                  location.pathname === "/Dashboard" ||
                  location.pathname === "/EDashboard"
                ) {
                  setShow1(true);
                }
              }}
            >
              Admin
            </li>
            <li
              className="text-[1.1rem] font-bold cursor-pointer bg-[#EBFFF3] p-3 ml-2 mr-2 mt-2"
              onClick={() => {
                if (location.pathname[1] === "E") {
                  router("/EDashboard/About");
                } else {
                  router("/Dashboard/About");
                }
                setShowx(false);
              }}
            >
              About
            </li>
            <li
              onClick={() => {
                if (location.pathname[1] === "E") {
                  router("/EDashboard/contact");
                } else {
                  router("/Dashboard/contact");
                }
                setShowx(false);
              }}
              className="text-[1.1rem] font-bold  cursor-pointer bg-[#EBFFF3] p-3 ml-2 mr-2 mt-2"
            >
              Contact
            </li>
            <li className="text-[1.1rem] font-bold cursor-pointer">
              <Dropdown
                menu={{
                  items: items1,
                }}
                placement="bottomRight"
                arrow
              >
                <div className=" bg-[#EBFFF3] p-3 ml-2 mr-2 mt-2">
                  <img src={L3} className="w-[1.8rem] " alt="" />
                </div>
              </Dropdown>
            </li>
            <li className="text-[1.1rem] font-bold cursor-pointer">
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
                placement="bottomRight"
                arrow
              >
                <div className=" bg-[#EBFFF3] p-3 ml-2 mr-2 mt-2">Actions</div>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Nav;
