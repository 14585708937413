import prof from "../../../../imgs/prof.png";
import { Dropdown, Pagination, Popover } from "antd";
import bs1 from "../../../../imgs/bs1.png";
import bs2 from "../../../../imgs/bs2.png";
import bs3 from "../../../../imgs/bs3.png";
import bs4 from "../../../../imgs/change.png";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
const PageE = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const option2 = useSelector((state) => state.option2);
  const location = useLocation();
  const dash = location.pathname;
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }
  const showHandler = (index) => {
    const wow = document.getElementById(`some${index}`);
    if (wow.classList.contains("hide-action")) {
      wow.classList.add("show-action");
      wow.classList.remove("hide-action");
    } else {
      wow.classList.remove("show-action");
      wow.classList.add("hide-action");
    }
  };
  const items = [
    {
      label: "View ",
      key: "1",
      icon: <img style={{ width: "1.5rem" }} src={bs1} />,
    },
    {
      label: "Change Default ",
      key: "2",
      icon: <img style={{ width: "1.2rem", marginLeft: "0.1rem" }} src={bs4} />,
    },
    {
      label: "Edit ",
      key: "3",
      icon: <img style={{ width: "1.1rem", marginLeft: "0.3rem" }} src={bs2} />,
    },
    {
      label: "Delete ",
      key: "4",
      icon: (
        <img style={{ width: "1.1rem", marginLeft: "0.28rem" }} src={bs3} />
      ),
    },
  ];
  const onClick = () => {
    console.log("soon");
  };
  return (
    <div className="w-[100%] overflow-x-auto overflow-y-hidden">
      <ul className="grid grid-cols-[2.5rem_6rem_6.7rem_8rem_12rem_9rem_9.5rem_8.5rem_4rem] pl-[1rem] mb-2 mt-5">
        <li className="font-semibold mr-3">ID</li>
        <li className="font-semibold">Employee</li>
        <li className="font-semibold">Last Name</li>
        <li className="font-semibold">GID</li>
        <li className="font-semibold">Email</li>
        <li className="font-semibold">Phone No</li>
        <li className="font-semibold">Date of Birth</li>
        <li className="font-semibold">Status</li>
        <li className="font-semibold">Created</li>
      </ul>
      {wow.map((item, index) => {
        return (
          <ul
            onClick={() => {
              router("/EDashboard/profPage");
              dispatch({ type: "universalPenSet", num: "Add" });
            }}
            className="relative rounded-md grid cursor-pointer w-fit content-center grid-cols-[2.5rem_6rem_6.2rem_8rem_12rem_9rem_9.5rem_8.5rem_5.5rem] bg-[#E8E8E8] hover:bg-[#cbcbcb] pl-[1rem] pt-2 pb-2 mb-3"
          >
            <li className="">{index + 1}.</li>
            <li className="flex items-center">5346758</li>
            <li className="relative flex items-center ml-2">
              <p>merchant</p>
            </li>
            <li className="flex items-center">
              {/* <div>
                <img className="w-[2.5rem]" src={prof} alt="" />
              </div> */}
              <p className="ml-2">QW24553245</p>
            </li>
            <li className="flex items-center">
              <p className="ml-2">Test123@gmail.com</p>
            </li>

            <li className="flex items-center ml-2">03359290606</li>
            <li className="flex items-center ml-2">1948-09-02</li>
            <li className="flex items-center">
              <div
                className={`border-none ${
                  index === 2
                    ? "bg-[#d64242]"
                    : index === 4 || index === 5
                    ? "bg-[#2F84F1]"
                    : "bg-[#57D38C]"
                }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer`}
              >
                {index === 2
                  ? "In-Active"
                  : index === 4 || index === 5
                  ? "Pending"
                  : "Active"}
              </div>
            </li>

            <li className="flex items-center ml-1">2023-09-02</li>

            {/* <li className="relative flex items-center">
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
                trigger={["click"]}
              >
                <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                </div>
              </Dropdown>
            </li> */}
          </ul>
        );
      })}
      <div
        className={`flex justify-end mt-7 ${
          dash === "/adminPanel" ? "mb-10" : ""
        }`}
      >
        <Pagination defaultCurrent={1} total={80} showSizeChanger={false} />
      </div>
    </div>
  );
};

export default PageE;
