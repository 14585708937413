import Register from "../Register/Register";
import back5 from "../../imgs/back5.png";
import SecondR from "../Register/stepsR/SecondR";
import Mid from "../Register/stepsR/mid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ThirdR from "../Register/stepsR/ThirdR";
import FifthR from "../Register/stepsR/FifthR";
import SecondI from "../Identification/stepsI/SecondI";
import ThirdI from "../Identification/stepsI/ThirdI";
import FourthI from "../Identification/stepsI/FourthI";
import Banking1 from "../Identification/stepsI/Banking";
import FirstI from "../Identification/stepsI/FirstI";

const AddPensioner = () => {
  const icurrent = useSelector((state) => state.icurrent);
  const router = useNavigate();
  const dispatch = useDispatch();
  const Show = [
    SecondR,
    Mid,
    ThirdR,
    FifthR,
    FirstI,
    SecondI,
    ThirdI,
    FourthI,
    Banking1,
  ];
  const Dis = Show[icurrent];

  return (
    <div>
      <div className="relative flex justify-start pl-[10rem] pr-[20rem] items-center text-[1.8rem] font-bold mb-[5rem]">
        <div
          onClick={() => {
            dispatch({ type: "SET", num: 0 });
            dispatch({ type: "BRSET", num: 0 });
            dispatch({ type: "BISET", num: 0 });
            router("/EDashboard/Pensioner_HistoryP");
          }}
          className="cursor-pointer pt-[5rem] mr-5"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <h2 className="pt-[5rem]">Add Pensioner</h2>
      </div>
      {console.log(icurrent)}
      <div className=" mb-[5rem]">
        <Dis />
      </div>
    </div>
  );
};

export default AddPensioner;
