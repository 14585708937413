import { useDispatch, useSelector } from "react-redux";
import plusp from "../../../imgs/plusp.png";
import { useState } from "react";
import { useLocation } from "react-router";
import trash from "../../../imgs/trash.png";
import axios from "axios";
import { Spin } from "antd";
import tick from "../../../imgs/across.png";
import cross from "../../../imgs/cross.png";
let file;

const ThirdI = () => {
  const location = useLocation();
  const icurrent = useSelector((state) => state.icurrent);
  const dataUser = useSelector((state) => state.dataUser);
  const idDone = useSelector((state) => state.idDone);
  const popData = useSelector((state) => state.popData);
  const benId = useSelector((state) => state.benId);
  const dash = location.pathname;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const option = useSelector((state) => state.option);
  const wowt = useSelector((state) => state.wowt);
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const [display2, setDisplay2] = useState("");

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [verify, setVerify] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [iShow1, setIShow1] = useState(false);

  const [nisNo, setNisNo] = useState("");
  const [nisFront, setNisFront] = useState("");
  const [tisNo, setTisNo] = useState("");
  const [tisFront, setTisFront] = useState("");

  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    if (file === undefined) {
      setIsLoading1(false);
      setIsLoading2(false);
    } else {
      const base641 = await convertToBase64(file);
      setState(base641);
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          console.log(data.data.url, "ww");
          setUrl(data.data.url);
          load(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  // const nextHandler = (e) => {
  //   e.preventDefault();

  // };

  const nextHandler = (e) => {
    e.preventDefault();

    if (nisNo === "" && tisNo === "" && nisFront === "" && tisFront === "") {
      if (idDone === "Yes") {
        console.log("ccccc");
        if (dash === "/EDashboard/BeneficiaryQuestions") {
          dispatch({ type: "BISET", num: 3 });
        } else {
          if (option === "Pensioner") {
            dispatch({ type: "IINCREMENT" });
          } else {
            dispatch({ type: "POP" });
            dispatch({ type: "ISET", num: 8 });
          }
        }
      } else {
        setVerify(true);
      }
    } else {
      const body = [];
      if (nisFront != "" || nisNo) {
        const nisData = {
          type: "national insurance",
          docIDName: "national insurance",
          docIDValue: `${nisNo}`,
          docImages: [],
        };
        if (nisFront != "") {
          nisData.docImages[0] = {
            type: "front image",
            imageUrl: `${nisFront}`,
          };
        }
        body.push(nisData);
      }
      if (tisFront != "" || tisNo) {
        const tisData = {
          type: "taxpayer identification",
          docIDName: "taxpayer identification",
          docIDValue: `${tisNo}`,
          docImages: [],
        };
        if (tisFront != "") {
          tisData.docImages[0] = {
            type: "front image",
            imageUrl: `${tisFront}`,
          };
        }
        body.push(tisData);
      }

      if (icurrent <= 9) {
        if (dash === "/EDashboard/addPensioner") {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/verification-docs/add-by-staff?verificationDocUserId=${popData.id}`,
              { verificationDocs: body },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "oo");
              if (option === "Pensioner") {
                dispatch({ type: "IINCREMENT" });
              } else {
                dispatch({ type: "POP" });
                dispatch({ type: "ISET", num: 8 });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/verification-docs`,
              body,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "oo");
              if (option === "Pensioner") {
                dispatch({ type: "IINCREMENT" });
              } else {
                dispatch({ type: "POP" });
                dispatch({ type: "ISET", num: 8 });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        if (dash === "/EDashboard/BeneficiaryQuestions") {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/verification-docs/add-by-staff?beneficiaryUserId=${benId}`,
              { verificationDocs: body },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "change");
              dispatch({ type: "BISET", num: 3 });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/verification-docs?beneficiaryUserId=${benId}`,
              body,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "change");
              if (dash === "/Dashboard/BeneficiaryQuestions") {
                dispatch({ type: "BISET", num: 3 });
                console.log("called");
              } else {
                dispatch({ type: "IINCREMENT" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  };
  const handleChange = (setState, check) => (event) => {
    setState(event.target.value);
  };
  return (
    <div>
      <form className="mt-10" onSubmit={nextHandler}>
        <div className="flex justify-center items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  National Insurance
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="NIS #"
                  onChange={handleChange(setNisNo)}
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Image Front
              </h2>
              <label
                htmlFor="special-input"
                className={`${
                  isLoading1 || isLoading2 || isLoading3
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Image Front</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(setDisplay, setNisFront, setIsLoading1)}
                />
              </label>
              <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading1 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <div
                  className="absolute bottom-0 right-0 bg-white cursor-pointer"
                  onClick={() => {
                    setDisplay("");
                    setNisFront("");
                    setIsLoading1(false);
                    setIsLoading2(false);
                    const wow = document.getElementById("special-input");
                    wow.value = "";
                  }}
                >
                  <img className="w-[3rem]" src={trash} alt="" />
                </div>
              </div>
            </div>
            <div
              className={`${
                iShow ? "" : "hidden"
              } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
            >
              <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                <img
                  className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                  src={cross}
                  onClick={() => setIShow(false)}
                  alt=""
                />
                <img className="w-[45rem] h-[28rem]" src={display} alt="" />
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Tax Information
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="TIN #"
                  onChange={handleChange(setTisNo)}
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>

              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Image Front
              </h2>
              <label
                htmlFor="special-input1"
                className={`${
                  isLoading1 || isLoading2 || isLoading3
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Image Front</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input1"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay1,
                    setTisFront,
                    setIsLoading2
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
                <div
                  className={`absolute ${
                    isLoading2 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <img
                  onClick={() => {
                    setIShow1(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display1}
                  alt=""
                />
                <div
                  className="absolute bottom-0 right-0 bg-white cursor-pointer"
                  onClick={() => {
                    setDisplay1("");
                    setTisFront("");
                    setIsLoading1(false);
                    setIsLoading2(false);
                    const wow = document.getElementById("special-input1");
                    wow.value = "";
                  }}
                >
                  <img className="w-[3rem]" src={trash} alt="" />
                </div>
              </div>
              <div
                className={`${
                  iShow1 ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow1(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={` lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center sm:w-[85%]`}
        >
          {/* <button
            onClick={() => dispatch({ type: "IDECREMENT" })}
            className={`lg:text-[1.1rem] bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button> */}
          <button
            type="submit"
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Next
          </button>
        </div>
      </form>
      <div
        className={`${
          verify ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setVerify(false)}
          >
            <img src={tick} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">
            Please Enter at least one
          </p>

          <button
            type="submit"
            onClick={() => setVerify(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThirdI;
