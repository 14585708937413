import { useDispatch, useSelector } from "react-redux";
import FirstA from "./stepsA/firstA";
import SecondA from "./stepsA/secondA";
import ThirdA from "./stepsA/thirdA";
import FourthA from "./stepsA/fourthA";
import { Steps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Finance from "./stepsA/finance";
import Pre from "./stepsA/pre";
import Mid from "./stepsA/mid";
import PreFilled from "./stepsA/preFilled";
import { useEffect } from "react";
import axios from "axios";
import Awaited from "../Awaited/Awaited";

const Application = () => {
  const location = useLocation();
  const dash = location.pathname;
  const router = useNavigate();
  const acurrent = useSelector((state) => state.acurrent);
  const dataUser = useSelector((state) => state.dataUser);
  const universalPenId = useSelector((state) => state.universalPenId);

  const appUrl = useSelector((state) => state.appUrl);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const Show = [
    Pre,
    PreFilled,
    FirstA,
    SecondA,
    ThirdA,
    Mid,
    Finance,
    FourthA,
    Awaited,
  ];
  const Dis = Show[acurrent];
  const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const items = steps.map((item) => ({
    key: "",
    title: "",
  }));

  useEffect(() => {
    return () => {
      localStorage.setItem("electricityBill", "y");
      localStorage.setItem("waterBill", "y");
      localStorage.setItem("publicAssistance", "y");
      localStorage.setItem("publicAssistanceType", "y");
    };
  }, []);
  useEffect(() => {
    if (dash != "/") {
      console.log(universalPenId, "eee");
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/profile/${universalPenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log("profile", data.data);
          dispatch({ type: "setProfileData", num: data.data });
          dispatch({
            type: "nameSet",
            num: `${data.data.person.firstName} ${data.data.person.lastName}`,
          });
          dispatch({
            type: "imageSet",
            num: `${data.data.person.image}`,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [universalPenId]);

  const nextHandler = (check) => {
    if (check === "n") {
      if (acurrent < 7) {
        dispatch({ type: "AINCREMENT" });
      } else if (acurrent === 7) {
      }
    } else if (check === "p") {
      if (acurrent > 0) {
        dispatch({ type: "ADECREMENT" });
      }
    } else if (check === "s") {
      dispatch({ type: "ISET", num: 20 });
    }
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/application-questions?programName=oldage-pensioner`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "eeer");
        dispatch({ type: "appQuestionsSet", num: data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(universalPenId, "eee");
    if (dash === "/Dashboard/application") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/profile/${dataUser.data.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log("profile", data.data);
          dispatch({ type: "setProfileData", num: data.data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [universalPenId]);
  return (
    <div className="flex justify-center">
      <div className="sm:w-[70%] w-[85%]">
        <div>
          <Dis />
        </div>
        <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center">
          {/* <button
            onClick={() => nextHandler("p")}
            className={`lg:text-[1.1rem] bg-[#EDEFFD] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button> */}
          {/* <div
            className={`${
              dash === "/Dashboard/application" ? "hidden" : ""
            }`}
          >
            <button
              onClick={() => nextHandler("s")}
              className={`lg:text-[1.1rem] bg-[#F7542E] text-white rounded-[0.5rem] p-2  sm:pr-8 sm:pl-8 pr-5 pl-5 ml-5 ${
                acurrent === 0 ? "" : "hidden"
              }`}
            >
              Skip for now
            </button>
          </div> */}
          {/* <button
            onClick={() => nextHandler("n")}
            className={`lg:text-[1.1rem] ${
              acurrent === 8 ? "hidden" : ""
            } bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            {acurrent === 7 ? "Done" : "Next"}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Application;
