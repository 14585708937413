import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import dis from "../../../../imgs/diss.png";
import edit from "../../../../imgs/edit.png";
import ben from "../../../../imgs/ben.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import across from "../../../../imgs/across.png";
import placeholder from "../../../../imgs/placeholder.png";
import dod from "../../../../imgs/dod.png";
import plusp from "../../../../imgs/plusp.png";
import back5 from "../../../../imgs/back5.png";
import edit1 from "../../../../imgs/edit1.png";
import logo1 from "../../../../imgs/logo12.png";
import cross from "../../../../imgs/cross.png";
import { LoadingOutlined } from "@ant-design/icons";
import { Select, Switch } from "antd";
import { Spin } from "antd";
let optopt1;
const Prof = () => {
  const universalPenId = useSelector((state) => state.universalPenId);
  const dataUser = useSelector((state) => state.dataUser);
  const reset = useSelector((state) => state.reset);
  const dispatch = useDispatch();
  const router = useNavigate();
  const [firstName, setFirstName] = useState("---");
  const [lastName, setLastName] = useState("---");
  const [isDisabled, setIsDisabled] = useState(false);
  const [img, setImg] = useState(placeholder);
  const [status, setStatus] = useState("");
  const [id, setId] = useState("");
  const [checkPerson, setCheckPerson] = useState("");
  const [notes, setNotes] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState("");
  const [able, setAble] = useState(false);
  const [status1, setStatus1] = useState("");
  const [other, setOther] = useState(false);
  const [test, setTest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otherChoice, setOtherChoice] = useState("");
  const [optopt, setOptopt] = useState([
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
  ]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  useEffect(() => {});
  useEffect(() => {
    if (universalPenId === "Add") {
      setOptopt([
        { label: "Active", value: "active" },
        { label: "Approved", value: "approved" },
        { label: "Rejected", value: "rejected" },
        { label: "Inactive", value: "in-active" },
      ]);
    } else {
      setOptopt([
        { label: "Approved", value: "approved" },
        { label: "Rejected", value: "rejected" },
      ]);
    }
  }, []);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/profile/${universalPenId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        console.log("profile", data.data);
        dispatch({ type: "setProfileData", num: data.data });
        dispatch({ type: "pensionerId", num: data.data.user.id });
        setStatus(data.data.user.status);
        setIsDisabled(data.data.user.isDisabled);

        setId(data.data.user.id);
        if (data.data.user.isDisabled) {
          optopt1 = [
            {
              label: "Remigrated",
              value: "Remigrated",
            },
            {
              label: "Other",
              value: "Other",
            },
          ];
          setAble(false);
        } else {
          optopt1 = [
            {
              label: "Out of Country",
              value: "Out of Country",
            },
            {
              label: "Hospitalized",
              value: "Hospitalized",
            },
            {
              label: "Migrated",
              value: "Migrated",
            },
            {
              label: "Education",
              value: "Education",
            },
            {
              label: "Other",
              value: "Other",
            },
          ];
          setAble(true);
        }
        if (data.data.person) {
          setFirstName(data.data.person.firstName);
          setLastName(data.data.person.lastName);
          setImg(data.data.person.image);
          dispatch({
            type: "pensionerName",
            num: `${data.data.person.firstName} ${data.data.person.lastName}`,
          });
          setCheckPerson("not null");
        } else {
          setCheckPerson("is null");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reset]);

  const changeHandler = (e) => {
    console.log(e, universalPenId);
    setStatus1(e);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/users/${universalPenId}/pensioner/status`,
        {
          status: status1,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "rere");
        dispatch({ type: "RESET" });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const ableHandler = (e) => {
    console.log(e, "swsws");
    setShow2(true);
  };
  const ableSubmitHandler = () => {
    if (notes === "") {
      setShow(true);
      setErr("Please enter notes");
    } else if (other && otherChoice === "") {
      setShow(true);
      setErr("Please enter a reason");
    } else {
      let wow;
      let wow1;
      if (isDisabled) {
        wow = "This pensioner was mistakenly disabled";
        wow1 = "enable";
      } else {
        wow = "This pensioner has no sufficient documents";
        wow1 = "disable";
      }
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/v1/users/${universalPenId}/${wow1}`,
          {
            reason: wow,
            note: notes,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "1212");
          if (isDisabled) {
            setAble(true);
          } else {
            setAble(false);
          }
          setOther(false);
          setOtherChoice("");
          dispatch({ type: "RESET" });
          setNotes("");
          setShow2(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (test) {
      setTest(false);
    } else {
      setTest(false);
    }
  }, [able]);
  return (
    <div className="sm:p-8 sm:pl-[7rem] sm:pr-[7rem]">
      <div className="relative flex justify-between items-center mb-[2rem]">
        <div
          onClick={() => {
            if (universalPenId === "Add") {
              router("/EDashboard/allEmployees");
            } else {
              router("/EDashboard/Pensioner_HistoryP");
            }
          }}
          className="absolute top-[2.8] cursor-pointer left-[-3.5%]"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <h2 className="font-bold text-[1.8rem]">
          {universalPenId === "Add" ? "Employee" : "Pensioner"}
        </h2>
      </div>
      <div className="flex w-full mb-[3rem]">
        <div
          className={`${
            universalPenId === "Add" ? "pointer-events-none" : ""
          } relative w-[45%] bg-[#F2F4F8] flex flex-col items-center pt-8 pb-8 pl-8 pr-8`}
        >
          <div className="absolute top-5 right-5">
            <Switch
              size="small"
              style={{ backgroundColor: able ? "#57d38c" : "#c3c6c9" }}
              checked={able}
              onChange={ableHandler}
            />
          </div>
          <div className="w-[3rem] h-[3rem] rounded-full flex justify-center items-center">
            <img
              src={img}
              className="w-[3rem] h-[3rem] rounded-full border-[1px] border-black"
              alt=""
            />
          </div>
          <h2 className="font-semibold mt-1">{`${firstName} ${lastName}`}</h2>

          <p
            className={`text-[0.7rem] ${
              isDisabled ? "text-[red]" : "text-[#57D38C]"
            }  font-medium`}
          >
            {isDisabled ? "Disabled" : status}
          </p>
          <div className="w-[100%] flex items-center mt-[1rem]">
            <div
              onClick={() => {
                dispatch({ type: "universalPenSet", num: id });
                router("/EDashboard/PcardView");
                dispatch({ type: "gidFilterSet", num: "" });
                dispatch({ type: "whichViewSet", num: "pensioner" });
              }}
              className="bg-[#57D38C] w-[50%] text-center pt-2 pb-2 text-white cursor-pointer"
            >
              Profile
            </div>
            <div
              onClick={() => {
                if (checkPerson === "is null") {
                  setErr(
                    "Personal data is missing or does not exist in profile"
                  );
                  setShow(true);
                } else if (checkPerson === "not null") {
                  dispatch({ type: "universalPenSet", num: id });
                  router("/EDashboard/allApplications");
                }
              }}
              className="bg-[#57D38C] w-[50%] text-center ml-[1px] pt-2 pb-2 text-white cursor-pointer"
            >
              Application
            </div>
          </div>
          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={edit1} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">
                Edit and modify account details for pensioner
              </p>
              <p
                className="text-[0.65rem] text-[#57D38C] cursor-pointer"
                onClick={() => {
                  router("/EDashboard/PensionerAccount");
                  dispatch({ type: "universalPenSet", num: id });
                  dispatch({ type: "gidFilterSet", num: "" });
                  dispatch({ type: "whichViewSet", num: "pensioner" });
                }}
              >
                Click Here
              </p>
            </div>
          </div>
          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={dis} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">
                Upload and view distribution details for pensioner
              </p>
              <p
                className="text-[0.65rem] text-[#57D38C] cursor-pointer"
                onClick={() => {
                  if (checkPerson === "is null") {
                    setErr(
                      "Personal data is missing or does not exist in profile"
                    );
                    setShow(true);
                  } else if (checkPerson === "not null") {
                    dispatch({ type: "universalPenSet", num: id });
                    router("/EDashboard/distributionsPage");
                  }
                }}
              >
                Click Here
              </p>
            </div>
          </div>
          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={edit} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">Upload documents for pensioner</p>
              <p
                onClick={() => {
                  dispatch({ type: "universalPenSet", num: id });
                  router("/EDashboard/additionalDocs");
                }}
                className="text-[0.65rem] text-[#57D38C] cursor-pointer"
              >
                Click Here
              </p>
            </div>
          </div>
          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={ben} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">View and update Beneficiary</p>
              <p
                className="text-[0.65rem] text-[#57D38C] cursor-pointer"
                onClick={() => {
                  if (checkPerson === "is null") {
                    setErr(
                      "Personal data is missing or does not exist in profile"
                    );
                    setShow(true);
                  } else if (checkPerson === "not null") {
                    setShow1(true);
                  }
                }}
              >
                Click Here
              </p>
            </div>
          </div>
        </div>

        <div className="w-[45%] ml-[4rem]">
          <div className="flex justify-between items-center">
            <h2 className="text-[1rem] font-medium">Reviewing Officer</h2>
            <img className="w-[1.5rem] h-[1.5rem]" src={dod} alt="" />
          </div>
          <h2 className="mt-6 text-[1rem] font-medium">
            Director of Social Services
          </h2>
          <div className="flex mt-4 mb-6 items-center">
            <div className="bg-[#DDE1E6] w-[3rem] h-[3rem] rounded-full"></div>
            <div className="ml-3">
              <h2 className="text-[0.9rem] font-semibold">Charlie Dean</h2>
              <p className="text-[0.8rem] text-[#878D96]">22/04/03</p>
            </div>
          </div>
          {/* <input
            type="text"
            placeholder="Decision"
            className="sqw lg:text-[1.1rem] mt-2 w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mb-4"
          /> */}
          <form onSubmit={submitHandler}>
            <Select
              placeholder="Decision"
              showSearch
              onChange={changeHandler}
              optionFilterProp="children"
              style={{ marginBottom: "0.5rem" }}
              className="wow1"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              options={optopt}
            />
            <textarea
              type="text"
              placeholder="Enter Notes"
              className="sqw lg:text-[1.1rem] h-[4rem] w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mt-2"
            />
            <textarea
              type="text"
              placeholder="Enter your private Notes"
              className="sqw lg:text-[1.1rem] h-[4rem] w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mt-2"
            />
            <div className="flex">
              <input
                type="text"
                placeholder="Payment Amount"
                className="lg:text-[1.1rem] mt-2 w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mb-4"
              />
              <input
                type="text"
                placeholder="Payment Date"
                className="ml-4 lg:text-[1.1rem] mt-2 w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mb-4"
              />
            </div>
            <div className="flex">
              <label
                htmlFor="special-input"
                className={` cursor-pointer relative w-[100%]`}
              >
                <div className=" lg:text-[1.1rem] w-[100%] bg-[#F2F4F8] rounded-[0.5rem] p-1 mt-2 flex items-center">
                  <div>
                    <img className="w-[1rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Signature</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input"
                  accept="image/png, image/gif, image/jpeg"
                />
              </label>
              <input
                type="text"
                placeholder="Payment Date"
                className="ml-4 lg:text-[1.1rem] mt-2 w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mb-4"
              />
            </div>
            <div className="flex justify-end">
              <button
                className={`lg:text-[1rem] bg-[#EDEFFD] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={` relative lg:text-[1.1rem] bg-gradient-to-tr bg-[#57D38C] text-white ml-2 rounded-[0.5rem] p-2  pr-8 pl-8`}
              >
                <Spin
                  style={{ display: `${isLoading ? "" : "none"}` }}
                  indicator={antIcon}
                />
                <span className="ml-3">Done</span>
                <div
                  className={`w-[100%] h-[100%] ${
                    isLoading ? "" : "hidden"
                  } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                ></div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`${
          show2 ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[30rem] pb-6 pl-6 pr-6 flex items-center p-3 bg-white flex-col">
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">
            Are you sure you want to {isDisabled ? "enable " : "disable"} this
            pensioner?
          </p>
          <Select
            placeholder="Reason"
            onChange={(e) => {
              console.log(e, "choice");
              if (e === "Other") {
                setOther(true);
              } else {
                setOther(false);
              }
            }}
            optionFilterProp="children"
            style={{ marginBottom: "0.5rem" }}
            className="wow1"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            options={optopt1}
          />
          <input
            type="text"
            placeholder="Enter Reason"
            value={otherChoice}
            onChange={(e) => {
              setOtherChoice(e.target.value);
            }}
            className={`lg:text-[1.1rem] ${
              other ? "" : "hidden"
            } mt-2 w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mb-4`}
          />
          <textarea
            type="text"
            placeholder="Enter Notes"
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            className="sqw lg:text-[1.1rem] h-[6rem] w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mt-2"
          />
          <div className="flex mt-4">
            <button
              onClick={() => {
                setShow2(false);
                setOther(false);
                setOtherChoice("");
                setNotes("");
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-black bg-[#EDEFFD] w-[60%]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                ableSubmitHandler();
              }}
              className="cursor-pointer rounded-xl ml-4 pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#57D38C] w-[60%]"
            >
              {isDisabled ? "Enable" : "Disable"}
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl pb-6 flex items-center p-3 pl-5 pr-5 bg-white flex-col">
          <div
            className="absolute top-3 right-4 cursor-pointer"
            onClick={() => {
              setShow1(false);
            }}
          >
            <img className="scale-[0.8]" src={cross} />
          </div>
          <p className="text-center text-[1.1rem] mt-[1rem] font-semibold mb-5">
            Please select a program to move forward
          </p>
          <div className="flex">
            {/* ${
                pending || approved ? "bg-[#cbcbcb]" : "bg-[#E8E8E8]"
              }  */}
            <div className="w-[50%]">
              <div
                onClick={() => {
                  dispatch({ type: "allBenIdSet", num: id });
                  // dispatch({ type: "universalPenSet", num: buffer });
                  router("/EDashboard/listing");
                }}
                className={`flex items-center cursor-pointer bg-[#E8E8E8] rounded-lg p-3 pl-5 pr-5 w-[100%] mb-2`}
              >
                <div>
                  <img className="w-[3rem]" src={logo1} alt="" />
                </div>
                <p className="ml-4">Old Age Pension - 65+ Years</p>
              </div>
              <div
                className={` pointer-events-none opacity-70 flex items-center  bg-[#E8E8E8] rounded-lg p-3 pl-5 pr-5 w-[100%] mb-2`}
              >
                <div>
                  <img className="w-[3rem]" src={logo1} alt="" />
                </div>
                <p className="ml-4">Medical</p>
              </div>
              <div
                className={`flex items-center pointer-events-none opacity-70 bg-[#E8E8E8] rounded-lg p-3 pl-5 pr-5 w-[100%] mb-2`}
              >
                <div>
                  <img className="w-[3rem]" src={logo1} alt="" />
                </div>
                <p className="ml-4">Economical</p>
              </div>
            </div>
            <div className="ml-4 w-[50%]">
              <div
                className={`flex items-center pointer-events-none opacity-70 bg-[#E8E8E8] rounded-lg p-3 pl-5 pr-5 w-[100%] mb-2`}
              >
                <div>
                  <img className="w-[3rem]" src={logo1} alt="" />
                </div>
                <p className="ml-4">Temporary Disability</p>
              </div>
              <div
                className={`flex items-center pointer-events-none opacity-70 bg-[#E8E8E8] rounded-lg p-3 pl-5 pr-5 w-[100%] mb-2`}
              >
                <div>
                  <img className="w-[3rem]" src={logo1} alt="" />
                </div>
                <p className="ml-4">Permanent Disability</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prof;
