import RegistrationP from "./viewParts/registrationP";
import IdentificationP from "./viewParts/identifictionP";
import { useState } from "react";
import back5 from "../../imgs/back5.png";
import { useLocation, useNavigate } from "react-router-dom";
import DigitalCard from "../card/card";
import DigitalCardBack from "../card/cardBack";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";

const PcardView = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const [wow, setWow] = useState(0);
  const Show = [RegistrationP, IdentificationP];
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  const universalPenId = useSelector((state) => state.universalPenId);
  const benDone = useSelector((state) => state.benDone);
  const penId = useSelector((state) => state.penId);
  const reset = useSelector((state) => state.reset);
  const Dis = Show[wow];

  useEffect(() => {
    return () => {
      dispatch({ type: "setProfileData", num: null });
      dispatch({ type: "benDone", num: "" });
    };
  }, []);
  useEffect(() => {
    console.log(universalPenId, "eee");

    if (benDone === "Done") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/users/profile?beneficiaryUserId=${universalPenId}&programId=${penId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log("profile", data.data);
          dispatch({ type: "setProfileData", num: data.data });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/profile/${universalPenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log("profile", data.data);
          dispatch({ type: "setProfileData", num: data.data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [universalPenId, reset]);
  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  return (
    <div className="pt-[5rem] sm:pb-[5rem] sm:pl-[12rem] sm:pr-[12rem]">
      {profileData && (
        <div>
          <div className="relative flex items-center mb-[3rem]">
            <div
              onClick={() => {
                if (dash === "/Dashboard/PensionerAccount") {
                  router("/Dashboard");
                } else if (dash === "/Dashboard/cardView") {
                  router("/Dashboard/listing");
                } else if (dash === "/EDashboard/cardView") {
                  router("/EDashboard/listing");
                } else {
                  router("/EDashboard/profPage");
                  // router("/EDashboard/Pensioner_HistoryP");
                }
              }}
              className="absolute top-[1] cursor-pointer sm:left-[-4%] left-[1%]"
            >
              <img className="w-[1.7rem]" src={back5} alt="" />
            </div>
            <div className="font-bold sm:text-[1.8rem] text-[1.2rem] sm:ml-0 ml-[2rem]">
              {dash === "/Dashboard/cardView" || dash === "/EDashboard/cardView"
                ? "View Beneficiary"
                : "View Pensioner"}
            </div>
          </div>
          <div className="lg:mb-[2rem] flex mb-[2rem]">
            <div className="cursor-pointer" onClick={() => changeHandler("R")}>
              <h2
                className={`lg:text-[1.1rem] ${
                  wow === 0 ? "font-semibold" : ""
                } `}
              >
                Registration Details
              </h2>
              <div
                className={`lg:w-[9.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
                  wow === 0 ? "bg-[#18B871]" : "bg-[#E8E8E8]"
                } `}
              ></div>
            </div>

            <div className="cursor-pointer" onClick={() => changeHandler("I")}>
              <h2
                className={`lg:text-[1.1rem] ml-6 ${
                  wow === 1 ? "font-semibold" : ""
                } `}
              >
                Identification Details
              </h2>
              <div
                className={`lg:w-[9.5] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${
                  wow === 1 ? "bg-[#18B871]" : "bg-[#E8E8E8]"
                }  `}
              ></div>
            </div>
          </div>

          <div>
            {profileData.user && (
              <div>
                {profileData.user.roles[0] === "pensioner" && (
                  <div className="sm:flex-row flex flex-col h-[25rem] w-full">
                    <div className="relative sm:mb-[3rem] sm:scale-[0.88] scale-[0.7]">
                      <div className="sm:inline-block absolute top-0 left-[-21%]">
                        <DigitalCard />
                      </div>
                    </div>
                    <div className="realtive sm:mb-[3rem] sm:scale-[0.88] scale-[0.7]">
                      <div className="absolute sm:top-0 sm:left-[35rem] top-72 left-[-21%]">
                        <DigitalCardBack />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <Dis />
          </div>
        </div>
      )}
    </div>
  );
};

export default PcardView;
