import { useSelector, useDispatch } from "react-redux";
import add from "../../imgs/addb.png";
import logo1 from "../../imgs/prof.png";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Checkbox } from "antd";
import axios from "axios";

const AddBenificiary = () => {
  const [word, setWord] = useState("Skip for now");
  const [color, setColor] = useState("#F7542E");
  const [dataWow, setDataWow] = useState([]);
  const [test, setTest] = useState(false);
  const [ownBen, setOwnBen] = useState(false);
  const [ownPen, setOwnPen] = useState("");
  const [meBen, setMeBen] = useState(false);
  const [allBens, setAllBens] = useState([]);
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const bicurrent = useSelector((state) => state.bicurrent);
  const token = useSelector((state) => state.token);
  const dataBen = useSelector((state) => state.dataBen);
  const popup = useSelector((state) => state.popup);
  const fromAwaited = useSelector((state) => state.fromAwaited);

  const skipHandler = () => {
    if (bicurrent > 1) {
      dispatch({ type: "ISET", num: 19 });
    } else {
      dispatch({ type: "ISET", num: 19 });
    }
  };

  useEffect(() => {
    if (bicurrent > 1) {
      setWord("Next");
      setColor("#57D38C");
    }
  }, [bicurrent]);
  const addHandler = (e) => {
    console.log(e, "--------------");
    dispatch({
      type: "penId",
      num: `${e}`,
    });
    if (dash === "/Dashboard/BeneficiaryQuestions") {
      dispatch({ type: "SET", num: 1 });
      dispatch({ type: "BRSET", num: 0 });
    } else {
      dispatch({ type: "ISET", num: 11 });
    }

    // dispatch({ type: "BRSET", num: 0 });
    // dispatch({ type: "BISET", num: 0 });
    // dispatch({ type: "SET", num: 4 });
  };
  useEffect(() => {
    console.log(dataBen, "ddada");
    if (dash === "/") {
      setDataWow(dataBen);
    } else {
      setDataWow(dataBen.pensionPrograms);
    }
    // console.log(dataBen.pensionPrograms.includes("medical"));
  }, []);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [dataWow, allBens, meBen]);

  const nextHandler = () => {
    if (allBens.length != 0) {
      if (fromAwaited === "Ben") {
        dispatch({ type: "ISET", num: 20 });
      } else {
        dispatch({ type: "ISET", num: 19 });
      }
    }
  };

  useEffect(() => {
    let wow;
    if (dash === "/") {
      wow = token;
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/list-by-pensioner`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "yyyaya");
        setAllBens(data.data);

        const wow = data.data.filter((item) => {
          return item.relationship === "Me";
        });
        console.log(wow, "111111111");
        if (wow.length != 0) {
          setMeBen(true);
        } else {
          setMeBen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className=" flex justify-center">
      <div className="sm:w-[70%] w-[100%] mt-10">
        {console.log(dataWow, "wwwwwwwwwwwwwwwwww")}
        <div className="flex sm:justify-center">
          {dataWow && (
            <div>
              {dataWow.length != 0 && (
                <div>
                  {dataWow.map((item) => {
                    return (
                      <div>
                        <div className="mb-8">
                          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2 sm:ml-0 ml-5">
                            {/* {item.name.charAt(0).toUpperCase() + item.name.slice(1)} */}
                            Old Age Pension - 65+ Years
                          </h2>
                          <div
                            className=" text-white mt-3"
                            //   onClick={() => dispatch({ type: "SET", num: 4 })}
                          >
                            <div className="flex mt-5 items-center">
                              <div
                                className="cursor-pointer"
                                onClick={() => addHandler(item.id)}
                              >
                                <img
                                  className="scale-[0.7] sm:ml-0 sm:mr-0 ml-5 mr-4"
                                  src={add}
                                  alt=""
                                />
                              </div>

                              <div>
                                {console.log(allBens, "ddede")}
                                {allBens && (
                                  <div>
                                    {allBens.length != 0 &&
                                      allBens.map((item) => {
                                        return (
                                          <div
                                            className={`flex items-center mt-[0.5rem] ml-5 sm:w-[22rem] w-[16rem] h-[4rem] bg-[#E8E8E8] rounded-xl mb-5 pt-2 pb-2 ${
                                              item.relationship === "Me"
                                                ? "hidden"
                                                : ""
                                            }`}
                                          >
                                            {item.beneficiaryuser && (
                                              <div className="flex pl-3">
                                                <div>
                                                  <img
                                                    className="w-[3rem] h-[3rem] rounded-full"
                                                    src={
                                                      item.beneficiaryuser
                                                        .beneficiaryperson.image
                                                    }
                                                    alt=""
                                                  />
                                                </div>

                                                <div className="ml-3">
                                                  <h2 className="font-bold text-[1rem] text-black">
                                                    {`${item.beneficiaryuser.beneficiaryperson.firstName} ${item.beneficiaryuser.beneficiaryperson.lastName}`}
                                                  </h2>
                                                  <p className="text-[0.8rem] text-black">
                                                    {item.relationship}
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="ml-2 bg-[#E8E8E8] w-[25rem] pl-3 pt-3 rounded-lg">
                    <Checkbox
                      // checked={copArr[index].isCurrent}
                      onChange={(e) => {
                        setOwnBen(e.target.checked);
                        setMeBen(e.target.checked);
                        setOwnPen(dataWow[0].id);
                        axios
                          .post(
                            `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/toggle-self`,
                            {
                              programId: dataWow[0].id,
                              isActive: true,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            }
                          )
                          .then((data) => {
                            console.log(data, "deded");
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                      checked={meBen}
                      className={"checkk"}
                    >
                      I am my own Beneficiary
                    </Checkbox>
                  </div>
                  <div className="flex mt-6 justify-end">
                    <button
                      type="submit"
                      onClick={nextHandler}
                      className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* <div className="w-[50%] ml-6">
            <div className="mb-8">
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Public Assistance - Economical
              </h2>
              <div className=" text-white mt-3">
                <div className="mt-4 cursor-pointer">
                  <img className="scale-[0.7]" src={add} alt="" />
                </div>
              </div>
            </div>
            <div className="mb-8">
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Public Assistance - Medical
              </h2>
              <div className=" text-white mt-3">
                <div className="mt-4 cursor-pointer">
                  <img className="scale-[0.7]" src={add} alt="" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div
          className={` ${
            dash === "/Dashboard/BeneficiaryQuestions" ? "hidden" : ""
          } flex sm:justify-end justify-center`}
        >
          <button
            className={`lg:text-[1.1rem] bg-[${color}] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            onClick={skipHandler}
          >
            {word}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default AddBenificiary;
