import cardimg from "../../imgs/cardInst.png";
import signature from "../../imgs/signature 1.png";
import code from "../../imgs/Qr Code.png";
import logo from "../../imgs/impLogo.png";
import flag from "../../imgs/flag.png";
import emblem from "../../imgs/emblem.png";
const DigitalCardBack = () => {
  return (
    <div>
      <div className="w-[32rem] h-[16.5rem] rounded-xl shadow-2xl overflow-hidden">
        <div className="w-full h-[82%] rounded-tl-xl rounded-tr-xl  pt-4">
          <div className="flex relative">
            <div className="absolute top-[-10%] left-[-1%] w-[5.5rem] h-[4.5rem]  ml-[1.3rem] flex justify-center items-center">
              <img className="w-[5.5rem] h-[4.5rem]" src={emblem} alt="" />
            </div>
            <div className="ml-[7rem]">
              <p className="w-[18.5rem] h-[2.5rem] pt-[0.4rem] pl-3 pr-3 mt-2 bg-[#0ca6fb] text-white text-[0.6rem]">
                If found, Please drop into Post Office box or any Government
                Office or call +592-225-4186 for instructions
              </p>
              <p className="text-[0.9rem] ml-3 mt-2 font-medium">
                GYD 10,000 for replacement card.
              </p>
            </div>
            <div className="w-[4.6rem] h-[4rem] ml-[1rem] mt-[0.5rem]">
              <img src={flag} alt="" />
            </div>
          </div>
          <div className="flex mt-[1.3rem] relative">
            <div>
              <div className="text-white bg-[#0ca6fb] font-bold text-[1.1rem] pt-1 pb-1 pl-6 pr-8">
                <p>OLD AGE PENSION PROGRAMME</p>
              </div>
              <p className="text-[0.8rem] font-semibold pl-6 mt-[0.4rem]">
                Additional Socail Assistance Programms:
                <br></br>
                <span className="text-[#07588F]">
                  ECONOMICAL, MEDICAL & DISABILITY
                </span>
              </p>
            </div>
            <div className="w-[8rem] h-[8rem] absolute top-[-45%] right-0">
              <img className="w-[8rem] h-[8rem]" src={logo} alt="" />
            </div>
          </div>
        </div>
        <div className="w-full h-[18%] bg-[#07588F] rounded-bl-xl rounded-br-xl p-2">
          <p className="text-white text-[0.7rem] text-center w-[30rem]">
            This card is issued by the Government of Guyana Ministry of Human
            Services and Social Security for its participating members for the
            different programs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DigitalCardBack;
