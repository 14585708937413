import { useLocation } from "react-router-dom";
import waiting1 from "../../imgs/waiting1.png";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
const Awaited = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const appUrl = useSelector((state) => state.appUrl);
  const token = useSelector((state) => state.token);
  const option = useSelector((state) => state.option);
  const [url, setUrl] = useState("");
  const dash = location.pathname;

  useEffect(() => {
    const body3 = [appUrl];
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
        {
          fileNames: body3,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "llkll");
        setUrl(data.data[0].url);
        // setDisplay2(data.data[0].url);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="flex justify-center">
      <div className="flex flex-col text-center justify-center items-center w-[70%]">
        <div className={`flex ${option === "Pensioner" ? "" : "hidden"}`}>
          <h2
            onClick={() => {
              dispatch({ type: "ISET", num: 10 });
              dispatch({ type: "fromAwaited", num: "Ben" });
            }}
            className=" cursor-pointer text-[1.2rem] text-[#2c5281] border-[#2c5281] border-r-[2px] pr-[1.2rem]"
          >
            Beneficiaries
          </h2>
          <h2
            onClick={() => {
              dispatch({ type: "fromAwaited", num: "App" });
            }}
            className="cursor-pointer text-[1.2rem] ml-[1rem] text-[#2c5281]"
          >
            Application
          </h2>
        </div>
        <div className="w-[311px] h-[297px]">
          <img className="scale-[1]" src={waiting1} alt="" />
        </div>
        <p className={`${dash === "/Dashboard/application" ? "" : "hidden"}`}>
          Your application form has been sent to MHSSS for approval. It might
          take some time for the management to go through all the steps of
          reviewing your account. Thank you for your patience.
        </p>
        <div>
          <div className="mt-5">
            {/* {url != "" && (
              <a
                href={`${url}`}
                target="_blank"
                className={`${option === "Employee" ? "hidden" : ""}`}
              >
                <button className="lg:text-[1.1rem] bg-[#18B871] text-white rounded-[0.5rem] p-2  pr-8 pl-8">
                  View Request
                </button>
              </a>
            )} */}
            <button
              onClick={() => {
                dispatch({ type: "RSET", num: 0 });
                dispatch({ type: "ISET", num: 1 });
                dispatch({ type: "ASET", num: 0 });
              }}
              className={`ml-5 lg:text-[1.1rem] bg-[#F7542E] ${
                dash === "/Dashboard/application" ? "hidden" : ""
              } text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awaited;
