import { Checkbox, DatePicker, Select } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import plusp from "../../../imgs/plusp.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import trash from "../../../imgs/trash.png";
import moment from "moment";
import cross from "../../../imgs/cross.png";
import MapsPage from "../../../pages/mapsPage";
import eye from "../../../imgs/search.png";

let arr = [1];
let firstQ = "";
let secondQ = "";
let file = "";
let buffer = [1];
let question1 = null;
let question1sub1 = null;
let question1sub2 = null;
let question1sub3 = null;
let question1sub4 = null;
let question1sub5 = null;
let question1sub6 = null;
let question1sub7 = null;
let question2 = null;
let question2sub1 = null;
let question2sub2 = null;

let sub1 = null;
let sub2 = null;
let sub3 = null;
let sub4 = null;
let sub5 = null;
let sub6 = null;

let allAdd = [
  {
    country: "",
    city: "",
    state: "",
  },
];

let rIndex = 0;

const SecondA = () => {
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const appQuestions = useSelector((state) => state.appQuestions);
  const placePop = useSelector((state) => state.placePop);
  const place = useSelector((state) => state.place);
  const applicationAnswers = useSelector((state) => state.applicationAnswers);
  const [test, setTest] = useState(false);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [checkBuffer, setCheckBuffer] = useState("");
  const [check1Buffer, setCheck1Buffer] = useState("");
  const [city, setCity] = useState("");
  const [state1, setState1] = useState("");
  const [country, setCountry] = useState("");
  const Question3 = useSelector((state) => state.Question3);
  const Question4 = useSelector((state) => state.Question4);
  const [display, setDisplay] = useState("");

  useEffect(() => {
    if (place) {
      const ci = place.address_components.filter((item) => {
        return item.types.includes("locality");
      });
      const co = place.address_components.filter((item) => {
        return item.types.includes("country");
      });
      const st = place.address_components.filter((item) => {
        return item.types.includes("administrative_area_level_1");
      });

      console.log(ci, st, co);
      if (ci.length != 0) {
        setCity(ci[0].long_name);
        allAdd[rIndex].city = ci[0].long_name;
      } else {
        setCity("");
      }
      if (st.length != 0) {
        setState1(st[0].long_name);
        allAdd[rIndex].state = st[0].long_name;
      } else {
        setState1("");
      }
      if (co.length != 0) {
        setCountry(co[0].long_name);
        allAdd[rIndex].country = co[0].long_name;
      } else {
        setCountry("");
      }
    }
  }, [place]);

  useEffect(() => {
    setCity("");
    setState1("");
    setCountry("");
    allAdd = [
      {
        country: "",
        city: "",
        state: "",
      },
    ];
  }, []);

  const nextHandler = () => {
    if (question1 != null) {
      Question3.push(question1);
    }
    if (question1sub1 != null) {
      Question3.push(question1sub1);
    }
    if (question1sub2 != null) {
      Question3.push(question1sub2);
    }
    if (question1sub3 != null) {
      Question3.push(question1sub3);
    }
    if (question1sub4 != null) {
      Question3.push(question1sub4);
    }
    if (question1sub5 != null) {
      Question3.push(question1sub5);
    }
    if (question1sub6 != null) {
      Question3.push(question1sub6);
    }
    if (question1sub7 != null) {
      Question3.push(question1sub7);
    }
    if (question2 != null) {
      Question4.push(question2);
    }
    if (question2sub1 != null) {
      Question4.push(question2sub1);
      localStorage.setItem("publicAssistanceType", question2sub1.answers[0]);
    }
    if (question2sub2 != null) {
      Question4.push(question2sub2);
      localStorage.setItem("publicAssistance", question2sub2.answers[0]);
    }
    if (Question3.length != 0) {
      Question3.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    console.log(Question4, "222222");
    if (Question4.length != 0) {
      Question4.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    dispatch({ type: "AINCREMENT" });
  };
  // useEffect(() => {
  //   console.log(applicationAnswers);
  //   return () => {
  //     // applicationAnswers.push(question1);
  //     // applicationAnswers.push(question2);
  //     // applicationAnswers.push(question1sub1);
  //     // applicationAnswers.push(question1sub2);
  //     // applicationAnswers.push(question1sub3);
  //     // applicationAnswers.push(question1sub4);
  //     // applicationAnswers.push(question1sub5);
  //     // applicationAnswers.push(question1sub6);
  //     // applicationAnswers.push(question1sub7);
  //     // applicationAnswers.push(question2sub1);
  //     // applicationAnswers.push(question2sub2);

  //     console.log(Question3);
  //     console.log(Question4);
  //     // question1 = {};
  //     // question1sub1 = {};
  //     // question1sub2 = {};
  //     // question1sub3 = {};
  //     // question1sub4 = {};
  //     // question1sub5 = {};
  //     // question1sub6 = {};
  //     // question1sub7 = {};
  //     // question2 = {};
  //     // question2sub1 = {};
  //     // question2sub2 = {};
  //   };
  // }, []);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [firstQ, secondQ, total]);

  const imageHandler = (setState) => async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const changeHandler = (item, index) => {
    setCheckBuffer(item);
    question1 = {
      appquestionId: firstQ[0].id,
      type: firstQ[0].type,
    };
    question1.answers = [item];
    console.log(question1, "qq");
  };
  const change2Handler = (item, index) => {
    setCheck1Buffer(item);
    question2 = {
      appquestionId: secondQ[0].id,
      type: secondQ[0].type,
    };
    question2.answers = [item];
    console.log(question2, "qq");
  };

  const addAddressHandler = (e) => {
    if (e === "add") {
      allAdd.push({
        country: "",
        city: "",
        state: "",
      });
      buffer.push(1);
    } else {
      buffer.pop();
    }
    console.log(buffer, "1111111111111");
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  };

  useEffect(() => {
    if (appQuestions) {
      firstQ = appQuestions.filter((item) => {
        return item.order === 3;
      });
      secondQ = appQuestions.filter((item) => {
        return item.order === 4;
      });
    }

    console.log(firstQ, secondQ);
    console.log(applicationAnswers, "wwwwwqqer");
  }, [appQuestions]);

  const change5Handler = (e, val, id, type, belongtoIndex, index) => {
    console.log(belongtoIndex, "22");
    if (index === 0) {
      question1sub1 = {
        appquestionId: id,
        type: type,
        belongToIndex: `${belongtoIndex}`,
        answers: [val],
      };
    } else {
      question1sub2 = {
        appquestionId: id,
        type: type,
        belongToIndex: `${belongtoIndex}`,
        answers: [val],
      };
    }

    // if (val != "") {
    //   applicationAnswers.push(wow);
    // }
    // console.log(applicationAnswers, "wiwiwi");
  };
  const change1Handler = (val, id, type, index1, index) => {
    // e, item.id, item.type, index1, index;
    console.log(val.target.value, id, type, index, index1);
    if (index1 === 0) {
      sub1 = Number(val.target.value);
    } else if (index1 === 1) {
      sub2 = Number(val.target.value);
    } else if (index1 === 2) {
      sub3 = Number(val.target.value);
    } else if (index1 === 3) {
      sub4 = Number(val.target.value);
    } else if (index1 === 4) {
      sub5 = Number(val.target.value);
    } else if (index1 === 5) {
      sub6 = Number(val.target.value);
    }
    console.log(sub1 + sub2 + sub3 + sub4 + sub5 + sub6, "111111111111111");
    setTotal(sub1 + sub2 + sub3 + sub4 + sub5 + sub6);
    const wow = total;
    console.log(wow, "22222");
    if (index === 2) {
      console.log("called1");
      question1sub3 = {
        appquestionId: id,
        type: type,
        belongToIndex: `${index1}`,
        answers: [val.target.value],
      };
    } else if (index === 3) {
      console.log("called2");
      question1sub4 = {
        appquestionId: id,
        type: type,
        belongToIndex: `${index1}`,
        answers: [val.target.value],
      };
    } else if (index === 4) {
      console.log("called3");
      question1sub5 = {
        appquestionId: id,
        type: type,
        belongToIndex: `${index1}`,
        answers: [val.target.value],
      };
    } else if (index === 6) {
      console.log("called4");
      question1sub7 = {
        appquestionId: id,
        type: type,
        belongToIndex: `${index1}`,
        answers: [val.target.value],
      };
    }

    console.log(applicationAnswers, "wiwiwi");
  };

  const handleChange = (setState, check) => (event) => {
    if (check === "tg") {
      setState(event);
    } else if (check === "tgf") {
      let wow;
      if (event.$M <= 9) {
        if (event.$D <= 9) {
          wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
        }
      } else {
        if (event.$D <= 9) {
          wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
        }
      }
      setState(wow);
    } else {
      setState(event.target.value);
    }
  };
  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  const date = getCurrentDate();
  const disabledBeforeDate = moment(
    `${date.props.children[0]}-${date.props.children[2]}-${date.props.children[4]}`
  );
  const disabledDate = (current) => {
    return current && current < disabledBeforeDate;
  };
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };

  return (
    <div>
      <div className="w-full min-h-[25rem] pb-5 pt-5 bg-[#F0F0F0] flex flex-col items-center justify-center sm:pl-10 sm:pr-10 pl-4 pr-4 rounded-2xl">
        <form action="" className="w-full mt-4">
          {firstQ != "" && secondQ != "" && (
            <div>
              <div>
                <h2 className={`mb-4 font-semibold`}>
                  {firstQ[0].questionText}
                </h2>
              </div>
              <div>
                {firstQ[0].choices.map((item, index) => {
                  return (
                    <div>
                      <Checkbox
                        className={`${
                          dash === "/Dashboard/application"
                            ? "checkk1"
                            : "checkk"
                        }`}
                        checked={checkBuffer === item ? true : false}
                        onClick={() => changeHandler(item, index)}
                      >
                        {item}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
              <div>
                {firstQ[0].childrenQuestions.length != 0 && (
                  <h2
                    className={`${
                      checkBuffer ===
                      firstQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                        ? ""
                        : "hidden"
                    } mb-4 lg:text-[1.1rem] font-semibold mt-2`}
                  >
                    {firstQ[0].childrenQuestions[0].wrapInHeading}
                  </h2>
                )}
                {console.log(firstQ[0].childrenQuestions, "dede")}
                {buffer.map((beforeItem, index1) => {
                  return (
                    <div>
                      <div
                        className={`flex justify-end cursor-pointer mb-[1rem] mt-[3rem] ${
                          index1 === 0 ? "hidden" : ""
                        }`}
                        onClick={() => addAddressHandler("remove")}
                      >
                        <p className="text-[red] underline text-[0.94rem]">
                          Remove Address
                        </p>
                      </div>
                      {firstQ[0].childrenQuestions.map((item, index) => {
                        return (
                          <div
                            className={`${
                              checkBuffer === item.showMeIfParentAnswerIs
                                ? ""
                                : "hidden"
                            }`}
                          >
                            <div
                              className={`${
                                item.questionText === "City" ||
                                item.questionText === "State" ||
                                item.questionText === "Country"
                                  ? "hidden"
                                  : ""
                              }`}
                            >
                              <input
                                className={`${
                                  item.type === "text" ? "" : "hidden"
                                } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                                onChange={(e) =>
                                  change1Handler(
                                    e,
                                    item.id,
                                    item.type,
                                    index1,
                                    index
                                  )
                                }
                                placeholder={`${item.questionText}`}
                              ></input>
                            </div>
                            <div
                              className={`${
                                item.questionText === "City" ||
                                item.questionText === "State" ||
                                item.questionText === "Country"
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              <div
                                className={`${
                                  checkBuffer ===
                                  firstQ[0].childrenQuestions[0]
                                    .showMeIfParentAnswerIs
                                    ? ""
                                    : "hidden"
                                }  flex justify-between items-center lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] border-[1px]  rounded-[0.3rem] p-2 mb-4`}
                              >
                                <input
                                  className={`w-[100%] bg-[#F7F7F7] `}
                                  // id={`wow${index}`}
                                  onChange={(e) =>
                                    change1Handler(
                                      e,
                                      item.id,
                                      item.type,
                                      index1,
                                      index
                                    )
                                  }
                                  value={
                                    item.questionText === "City"
                                      ? allAdd[index1].city
                                      : item.questionText === "Country"
                                      ? allAdd[index1].country
                                      : allAdd[index1].state
                                  }
                                  placeholder={`${item.questionText}`}
                                ></input>
                                <img
                                  className=" cursor-pointer w-[1.3rem] h-[1.3rem]"
                                  onClick={() => {
                                    rIndex = index1;
                                    dispatch({ type: "placePop" });
                                  }}
                                  src={eye}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div
                              className={`${
                                item.questionText ===
                                "Total Number of months in the last 5 years out of the country"
                                  ? "hidden"
                                  : ""
                              }`}
                            >
                              <input
                                className={`${
                                  item.type === "number" ? "" : "hidden"
                                } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                                onChange={(e) =>
                                  change1Handler(
                                    e,
                                    item.id,
                                    item.type,
                                    index1,
                                    index
                                  )
                                }
                                type="number"
                                placeholder={`${item.questionText}`}
                              ></input>
                            </div>
                            {item.choices && (
                              <div
                                className={`${
                                  item.type === "dropdown" ? "" : "hidden"
                                }`}
                              >
                                <Select
                                  onChange={(value) => {
                                    question1sub6 = {
                                      appquestionId: item.id,
                                      type: item.type,
                                      belongToIndex: `${index1}`,
                                      answers: [value],
                                    };
                                  }}
                                  // value={option}

                                  placeholder={`${item.questionText}`}
                                  className="mb-2 mt-2 wow"
                                >
                                  {console.log(item.choices, "qwqw")}
                                  {item.choices.map((option) => (
                                    <Select.Option key={option} value={option}>
                                      {option}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </div>
                            )}
                            <div
                              className={`${
                                item.type === "date" ? "" : "hidden"
                              }`}
                            >
                              <DatePicker
                                style={{ marginBottom: "1.2rem" }}
                                className="date-class"
                                placeholder={`${item.questionText}`}
                                disabledDate={
                                  item.questionText === "To" ||
                                  item.questionText === "From"
                                    ? disabledDate1
                                    : null
                                }
                                onChange={(e, val) =>
                                  change5Handler(
                                    e,
                                    val,
                                    item.id,
                                    item.type,
                                    index1,
                                    index
                                  )
                                }
                              />
                            </div>
                            <div
                              className={`${
                                item.type === "image" ? "" : "hidden"
                              }`}
                            >
                              <label
                                htmlFor="special-input1"
                                className={` cursor-pointer relative`}
                              >
                                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                                  <div>
                                    <img
                                      className="w-[1.2rem]"
                                      src={plusp}
                                      alt=""
                                    />
                                  </div>
                                  <p className="text-[#9CA6C2] ml-2">
                                    {item.questionText}
                                  </p>
                                </div>
                                <input
                                  type="file"
                                  className="hidden"
                                  id="special-input1"
                                  accept="image/png, image/gif, image/jpeg"
                                  onInput={imageHandler(setDisplay)}
                                />
                              </label>
                              <div
                                className={`relative mb-4 ${
                                  display ? "" : "hidden"
                                }`}
                              >
                                <img
                                  className="w-[25rem] h-[13rem]"
                                  src={display}
                                  alt=""
                                />
                                <div
                                  className={`absolute ${
                                    isLoading ? "" : "hidden"
                                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                                >
                                  <Spin size="large" />
                                </div>
                                <div
                                  className="absolute bottom-0 right-0 cursor-pointer bg-white"
                                  onClick={() => setDisplay("")}
                                >
                                  <img
                                    className="w-[3rem]"
                                    src={trash}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <div
                className={`flex mb-2 ${
                  firstQ[0].childrenQuestions[0].showMeIfParentAnswerIs ===
                  checkBuffer
                    ? ""
                    : "hidden"
                } justify-center items-center mt-4`}
                onClick={() => addAddressHandler("add")}
              >
                <div className="flex justify-center items-center cursor-pointer">
                  <div>
                    <img
                      className="w-[1.2rem] h-[1.2rem] mr-2"
                      src={plusp}
                      alt=""
                    />
                  </div>
                  <p className="text-[#18B871]">Add Another</p>
                </div>
              </div>
            </div>
          )}
          <div className={`${checkBuffer === "Yes" ? "" : "hidden"} mb-4`}>
            <h2 className="font-bold">Total Months</h2>
            <input
              type="number"
              name=""
              id=""
              value={total}
              className="lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4"
              placeholder="Total Number of months in the last 5 years out of the country"
            />
          </div>

          {firstQ != "" && secondQ != "" && (
            <div>
              <div>
                <h2 className={`mb-4 font-semibold`}>
                  {secondQ[0].questionText}
                </h2>
              </div>
              <div>
                {secondQ[0].choices.map((item, index) => {
                  return (
                    <div>
                      <Checkbox
                        className={`${
                          dash === "/Dashboard/application"
                            ? "checkk1"
                            : "checkk"
                        }`}
                        checked={check1Buffer === item ? true : false}
                        onClick={() => change2Handler(item, index)}
                      >
                        {item}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
              <div>
                {secondQ[0].childrenQuestions.length != 0 && (
                  <h2
                    className={`${
                      checkBuffer ===
                      secondQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                        ? ""
                        : "hidden"
                    } mb-4 lg:text-[1.1rem] font-semibold mt-2`}
                  >
                    {secondQ[0].childrenQuestions[0].wrapInHeading}
                  </h2>
                )}
                {secondQ[0].childrenQuestions.map((item, index) => {
                  return (
                    <div
                      className={`${
                        check1Buffer === item.showMeIfParentAnswerIs
                          ? ""
                          : "hidden"
                      }`}
                    >
                      <input
                        className={` ${
                          item.type === "text" ? "" : "hidden"
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        placeholder={`${item.questionText}`}
                      ></input>
                      <input
                        className={`${
                          item.type === "number" ? "" : "hidden"
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        onChange={(e) => {
                          question2sub2 = {
                            appquestionId: item.id,
                            type: item.type,
                            answers: [e.target.value],
                          };
                        }}
                        type="number"
                        placeholder={`${item.questionText}`}
                      ></input>
                      {item.choices && (
                        <div
                          className={`${
                            item.type === "dropdown" ? "" : "hidden"
                          }`}
                        >
                          <Select
                            onChange={(value) => {
                              console.log(value);
                              question2sub1 = {
                                appquestionId: item.id,
                                type: item.type,
                                answers: [value],
                              };
                            }}
                            // value={option}
                            placeholder={`${item.questionText}`}
                            className="mb-2 mt-2 wow"
                          >
                            {console.log(item.choices, "qwqw")}
                            {item.choices.map((option) => (
                              <Select.Option key={option} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div
                        className={`${item.type === "date" ? "" : "hidden"}`}
                      >
                        <DatePicker
                          style={{ marginBottom: "1.2rem" }}
                          className="date-class"
                          placeholder={`${item.questionText}`}
                        />
                      </div>
                      <div
                        className={`${item.type === "image" ? "" : "hidden"}`}
                      >
                        <label
                          htmlFor="special-input1"
                          className={` cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <img className="w-[1.2rem]" src={plusp} alt="" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">
                              {item.questionText}
                            </p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            id="special-input1"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(setDisplay)}
                          />
                        </label>
                        <div
                          className={`relative mb-4 ${display ? "" : "hidden"}`}
                        >
                          <img
                            className="w-[25rem] h-[13rem]"
                            src={display}
                            alt=""
                          />
                          <div
                            className={`absolute ${
                              isLoading ? "" : "hidden"
                            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                          >
                            <Spin size="large" />
                          </div>
                          <div
                            className="absolute bottom-0 right-0 cursor-pointer bg-white"
                            onClick={() => setDisplay("")}
                          >
                            <img className="w-[3rem]" src={trash} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </form>
        <div
          className={`${
            placePop ? "" : "hidden"
          } w-full h-full fixed top-0 bg-slate-400 flex justify-center items-center bg-opacity-[40%]`}
        >
          <div className="relative w-[20rem] h-[8.5rem] bg-white flex justify-center items-center rounded-xl">
            <img
              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
              src={cross}
              onClick={() => dispatch({ type: "placePop" })}
              alt=""
            />

            <MapsPage />
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={nextHandler}
          className={`lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SecondA;
