import { useDispatch, useSelector } from "react-redux";
import pensioner1 from "../../../imgs/pensioner1.png";
import merchant1 from "../../../imgs/merchant1.png";
import events1 from "../../../imgs/events1.png";
import employee1 from "../../../imgs/employee1.png";
import social1 from "../../../imgs/social1.png";
import wallet1 from "../../../imgs/wallet1.png";
import social2 from "../../../imgs/Rectangle 20.png";
import { useState } from "react";
import check from "../../../imgs/checkkk.png";
const FirstR = () => {
  const dispatch = useDispatch();
  const [pc, setPc] = useState(true);
  const [ec, setEc] = useState(true);
  const [mc, setMc] = useState(false);
  const option = useSelector((state) => state.option);
  const optHandler = (e) => {
    if (e === "Pensioner") {
      dispatch({ type: "PENSIONER" });
      setPc(true);
      setEc(false);
      setMc(false);
    }
    if (e === "Employee") {
      dispatch({ type: "EMPLOYEE" });
      setPc(false);
      setEc(true);
      setMc(false);
    }
    if (e === "Merchant") {
      dispatch({ type: "MERCHANT" });
      setPc(false);
      setEc(false);
      setMc(true);
    }
  };
  return (
    <div>
      <div className="text-center mt-[2rem] flex flex-col items-center">
        <h2 className="font-medium text-[1.6rem] text-[#2c5281] mb-1">
          Register
        </h2>
        <p className="sm:w-[60%] w-[80%] text-[#596F96] text-[0.9rem]">
          Use the approved credentials provided by the Ministry of Human
          Services and Social Security (MHSSS) to access information on Old Age
          Pension applications, status, and services via your account.
        </p>
      </div>
      <div className="flex justify-center items-center ">
        <div className="mt-[2rem] grid sm:w-[55%] w-[90%] sm:grid-cols-3 grid-cols-2 gap-4">
          <div
            onClick={() => optHandler("Pensioner")}
            className="relative sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#EDEFFD] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4" src={pensioner1} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">Pensioner</p>
            <div
              className={`bg-[#18B871] top-2 right-2 rounded-sm absolute ${
                pc && option === "Pensioner" ? "" : "hidden"
              }`}
            >
              <img src={check} className="lg:w-5 w-5" alt="" />
            </div>
          </div>

          <div
            onClick={() => alert("Coming Soon")}
            className="sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#EDEFFD] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4" src={social2} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">Social Benefits</p>
          </div>
          <div
            onClick={() => optHandler("Employee")}
            className="relative sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#EDEFFD] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4" src={employee1} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">Employee</p>
            <div
              className={`bg-[#18B871]  absolute top-2 right-2 rounded-sm ${
                ec && option === "Employee" ? "" : "hidden"
              }`}
            >
              <img src={check} className="lg:w-5 w-5" alt="" />
            </div>
          </div>
          <div
            onClick={() => alert("Coming Soon")}
            className="sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#EDEFFD] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4 rounded-xl" src={events1} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">Events</p>
          </div>

          <div
            onClick={() => alert("Coming Soon")}
            className="sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#EDEFFD] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4" src={merchant1} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">Merchant</p>
          </div>
          <div
            onClick={() => alert("Coming Soon")}
            className="sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#EDEFFD] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4" src={wallet1} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">OAP eWallet</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <button
          onClick={() => dispatch({ type: "RSET", num: 1 })}
          className="cursor-pointer mt-10 bg-gradient-to-tr bg-[#57D38C] pt-2 pb-2 pl-[4rem] pr-[4rem] text-white rounded-xl"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default FirstR;
