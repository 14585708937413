import { Checkbox, DatePicker, Select } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import plusp from "../../../imgs/plusp.png";
import trash from "../../../imgs/trash.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Spin } from "antd";
import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import MapsPage from "../../../pages/mapsPage";
import eye from "../../../imgs/search.png";
import cross from "../../../imgs/cross.png";
import across from "../../../imgs/across.png";
import moment from "moment";

let file;
let firstQ = "";
let secondQ = "";
let question1 = null;
let question1sub1 = null;
let question1sub2 = null;
let question1sub3 = null;
let question2 = null;
let question2sub1 = null;
let question2sub2 = null;
let question2sub3 = null;
const FirstA = () => {
  const [display, setDisplay] = useState("");
  const [err, setErr] = useState("");
  const [display1, setDisplay1] = useState("");
  const location = useLocation();
  const dash = location.pathname;
  const [show, setShow] = useState(false);
  const [iShow, setIShow] = useState(false);

  const [show1, setShow1] = useState(false);
  const [initial, setInitial] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [checkBuffer, setCheckBuffer] = useState("");
  const [check1Buffer, setCheck1Buffer] = useState("");
  const appQuestions = useSelector((state) => state.appQuestions);
  const applicationAnswers = useSelector((state) => state.applicationAnswers);
  const placePop = useSelector((state) => state.placePop);
  const place = useSelector((state) => state.place);
  const Question1 = useSelector((state) => state.Question1);
  const Question2 = useSelector((state) => state.Question2);
  const dataUser = useSelector((state) => state.dataUser);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [test, setTest] = useState(false);
  const [city, setCity] = useState("");
  const [state1, setState1] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    setCity("");
    setState1("");
    setCountry("");
  }, []);
  const nextHandler = () => {
    console.log(
      "calllled",
      question2,
      question2sub1,
      question2sub2,
      question2sub3
    );
    if (question1 != null) {
      Question1.push(question1);
    }
    if (question1sub1 != null) {
      Question1.push(question1sub1);
    }
    if (question1sub2 != null) {
      Question1.push(question1sub2);
    }
    if (question1sub3 != null) {
      Question2.push(question1sub3);
    }
    if (question2 != null) {
      Question2.push(question2);
    }
    if (question2sub1 != null) {
      Question2.push(question2sub1);
    }
    if (question2sub2 != null) {
      Question2.push(question2sub2);
    }
    if (question2sub3 != null) {
      Question2.push(question2sub3);
    }
    if (Question1.length != 0) {
      Question1.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    if (Question2.length != 0) {
      Question2.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }

    if (!question2 || !question1) {
      setErr("Please fill in the complete information");
      setShow(true);
      console.log(question1, question2, "dedwqwdfwf");
    } else {
      if (question2.answers[0] === "Birth") {
        if (question2sub3 === null || question2sub1 === null) {
          setErr("Please fill in the complete information");
          setShow(true);
        } else {
          dispatch({ type: "AINCREMENT" });
        }
      } else {
        if (
          question2sub1 === null ||
          question2sub2 === null ||
          question2sub3 === null
        ) {
          setErr("Please fill in the complete information");
          setShow(true);
        } else {
          dispatch({ type: "AINCREMENT" });
        }
      }
    }
  };
  // useEffect(() => {
  //   console.log(applicationAnswers);

  //   return () => {

  //     console.log(Question1);
  //     console.log(Question2);
  //   };
  // }, []);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [firstQ, secondQ]);

  const changeHandler = (item, index) => {
    setCheckBuffer(item);
    question1 = {
      appquestionId: firstQ[0].id,
      type: firstQ[0].type,
    };
    question1.answers = [item];
    console.log(question1, "qq");
  };
  const change2Handler = (item, index) => {
    setCheck1Buffer(item);
    question2 = {
      appquestionId: secondQ[0].id,
      type: secondQ[0].type,
    };
    question2.answers = [item];
    console.log(question1, "qq");
  };
  const change3Handler = (e, index, id, type) => {
    if (index === "city") {
      setCity(e.target.value);
      question1sub1 = {
        appquestionId: id,
        type: type,
        answers: [city],
      };
    } else if (index === "state") {
      setState1(e.target.value);
      question1sub2 = {
        appquestionId: id,
        type: type,
        answers: [state1],
      };
    } else if (index === "country") {
      setCountry(e.target.value);
      question1sub3 = {
        appquestionId: id,
        type: type,
        answers: [country],
      };
    }
  };

  const change4Handler = (e, id, type) => {
    question2sub1 = {
      appquestionId: id,
      type: type,
      answers: [e.target.value],
    };
    console.log(question2sub1);
  };
  const change5Handler = (e, val, id, type) => {
    question2sub2 = {
      appquestionId: id,
      type: type,
      answers: [val],
    };
    console.log(question2sub2);
  };
  const imageHandler = (setState, id, type) => async (e) => {
    console.log("called", display);
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    console.log(file, "see");
    setIsLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        // setUrl(data.data.url);
        question2sub3 = {
          appquestionId: id,
          type: "img-url",
          answers: [data.data.url],
        };
        setIsLoading(false);
        console.log(question2sub3);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  useEffect(() => {
    if (appQuestions) {
      firstQ = appQuestions.filter((item) => {
        return item.order === 1;
      });
      secondQ = appQuestions.filter((item) => {
        return item.order === 2;
      });
    }

    console.log(firstQ, secondQ);
  }, [appQuestions]);

  useEffect(() => {
    if (place) {
      const ci = place.address_components.filter((item) => {
        return item.types.includes("locality");
      });
      const co = place.address_components.filter((item) => {
        return item.types.includes("country");
      });
      const st = place.address_components.filter((item) => {
        return item.types.includes("administrative_area_level_1");
      });

      console.log(ci, st, co);
      if (ci.length != 0) {
        setCity(ci[0].long_name);
      } else {
        setCity("");
      }
      if (st.length != 0) {
        setState1(st[0].long_name);
      } else {
        setState1("");
      }
      if (co.length != 0) {
        setCountry(co[0].long_name);
      } else {
        setCountry("");
      }
    }
  }, [place]);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [country, state1, city]);

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }

  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };
  return (
    <div>
      <div className="w-full min-h-[25rem] pb-5 pt-5 bg-[#F0F0F0] flex flex-col items-center justify-center sm:pl-10 sm:pr-10 pl-4 pr-4 rounded-2xl">
        <form action="" className="w-full mt-4">
          {firstQ != "" && secondQ != "" && (
            <div>
              <div>
                <h2 className={`mb-4 font-semibold`}>
                  {firstQ[0].questionText}
                </h2>
              </div>
              <div>
                {firstQ[0].choices.map((item, index) => {
                  return (
                    <div>
                      <Checkbox
                        className={`${
                          dash === "/Dashboard/application"
                            ? "checkk1"
                            : "checkk"
                        }`}
                        checked={checkBuffer === item ? true : false}
                        onClick={() => changeHandler(item, index)}
                      >
                        {item}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
              <div>
                {firstQ[0].childrenQuestions.length != 0 && (
                  <h2
                    className={`${
                      checkBuffer ===
                      firstQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                        ? ""
                        : "hidden"
                    } mb-4 lg:text-[1.1rem] font-semibold mt-2`}
                  >
                    {firstQ[0].childrenQuestions[0].wrapInHeading}
                  </h2>
                )}

                <div
                  className={`${
                    checkBuffer ===
                    firstQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                      ? ""
                      : "hidden"
                  } flex justify-between items-center lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] border-[1px]  rounded-[0.3rem] p-2 mb-4`}
                >
                  <input
                    className="w-[100%] bg-[#F7F7F7] "
                    // id={`wow${index}`}
                    onChange={(e) =>
                      change3Handler(
                        e,
                        "city",
                        firstQ[0].childrenQuestions[0].id,
                        firstQ[0].childrenQuestions[0].type
                      )
                    }
                    value={city}
                    placeholder={`${firstQ[0].childrenQuestions[0].questionText}`}
                  ></input>
                  <img
                    className=" cursor-pointer w-[1.3rem] h-[1.3rem]"
                    onClick={() => dispatch({ type: "placePop" })}
                    src={eye}
                    alt=""
                  />
                </div>

                <div
                  className={`${
                    checkBuffer ===
                    firstQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                      ? ""
                      : "hidden"
                  } flex justify-between items-center lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] border-[1px]  rounded-[0.3rem] p-2 mb-4`}
                >
                  <input
                    className="w-[100%] bg-[#F7F7F7] "
                    // id={`wow${index}`}
                    onChange={(e) =>
                      change3Handler(
                        e,
                        "state",
                        firstQ[0].childrenQuestions[1].id,
                        firstQ[0].childrenQuestions[1].type
                      )
                    }
                    value={state1}
                    placeholder={`${firstQ[0].childrenQuestions[1].questionText}`}
                  ></input>
                  <img
                    className="w-[1.3rem] h-[1.3rem] cursor-pointer"
                    onClick={() => dispatch({ type: "placePop" })}
                    src={eye}
                    alt=""
                  />
                </div>

                {console.log(country, "sswssww")}
                <div
                  className={`${
                    checkBuffer ===
                    firstQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                      ? ""
                      : "hidden"
                  } flex justify-between items-center lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] border-[1px]  rounded-[0.3rem] p-2 mb-4`}
                >
                  <input
                    className="w-[100%] bg-[#F7F7F7] "
                    // id={`wow${index}`}
                    onChange={(e) =>
                      change3Handler(
                        e,
                        "country",
                        firstQ[0].childrenQuestions[2].id,
                        firstQ[0].childrenQuestions[2].type
                      )
                    }
                    value={country}
                    placeholder={`${firstQ[0].childrenQuestions[2].questionText}`}
                  ></input>
                  <img
                    className="w-[1.3rem] h-[1.3rem] cursor-pointer"
                    onClick={() => dispatch({ type: "placePop" })}
                    src={eye}
                    alt=""
                  />
                </div>
              </div>
            </div>
          )}
          {firstQ != "" && secondQ != "" && (
            <div>
              <div>
                <h2 className={`mb-4 font-semibold`}>
                  {secondQ[0].questionText}
                </h2>
              </div>
              <div>
                {secondQ[0].choices.map((item, index) => {
                  return (
                    <div>
                      <Checkbox
                        className={`${
                          dash === "/Dashboard/application"
                            ? "checkk1"
                            : "checkk"
                        }`}
                        checked={check1Buffer === item ? true : false}
                        onClick={() => change2Handler(item, index)}
                      >
                        {item}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
              <div>
                {secondQ[0].childrenQuestions.length != 0 && (
                  <h2
                    className={`${
                      checkBuffer ===
                      secondQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                        ? ""
                        : "hidden"
                    } mb-4 lg:text-[1.1rem] font-semibold mt-2`}
                  >
                    {secondQ[0].childrenQuestions[0].wrapInHeading}
                  </h2>
                )}
                {secondQ[0].childrenQuestions.map((item, index) => {
                  return (
                    <div
                      className={`${
                        check1Buffer === item.showMeIfParentAnswerIs
                          ? ""
                          : "hidden"
                      }`}
                    >
                      <input
                        className={` ${
                          item.type === "text" ? "" : "hidden"
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        onChange={(e) => change4Handler(e, item.id, item.type)}
                        placeholder={`${item.questionText}`}
                      ></input>
                      {/* <input
                      className={`${
                        item.type === "number" ? "" : "hidden"
                      } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                      placeholder={`${item.questionText}`}
                    ></input> */}
                      {item.choices && (
                        <div
                          className={`${
                            item.type === "dropdown" ? "" : "hidden"
                          }`}
                        >
                          <Select
                            // onChange={(value) => {
                            //   setValue(option);
                            // }}
                            // value={option}
                            placeholder={`${item.questionText}`}
                            className="mb-2 mt-2 bg-[#F7F7F7] wow"
                          >
                            {console.log(item.choices, "qwqw")}
                            {item.choices.map((option) => (
                              <Select.Option key={option} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div
                        className={`${item.type === "date" ? "" : "hidden"}`}
                      >
                        <DatePicker
                          style={{ marginBottom: "1.2rem" }}
                          className="date-class"
                          placeholder={`${item.questionText}`}
                          disabledDate={disabledDate1}
                          onChange={(e, val) =>
                            change5Handler(e, val, item.id, item.type)
                          }
                        />
                      </div>
                      <div
                        className={`${item.type === "image" ? "" : "hidden"}`}
                      >
                        <label
                          htmlFor="special-input1"
                          className={` cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <img className="w-[1.2rem]" src={plusp} alt="" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">
                              {item.questionText}
                            </p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            id="special-input1"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setDisplay,
                              item.id,
                              item.type
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 ${display ? "" : "hidden"}`}
                        >
                          <img
                            onClick={() => {
                              setIShow(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={display}
                            alt=""
                          />
                          <div
                            className={`absolute ${
                              isLoading ? "" : "hidden"
                            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                          >
                            <Spin size="large" />
                          </div>
                          <div
                            className="absolute bottom-0 right-0 cursor-pointer bg-white"
                            onClick={() => setDisplay("")}
                          >
                            <img className="w-[3rem]" src={trash} alt="" />
                          </div>
                        </div>
                        <div
                          className={`${
                            iShow ? "" : "hidden"
                          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                        >
                          <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                            <img
                              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                              src={cross}
                              onClick={() => setIShow(false)}
                              alt=""
                            />
                            <img
                              className="w-[45rem] h-[28rem]"
                              src={display}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </form>
        <div
          className={`${
            placePop ? "" : "hidden"
          } w-full h-full fixed top-0 bg-slate-400 flex justify-center items-center bg-opacity-[40%]`}
        >
          <div className="relative w-[20rem] h-[8.5rem] bg-white flex justify-center items-center rounded-xl">
            <img
              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
              src={cross}
              onClick={() => dispatch({ type: "placePop" })}
              alt=""
            />

            <MapsPage />
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={nextHandler}
          className={`lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstA;
