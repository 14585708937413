import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import across from "../../../imgs/across.png";

const Banking1 = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const router = useNavigate();
  const dash = location.pathname;
  const namePerson = useSelector((state) => state.namePerson);
  const popData = useSelector((state) => state.popData);
  const dataUser = useSelector((state) => state.dataUser);
  const token = useSelector((state) => state.token);
  const data3 = useSelector((state) => state.data3);
  const data1 = useSelector((state) => state.data1);
  const [test, setTest] = useState(false);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [iimg, setIimg] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [accountTitle, setAccountTitle] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [swiftNo, setSwiftNo] = useState("");
  const [routingNo, setRoutingNo] = useState("");
  const [accountType, setAccountType] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [isInternational, setIsInternational] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [val, setVal] = useState("");
  const nextHandler = (e) => {
    e.preventDefault();
    if (
      bankName ||
      bankAddress ||
      accountTitle ||
      accountNo ||
      isInternational ||
      routingNo ||
      accountType ||
      homeAddress ||
      swiftNo
    ) {
      if (!one || !two || !three) {
        setErr("Please agreed to all the statement");
        setShow(true);
      } else {
        let wow = true;
        let body = {
          bankName,
          bankAddress,
          accountTitle,
          accountNo,
          isInternational,
          routingNo,
          accountType,
          homeAddress,
        };
        if (isInternational) {
          body.swiftNo = swiftNo;
          if (swiftNo != "") {
            wow = true;
          } else {
            wow = false;
            setErr("Please enter the swift number");
            setShow(true);
          }
        }

        if (wow) {
          if (dash === "/EDashboard/addPensioner") {
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/bank-info/add-by-staff?pensionerUserId=${popData.id}`,
                { bankInfo: body },
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then((data) => {
                console.log(data, "deef");
                router("/EDashboard/Pensioner_HistoryP");
              })
              .catch((err) => {
                console.log(err);
                console.log(err);
                setErr(err.response.data.message);
                setShow(true);
              });
          } else {
            axios
              .post(`${process.env.REACT_APP_BASE_URL}/v1/bank-info`, body, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((data) => {
                console.log(data, "1212");
                dispatch({ type: "SET", num: 3 });
                dispatch({ type: "POP" });

                dispatch({ type: "IINCREMENT" });
              })
              .catch((err) => {
                console.log(err);
                setErr(err.response.data.message);
                setShow(true);
              });
          }
        }
      }
    }
  };

  useEffect(() => {
    let sett;
    if (data3.data.signature != null) {
      sett = [data3.data.signature];
    } else if (data3.data.leftIndex != null) {
      sett = [data3.data.leftIndex];
    } else if (data3.data.rightIndex != null) {
      sett = [data3.data.rightIndex];
    } else if (data3.data.leftThumb != null) {
      sett = [data3.data.leftThumb];
    } else {
      sett = [data3.data.rightThumb];
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
        {
          fileNames: sett,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "llkll");
        setIimg(data.data[0].url);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data3]);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [iimg]);

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
    valHandler();
  };
  const checkHandler = (e) => {
    console.log(e.target.value);
    setAccountType(e.target.value);
  };

  const conHandler = (e) => {
    setConfirm(e.target.value);
    valHandler();
  };
  const valHandler = (e) => {
    if (
      document.getElementById("password").value ==
      document.getElementById("validate").value
    ) {
      // document.getElementById("message").style.color = "green";
      document.getElementById("message1").style.color = "#596F96";
      document.getElementById("message2").style.color = "#596F96";
      document.getElementById("message1").innerHTML = "Bank Account No";
      document.getElementById("message2").innerHTML = "Confirm Bank Account No";
      setVal(false);
    } else {
      // document.getElementById("message").style.color = "red";
      document.getElementById("message1").style.color = "red";
      document.getElementById("message2").style.color = "red";
      document.getElementById("message1").innerHTML = "Bank Account No**";
      document.getElementById("message2").innerHTML =
        "Confirm Bank Account No**";
      setVal(true);
    }
  };
  return (
    <div>
      <div className="flex justify-center">
        <form onSubmit={nextHandler} action="" className=" mt-10">
          <div className="flex justify-center items-center">
            <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
              <div>
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Name of Bank
                </h2>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Name of Bank"
                  onChange={handleChange(setBankName)}
                  value={bankName}
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Address of Branch
                </h2>
                <textarea
                  type="text"
                  name=""
                  id=""
                  onChange={handleChange(setBankAddress)}
                  value={bankAddress}
                  placeholder="Address of Branch"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Name(s) on Account
                </h2>
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={handleChange(setAccountTitle)}
                  value={accountTitle}
                  placeholder="Name(s) on Account"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2
                  id="message1"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Bank Account No
                </h2>
                <input
                  type="text"
                  name=""
                  id="password"
                  onChange={handleChange(setAccountNo)}
                  value={accountNo}
                  placeholder="Bank Account No"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2
                  id="message2"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Confirm Bank Account No
                </h2>
                <input
                  type="text"
                  name=""
                  id="validate"
                  value={confirm}
                  onChange={conHandler}
                  placeholder="Confirm Bank Account No"
                  className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                />
                {/* <div style={{ marginBottom: "1rem" }}>
                  <label htmlFor="validate">
                    <span id="message"></span>
                  </label>
                </div> */}
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Routing #
                </h2>
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={handleChange(setRoutingNo)}
                  value={routingNo}
                  placeholder="Routing #"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div>
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Bank Account Type
                </h2>
                <div className="mb-4 mt-2">
                  <input
                    type="radio"
                    id="saving"
                    name="fav_language"
                    value="Saving"
                    onChange={checkHandler}
                  ></input>
                  <label for="saving" className="mr-8 ml-1 text-[0.9rem]">
                    Saving
                  </label>
                  <input
                    type="radio"
                    id="chequing"
                    name="fav_language"
                    onChange={checkHandler}
                    value="Chequing"
                  ></input>
                  <label for="chequing" className=" ml-1 text-[0.9rem]">
                    Chequing
                  </label>
                </div>
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Home Address
                </h2>
                <textarea
                  type="text"
                  name=""
                  id=""
                  onChange={handleChange(setHomeAddress)}
                  value={homeAddress}
                  placeholder="Home Address"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2
                  className={`${
                    isInternational ? "" : "hidden"
                  } text-[#596F96] text-[0.88rem] font-semibold mb-2`}
                >
                  Swift #
                </h2>
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={handleChange(setSwiftNo)}
                  value={swiftNo}
                  style={{ textTransform: "uppercase" }}
                  placeholder="Swift #"
                  className={`${
                    isInternational ? "" : "hidden"
                  } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                />
                <div className="mb-4 bg-[#f5f5ff] p-4 pt-8">
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFour(true);
                        setIsInternational(true);
                      } else {
                        setFour(false);
                        setIsInternational(false);
                      }
                    }}
                    className={"checkk2"}
                  >
                    International Account
                  </Checkbox>
                </div>
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  SURRENDER OF VOUCHER DETAILS
                </h2>
                <p className="text-[0.8rem]  mb-4">
                  I {namePerson} of my ownn free will Surrender my voucher to
                  MHSSS
                </p>
                <div className="ml-4">
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOne(true);
                      } else {
                        setOne(false);
                      }
                    }}
                    className={"checkk2"}
                  >
                    I agree to the statement above
                  </Checkbox>
                </div>
                {namePerson && (
                  <div>
                    <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                      SURRENDER OF BANK INFORMATION DETAILS
                    </h2>
                    <p className="text-[0.8rem] mb-4">
                      I {namePerson} of my ownn free will Surrender my voucher
                      to MHSSS
                    </p>
                    <div className="ml-4">
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            setTwo(true);
                          } else {
                            setTwo(false);
                          }
                        }}
                        className={"checkk2"}
                      >
                        I agree to the statement above
                      </Checkbox>
                    </div>
                  </div>
                )}
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  WAIVING MY RIGHTS TO BE PAID IN PERSON IN ACCORDANCE WITH THE
                  OLD AGE PENSIONER ACT, CAP. 36:30
                </h2>
                <p className="text-[0.8rem] mb-4">
                  I {namePerson} of my ownn free will Surrender my voucher to
                  MHSSS
                </p>
                <div className="ml-4">
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setThree(true);
                      } else {
                        setThree(false);
                      }
                    }}
                    className={"checkk2"}
                  >
                    I agree to the statement above
                  </Checkbox>
                </div>
                <div className="mt-4 mb-4">
                  <img className="w-[25rem] h-[13rem]" src={iimg} />
                </div>
                {console.log(data1, "dde")}
                <input
                  type="text"
                  name=""
                  id=""
                  value={
                    dash === "/EDashboard/addPensioner"
                      ? `${namePerson}`
                      : `${data1.title} ${namePerson}`
                  }
                  className="pointer-events-none lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
          </div>
          <div className="lg:mt-[3rem] flex mt-8 items-center sm:justify-end justify-center w-[85%]">
            <button
              type="button"
              onClick={() => {
                if (dash === "/EDashboard/addPensioner") {
                  router("/EDashboard/Pensioner_HistoryP");
                } else {
                  dispatch({ type: "IINCREMENT" });
                }
              }}
              className={`lg:text-[1.1rem] bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Skip
            </button>
            <button
              type="submit"
              className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Done
            </button>
          </div>
        </form>
        <div
          className={`${
            show ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div
              className="absolute top-[-11%] left-[42%]"
              onClick={() => setShow(false)}
            >
              <img src={across} alt="" />
            </div>
            <p className="text-center mt-[3rem] mb-5">{err}</p>
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banking1;
