import { useLocation, useNavigate } from "react-router-dom";

import back5 from "../../imgs/back5.png";
import Oap from "./parts/oap";
import { useState } from "react";
import Saf from "./parts/saf";
import Sam from "./parts/sam";

const About = () => {
  const [wow, setWow] = useState("R");
  const [wow1, setWow1] = useState(0);
  const router = useNavigate();
  const location = useLocation();
  const Show = [Oap, Saf, Sam];
  let Dis = Show[wow1];
  const changeHandler = (e) => {
    if (e === "R") {
      setWow1(0);
    } else if (e === "I") {
      setWow1(1);
    } else {
      setWow1(2);
    }
    setWow(e);
  };
  return (
    <div>
      <div className="mt-[3rem]">
        <div
          onClick={() => {
            if (location.pathname === "/EDashboard/About") {
              router("/EDashboard");
            } else {
              router("/Dashboard");
            }
          }}
          className=" cursor-pointer flex items-center ml-4"
        >
          <div>
            <img className="w-[1.7rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold sm:text-[2rem] text-[1.6rem] ml-3">About</h2>
        </div>
        <div className="w-full flex h-[4rem] mt-[2rem] bg-[#EEF0FC]">
          <div
            className={`cursor-pointer flex justify-center items-center  w-[33%] ${
              wow === "R" ? "bg-[#F7542E] text-white" : ""
            }  rounded-tr-[5rem] rounded-br-[5rem] sm:text-[1rem] text-[0.8rem]`}
            onClick={() => changeHandler("R")}
          >
            General
          </div>
          <div
            className={`${
              wow === "I" ? "bg-[#F7542E] text-[white]" : ""
            } cursor-pointer w-[33%] flex justify-center rounded-[5rem] items-center sm:text-[1rem] text-[0.8rem]`}
            onClick={() => changeHandler("I")}
          >
            Old Age Pension
          </div>
          <div
            className={`${
              wow === "L" ? "bg-[#F7542E] text-white" : ""
            } cursor-pointer w-[34%] rounded-tl-[5rem] rounded-bl-[5rem] flex justify-center items-center sm:text-[1rem] text-[0.8rem]`}
            onClick={() => changeHandler("L")}
          >
            Social Assistance
          </div>
        </div>
      </div>

      <Dis />
    </div>
  );
};

export default About;
