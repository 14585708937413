import { useState } from "react";
import LoginE from "./tabsR/loginE";
import PersonalE from "./tabsR/personalE";
import LocationE from "./tabsR/locationE";
import LocationsE from "./tabsR/locationE";
import EmployerE from "./tabsR/employerE";

import PersonalDetails1 from "../../PAccount/parts/tabsR/personalDetails";
import Location1 from "../../PAccount/parts/tabsR/location";
import Identification2 from "../../PAccount/parts/tabsR/identification";

const Eregistration = () => {
  const [wow, setWow] = useState(0);
  const Show = [LoginE, PersonalDetails1, Location1, EmployerE];
  const Dis = Show[wow];
  return (
    <div className="">
      <div className="flex flex-wrap mt-8 mb-[5rem]">
        <button
          onClick={() => setWow(0)}
          className={` ${
            wow === 0 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          }  p-2 rounded-xl mr-2  min-w-[5.5rem] pl-4 pr-4 relative`}
        >
          Login
          <div
            className={`${
              wow === 0 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(1)}
          className={`${
            wow === 1 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl mr-2 min-w-[5.5rem] pl-4 pr-4 relative`}
        >
          Personal
          <div
            className={`${
              wow === 1 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(2)}
          className={`${
            wow === 2 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl mr-2 min-w-[5.5rem] pl-4 pr-4 relative`}
        >
          Location
          <div
            className={`${
              wow === 2 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(3)}
          className={`${
            wow === 3 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl mr-2 min-w-[5.5rem] pl-4 pr-4 relative sm:mt-0 mt-8`}
        >
          Employer
          <div
            className={`${
              wow === 3 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>
      </div>
      <Dis />
    </div>
  );
};

export default Eregistration;
