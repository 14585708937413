import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusp from "../../../../imgs/plusp.png";
import { Spin } from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
let data;
let dash;
let file;
const Witness = () => {
  const [test, setTest] = useState(false);
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoadingx, setIsLoadingx] = useState(false);
  const [prof1, setProf1] = useState("");
  const [prof2, setProf2] = useState("");
  const [Iprof1, setIProf1] = useState("");
  const [Iprof2, setIProf2] = useState("");
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [govId1, setGovId1] = useState("");
  const [govId2, setGovId2] = useState("");
  const [sig1, setSig1] = useState("");
  const [sig2, setSig2] = useState("");
  const [govImg1, setGovImg1] = useState("");
  const [govImg2, setGovImg2] = useState("");
  const token = useSelector((state) => state.token);
  const dataUser = useSelector((state) => state.dataUser);
  const penId = useSelector((state) => state.penId);
  const reset = useSelector((state) => state.reset);
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const [display2, setDisplay2] = useState("");
  const [display3, setDisplay3] = useState("");
  const [signature, setSignature] = useState("");
  const [govImg, setGovImg] = useState("");
  const [status, setStatus] = useState("");
  const [id1, setId1] = useState("");
  const [id2, setId2] = useState("");

  const profileData = useSelector((state) => state.profileData);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  useEffect(() => {
    console.log(profileData, "ded");
    if (profileData.witnesses.length != 0) {
      data = profileData.witnesses;
      if (data.length != 0) {
        setId1(data[0].id);
        setName1(data[0].name);
        setGovId1(data[0].govtID);
        setProf1(data[0].image);

        const body1 = [data[0].signature];
        setSignature(data[0].signature);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
            {
              fileNames: body1,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "llkll");
            setDisplay(data.data[0].url);
          })
          .catch((err) => {
            console.log(err);
          });
        const body2 = [data[0].govtIDFrontImg];
        setGovImg1(data[0].govtIDFrontImg);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
            {
              fileNames: body2,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "llkll");
            setDisplay1(data.data[0].url);
          })
          .catch((err) => {
            console.log(err);
          });
        if (data.length > 1) {
          setId2(data[1].id);
          setName2(data[1].name);
          setGovId2(data[1].govtID);
          setProf1(data[1].image);
          const body3 = [data[1].signature];
          setSig2(data[1].signature);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body3,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setDisplay2(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
          const body4 = [data[1].govtIDFrontImg];
          setGovImg2(data[1].govtIDFrontImg);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
              {
                fileNames: body4,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "llkll");
              setDisplay3(data.data[0].url);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        if (!test) {
          setTest(true);
        } else {
          setTest(false);
        }
      }
    }
  }, [profileData, reset]);

  const imageHandler = (setState, setUrl, load, check) => async (e) => {
    load(true);
    file = e.target.files[0];
    console.log(file);
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    if (check === "ww") {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=profile`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          console.log(data.data.url, "ww");
          setUrl(data.data.url);
          load(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          console.log(data, "ww");
          setUrl(data.data.url);
          load(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const updateHandler = () => {
    // /v1/witnesses?beneficiaryUserId=&programId=
    //
    setIsLoadingx(true);
    let wow1;
    let wow2;

    let basic1 = {
      name: name1,
      signature: signature,
      govtID: govId1,
      image: `${Iprof1}`,
      govtIDFrontImg: govImg1,
    };
    let basic2 = {
      name: name2,
      signature: sig2,
      govtID: govId2,
      image: `${Iprof2}`,
      govtIDFrontImg: govImg2,
    };

    let body1 = basic1;
    let body2 = basic2;

    if (dash === "/EDashboard/BeneficiaryAccount") {
      if (id1) {
        wow1 = `${process.env.REACT_APP_BASE_URL}/v1/witnesses/${id1}/update-by-staff`;
        body1 = {
          witness: basic1,
        };
      } else {
        body1 = {
          witnesses: [basic1],
        };
        wow1 = `${process.env.REACT_APP_BASE_URL}/v1/witnesses/add-by-staff?beneficiaryUserId=${profileData.beneficiaryUser.id}&programId=${penId}`;
      }
      if (id2) {
        wow2 = `${process.env.REACT_APP_BASE_URL}/v1/witnesses/${id2}/update-by-staff`;
        body2 = {
          witness: basic2,
        };
      } else {
        body2 = {
          witnesses: [basic2],
        };
        wow2 = `${process.env.REACT_APP_BASE_URL}/v1/witnesses/add-by-staff?beneficiaryUserId=${profileData.beneficiaryUser.id}&programId=${penId}`;
      }
    } else {
      if (id1) {
        wow1 = `${process.env.REACT_APP_BASE_URL}/v1/witnesses/${id1}`;
      } else {
        wow1 = `${process.env.REACT_APP_BASE_URL}/v1/witnesses?beneficiaryUserId=${profileData.beneficiaryUser.id}&programId=${penId}`;
      }
      if (id2) {
        wow2 = `${process.env.REACT_APP_BASE_URL}/v1/witnesses/${id2}`;
      } else {
        wow2 = `${process.env.REACT_APP_BASE_URL}/v1/witnesses?beneficiaryUserId=${profileData.beneficiaryUser.id}&programId=${penId}`;
      }
    }
    console.log(wow2, "jjjj");
    if (id1) {
      axios
        .put(wow1, body1, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          console.log(data, "wit updateed");
          dispatch({ type: "RESET" });
          setIsLoadingx(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingx(false);
        });
    } else {
      if (name1 || signature || govId1 || govImg1) {
        axios
          .post(wow1, body1, {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          })
          .then((data) => {
            console.log(data, "wit updateed");
            dispatch({ type: "RESET" });
            setIsLoadingx(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoadingx(false);
          });
      }
    }
    if (id2) {
      axios
        .put(wow2, body2, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          console.log(data, "wit updateed");
          dispatch({ type: "RESET" });
          setIsLoadingx(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingx(false);
        });
    } else {
      if (name2 || sig2 || govId2 || govImg2) {
        axios
          .post(wow2, body2, {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          })
          .then((data) => {
            console.log(data, "wit updateed");
            dispatch({ type: "RESET" });
            setIsLoadingx(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoadingx(false);
          });
      }
    }
  };
  return (
    <div>
      <div className="mt-4">
        {console.log(data, "wit")}
        <div>
          <h2 className="mb-4 text-center text-[1.5rem] font-bold">
            Witness 1
          </h2>
          <h2 className="mb-4 text-[1.1rem] font-semibold">Profile Image:</h2>
          <label
            htmlFor="special-inputg"
            className={`${
              isLoading2 ? "pointer-events-none" : ""
            } cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <img className="w-[1.2rem]" src={plusp} alt="" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Profile Image</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-inputg"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setProf1, setIProf1, setIsLoading2, "ww")}
            />
          </label>
          <div className={`relative mb-4 ${prof1 ? "" : "hidden"}`}>
            <img className="w-[25rem] h-[13rem]" src={prof1} alt="" />
            <div
              className={`absolute ${
                isLoading2 ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div>
          </div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>Name:</h2>
          <input
            type="text"
            name=""
            id=""
            placeholder="Name"
            onChange={(e) => {
              setName1(e.target.value);
            }}
            value={name1}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>Signature:</h2>
          <label
            htmlFor="special-input"
            className={`${isLoading1 || isLoading} cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <img className="w-[1.2rem]" src={plusp} alt="" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Signature</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay, setSignature, setIsLoading)}
            />
          </label>
          <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
            <img className="w-[25rem] h-[13rem]" src={display} alt="" />
            <div
              className={`absolute ${
                isLoading ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div>
            {/* <div
                className="absolute bottom-0 right-0 cursor-pointer bg-white"
                onClick={() => setDisplay("")}
              >
                <img className="w-[3rem]" src={trash} alt="" />
              </div> */}
          </div>
          <h2 className={`mb-4 text-[1.1rem] font-semibold `}>
            Government ID #:
          </h2>
          <input
            type="text"
            name=""
            id=""
            placeholder="Government ID #"
            onChange={(e) => {
              setGovId1(e.target.value);
            }}
            value={govId1}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
            Image Front:
          </h2>
          <label
            htmlFor="special-input4"
            className={`${isLoading1 || isLoading} cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <img className="w-[1.2rem]" src={plusp} alt="" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Front</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input4"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay1, setGovImg1, setIsLoading1)}
            />
          </label>
          <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
            <img className="w-[25rem] h-[13rem]" src={display1} alt="" />
            <div
              className={`absolute ${
                isLoading1 ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div>
          </div>
        </div>
        <div>
          <h2 className="mb-4 text-center text-[1.5rem] font-bold">
            Witness 2
          </h2>
          <h2 className="mb-4 text-[1.1rem] font-semibold">Profile Image:</h2>
          <label
            htmlFor="special-inputj"
            className={`${
              isLoading3 ? "pointer-events-none" : ""
            } cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <img className="w-[1.2rem]" src={plusp} alt="" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Profile Image</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-inputj"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setProf2, setIProf2, setIsLoading3, "ww")}
            />
          </label>
          <div className={`relative mb-4 ${prof2 ? "" : "hidden"}`}>
            <img className="w-[25rem] h-[13rem]" src={prof2} alt="" />
            <div
              className={`absolute ${
                isLoading3 ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div>
          </div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>Name:</h2>
          <input
            type="text"
            name=""
            id=""
            placeholder="Name"
            value={name2}
            onChange={(e) => {
              setName2(e.target.value);
            }}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>Signature:</h2>
          <label
            htmlFor="special-inputv"
            className={`${isLoading1 || isLoading} cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <img className="w-[1.2rem]" src={plusp} alt="" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Signature</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-inputv"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay2, setSig2, setIsLoading)}
            />
          </label>
          <div className={`relative mb-4 ${display2 ? "" : "hidden"}`}>
            <img className="w-[25rem] h-[13rem]" src={display2} alt="" />
            <div
              className={`absolute ${
                isLoading ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div>
            {/* <div
                className="absolute bottom-0 right-0 cursor-pointer bg-white"
                onClick={() => setDisplay("")}
              >
                <img className="w-[3rem]" src={trash} alt="" />
              </div> */}
          </div>
          <h2 className={`mb-4 text-[1.1rem] font-semibold `}>
            Government ID #:
          </h2>
          <input
            type="text"
            name=""
            id=""
            placeholder="Government ID #"
            onChange={(e) => {
              setGovId2(e.target.value);
            }}
            value={govId2}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
            Image Front:
          </h2>
          <label
            htmlFor="special-inputx"
            className={`${isLoading1 || isLoading} cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <img className="w-[1.2rem]" src={plusp} alt="" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Front</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-inputx"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay3, setGovImg2, setIsLoading1)}
            />
          </label>
          <div className={`relative mb-4 ${display3 ? "" : "hidden"}`}>
            <img className="w-[25rem] h-[13rem]" src={display3} alt="" />
            <div
              className={`absolute ${
                isLoading1 ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div>
            {/* <div
                className="absolute bottom-0 right-0 cursor-pointer bg-white"
                onClick={() => setDisplay("")}
              >
                <img className="w-[3rem]" src={trash} alt="" />
              </div> */}
          </div>
        </div>
        {/* $
          {dataUser.user.status === "pending" &&
          dash === "/Dashboard/PensionerAccount"
            ? "pointer-events-none"
            : "pointer-events-auto"} */}
        <button
          onClick={updateHandler}
          className={`relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          <Spin
            style={{ display: `${isLoadingx ? "" : "none"}` }}
            indicator={antIcon}
          />
          <span className="ml-3">Save</span>
          <div
            className={`w-[100%] h-[100%] ${
              isLoadingx ? "" : "hidden"
            } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
          ></div>
        </button>
      </div>
    </div>
  );
};

export default Witness;
