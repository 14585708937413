import { useSelector } from "react-redux";
import back5 from "../imgs/back5.png";
import Nav from "../components/Nav/nav";
import { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import pdf from "../imgs/pdf.png";
import { Dropdown } from "antd";
import bs1 from "../imgs/bs1.png";
import bs3 from "../imgs/bs3.png";
import add from "../imgs/addb.png";
import qwqw from "../imgs/qwqw.png";

const AllApplications = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const universalPenId = useSelector((state) => state.universalPenId);
  const [oldage, setOldAge] = useState([]);

  const [actualUrl, setActualUrl] = useState(false);
  const [test, setTest] = useState(false);
  const location = useLocation();
  const dash = location.pathname;
  const router = useNavigate();

  const items = [
    {
      label: "View ",
      key: "1",
      icon: <img style={{ width: "1.5rem" }} src={bs1} />,
    },
    {
      label: "Delete ",
      key: "4",
      icon: (
        <img style={{ width: "1.1rem", marginLeft: "0.28rem" }} src={bs3} />
      ),
    },
  ];
  useEffect(() => {
    if (dash === "/EDashboard/allApplications") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/applications/pensioner-recent?pensionerUserId=${universalPenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "ded");
          const wow = data.data.filter((item) => {
            return item.program.name === "oldage-pensioner";
          });
          setOldAge(wow);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/applications/my-recent`, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          console.log(data, "ded");
          const wow = data.data.filter((item) => {
            return item.program.name === "oldage-pensioner";
          });
          setOldAge(wow);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [oldage]);
  const onClick = ({ key }) => {
    if (key === "1") {
      const randomLinkElement = document.getElementById("randomLink");
      randomLinkElement.click();
    }
  };

  useEffect(() => {
    if (oldage.length != 0) {
      console.log(oldage, "qqqqqqqqqqqqqqqqq");
      if (oldage[0].applications.length != 0) {
        const body2 = [oldage[0].applications[0].applicationpdfs[0].url];
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
            {
              fileNames: body2,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "llkll");
            setActualUrl(data.data[0].url);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [oldage]);
  return (
    <div>
      <Nav />
      <div className="sm:p-10 sm:pl-[7rem] sm:pr-[7rem]">
        <div
          className={` relative sm:flex-row flex-col flex justify-between sm:mt-0 mt-4`}
        >
          <div
            onClick={() => {
              //   if (dash === "/Dashboard/listing") {
              //     router("/Dashboard");
              //   } else {
              //     router("/EDashboard/Pensioner_HistoryP");
              //   }
              if (dash === "/EDashboard/allApplications") {
                router("/EDashboard/profPage");
              } else {
                router("/Dashboard");
              }
            }}
            className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] sm:mt-3 left-[8%]"
          >
            <img className="w-[1.7rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold sm:text-[1.8rem] sm:ml-0 ml-[4rem] text-[1.2rem]">
            Applications
          </h2>
        </div>

        <div className="flex items-center mt-[4rem]">
          <label className="text-[#596F96] text-[1.2rem] font-semibold">
            Old-Age Pensioners
          </label>
          {console.log(oldage, "deded")}
          <div
            // className={` cursor-pointer ml-2 ${
            //   oldage[0].finalStatus === "rejected" ? "" : "hidden"
            // }`}
            onClick={() => {
              if (dash === "/EDashboard/allApplications") {
                router("/EDashboard/application");
              } else {
                router("/Dashboard/application");
              }
            }}
          >
            <img
              className="scale-[0.8] sm:ml-0 sm:mr-0 ml-5 mr-4"
              src={add}
              alt=""
            />
          </div>
        </div>
        {console.log(oldage, "ewewewe")}
        {oldage.length != 0 && (
          <div>
            {oldage[0].applications.length != 0 && (
              <div>
                <div className="flex justify-center items-center">
                  {oldage[0].applications[0].applicationpdfs.map((item) => {
                    return (
                      <ul className="w-[85%] flex mt-[1rem] justify-between items-center rounded-md bg-[#E8E8E8] hover:bg-[#cbcbcb] pl-[1rem] pr-[1rem] pt-3 pb-3 mb-3">
                        <div>
                          <img className="w-[4rem]" src={pdf} alt="" />
                        </div>
                        <a
                          className="hidden"
                          id="randomLink"
                          href={`${actualUrl}`}
                          target="_blank"
                        >
                          Random Link
                        </a>
                        {console.log(item.url, "defefedsasa", oldage)}
                        <div className="flex">
                          <h2 className="text-[#596F96] text-[0.88rem] font-semibold">
                            {oldage[0].applications[0].finalStatus}
                          </h2>
                          <li
                            className={`relative flex items-center ml-4 ${
                              dash === "/Dashboard/allApplications"
                                ? "hidden"
                                : ""
                            }`}
                          >
                            <Dropdown
                              menu={{
                                items,
                                onClick,
                              }}
                              trigger={["click"]}
                            >
                              <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                                <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                                <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                                <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                              </div>
                            </Dropdown>
                          </li>
                        </div>
                      </ul>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="fixed bottom-[8%] right-[3%] bg-[#25D366] w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
        <img className=" w-[3.5rem]" src={qwqw} alt="" />
      </div>
    </div>
  );
};

export default AllApplications;
