import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Checkbox } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import across from "../../../imgs/across.png";
import cross from "../../../imgs/cross.png";
import { useLocation } from "react-router-dom";

const Banking = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const namePerson = useSelector((state) => state.namePerson);
  const profileData = useSelector((state) => state.profileData);
  const location = useLocation();
  const dash = location.pathname;
  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [accountTitle, setAccountTitle] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [confirmAccountNo, setConfirmAccountNo] = useState("");
  const [swiftNo, setSwiftNo] = useState("");
  const [routingNo, setRoutingNo] = useState("");
  const [accountType, setAccountType] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [sigg, setSigg] = useState("");
  const [fullName, setFullName] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [test, setTest] = useState(false);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(true);
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [info, setInfo] = useState(false);
  const [val, setVal] = useState(true);
  const [allDis, setAllDis] = useState(false);

  const [isInternational, setIsInternational] = useState(false);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  useEffect(() => {
    if (dash === "/Dashboard/PensionerAccount") {
      if (dataUser.data.user.status === "pending") {
        setAllDis(true);
      }
    } else if (
      dash === "/EDashboard/PensionerAccount" &&
      profileData.user.isDisabled
    ) {
      setAllDis(true);
    }
  }, []);
  useEffect(() => {
    if (dash === "/EDashboard/PensionerAccount") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/bank-info/pensioner?pensionerUserId=${profileData.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "qqq");
          setInfo(true);
          setBankName(data.data.bankName);
          setBankAddress(data.data.bankAddress);
          setAccountTitle(data.data.accountTitle);
          setAccountNo(data.data.accountNo);
          setConfirmAccountNo(data.data.accountNo);
          if (data.data.swiftNo) {
            setSwiftNo(data.data.swiftNo);
          }
          setRoutingNo(data.data.routingNo);
          setAccountType(data.data.accountType);
          setHomeAddress(data.data.homeAddress);
          setIsInternational(data.data.isInternational);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/bank-info`, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          console.log(data, "qqq");
          setInfo(true);
          setBankName(data.data.bankName);
          setBankAddress(data.data.bankAddress);
          setAccountTitle(data.data.accountTitle);
          setAccountNo(data.data.accountNo);
          setConfirmAccountNo(data.data.accountNo);
          if (data.data.swiftNo) {
            setSwiftNo(data.data.swiftNo);
          }
          setRoutingNo(data.data.routingNo);
          setAccountType(data.data.accountType);
          setHomeAddress(data.data.homeAddress);
          setIsInternational(data.data.isInternational);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    let sett;
    if (profileData.pensionerInfo) {
      if (profileData.pensionerInfo.signature != null) {
        sett = [profileData.pensionerInfo.signature];
      } else if (profileData.pensionerInfo.leftIndex != null) {
        sett = [profileData.pensionerInfo.leftIndex];
      } else if (profileData.pensionerInfo.rightIndex != null) {
        sett = [profileData.pensionerInfo.rightIndex];
      } else if (profileData.pensionerInfo.leftThumb != null) {
        sett = [profileData.pensionerInfo.leftThumb];
      } else {
        sett = [profileData.pensionerInfo.rightThumb];
      }

      const body1 = [sett];
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url`,
          {
            fileNames: body1,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "llkll");
          setSigg(data.data[0].url);
          setFullName(
            `${profileData.person.firstName} ${profileData.person.lastName}`
          );
          setTitle(`${profileData.person.title}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [accountType, isInternational]);

  const valHandler = (e) => {
    setConfirmAccountNo(e.target.value);
    if (
      document.getElementById("password").value ==
      document.getElementById("validate").value
    ) {
      // document.getElementById("message").style.color = "green";
      document.getElementById("message1").style.color = "black";
      document.getElementById("message2").style.color = "black";
      document.getElementById("message1").innerHTML = "Bank Account No";
      document.getElementById("message2").innerHTML = "Confirm Bank Account No";
      setVal(true);
    } else {
      // document.getElementById("message").style.color = "red";
      document.getElementById("message1").style.color = "red";
      document.getElementById("message2").style.color = "red";
      document.getElementById("message1").innerHTML = "Bank Account No**";
      document.getElementById("message2").innerHTML =
        "Confirm Bank Account No**";
      setVal(false);
    }
  };
  const checkHandler = (e) => {
    setAccountType(e.target.value);
  };
  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const nextHandler = (e) => {
    e.preventDefault();
    if (!val) {
      setErr("Please confirm the account number");
      setShow(true);
    } else {
      console.log(one, two, three);
      if (one && two && three) {
        setIsLoading(true);
        let wow = true;
        let body = {
          bankName,
          bankAddress,
          accountTitle,
          accountNo,
          isInternational,
          routingNo,
          accountType,
          homeAddress,
        };
        if (isInternational) {
          body.swiftNo = swiftNo;
          if (swiftNo != "") {
            wow = true;
          } else {
            wow = false;
            setErr("Please enter the swift number");
            setShow(true);
          }
        }

        if (wow) {
          console.log(body, "de");
          if (dash === "/Dashboard/PensionerAccount") {
            axios
              .put(`${process.env.REACT_APP_BASE_URL}/v1/bank-info`, body, {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              })
              .then((data) => {
                console.log(data, "1212");
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                setErr(err.response.data.message);
                setShow(true);
                setIsLoading(false);
              });
          } else {
            axios
              .put(
                `${process.env.REACT_APP_BASE_URL}/v1/bank-info/update-by-staff?pensionerUserId=${profileData.user.id}`,
                { bankInfo: body },
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then((data) => {
                console.log(data, "ttt");
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                setErr(err.response.data.message);
                setShow(true);
                setIsLoading(false);
              });
          }
        }
      } else {
        setErr("Please select all the checkboxes");
        setShow(true);
      }
    }
  };
  return (
    <div>
      <form onSubmit={nextHandler} action="" className=" mt-10">
        <div className="flex justify-center items-center">
          <div className="w-[100%]">
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">Name of Bank</h2>
              <input
                type="text"
                name=""
                id=""
                placeholder="Name of Bank"
                onChange={handleChange(setBankName)}
                value={bankName}
                className={`${
                  allDis ? "pointer-events-none" : ""
                } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Address of Branch
              </h2>
              <textarea
                type="text"
                name=""
                id=""
                onChange={handleChange(setBankAddress)}
                value={bankAddress}
                placeholder="Address of Branch"
                className={`${
                  allDis ? "pointer-events-none" : ""
                } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Name(s) on Account
              </h2>
              <input
                type="text"
                name=""
                id=""
                onChange={handleChange(setAccountTitle)}
                value={accountTitle}
                placeholder="Name(s) on Account"
                className={`${
                  allDis ? "pointer-events-none" : ""
                } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
              <h2 id="message1" className="mb-4 text-[1.1rem] font-semibold">
                Bank Account No
              </h2>
              <input
                type="text"
                name=""
                id="password"
                onChange={handleChange(setAccountNo)}
                value={accountNo}
                placeholder="Bank Account No"
                className={` ${
                  allDis ? "pointer-events-none" : ""
                } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
              <h2 id="message2" className="mb-4 text-[1.1rem] font-semibold">
                Confirm Bank Account No
              </h2>
              <input
                type="text"
                name=""
                id="validate"
                value={confirmAccountNo}
                onChange={valHandler}
                placeholder="Confirm Bank Account No"
                className={`${
                  allDis ? "pointer-events-none" : ""
                } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
              {/* <div style={{ marginBottom: "1rem" }}>
                  <label htmlFor="validate">
                    <span id="message"></span>
                  </label>
                </div> */}
              <h2 className="mb-4 text-[1.1rem] font-semibold">Routing #</h2>
              <input
                type="text"
                name=""
                id=""
                onChange={handleChange(setRoutingNo)}
                value={routingNo}
                placeholder="Routing #"
                className={`${
                  allDis ? "pointer-events-none" : ""
                } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
            </div>
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Bank Account Type
              </h2>
              {console.log(accountType, "ewrdwds")}
              <div className={`${allDis ? "pointer-events-none" : ""}`}>
                <div className="mb-4 mt-2">
                  <input
                    type="radio"
                    id="saving"
                    name="fav_language"
                    value="Saving"
                    checked={accountType === "Saving" ? true : false}
                    onChange={checkHandler}
                  ></input>
                  <label for="saving" className="mr-8 ml-1 text-[0.9rem]">
                    Saving
                  </label>
                  <input
                    type="radio"
                    id="chequing"
                    name="fav_language"
                    onChange={checkHandler}
                    checked={accountType === "Chequing" ? true : false}
                    value="Chequing"
                  ></input>
                  {console.log(accountType, "deddqw")}
                  <label for="chequing" className=" ml-1 text-[0.9rem]">
                    Chequing
                  </label>
                </div>
              </div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">Home Address</h2>
              <textarea
                type="text"
                name=""
                id=""
                onChange={handleChange(setHomeAddress)}
                value={homeAddress}
                placeholder="Home Address"
                className={`${
                  allDis ? "pointer-events-none" : ""
                } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
              <h2
                className={`${
                  isInternational ? "" : "hidden"
                } mb-4 text-[1.1rem] font-semibold`}
              >
                Swift #
              </h2>
              <input
                type="text"
                name=""
                id=""
                onChange={handleChange(setSwiftNo)}
                value={swiftNo}
                placeholder="Swift #"
                style={{ textTransform: "uppercase" }}
                className={`${isInternational ? "" : "hidden"} ${
                  allDis ? "pointer-events-none" : ""
                } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              />
              <h2 className={` mb-4 text-[1.1rem] font-semibold`}>
                International Account
              </h2>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFour(true);
                    setIsInternational(true);
                  } else {
                    setFour(false);
                    setIsInternational(false);
                  }
                }}
                checked={isInternational}
                className={"checkk2"}
                style={{
                  marginLeft: "1rem",
                  pointerEvents: `${allDis ? "none" : "auto"}`,
                }}
              >
                True
              </Checkbox>

              <h2 className="mb-4 mt-6 text-[1.1rem] font-semibold">
                SURRENDER OF VOUCHER DETAILS
              </h2>
              <p className="text-[0.9rem]  mb-4">
                I {namePerson} of my ownn free will Surrender my voucher to
                MHSSS
              </p>
              <div className="ml-4">
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOne(true);
                    } else {
                      setOne(false);
                    }
                  }}
                  defaultChecked={one}
                  className={"checkk2"}
                  style={{ pointerEvents: `${allDis ? "none" : "auto"}` }}
                >
                  I agree to the statement above
                </Checkbox>
              </div>
              {namePerson && (
                <div>
                  <h2 className="mb-4 mt-6 text-[1.1rem] font-semibold">
                    SURRENDER OF BANK INFORMATION DETAILS
                  </h2>
                  <p className="text-[0.9rem] mb-4">
                    I {namePerson} of my ownn free will Surrender my voucher to
                    MHSSS
                  </p>
                  <div className="ml-4">
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          setTwo(true);
                        } else {
                          setTwo(false);
                        }
                      }}
                      defaultChecked={two}
                      className={"checkk2"}
                      style={{ pointerEvents: `${allDis ? "none" : "auto"}` }}
                    >
                      I agree to the statement above
                    </Checkbox>
                  </div>
                </div>
              )}
              <h2 className="mb-4 mt-6 text-[1.1rem] font-semibold">
                WAIVING MY RIGHTS TO BE PAID IN PERSON IN ACCORDANCE WITH THE
                OLD AGE PENSIONER ACT, CAP. 36:30
              </h2>
              <p className="text-[0.9rem] mb-4">
                I {namePerson} of my ownn free will Surrender my voucher to
                MHSSS
              </p>
              <div className="ml-4">
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setThree(true);
                    } else {
                      setThree(false);
                    }
                  }}
                  defaultChecked={three}
                  style={{ pointerEvents: `${allDis ? "none" : "auto"}` }}
                  className={"checkk2"}
                >
                  I agree to the statement above
                </Checkbox>
              </div>
              <div className="mt-4 mb-4">
                <img
                  onClick={() => {
                    setIShow(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={sigg}
                />
              </div>
              <input
                type="text"
                name=""
                id=""
                value={`${title} ${fullName}`}
                className="pointer-events-none lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
            </div>
          </div>
          <div
            className={`${
              iShow ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
          >
            <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
              <img
                className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                src={cross}
                onClick={() => setIShow(false)}
                alt=""
              />
              <img className="w-[45rem] h-[28rem]" src={sigg} alt="" />
            </div>
          </div>
        </div>
        <button
          //   onClick={updateHandler}
          type="submit"
          className={`${
            profileData.user.status === "pending" &&
            dash === "/Dashboard/PensionerAccount"
              ? "hidden"
              : ""
          } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          <Spin
            style={{ display: `${isLoading ? "" : "none"}` }}
            indicator={antIcon}
          />
          <span className="ml-3">Save</span>
          <div
            className={`w-[100%] h-[100%] ${
              isLoading ? "" : "hidden"
            } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
          ></div>
        </button>
      </form>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
export default Banking;
