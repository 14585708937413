const Saf = () => {
  return (
    <div>
      <div className="relative w-full sm:h-[100vh] h-[50vh] bg-[#ffe7e2] flex flex-col justify-center items-center">
        <h2 className="text-center sm:text-[3.8rem] text-[2.8rem] font-extrabold leading-[3rem] mb-[3.5rem]">
          <span className="text-[#F7542E]">Old Age&nbsp;</span>
          <span className="dancing">Pension</span>
          <br></br>
          <span className="text-[#F15B27] text-[1rem]">
            & Social Assistance Programmes
          </span>
        </h2>
      </div>
      <div className="w-full h-[35vh] flex items-center justify-center shadow-2xl">
        <p className="dancing text-center  text-[1.3rem] w-[25rem] pl-4 pr-4">
          Anyone that is 65 years of age and a citizen of Guyana is eligible for
          MHSSS-OAP benefits upon completing the application, submit documents
          and approved by program officer.
        </p>
      </div>
      <div className="w-full bg-[#F5F4F0]  pb-[5rem] flex flex-col items-center pl-4 pr-4 sm:pl-[25rem] sm:pr-[25rem]">
        <div className="text-center mt-[5rem]">
          <h2 className="mb-5 text-[#F7542E] font-bold text-[1.2rem]">
            Entitlements
          </h2>
          <p className=" text-[#9B9A9D] font-medium">
            Each pensioner is entitled to a free travel pass for the Government
            owned ferries. A pensioner who receives a water bill in his/her name
            is entitled to water rates assistance. Contact the Social Worker in
            your district or in the Ministry of Social Protection to get more
            information.
          </p>
        </div>
        <div className="mt-[5rem]">
          <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
            Pension Book Guidelines
          </h2>
          <p className=" text-[#9B9A9D] font-medium mt-5 mb-4">
            A Pension Book, consisting of payments for 12 months, is given to
            each eligible pensioner. This book allows a Pensioner to cash the
            vouchers in the book on a monthly basis.
          </p>
        </div>
        <div className="mt-[5rem]">
          <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
            Tips To Know
          </h2>
          <ul className="list-disc ml-10">
            <li className=" text-[#9B9A9D] font-medium mb-1">
              Keep the covers of the old/previous pension book. You need it
              along with the identification(ID) card in order to uplift the new
              book
            </li>
            <li className=" text-[#9B9A9D] font-medium mb-1">
              Do not allow three(3) months to elapse without uplifting payment
              for senior citizens pension form your district post office
            </li>
            <li className=" text-[#9B9A9D] font-medium mb-1">
              In the event of death, agents or relatives should return pension
              book to the distric social worker. No one else can use the pension
              book. It is the property of the government
            </li>
            <li className=" text-[#9B9A9D] font-medium mb-1">
              If the pension book gets lost or stolen, report this to the
              nearest police station quickly. Obtain a statement from the police
              and take it to your district social worker. A replacement book
              will be issued three months later after the date of report
            </li>
          </ul>
        </div>
        <div className="mt-[5rem]">
          <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
            Senior Citizen Pension Eligibility
          </h2>
          <ul className="list-disc ml-10">
            <li className=" text-[#9B9A9D] font-medium mb-1">
              You must be 65 years or older
            </li>
            <li className=" text-[#9B9A9D] font-medium mb-1">
              You must be a Guyanese Citizen
            </li>
            <li className=" text-[#9B9A9D] font-medium mb-1">
              You must have lived in Guyana for at least 10 years
            </li>
          </ul>
        </div>
        {/* <div className="mt-[5rem]">
            <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
              What you can get
            </h2>
            <p className=" text-[#9B9A9D] font-medium mb-[2rem]">
              If you are eligible for MHSSS-SAP, the amount of money you get will
              depend on your specific criteria. You could receive up to GYD7000 a
              month for basic needs and shelter if you are single.
            </p>
            <p className=" text-[#9B9A9D] font-medium mb-1">
              You may receive more than this for:
            </p>
            <ul className="list-disc ml-10">
              <li className=" text-[#9B9A9D] font-medium mb-1">
                benefits (for example, you might get additional money for
                transportation to medical appointments)
              </li>
              <li className=" text-[#9B9A9D] font-medium mb-1">
                other immediate family members (for example, if you have a spouse
                or child/children you will receive a larger amount)
              </li>
            </ul>
          </div> */}
      </div>
      {/* <div className="w-full  pb-[5rem] flex flex-col items-center  pl-[25rem] pr-[25rem]">
          <div className="mt-[5rem]">
            <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
              Step1 Application
            </h2>
            <p className=" text-[#9B9A9D] font-medium mb-[2rem]">
              Make sure all family members included in your application are with
              you when you fill out the form so they can provide their consent and
              confirm their information.
            </p>
            <p className=" text-[#9B9A9D] font-medium mb-1">
              Have your family’s identification, details, and documents ready,
              such as:
            </p>
            <ul className="list-disc ml-10">
              <li className=" text-[#9B9A9D] font-medium mb-1">
                Tax Identification Numbers (TIN)
              </li>
              <li className=" text-[#9B9A9D] font-medium mb-1">GID cards</li>
              <li className=" text-[#9B9A9D] font-medium mb-1">
                Birth certificates
              </li>
              <li className=" text-[#9B9A9D] font-medium mb-1">Passport</li>
            </ul>
          </div>
          <div className="mt-[5rem]">
            <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
              Step 2: MHSSS-SA reviews your application
            </h2>
            <p className=" text-[#9B9A9D] font-medium mb-1">
              A caseworker from your local MHSSS office will review your
              application and call you within ten business days of submitting your
              application to schedule an appointment. This does not include
              weekends and holidays.
            </p>
          </div>
          <div className="mt-[5rem]">
            <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
              Step 3: Meet with MHSSS to sign and verify your application
            </h2>
            <p className=" text-[#9B9A9D] font-medium mb-1">
              Meet with a caseworker for your verification appointment. The
              caseworker might ask you to provide more information, like copies of
              bills or other documents, so we can verify the information you
              included in your application. You will also be asked to sign your
              application package, including a consent form to allow MHSSS to
              access information about you from other government programs or
              organizations (for example, the Ministry of Transportation,
              Employment Insurance, NIS). This information will help to determine
              if you are qualify for assistance. If MHSSS determines that you are
              not financially eligible, you will have 30 days to request that
              MHSSS review your information again.
            </p>
          </div>
          <div className="mt-[5rem]">
            <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
              Step 4: Get approved and receive your payment
            </h2>
            <p className=" text-[#9B9A9D] font-medium mb-1">
              Once the caseworker receives all the information they need, they
              will tell you within ten business days if your application has been
              approved and how much you may receive. If your application is
              approved, your local MHSSS office will advise when you can expect
              your first payment.
            </p>
          </div>
        </div> */}
    </div>
  );
};

export default Saf;
