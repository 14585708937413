import { useDispatch } from "react-redux";
import Application from "../components/Application/application";
import Nav from "../components/Nav/nav";
import back5 from "../imgs/back5.png";
import qwqw from "../imgs/qwqw.png";
import { useLocation, useNavigate } from "react-router-dom";
const ApplicationPage = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  return (
    <div>
      <Nav />
      <div className="relative flex justify-start sm:pl-[20rem] sm:pr-[20rem] sm:ml-0 ml-4 items-center text-[1.8rem] font-bold mb-[5rem]">
        <div
          onClick={() => {
            dispatch({ type: "ASET", num: 0 });
            dispatch({ type: "answersSet", num: [] });
            if (dash === "/EDashboard/application") {
              router("/EDashboard/allApplications");
            } else {
              router("/Dashboard/allApplications");
            }
          }}
          className="cursor-pointer pt-[5rem] mr-5"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <h2 className="pt-[5rem]">Application</h2>
      </div>
      <div className=" sm:pl-[8rem] sm:pr-[8rem] mb-[5rem]">
        <Application />
      </div>
      <div className="fixed bottom-[8%] right-[3%] bg-[#25D366] w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
        <img className=" w-[3.5rem]" src={qwqw} alt="" />
      </div>
    </div>
  );
};

export default ApplicationPage;
