import { useDispatch, useSelector } from "react-redux";
import plusp from "../../../imgs/plusp.png";
import { useState } from "react";
import { Select } from "antd";
import { useEffect } from "react";
import axios from "axios";
let arr = [1];
let wow = [];
const FourthR = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [test, setTest] = useState(false);
  const [allLocations, setAllLocations] = useState("");
  const [address1, setAddress1] = useState(null);
  const [region1, setRegion1] = useState(null);
  const [regionName1, setRegionName1] = useState(null);
  const [town1, setTown1] = useState(null);
  const [ward1, setWard1] = useState(null);

  const [address, setAddress] = useState("");
  const [regionNo, setRegionNo] = useState("");
  const [regionName, setRegionName] = useState("");
  const [lot, setLot] = useState("");
  const [city, setCity] = useState(null);
  const [ward, setWard] = useState(null);
  const [allRegions, setAllRegions] = useState("");
  const [allCities, setAllCities] = useState("");
  const [allWards, setAllWards] = useState("");
  const [initial, setInitial] = useState(true);
  const [initial1, setInitial1] = useState(true);
  const [initial2, setInitial2] = useState(true);

  const nextHandler = (e) => {
    // dispatch({ type: "RINCREMENT" });
    // onClick={() => dispatch({ type: "IINCREMENT" })}
    e.preventDefault();
    let data = [
      {
        isCurrent: true,
        regionNo: region1,
        regionName: regionName1,
        address: address1,
        type: "office",
        city: town1,
        ward: ward1,
      },
      {
        isCurrent: true,
        regionNo,
        regionName,
        address,
        type: "home",
        lot,
        city,
        ward,
      },
    ];
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/addresses`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        console.log(data, "wwow");
        dispatch({ type: "IINCREMENT" });
      })
      .catch((err) => {
        console.log(err, "w");
        // setErr(err.response.data.message);
        // setShow1(true);
      });
  };
  const addAddressHandler = (check) => {
    if (check === "add") {
      arr.push(1);
    } else {
      arr.pop(1);
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/employee-locations`)
      .then((data) => {
        console.log(data.data, "w");
        const wow = data.data.map((region) => {
          console.log(region, region.name);
          return {
            label: `${region.name}`,
            value: `${region.id}`,
          };
        });
        setAllLocations(wow);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const locationHandler = (e) => {
    console.log(e);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/employee-location-address?empLocationId=${e}`
      )
      .then((data) => {
        console.log(data, "all");
        setAddress1(data.data.address);
        setRegion1(data.data.regionNo);
        setRegionName1(data.data.regionName);
        setTown1(data.data.city);
        setWard1(data.data.ward);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/gy-addresses`)
      .then((data) => {
        console.log(data.data.data, "get");
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllRegions(wow);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}`
        )
        .then((data) => {
          console.log(data.data.data, "get2");
          setCity(null);
          setAllCities("");
          setAllWards("");
          setRegionName("");
          setWard(null);
          setRegionName(data.data.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
      if (!test) {
        setTest(true);
      } else {
        setTest(false);
      }
    }
  }, [regionNo]);
  useEffect(() => {
    if (initial1) {
      setInitial1(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}`
        )
        .then((data) => {
          console.log(data.data.data, "get3");
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllCities(wow);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [regionName]);
  useEffect(() => {
    if (initial2) {
      setInitial2(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}&city=${city}`
        )
        .then((data) => {
          console.log(data.data.data, "get3");
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllWards(wow);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [city]);

  const handleChange = (setChange, check) => (event) => {
    if (check === "tt" || check === "tn") {
      console.log(event);
      setChange(event);
    } else {
      console.log(event.target.value);
      setChange(event.target.value);
    }
  };

  const addHadler = (e) => {
    setAddress(e.target.value);
  };
  const lotHadler = (e) => {
    setLot(e.target.value);
  };
  return (
    <div className="flex justify-center">
      <form
        action=""
        className="sm:w-[50%] w-[85%] mt-10"
        onSubmit={nextHandler}
      >
        <h2 className="mb-4 text-[1.1rem] font-bold">Work Address:</h2>
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Location
        </h2>
        <Select
          placeholder="Location"
          optionFilterProp="children"
          className="wow"
          style={{ marginBottom: "1.2rem" }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          onChange={locationHandler}
          options={allLocations}
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Address
        </h2>
        <input
          type="text"
          name=""
          id=""
          placeholder="Address"
          value={address1}
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Region
        </h2>
        <input
          type="text"
          name=""
          id=""
          placeholder="Region"
          value={region1}
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Region Name
        </h2>
        <input
          type="text"
          name=""
          id=""
          placeholder="Region Name"
          value={regionName1}
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Town / City
        </h2>
        <input
          type="text"
          name=""
          id=""
          placeholder="Town / City"
          value={town1}
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Ward / Village
        </h2>
        <input
          type="text"
          name=""
          id=""
          placeholder="Ward / Village"
          value={ward1}
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        {/* <input
          type="text"
          name=""
          id=""
          placeholder="Location"
          className="lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-8"
        /> */}
        {arr.map((add, index) => {
          return (
            <div>
              <div
                className={`flex justify-end cursor-pointer mt-[3rem] ${
                  index === 0 ? "hidden" : ""
                }`}
                onClick={() => addAddressHandler("remove")}
              >
                <p className="text-[red] underline text-[0.94rem]">
                  Remove Address
                </p>
              </div>
              <h2 className="mb-4 text-[1.1rem] font-bold">
                Home Address {index + 1}:
              </h2>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Lot #
              </h2>
              <input
                type="text"
                name=""
                id=""
                placeholder="Lot #"
                value={lot}
                onChange={lotHadler}
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Address
              </h2>
              <input
                type="text"
                name=""
                id=""
                value={address}
                placeholder="Address"
                onChange={addHadler}
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Region No
              </h2>
              <Select
                placeholder="Region No"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.2rem" }}
                onChange={handleChange(setRegionNo, "tn")}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                key={index}
                options={allRegions}
              />
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Region Name
              </h2>
              <input
                type="text"
                name=""
                id=""
                onChange={handleChange(setRegionName, "tt")}
                value={regionName}
                placeholder="Region Name"
                key={index}
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Town/City
              </h2>
              <Select
                placeholder="Town/City"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.2rem" }}
                onChange={handleChange(setCity, "tt")}
                // value={city}
                key={index}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                options={allCities}
              />
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Ward/Village
              </h2>
              <Select
                placeholder="Ward/Village"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.2rem" }}
                // value={ward}
                showSearch
                key={index}
                onChange={handleChange(setWard, "tt")}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                options={allWards}
              />
            </div>
          );
        })}
        <div
          className="flex justify-center items-center mt-4"
          onClick={() => addAddressHandler("add")}
        >
          <div className="flex justify-center items-center cursor-pointer">
            <div>
              <img className="w-[1.2rem] h-[1.2rem] mr-2" src={plusp} alt="" />
            </div>
            <p className="text-[#18B871]">Add Address</p>
          </div>
        </div>

        <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center sm:w-[100%]">
          {/* <button
            onClick={() => dispatch({ type: "IDECREMENT" })}
            className={`lg:text-[1.1rem] bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button> */}
          <button
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
export default FourthR;
