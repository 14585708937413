import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, DatePicker, Dropdown } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useState } from "react";

const General = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  return (
    <div>
      <h2 className="mb-8 text-[1.5rem] font-bold mt-8">General Info</h2>
      <h2
        className={`mb-4 ${
          dash === "/Dashboard/application" ? "text-[1.1rem]" : "text-[1.1rem] "
        } font-semibold`}
      >
        Are you in receipt of Public Assistance?
      </h2>
      <div className="flex flex-col ml-5 mb-5">
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          Yes
        </Checkbox>
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          No
        </Checkbox>
      </div>
      <h2
        className={`mb-4 ${
          dash === "/Dashboard/application" ? "text-[1.1rem]" : "text-[1.1rem] "
        } font-semibold`}
      >
        Are you the owner of the property?
      </h2>
      <div className="flex flex-col ml-5 mb-5">
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          Yes
        </Checkbox>
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          No
        </Checkbox>
      </div>
      <h2
        className={`mb-4 ${
          dash === "/Dashboard/application" ? "text-[1.1rem]" : "text-[1.1rem] "
        } font-semibold`}
      >
        Do you pay water bills?
      </h2>
      <div className="flex flex-col ml-5 mb-5">
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          Yes
        </Checkbox>
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          No
        </Checkbox>
      </div>
      <h2
        className={`mb-4 ${
          dash === "/Dashboard/application" ? "text-[1.1rem]" : "text-[1.1rem] "
        } font-semibold`}
      >
        Do you pay electricity bills?
      </h2>
      <div className="flex flex-col ml-5 mb-5">
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          Yes
        </Checkbox>
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          No
        </Checkbox>
      </div>
      <h2
        className={`mb-4 ${
          dash === "/Dashboard/application" ? "text-[1.1rem]" : "text-[1.1rem] "
        } font-semibold`}
      >
        Are you living alone?
      </h2>
      <div className="flex flex-col ml-5 mb-5">
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          Yes
        </Checkbox>
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          No
        </Checkbox>
      </div>
      <h2
        className={`mb-4 ${
          dash === "/Dashboard/application" ? "text-[1.1rem]" : "text-[1.1rem] "
        } font-semibold`}
      >
        Are you a shut in?
      </h2>
      <div className="flex flex-col ml-5 mb-5">
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          Yes
        </Checkbox>
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          No
        </Checkbox>
      </div>
    </div>
  );
};

export default General;
