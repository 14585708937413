import { useDispatch, useSelector } from "react-redux";
import plusp from "../../../imgs/plusp.png";
import { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import trash from "../../../imgs/trash.png";
import tick from "../../../imgs/across.png";
import { Space, Spin } from "antd";
import cross from "../../../imgs/cross.png";

let file;
let file1;
let file2;
let file3;
let file4;
const FifthR = () => {
  const location = useLocation();
  const dash = location.pathname;
  const benId = useSelector((state) => state.benId);
  const penId = useSelector((state) => state.penId);
  const dataUser = useSelector((state) => state.dataUser);
  const popData = useSelector((state) => state.popData);
  const icurrent = useSelector((state) => state.icurrent);
  const dispatch = useDispatch();
  const wowt = useSelector((state) => state.wowt);
  const token = useSelector((state) => state.token);
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const [display2, setDisplay2] = useState("");
  const [display3, setDisplay3] = useState("");
  const [display4, setDisplay4] = useState("");
  const [signature, setSignature] = useState("");
  const [thumbLeft, setThumbLeft] = useState("");
  const [thumbRight, setThumbRight] = useState("");
  const [indexLeft, setIndexLeft] = useState("");
  const [indexRight, setIndexRight] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [verify, setVerify] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [iShow1, setIShow1] = useState(false);
  const [iShow2, setIShow2] = useState(false);
  const [iShow3, setIShow3] = useState(false);
  const [iShow4, setIShow4] = useState(false);
  const [value, setValue] = useState("");
  const [word, setWord] = useState("");
  const checkHandler = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const nextHandler = (e) => {
    e.preventDefault();
    if (
      signature === "" &&
      thumbLeft === "" &&
      thumbRight === "" &&
      indexLeft === "" &&
      indexRight === ""
    ) {
      console.log(signature, "kk");
      setVerify(true);
    } else {
      let body = {};
      if (signature) {
        body.signature = signature;
      }
      if (thumbLeft) {
        body.leftThumb = thumbLeft;
      }
      if (thumbRight) {
        body.rightThumb = thumbRight;
      }
      if (indexLeft) {
        body.leftIndex = indexLeft;
      }
      if (indexRight) {
        body.rightIndex = indexRight;
      }
      if (icurrent <= 5) {
        if (dash === "/EDashboard/addPensioner") {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info/add-by-staff?pensionerUserId=${popData.id}`,
              { pensionerInfo: body },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "wwo");
              dispatch({ type: "IINCREMENT" });
              dispatch({ type: "POP" });
              dispatch({ type: "data3Set", num: data });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/v1/pensioner-info`, body, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((data) => {
              console.log(data, "wwo");
              dispatch({ type: "IINCREMENT" });
              dispatch({ type: "POP" });
              dispatch({ type: "data3Set", num: data });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        if (value != "") {
          if (value === "HTML") {
            body.amount = word;
          } else if (value === "CSS") {
            body.percentage = word;
          }
        }
        if (dash === "/EDashboard/BeneficiaryQuestions") {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/add-by-staff?beneficiaryUserId=${benId}&programId=${penId}`,
              { beneficiaryInfo: body },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "change");
              dispatch({ type: "SET", num: 1 });
              dispatch({ type: "BISET", num: 0 });
              dispatch({ type: "POP" });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          body.programId = penId;
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info?beneficiaryUserId=${benId}`,
              body,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "change");
              if (dash === "/Dashboard/BeneficiaryQuestions") {
                dispatch({ type: "SET", num: 1 });
                dispatch({ type: "BISET", num: 0 });
                console.log("called");
              } else {
                dispatch({ type: "IINCREMENT" });
              }
              dispatch({ type: "POP" });
            })
            .catch((err) => {
              console.log(err);
            });
        }
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info?beneficiaryUserId=/${benId}`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((data) => {
            console.log(data, "change");
            if (dash === "/Dashboard/BeneficiaryQuestions") {
              dispatch({ type: "SET", num: 2 });
              dispatch({ type: "BISET", num: 0 });
              console.log("called");
            } else {
              dispatch({ type: "IINCREMENT" });
            }
            dispatch({ type: "POP" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const changeHandler = (e) => {
    setWord(e.target.value);
  };
  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];

    if (file === undefined) {
      setIsLoading(false);
      setIsLoading1(false);
      setIsLoading2(false);
      setIsLoading3(false);
      setIsLoading4(false);
    } else {
      const base641 = await convertToBase64(file);
      setState(base641);
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          console.log(data, "ww");
          setUrl(data.data.url);
          load(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  return (
    <div>
      <form action="" className="mt-4" onSubmit={nextHandler}>
        <h2
          className={`${
            dash === "/EDashboard/addPensioner"
              ? "text-[1.2rem]"
              : "text-[0.9rem]"
          } text-center mb-6 text-[#2C5281] `}
        >
          Please upload at least one, Signature or one Fingerprint
        </h2>
        <div className="flex justify-center items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div>
              <div
                className={` flex flex-col ${icurrent <= 6 ? "hidden" : ""}`}
              >
                <label
                  htmlFor="input2"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Payment Amount
                </label>
                <div className="mb-4 mt-2">
                  <input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="HTML"
                    onChange={checkHandler}
                  ></input>
                  <label for="html" className="mr-8 ml-1 text-[0.9rem]">
                    Amount
                  </label>
                  <input
                    type="radio"
                    id="css"
                    name="fav_language"
                    value="CSS"
                    onChange={checkHandler}
                  ></input>
                  <label for="css" className=" ml-1 text-[0.9rem]">
                    Percentage
                  </label>
                </div>
                <input
                  className={`${
                    value === "" ? "hidden" : ""
                  } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                  type="text"
                  name="input2"
                  onChange={changeHandler}
                  placeholder={value === "HTML" ? "$" : "% Percentage"}
                  id=""
                />
              </div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Signature
              </h2>
              <label
                htmlFor="special-input"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Signature</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(setDisplay, setSignature, setIsLoading)}
                />
              </label>
              <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <div
                  className="absolute bottom-0 right-0 cursor-pointer bg-white"
                  onClick={() => {
                    setDisplay("");
                    setSignature("");
                    setIsLoading(false);
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    setIsLoading4(false);
                    const wow = document.getElementById("special-input");
                    wow.value = "";
                  }}
                >
                  <img className="w-[3rem]" src={trash} alt="" />
                </div>
              </div>
              <div
                className={`${
                  iShow ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display} alt="" />
                </div>
              </div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Thumb Print Left
              </h2>
              <label
                htmlFor="special-input1"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Thumb Print Left</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input1"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay1,
                    setThumbLeft,
                    setIsLoading1
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow1(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display1}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading1 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <div
                  className="absolute bottom-0 right-0 cursor-pointer bg-white"
                  onClick={() => {
                    setDisplay1("");
                    setThumbLeft("");
                    setIsLoading(false);
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    setIsLoading4(false);
                    const wow = document.getElementById("special-input1");
                    wow.value = "";
                  }}
                >
                  <img className="w-[3rem]" src={trash} alt="" />
                </div>
              </div>
              <div
                className={`${
                  iShow1 ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow1(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display1} alt="" />
                </div>
              </div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Thumb Print Right
              </h2>
              <label
                htmlFor="special-input2"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Thumb Print Right</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input2"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay2,
                    setThumbRight,
                    setIsLoading2
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display2 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow2(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display2}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading2 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <div
                  className="absolute bottom-0 right-0 cursor-pointer bg-white"
                  onClick={() => {
                    setDisplay2("");
                    setThumbRight("");
                    setIsLoading(false);
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    setIsLoading4(false);
                    const wow = document.getElementById("special-input2");
                    wow.value = "";
                  }}
                >
                  <img className="w-[3rem]" src={trash} alt="" />
                </div>
              </div>
            </div>
            <div
              className={`${
                iShow2 ? "" : "hidden"
              } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
            >
              <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                <img
                  className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                  src={cross}
                  onClick={() => setIShow2(false)}
                  alt=""
                />
                <img className="w-[45rem] h-[28rem]" src={display2} alt="" />
              </div>
            </div>
            <div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Index Print Left
              </h2>
              <label
                htmlFor="special-input3"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Index Print Left</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input3"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay3,
                    setIndexLeft,
                    setIsLoading3
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display3 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow3(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display3}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading3 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <div
                  className="absolute bottom-0 right-0 cursor-pointer bg-white"
                  onClick={() => {
                    setDisplay3("");
                    setIndexLeft("");
                    setIsLoading(false);
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    setIsLoading4(false);
                    const wow = document.getElementById("special-input3");
                    wow.value = "";
                  }}
                >
                  <img className="w-[3rem]" src={trash} alt="" />
                </div>
              </div>
              <div
                className={`${
                  iShow3 ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow3(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display3} alt="" />
                </div>
              </div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Index Print Right
              </h2>
              <label
                htmlFor="special-input4"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <img className="w-[1.2rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Index Print Right</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input4"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay4,
                    setIndexRight,
                    setIsLoading4
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display4 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow4(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display4}
                  alt=""
                />
                <div
                  className={`absolute ${
                    isLoading4 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div>
                <div
                  className="absolute bottom-0 right-0 cursor-pointer bg-white"
                  onClick={() => {
                    setDisplay4("");
                    setIndexRight("");
                    setIsLoading(false);
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    setIsLoading4(false);
                    const wow = document.getElementById("special-input4");
                    wow.value = "";
                  }}
                >
                  <img className="w-[3rem]" src={trash} alt="" />
                </div>
              </div>
              <div
                className={`${
                  iShow4 ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow4(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display4} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center sm:w-[85%]`}
        >
          {/* <button
            type="button"
            onClick={() => dispatch({ type: "IDECREMENT" })}
            className={`lg:text-[1.1rem] bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button> */}
          <button
            type="submit"
            // onClick={}
            className={`${
              isLoading1 || isLoading || isLoading2 || isLoading3 || isLoading4
                ? "pointer-events-none"
                : ""
            } lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Next
          </button>
        </div>
      </form>
      <div
        className={`${
          verify ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setVerify(false)}
          >
            <img src={tick} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">
            Please enter at least one, Signature or one fingerprint
          </p>

          <button
            type="submit"
            onClick={() => setVerify(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
export default FifthR;
