import incomp from "../../imgs/incomp.png";
import incompa from "../../imgs/incompa.png";
import incompb from "../../imgs/incompb.png";
import cross from "../../imgs/cross.png";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Popup1 = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const [word, setWord] = useState("");
  const router = useNavigate();
  const dash = location.pathname;
  console.log(profileData, "ww", dataUser);

  useEffect(() => {
    if (dataUser.data.user.status === "rejected") {
      setWord(
        "Your Account is rejected due to mismatch in your address details. Kindly provide your valid details and submit your profile again."
      );
    } else if (dataUser.data.user.status === "pending") {
      if (dash === "/Dashboard/listing") {
        setWord(
          "Click the confirm button below to change the default Beneficiary to the one you seleceted"
        );
      } else {
        setWord(
          "Your Account is in Pending mode. You will be allowed access to all funtionality once the account is approved"
        );
      }
    }
  }, []);
  return (
    <div className="fixed top-0 w-full h-full  bg-slate-500/50 flex justify-center items-center">
      <div
        className={`relative ${
          dash === "/Dashboard/listing"
            ? "h-[13.5rem] lg:w-[32%]"
            : "h-[24.5rem] lg:w-[40%]"
        }  bg-white w-[90%] rounded-2xl flex flex-col items-center p-4 text-center`}
      >
        <img
          className={`w-[12rem] h-[10rem] ${
            dash === "/Dashboard/listing" ? "hidden" : ""
          }`}
          src={incompa}
          alt=""
        />
        <div className="">
          <h2 className="sm:text-[1.4rem] text-[1.1rem] font-bold mt-3">
            {" "}
            {dash === "/Dashboard/listing"
              ? "Confirm Change?"
              : `Welcome ${dataUser.data.user.person.firstName} ${dataUser.data.user.person.lastName}!`}
          </h2>
          <p className="sm:text-[1rem] text-[0.9rem] font-medium sm:mt-5 mt-2 pr-2 pl-2">
            {word}
          </p>
          <div className="flex justify-center">
            <button
              onClick={() => dispatch({ type: "SIGN_IN" })}
              className={`${
                dataUser.data.user.status === "rejected" ||
                dataUser.data.user.status === "pending"
                  ? "hidden"
                  : ""
              } bg-[#F7542E] text-white rounded-xl pt-2 pb-2 pl-5 pr-5 font-normal mt-3`}
            >
              {dash === "/Dashboard/listing" ? "Cancel" : "Skip Anyway"}
            </button>

            <button
              onClick={() => {
                if (dataUser.data.user.status === "rejected") {
                  router("/Dashboard/PensionerAccount");
                  dispatch({ type: "SIGN_IN" });
                  dispatch({
                    type: "universalPenSet",
                    num: dataUser.data.user.id,
                  });
                } else {
                  dispatch({ type: "SIGN_IN" });
                }
              }}
              className="ml-3 bg-[#57D38C] text-white rounded-xl pt-2 pb-2 sm:pl-5 sm:pr-5 pl-2 pr-2 font-normal mt-3"
            >
              {dash === "/Dashboard/listing" ? "Confirm" : "Proceed"}
            </button>
          </div>
        </div>
        <img
          src={cross}
          onClick={() => dispatch({ type: "SIGN_IN" })}
          className="absolute scale-75 top-3 right-3 cursor-pointer"
          alt=""
        />
      </div>
    </div>
  );
};

export default Popup1;
