import { Select, DatePicker } from "antd";
import LoginCredentials from "./tabsR/loginCredentials";
import PersonalInfo from "./tabsR/personalInfo";
import Address from "./tabsR/address";
import Identification1 from "./tabsR/identification";
import Witness from "./tabsR/witness";

import PersonalDetails1 from "../../PAccount/parts/tabsR/personalDetails";
import Location1 from "../../PAccount/parts/tabsR/location";
import Identification2 from "../../PAccount/parts/tabsR/identification";

import p1 from "../../../imgs/p1.png";
import p2 from "../../../imgs/p2.png";
import p3 from "../../../imgs/p3.png";
import p4 from "../../../imgs/p4.png";
import { useState } from "react";

const Bregistration = () => {
  const [wow, setWow] = useState(0);
  const Show = [
    LoginCredentials,
    PersonalDetails1,
    Location1,
    Identification2,
    Witness,
  ];
  const Dis = Show[wow];
  // className = "absolute left-[-18%] top-[8%]";
  return (
    <div className="">
      <div className="flex mb-8 flex-wrap">
        <button
          onClick={() => setWow(0)}
          className={` ${
            wow === 0 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          }  p-2 rounded-xl mr-2  min-w-[5.5rem] pl-4 pr-4 relative`}
        >
          Login
          <div
            className={`${
              wow === 0 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(1)}
          className={`${
            wow === 1 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl mr-2 min-w-[5.5rem] pl-4 pr-4 relative`}
        >
          Personal
          <div
            className={`${
              wow === 1 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(2)}
          className={`${
            wow === 2 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl mr-2 min-w-[5.5rem] pl-4 pr-4 relative`}
        >
          Address
          <div
            className={`${
              wow === 2 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(3)}
          className={`${
            wow === 3 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl mr-2 min-w-[5.5rem] pl-4 pr-4 relative sm:mt-0 mt-8`}
        >
          Identity
          <div
            className={`${
              wow === 3 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(4)}
          className={`${
            wow === 4 ? "bg-[#18B871] text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl  mr-2 min-w-[5.5rem] pl-4 pr-4 relative sm:mt-0 mt-8`}
        >
          Witness
          <div
            className={`${
              wow === 4 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>
      </div>
      <Dis />
    </div>
  );
};

export default Bregistration;
