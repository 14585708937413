import { Checkbox, Select } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import plusp from "../../../imgs/plusp.png";
import trash from "../../../imgs/trash.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DatePicker } from "antd";
import { Spin } from "antd";
import axios from "axios";
import cross from "../../../imgs/cross.png";

let file;
let firstQ = "";
let secondQ = "";
let thirdQ = "";
let fourthQ = "";

let question1 = null;
let question2 = null;
let question2sub1 = null;
let question2sub2 = null;
let question3 = null;
let question3sub1 = null;
let question3sub2 = null;
let question4 = null;
let question4sub1 = null;
const ThirdA = () => {
  const [display, setDisplay] = useState("");
  const dispatch = useDispatch();
  const [display1, setDisplay1] = useState("");
  const [iShow, setIShow] = useState(false);
  const [iShow1, setIShow1] = useState(false);
  const appQuestions = useSelector((state) => state.appQuestions);
  const dataUser = useSelector((state) => state.dataUser);
  const token = useSelector((state) => state.token);
  const applicationAnswers = useSelector((state) => state.applicationAnswers);
  const [isLoading, setIsLoading] = useState(false);
  const [checkBuffer, setCheckBuffer] = useState("");
  const [check1Buffer, setCheck1Buffer] = useState("");
  const [check2Buffer, setCheck2Buffer] = useState("");
  const [check3Buffer, setCheck3Buffer] = useState("");
  const Questionx = useSelector((state) => state.Questionx);
  const Questiony = useSelector((state) => state.Questiony);
  const Question5 = useSelector((state) => state.Question5);
  const Question6 = useSelector((state) => state.Question6);

  const nextHandler = () => {
    if (question1 != null) {
      Question5.push(question1);
    }
    if (question2 != null) {
      Question6.push(question2);
    }
    if (question2sub1 != null) {
      Question6.push(question2sub1);
      localStorage.setItem("waterBill", question2sub1.answers[0]);
    }
    if (question2sub2 != null) {
      Question6.push(question2sub2);
    }
    if (question3 != null) {
      Questionx.push(question3);
    }
    if (question3sub1 != null) {
      Questionx.push(question3sub1);
      localStorage.setItem("electricityBill", question3sub1.answers[0]);
    }
    if (question3sub2 != null) {
      Questionx.push(question3sub2);
    }
    if (question4 != null) {
      Questiony.push(question4);
    }
    if (question4sub1 != null) {
      Questiony.push(question4sub1);
    }
    if (Question5.length != 0) {
      Question5.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    if (Question6.length != 0) {
      Question6.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    console.log(Questionx);
    console.log(Questiony);
    console.log(Question5);
    console.log(Question6);
    if (Questionx.length != 0) {
      Questionx.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    if (Questiony.length != 0) {
      Questiony.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    console.log(question1);
    console.log(question2);
    console.log(question3);
    console.log(question4);
    console.log(question2sub1);
    console.log(question2sub2);
    console.log(question3sub1);
    console.log(question3sub2);
    console.log("22222222222222222222222222222222");
    dispatch({ type: "AINCREMENT" });
  };
  // useEffect(() => {
  //   console.log(applicationAnswers);
  //   return () => {
  //     // applicationAnswers.push(question1);
  //     // applicationAnswers.push(question2);
  //     // applicationAnswers.push(question3);
  //     // applicationAnswers.push(question4);
  //     // applicationAnswers.push(question2sub1);
  //     // applicationAnswers.push(question2sub2);
  //     // applicationAnswers.push(question3sub1);
  //     // applicationAnswers.push(question3sub2);
  //     // applicationAnswers.push(question4sub1);

  //     // question1 = {};
  //     // question2 = {};
  //     // question3 = {};
  //     // question4 = {};
  //     // question2sub1 = {};
  //     // question2sub2 = {};
  //     // question3sub1 = {};
  //     // question3sub2 = {};
  //     // question4sub1 = {};
  //   };
  // }, []);

  const location = useLocation();
  const dash = location.pathname;

  const [test, setTest] = useState(false);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [firstQ, secondQ, thirdQ, fourthQ]);

  const change2Handler = (item, index) => {
    setCheck1Buffer(item);
    question1 = {
      appquestionId: firstQ[0].id,
      type: firstQ[0].type,
    };

    question1.answers = [item];
  };
  const changeHandler = (item, index) => {
    setCheckBuffer(item);
    question2 = {
      appquestionId: secondQ[0].id,
      type: secondQ[0].type,
    };
    question2.answers = [item];
  };
  const change3Handler = (item, index) => {
    setCheck2Buffer(item);

    question3 = {
      appquestionId: thirdQ[0].id,
      type: secondQ[0].type,
    };
    question3.answers = [item];
  };
  const change4Handler = (item, index) => {
    setCheck3Buffer(item);
    question4 = {
      appquestionId: fourthQ[0].id,
      type: secondQ[0].type,
      answers: [item],
    };
  };

  const imageHandler = (setState, id, type, question) => async (e) => {
    console.log(id, type, "wwew");
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);

    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    console.log(file, "see");
    setIsLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        // setUrl(data.data.url);
        console.log(question, "11");
        if (question === "Do you pay water bills?") {
          question2sub2 = {
            appquestionId: id,
            type: "img-url",
            answers: [data.data.url],
          };
        } else if (question === "Do you pay electricity bills?") {
          question3sub2 = {
            appquestionId: id,
            type: "img-url",
            answers: [data.data.url],
          };
        }
        setIsLoading(false);
        console.log(question2sub2, "ll", question3sub2);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  useEffect(() => {
    if (appQuestions) {
      firstQ = appQuestions.filter((item) => {
        return item.order === 5;
      });
      secondQ = appQuestions.filter((item) => {
        return item.order === 6;
      });
      thirdQ = appQuestions.filter((item) => {
        return item.order === 7;
      });
      fourthQ = appQuestions.filter((item) => {
        return item.order === 8;
      });
    }
    console.log(firstQ, secondQ);
  }, [appQuestions]);
  console.log(applicationAnswers, "l");
  return (
    <div>
      <div className="w-full min-h-[25rem] pb-5 pt-5 bg-[#F0F0F0] flex flex-col items-center justify-center sm:pl-10 sm:pr-10 pl-4 pr-4 rounded-2xl">
        <form action="" className="w-full mt-4">
          {firstQ != "" && secondQ != "" && (
            <div>
              <div>
                <h2 className={`mb-4 font-semibold`}>
                  {firstQ[0].questionText}
                </h2>
              </div>
              <div>
                {firstQ[0].choices.map((item, index) => {
                  return (
                    <div>
                      <Checkbox
                        className={`${
                          dash === "/Dashboard/application"
                            ? "checkk1"
                            : "checkk"
                        }`}
                        checked={checkBuffer === item ? true : false}
                        onClick={() => changeHandler(item, index)}
                      >
                        {item}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
              <div>
                {firstQ[0].childrenQuestions.length != 0 && (
                  <h2
                    className={`${
                      checkBuffer ===
                      firstQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                        ? ""
                        : "hidden"
                    } mb-4 lg:text-[1.1rem] font-semibold mt-2`}
                  >
                    {firstQ[0].childrenQuestions[0].wrapInHeading}
                  </h2>
                )}
                {firstQ[0].childrenQuestions.map((item, index) => {
                  return (
                    <div
                      className={`${
                        checkBuffer === item.showMeIfParentAnswerIs
                          ? ""
                          : "hidden"
                      }`}
                    >
                      <input
                        className={` ${
                          item.type === "text" ? "" : "hidden"
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        placeholder={`${item.questionText}`}
                      ></input>
                      <input
                        className={`${
                          item.type === "number" ? "" : "hidden"
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        type="number"
                        placeholder={`${item.questionText}`}
                      ></input>
                      {item.choices && (
                        <div
                          className={`${
                            item.type === "dropdown" ? "" : "hidden"
                          }`}
                        >
                          <Select
                            // onChange={(value) => {
                            //   setValue(option);
                            // }}
                            // value={option}
                            placeholder={`${item.questionText}`}
                            className="mb-2 mt-2 wow"
                          >
                            {console.log(item.choices, "qwqw")}
                            {item.choices.map((option) => (
                              <Select.Option key={option} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div
                        className={`${item.type === "date" ? "" : "hidden"}`}
                      >
                        <DatePicker
                          style={{ marginBottom: "1.2rem" }}
                          className="date-class"
                          placeholder={`${item.questionText}`}
                        />
                      </div>
                      <div
                        className={`${item.type === "image" ? "" : "hidden"}`}
                      >
                        <label
                          htmlFor="special-input1"
                          className={` cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <img className="w-[1.2rem]" src={plusp} alt="" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">
                              {item.questionText}
                            </p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            id="special-input1"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setDisplay,
                              item.id,
                              item.type,
                              ""
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 ${display ? "" : "hidden"}`}
                        >
                          <img
                            className="w-[25rem] h-[13rem]"
                            src={display}
                            alt=""
                          />
                          <div
                            className={`absolute ${
                              isLoading ? "" : "hidden"
                            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                          >
                            <Spin size="large" />
                          </div>
                          <div
                            className="absolute bottom-0 right-0 cursor-pointer bg-white"
                            onClick={() => setDisplay("")}
                          >
                            <img className="w-[3rem]" src={trash} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {firstQ != "" && secondQ != "" && (
            <div>
              <div>
                <h2 className={`mb-4 font-semibold`}>
                  {secondQ[0].questionText}
                </h2>
              </div>
              <div>
                {secondQ[0].choices.map((item, index) => {
                  return (
                    <div>
                      <Checkbox
                        className={`${
                          dash === "/Dashboard/application"
                            ? "checkk1"
                            : "checkk"
                        }`}
                        checked={check1Buffer === item ? true : false}
                        onClick={() => change2Handler(item, index)}
                      >
                        {item}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
              <div>
                {secondQ[0].childrenQuestions.length != 0 && (
                  <h2
                    className={`${
                      checkBuffer ===
                      secondQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                        ? ""
                        : "hidden"
                    } mb-4 lg:text-[1.1rem] font-semibold mt-2`}
                  >
                    {secondQ[0].childrenQuestions[0].wrapInHeading}
                  </h2>
                )}
                {secondQ[0].childrenQuestions.map((item, index) => {
                  return (
                    <div
                      className={`${
                        check1Buffer === item.showMeIfParentAnswerIs
                          ? ""
                          : "hidden"
                      }`}
                    >
                      <input
                        className={`${
                          item.type === "text" ? "" : "hidden"
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        placeholder={`${item.questionText}`}
                      ></input>
                      <input
                        className={`${
                          item.type === "number" ? "" : "hidden"
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        type="number"
                        onChange={(e) => {
                          question2sub1 = {
                            appquestionId: item.id,
                            type: item.type,
                            answers: [e.target.value],
                          };
                        }}
                        placeholder={`${item.questionText}`}
                      ></input>
                      {item.choices && (
                        <div
                          className={`${
                            item.type === "dropdown" ? "" : "hidden"
                          }`}
                        >
                          <Select
                            // onChange={(value) => {
                            //   setValue(option);
                            // }}
                            // value={option}
                            placeholder={`${item.questionText}`}
                            className="mb-2 mt-2 wow"
                          >
                            {console.log(item.choices, "qwqw")}
                            {item.choices.map((option) => (
                              <Select.Option key={option} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div
                        className={`${item.type === "date" ? "" : "hidden"}`}
                      >
                        <DatePicker
                          style={{ marginBottom: "1.2rem" }}
                          className="date-class"
                          placeholder={`${item.questionText}`}
                        />
                      </div>
                      <div
                        className={`${item.type === "image" ? "" : "hidden"}`}
                      >
                        <label
                          htmlFor="special-input1"
                          className={` cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <img className="w-[1.2rem]" src={plusp} alt="" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">
                              {item.questionText}
                            </p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            id="special-input1"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setDisplay,
                              item.id,
                              item.type,
                              "Do you pay water bills?"
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 ${display ? "" : "hidden"}`}
                        >
                          <img
                            onClick={() => {
                              setIShow(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={display}
                            alt=""
                          />
                          <div
                            className={`absolute ${
                              isLoading ? "" : "hidden"
                            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                          >
                            <Spin size="large" />
                          </div>
                          <div
                            className="absolute bottom-0 right-0 cursor-pointer bg-white"
                            onClick={() => setDisplay("")}
                          >
                            <img className="w-[3rem]" src={trash} alt="" />
                          </div>
                        </div>
                        <div
                          className={`${
                            iShow ? "" : "hidden"
                          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                        >
                          <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                            <img
                              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                              src={cross}
                              onClick={() => setIShow(false)}
                              alt=""
                            />
                            <img
                              className="w-[45rem] h-[28rem]"
                              src={display}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {firstQ != "" && secondQ != "" && (
            <div>
              <div>
                <h2 className={`mb-4 font-semibold`}>
                  {thirdQ[0].questionText}
                </h2>
              </div>
              <div>
                {thirdQ[0].choices.map((item, index) => {
                  return (
                    <div>
                      <Checkbox
                        className={`${
                          dash === "/Dashboard/application"
                            ? "checkk1"
                            : "checkk"
                        }`}
                        checked={check2Buffer === item ? true : false}
                        onClick={() => change3Handler(item, index)}
                      >
                        {item}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
              <div>
                {thirdQ[0].childrenQuestions.length != 0 && (
                  <h2
                    className={`${
                      check2Buffer ===
                      thirdQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                        ? ""
                        : "hidden"
                    } mb-4 lg:text-[1.1rem] font-semibold mt-2`}
                  >
                    {thirdQ[0].childrenQuestions[0].wrapInHeading}
                  </h2>
                )}
                {thirdQ[0].childrenQuestions.map((item, index) => {
                  return (
                    <div
                      className={`${
                        check2Buffer === item.showMeIfParentAnswerIs
                          ? ""
                          : "hidden"
                      }`}
                    >
                      <input
                        className={`${
                          item.type === "text" ? "" : "hidden"
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        placeholder={`${item.questionText}`}
                      ></input>
                      <input
                        className={`${
                          item.type === "number" ? "" : "hidden"
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        type="number"
                        onChange={(e) => {
                          question3sub1 = {
                            appquestionId: item.id,
                            type: item.type,
                            answers: [e.target.value],
                          };
                        }}
                        placeholder={`${item.questionText}`}
                      ></input>
                      {item.choices && (
                        <div
                          className={`${
                            item.type === "dropdown" ? "" : "hidden"
                          }`}
                        >
                          <Select
                            // onChange={(value) => {
                            //   setValue(option);
                            // }}
                            // value={option}
                            placeholder={`${item.questionText}`}
                            className="mb-2 mt-2 wow"
                          >
                            {console.log(item.choices, "qwqw")}
                            {item.choices.map((option) => (
                              <Select.Option key={option} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div
                        className={`${item.type === "date" ? "" : "hidden"}`}
                      >
                        <DatePicker
                          style={{ marginBottom: "1.2rem" }}
                          className="date-class"
                          placeholder={`${item.questionText}`}
                        />
                      </div>
                      <div
                        className={`${item.type === "image" ? "" : "hidden"}`}
                      >
                        <label
                          htmlFor="special-input2"
                          className={` cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <img className="w-[1.2rem]" src={plusp} alt="" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">
                              {item.questionText}
                            </p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            id="special-input2"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setDisplay1,
                              item.id,
                              item.type,
                              "Do you pay electricity bills?"
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 ${
                            display1 ? "" : "hidden"
                          }`}
                        >
                          <img
                            onClick={() => {
                              setIShow1(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={display1}
                            alt=""
                          />
                          <div
                            className={`absolute ${
                              isLoading ? "" : "hidden"
                            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                          >
                            <Spin size="large" />
                          </div>
                          <div
                            className="absolute bottom-0 right-0 cursor-pointer bg-white"
                            onClick={() => {
                              setDisplay1("");
                            }}
                          >
                            <img className="w-[3rem]" src={trash} alt="" />
                          </div>
                        </div>
                        <div
                          className={`${
                            iShow1 ? "" : "hidden"
                          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                        >
                          <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                            <img
                              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                              src={cross}
                              onClick={() => setIShow1(false)}
                              alt=""
                            />
                            <img
                              className="w-[45rem] h-[28rem]"
                              src={display1}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {firstQ != "" && secondQ != "" && (
            <div>
              <div>
                <h2 className={`mb-4 font-semibold`}>
                  {fourthQ[0].questionText}
                </h2>
              </div>
              <div>
                {fourthQ[0].choices.map((item, index) => {
                  return (
                    <div>
                      <Checkbox
                        className={`${
                          dash === "/Dashboard/application"
                            ? "checkk1"
                            : "checkk"
                        }`}
                        checked={check3Buffer === item ? true : false}
                        onClick={() => change4Handler(item, index)}
                      >
                        {item}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
              <div>
                {fourthQ[0].childrenQuestions.length != 0 && (
                  <h2
                    className={`${
                      check3Buffer ===
                      fourthQ[0].childrenQuestions[0].showMeIfParentAnswerIs
                        ? ""
                        : "hidden"
                    } mb-4 lg:text-[1.1rem] font-semibold mt-2`}
                  >
                    {fourthQ[0].childrenQuestions[0].wrapInHeading}
                  </h2>
                )}
                {fourthQ[0].childrenQuestions.map((item, index) => {
                  return (
                    <div
                      className={`${
                        check3Buffer === item.showMeIfParentAnswerIs
                          ? ""
                          : "hidden"
                      }`}
                    >
                      <input
                        className={`${
                          item.type === "text" ? "" : "hidden"
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        placeholder={`${item.questionText}`}
                      ></input>
                      <input
                        className={`${
                          item.type === "number" ? "" : "hidden"
                        } lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        type="number"
                        placeholder={`${item.questionText}`}
                      ></input>
                      {item.choices && (
                        <div
                          className={`${
                            item.type === "dropdown" ? "" : "hidden"
                          }`}
                        >
                          <Select
                            onChange={(value) => {
                              question4sub1 = {
                                appquestionId: item.id,
                                type: item.type,
                                answers: [value],
                              };
                              console.log(value, question4sub1);
                            }}
                            // value={option}
                            placeholder={`${item.questionText}`}
                            className="mb-2 mt-2 wow"
                          >
                            {console.log(item.choices, "qwqw")}
                            {item.choices.map((option) => (
                              <Select.Option key={option} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div
                        className={`${item.type === "date" ? "" : "hidden"}`}
                      >
                        <DatePicker
                          style={{ marginBottom: "1.2rem" }}
                          className="date-class"
                          placeholder={`${item.questionText}`}
                        />
                      </div>
                      <div
                        className={`${item.type === "image" ? "" : "hidden"}`}
                      >
                        <label
                          htmlFor="special-input1"
                          className={` cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <img className="w-[1.2rem]" src={plusp} alt="" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">
                              {item.questionText}
                            </p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            id="special-input1"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setDisplay,
                              item.id,
                              item.type,
                              item.questionText
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 ${display ? "" : "hidden"}`}
                        >
                          <img
                            className="w-[25rem] h-[13rem]"
                            src={display}
                            alt=""
                          />
                          <div
                            className={`absolute ${
                              isLoading ? "" : "hidden"
                            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                          >
                            <Spin size="large" />
                          </div>
                          <div
                            className="absolute bottom-0 right-0 cursor-pointer bg-white"
                            onClick={() => setDisplay("")}
                          >
                            <img className="w-[3rem]" src={trash} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </form>
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={nextHandler}
          className={`lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ThirdA;
