import { Button, message, Steps, theme } from "antd";
import { useState } from "react";
import First from "./stepsBR/FirstBR";
import SecondR from "../Register/stepsR/SecondR";
import ThirdR from "../Register/stepsR/ThirdR";
import FifthR from "../Register/stepsR/FifthR";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Mid from "../Register/stepsR/mid";

const BenificiaryR = () => {
  const location = useLocation();
  const dash = location.pathname;
  const brcurrent = useSelector((state) => state.brcurrent);
  const dispatch = useDispatch();
  const Show = [First, Mid, ThirdR, FifthR];
  const Dis = Show[brcurrent];
  const steps = [
    {
      title: "",
      content: "First-content",
    },
    {
      title: "",
      content: "Second-content",
    },
    {
      title: "",
      content: "Last-content",
    },
    {
      title: "",
      content: "Last-content",
    },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const nextHandler = (check) => {
    if (check === "n") {
      if (brcurrent < 3) {
        dispatch({ type: "BRINCREMENT" });
      } else if (brcurrent === 3) {
        if (dash === "/Dashboard/BeneficiaryQuestions") {
          dispatch({ type: "SET", num: 1 });
          dispatch({ type: "POP" });
        } else {
          dispatch({ type: "SET", num: 5 });
          dispatch({ type: "POP" });
        }
      }
    } else {
      if (brcurrent > 0) {
        dispatch({ type: "BRDECREMENT" });
      }
    }
  };
  return (
    <div>
      <div>
        <Dis />
      </div>
    </div>
  );
};

export default BenificiaryR;
