const Sam = () => {
  return (
    <div>
      <div className="relative w-full sm:h-[100vh] h-[50vh] bg-[#ffe7e2] flex flex-col justify-center items-center">
        <h2 className="text-center sm:text-[3.8rem] text-[2.8rem] font-extrabold leading-[3rem] mb-[3.5rem]">
          <span className="text-[#F7542E]">Social&nbsp;</span>
          <span className="dancing">Assistance</span>
          <br></br>
          <span className="text-[#F15B27] text-[1rem]">
            & Social Assistance Programmes
          </span>
        </h2>
      </div>
      <div className="w-full h-[35vh] flex items-center justify-center shadow-2xl pl-4 pr-4">
        <p className="dancing text-center  text-[1.3rem] w-[25rem]">
          These programmes provide psychological, social, financial and other
          material assistance as well as regulatory services for senior
          citizens, the infirm, the indigent and destitute to improve their
          status and implement their abilities to cope with the stress of
          living.
        </p>
      </div>
      <div className="w-full bg-[#F5F4F0]  pb-[5rem] flex flex-col items-center  pl-4 pr-4 sm:pl-[25rem] sm:pr-[25rem]">
        <div className="text-center mt-[5rem]">
          <h2 className="mb-5 text-[#F7542E] font-bold text-[1.2rem]">
            Public Assistance
          </h2>
          <p className=" text-[#9B9A9D] font-medium">
            Public Assistance is a temporary financial assistance designed to
            assist the infirm, aged, handicapped, orphans, persons affected by
            HIV and all other persons who cannot adequately maintain their
            households. The programme is subjected to periodic reviews to ensure
            eligibility. Applicants are interviewed by the officers after which
            the duly constituted Local Board of Guardians of the district will
            deliberate and decide to whom and how much to be given. Do not allow
            three (3) months to elapse without uplifting payment for Public
            Assistance from your District Post Office.
          </p>
        </div>
        <div className="mt-[5rem]">
          <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
            Economic Eligibility
          </h2>
          <p className=" text-[#9B9A9D] font-medium">
            To determine if you are eligible to receive Public Assisatnce, the
            Ministry will evalute yoa and you family's size, income and assets.
          </p>
        </div>
        <div className="mt-[5rem]">
          <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
            Disability Eligibility
          </h2>
          <p className=" text-[#9B9A9D] font-medium">
            To determine if you are eligible to receive Public Assistance, the
            Ministry looks at if you have substantial physical or mental
            disability that:is expected to last a year or more, and makes it
            hard for you to care for yourself, take part in comunity life or
            work.
          </p>
        </div>
        <div className="mt-[5rem]">
          <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
            Required Documents
          </h2>
          <ul className="list-disc ml-10">
            <li className=" text-[#9B9A9D] font-medium mb-1">
              Birth Certificate
            </li>
            <li className=" text-[#9B9A9D] font-medium mb-1">
              Passport or Citizenship Papers
            </li>
            <li className=" text-[#9B9A9D] font-medium mb-1">NIS Numbers</li>
            <li className=" text-[#9B9A9D] font-medium mb-1">
              Document Stating your Financial Expenditure (rent receipts,
              mortgage statements or utility)
            </li>
            <li className=" text-[#9B9A9D] font-medium mb-1">
              Document Stating your income (pay stubs or support orders)
            </li>
            <li className=" text-[#9B9A9D] font-medium mb-1">
              Information about your job or training programme
            </li>
          </ul>
        </div>
        <div className="mt-[5rem]">
          <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
            How to get started
          </h2>
          <p className=" text-[#9B9A9D] font-medium mb-[2rem]">
            Contact your local Probation and Social Services Office to request a
            meeting. If you are unable to come to us, we can meet with you at
            another location.
          </p>
          <p className=" text-[#9B9A9D] font-medium mb-1">
            Let us know in advance if you require special needs such as sign or
            language interpretation. We can make arrangements to facilitate
            this. The purpose of the meeting is to asses your financial
            situation
          </p>
        </div>
      </div>
      {/* <div className="w-full  pb-[5rem] flex flex-col items-center  pl-[25rem] pr-[25rem]">
          <div className="mt-[5rem]">
            <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
              Step1 Application
            </h2>
            <p className=" text-[#9B9A9D] font-medium mb-[2rem]">
              Make sure all family members included in your application are with
              you when you fill out the form so they can provide their consent and
              confirm their information.
            </p>
            <p className=" text-[#9B9A9D] font-medium mb-1">
              Have your family’s identification, details, and documents ready,
              such as:
            </p>
            <ul className="list-disc ml-10">
              <li className=" text-[#9B9A9D] font-medium mb-1">
                Tax Identification Numbers (TIN)
              </li>
              <li className=" text-[#9B9A9D] font-medium mb-1">GID cards</li>
              <li className=" text-[#9B9A9D] font-medium mb-1">
                Birth certificates
              </li>
              <li className=" text-[#9B9A9D] font-medium mb-1">Passport</li>
            </ul>
          </div>
          <div className="mt-[5rem]">
            <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
              Step 2: MHSSS-SA reviews your application
            </h2>
            <p className=" text-[#9B9A9D] font-medium mb-1">
              A caseworker from your local MHSSS office will review your
              application and call you within ten business days of submitting your
              application to schedule an appointment. This does not include
              weekends and holidays.
            </p>
          </div>
          <div className="mt-[5rem]">
            <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
              Step 3: Meet with MHSSS to sign and verify your application
            </h2>
            <p className=" text-[#9B9A9D] font-medium mb-1">
              Meet with a caseworker for your verification appointment. The
              caseworker might ask you to provide more information, like copies of
              bills or other documents, so we can verify the information you
              included in your application. You will also be asked to sign your
              application package, including a consent form to allow MHSSS to
              access information about you from other government programs or
              organizations (for example, the Ministry of Transportation,
              Employment Insurance, NIS). This information will help to determine
              if you are qualify for assistance. If MHSSS determines that you are
              not financially eligible, you will have 30 days to request that
              MHSSS review your information again.
            </p>
          </div>
          <div className="mt-[5rem]">
            <h2 className="text-center mb-5 text-[#F7542E] font-bold text-[1.2rem]">
              Step 4: Get approved and receive your payment
            </h2>
            <p className=" text-[#9B9A9D] font-medium mb-1">
              Once the caseworker receives all the information they need, they
              will tell you within ten business days if your application has been
              approved and how much you may receive. If your application is
              approved, your local MHSSS office will advise when you can expect
              your first payment.
            </p>
          </div>
        </div> */}
    </div>
  );
};

export default Sam;
