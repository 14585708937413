import axios from "axios";
import { useDispatch } from "react-redux";

const ForgotPassword = () => {
  const dispatch = useDispatch();

  // const nextHandler = (e) => {
  //   e.preventDefault();
  //   axios.post(
  //     "{{baseUrl}}/v1/auth/forgot-password",
  //     {},
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }
  //   );
  // };
  return (
    <div className="flex justify-center">
      <form className="w-[50%]">
        <div className="text-center mt-[3rem] flex flex-col items-center mb-[3rem]">
          <h2 className="font-medium text-[1.6rem] text-[#2c5281] mb-1">
            Password Reset
          </h2>
          <p className="w-[60%] text-[#596F96] text-[0.9rem]">
            To reset your password, enter the email address you use to sign in
            to OAP.
          </p>
        </div>
        <input
          className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          type="email"
          placeholder="E-mail Address"
          name=""
          id=""
        />
        <button
          type="submit"
          className="justify-center flex w-[100%] cursor-pointer bg-[#57D38C] pt-2 pb-2 pl-[4rem] pr-[4rem] text-white rounded-xl"
        >
          Send Reset Link
        </button>
        {/* <button
          className="lg:text-[1.1rem] bg-[#d64242] mt-4 w-full text-white rounded-[0.5rem] p-2  pr-8 pl-8"
          onClick={() => dispatch({ type: "XSET", num: 0 })}
        >
          Back
        </button> */}
      </form>
    </div>
  );
};

export default ForgotPassword;
