// import prof from "../../../../imgs/prof.png";
import { Dropdown, Pagination, Popover } from "antd";
import bs1 from "../../imgs/bs1.png";
import across from "../../imgs/across.png";
import bs2 from "../../imgs/bs2.png";
import bs3 from "../../imgs/bs3.png";
import bs4 from "../../imgs/change.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import EmpRegisterPol from "./empRegisterPol";
import EmpRegisterPol1 from "./empRegisterPol1";

const PageP = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const pol = useSelector((state) => state.pol);
  const pol1 = useSelector((state) => state.pol1);
  const gidFilter = useSelector((state) => state.gidFilter);
  const emailFilter = useSelector((state) => state.emailFilter);
  const phoneFilter = useSelector((state) => state.phoneFilter);
  const [allData, setAllData] = useState([]);
  const [checkProfile, setCheckProfile] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [buffer, setBuffer] = useState("");
  const [err, setErr] = useState("");
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  useEffect(() => {}, [page, gidFilter, phoneFilter]);
  const router = useNavigate();
  const dispatch = useDispatch();
  const option2 = useSelector((state) => state.option2);
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }
  useEffect(() => {
    console.log(dataUser, "2222");
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/pensioners/inactive-pol?limit=6&skip=0&page=${page}&sortBy=createdAt:desc`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "eee");
        setAllData(data.data.rows);
        setTotalPages(data.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);
  const items = [
    {
      label: "POL Upload",
      key: "1",
      icon: <img style={{ width: "1.5rem" }} src={bs1} />,
    },
    {
      label: "POL Capture",
      key: "2",
      icon: <img style={{ width: "1.5rem" }} src={bs1} />,
    },
  ];

  const onClick = ({ key, index }) => {
    console.log("ww", index);
    console.log(checkProfile, "22");
    if (checkProfile != null) {
      if (key === "1") {
        dispatch({ type: "pol" });
      } else if (key === "2") {
        dispatch({ type: "pol1" });
        dispatch({ type: "cam" });
      }
    } else {
      setShow1(true);
      setErr("Personal data is missing or does not exist in profile");
    }

    // if (key === "3") {
    //   router("/EDashboard/PensionerAccount");
    //   console.log(buffer, "imndex");
    //   dispatch({ type: "universalPenSet", num: buffer });
    //   dispatch({ type: "gidFilterSet", num: "" });
    // } else if (key === "1") {
    //   dispatch({ type: "universalPenSet", num: buffer });
    //   router("/EDashboard/PcardView");
    //   dispatch({ type: "gidFilterSet", num: "" });
    // }
  };
  const onClick2 = (e, polStatus, profile) => {
    setCheckProfile(profile);
    if (profile === null) {
      console.log("err");
    } else {
      dispatch({ type: "polIdSet", num: e });
      dispatch({ type: "polStatusSet", num: polStatus });
      dispatch({
        type: "polNameSet",
        num: `${profile.firstName} ${profile.lastName}`,
      });
    }
    setBuffer(e);
  };
  const pageHandler = (e) => {
    console.log(e);
    setPage(e);
  };
  return (
    <div className="w-[100%] overflow-y-hidden overflow-x-auto">
      <ul className="grid grid-cols-[2.5rem_6.3rem_6.7rem_8.8rem_8.6rem_9rem_10.4rem_8.8rem_9rem_3rem] pl-[1rem] mb-2 mt-5">
        <li className="font-semibold">ID</li>
        <li className="font-semibold">Pensioner</li>
        <li className="font-semibold">Last Name</li>
        <li className="font-semibold">GID</li>
        <li className="font-semibold">Booklet Number</li>
        <li className="font-semibold ml-3">Phone</li>
        <li className="font-semibold">Date of Birth</li>
        <li className="font-semibold">POL Status</li>
        <li className="font-semibold">Created</li>
        <li className="font-semibold">Action</li>
      </ul>
      {allData.map((item, index) => {
        return (
          <ul className="relative rounded-md grid cursor-pointer w-fit content-center grid-cols-[2.5rem_6.3rem_6.7rem_8.8rem_8.6rem_9rem_10.4rem_8.8rem_9rem_3rem] bg-[#E8E8E8] hover:bg-[#cbcbcb] pl-[1rem] pt-2 pb-2 mb-3">
            <li className="flex items-center ml-1">{index + 1}.</li>
            <Popover content={item.uniqueId} placement="bottomLeft">
              <li className="relative flex items-center ml-2">
                <p>{item.uniqueId.substring(0, 5)}..</p>
              </li>
            </Popover>
            {item.person != null && (
              <li className="flex items-center">
                <div>{item.person.lastName}</div>
              </li>
            )}
            <li
              className={`flex items-center ${
                item.person === null ? "" : "hidden"
              }`}
            >
              <div>---</div>
            </li>
            <li className="flex items-center">
              <div>{item.gid}</div>
            </li>

            <li className="flex items-center">
              <div className="border-none bg-[white] text-[#F7542E] font-semibold p-1 pl-5 pr-5 rounded-md  focus:border-none cursor-pointer">
                145352
              </div>
            </li>
            <li className="flex items-center ml-2">
              {item.phoneNo === null ? "----" : `${item.phoneNo}`}
            </li>
            {item.person != null && (
              <li className="flex items-center">
                <div>{item.person.dateOfBirth}</div>
              </li>
            )}
            <li
              className={`flex items-center ${
                item.person === null ? "" : "hidden"
              }`}
            >
              <div>---</div>
            </li>
            <li className="flex items-center">
              {/* <Popover
                content={`${
                  index === 3 ? "Deceased" : index === 1 ? "In-Process" : ""
                }`}
                placement="bottomLeft"
              >
                <div
                  className={`border-none ${
                    index === 3 || index === 5
                      ? "bg-[#d64242]"
                      : index === 1
                      ? "bg-[#2F84F1]"
                      : "bg-[#57D38C]"
                  }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer`}
                >
                  {index === 3 || index === 5 ? "In-Active" : "Active"}
                </div>
              </Popover> */}
              <Popover
                // content={`${item.status === "pending" ? "In-Process" : ""}`}
                placement="bottomLeft"
              >
                <div
                  className={`border-none ${
                    item.polStatus === "no-pol"
                      ? "bg-[#2F84F1]"
                      : " bg-[#57D38C]"
                  }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer`}
                >
                  {item.polStatus === "no-pol"
                    ? item.polStatus.toUpperCase()
                    : item.polStatus}
                </div>
              </Popover>
            </li>
            <li className="flex items-center">
              {item.createdAt.substring(0, 10)}
            </li>
            <li className="relative flex items-center">
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
                onClick={() => onClick2(item.id, item.polStatus, item.person)}
                trigger={["click"]}
              >
                <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                  <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                </div>
              </Dropdown>
            </li>
          </ul>
        );
      })}
      <div className="flex justify-end mt-7">
        <Pagination
          defaultCurrent={1}
          total={totalPages * 10}
          showSizeChanger={false}
          onChange={pageHandler}
        />
      </div>
      <div className={`${pol ? "" : "hidden"}`}>
        <EmpRegisterPol />
      </div>
      {console.log(pol1, "wqwqqw")}
      <div className={`${pol1 ? "" : "hidden"}`}>
        <EmpRegisterPol1 />
      </div>
      <div
        className={`${
          show1 ? "" : "hidden"
        } z-[50] w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow1(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageP;
