import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const EmployeeInfo = () => {
  const [employer, setEmployer] = useState(
    "Ministry of Human Services & Social Services Guyana"
  );
  const [supervisor, setSupervisor] = useState("");
  const [position, setPosition] = useState("");
  const [location, setLocation] = useState("");
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const nextHandler = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/employee-info`,
        {
          employer,
          supervisor,
          position,
          departmentOrLocation: `${location}`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "ll");
        dispatch({ type: "IINCREMENT" });
        dispatch({ type: "POP" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };
  return (
    <div className="flex justify-center">
      <form
        action=""
        className="sm:w-[50%] w-[85%] mt-10"
        onSubmit={nextHandler}
      >
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Employer
        </h2>
        <input
          type="text"
          name=""
          id=""
          placeholder="Employer"
          onChange={handleChange(setEmployer)}
          value={employer}
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Supervisor
        </h2>
        <input
          type="text"
          name=""
          id=""
          onChange={handleChange(setSupervisor)}
          value={supervisor}
          placeholder="Supervisor"
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Position
        </h2>
        <input
          type="text"
          name=""
          id=""
          onChange={handleChange(setPosition)}
          value={position}
          placeholder="Position"
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Department/Location
        </h2>
        <input
          type="text"
          name=""
          id=""
          onChange={handleChange(setLocation)}
          value={location}
          placeholder="Department/Location"
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center">
          <button
            onClick={() => dispatch({ type: "IDECREMENT" })}
            className={`lg:text-[1.1rem] bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button>
          <button
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-[#57D38C] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Register
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmployeeInfo;
