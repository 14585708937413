export const wowReducer = (state = 0, action) => {
  switch (action.type) {
    case "SET":
      return action.num;
    default:
      return state;
  }
};
export const wowxReducer = (state = 0, action) => {
  switch (action.type) {
    case "XSET":
      return action.num;
    default:
      return state;
  }
};

export const isLoggedReducer = (state = false, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return !state;
    default:
      return state;
  }
};

export const veriReducer = (state = false, action) => {
  switch (action.type) {
    case "veri":
      return !state;
    default:
      return state;
  }
};
export const camReducer = (state = false, action) => {
  switch (action.type) {
    case "cam":
      return !state;
    default:
      return state;
  }
};

export const veri2Reducer = (state = false, action) => {
  switch (action.type) {
    case "veri2":
      return !state;
    default:
      return state;
  }
};

export const tokenReducer = (state = "0", action) => {
  switch (action.type) {
    case "tokenSet":
      return action.num;
    default:
      return state;
  }
};
export const penIdReducer = (state = "0", action) => {
  switch (action.type) {
    case "penId":
      return action.num;
    default:
      return state;
  }
};
export const benIdReducer = (state = "0", action) => {
  switch (action.type) {
    case "benId":
      return action.num;
    default:
      return state;
  }
};
export const dataReducer = (state = [], action) => {
  switch (action.type) {
    case "setData":
      return action.num;
    default:
      return state;
  }
};
export const userDataReducer = (state = [], action) => {
  switch (action.type) {
    case "setUserData":
      return action.num;
    default:
      return state;
  }
};
export const refreshTokenReducer = (state = "0", action) => {
  switch (action.type) {
    case "refreshTokenSet":
      return action.num;
    default:
      return state;
  }
};

export const loginTokenReducer = (state = "0", action) => {
  switch (action.type) {
    case "loginTokenSet":
      return action.num;
    default:
      return state;
  }
};

export const profileDataReducer = (state = [], action) => {
  switch (action.type) {
    case "setProfileData":
      return action.num;
    default:
      return state;
  }
};

export const nameReducer = (state = "", action) => {
  switch (action.type) {
    case "nameSet":
      return action.num;
    default:
      return state;
  }
};
export const imgReducer = (state = "", action) => {
  switch (action.type) {
    case "imageSet":
      return action.num;
    default:
      return state;
  }
};
export const universalCheckReducer = (state = "", action) => {
  switch (action.type) {
    case "checkSet":
      return action.num;
    default:
      return state;
  }
};
export const popDataReducer = (state = "", action) => {
  switch (action.type) {
    case "popDataSet":
      return action.num;
    default:
      return state;
  }
};
export const appQuestionsReducer = (state = [], action) => {
  switch (action.type) {
    case "appQuestionsSet":
      return action.num;
    default:
      return state;
  }
};

export const data1Reducer = (state = "", action) => {
  switch (action.type) {
    case "data1Set":
      return action.num;
    default:
      return state;
  }
};
export const data2Reducer = (state = "", action) => {
  switch (action.type) {
    case "data2Set":
      return action.num;
    default:
      return state;
  }
};
export const data3Reducer = (state = "", action) => {
  switch (action.type) {
    case "data3Set":
      return action.num;
    default:
      return state;
  }
};
export const applicationAnswersReducer = (state = [], action) => {
  switch (action.type) {
    case "answersSet":
      return action.num;
    default:
      return state;
  }
};
export const editReducer = (state = "", action) => {
  switch (action.type) {
    case "editSet":
      return action.num;
    default:
      return state;
  }
};
export const placeReducer = (state = null, action) => {
  switch (action.type) {
    case "placeSet":
      return action.num;
    default:
      return state;
  }
};

export const addPensionerReducer = (state = {}, action) => {
  switch (action.type) {
    case "addPensionerSet":
      return action.num;
    default:
      return state;
  }
};
export const allBenReducer = (state = "", action) => {
  switch (action.type) {
    case "allBenIdSet":
      return action.num;
    default:
      return state;
  }
};
export const whichViewReducer = (state = "", action) => {
  switch (action.type) {
    case "whichViewSet":
      return action.num;
    default:
      return state;
  }
};
export const idDoneReducer = (state = "", action) => {
  switch (action.type) {
    case "IdDoneSet":
      return action.num;
    default:
      return state;
  }
};
export const benDoneReducer = (state = "", action) => {
  switch (action.type) {
    case "benDone":
      return action.num;
    default:
      return state;
  }
};
export const fromAwaitedReducer = (state = "", action) => {
  switch (action.type) {
    case "fromAwaited":
      return action.num;
    default:
      return state;
  }
};
export const pensionerNameReducer = (state = "", action) => {
  switch (action.type) {
    case "pensionerName":
      return action.num;
    default:
      return state;
  }
};
export const pensionerIdReducer = (state = "", action) => {
  switch (action.type) {
    case "pensionerId":
      return action.num;
    default:
      return state;
  }
};
export const userDataReducer1 = (state = "", action) => {
  switch (action.type) {
    case "userDataSet":
      return action.num;
    default:
      return state;
  }
};
