import real1 from "../imgs/real1.png";
import real2 from "../imgs/real2.png";
import real4 from "../imgs/real4.png";
import reals from "../imgs/reals.png";
import oapm from "../imgs/oapm.png";
import eve from "../imgs/eve.png";
import prof from "../imgs/prof.png";
import ee from "../imgs/ee.png";
import gro from "../imgs/gro.png";
import effect from "../imgs/effect.png";
import employeee from "../imgs/employeee.png";
import back5 from "../imgs/back5.png";
import qwqw from "../imgs/qwqw.png";

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Nav from "../components/Nav/nav";
import Aav from "../components/Nav/aav";
import { Collapse } from "antd";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import PageE from "../components/Dash/Test/Listing/pageE";

const AdminPanel = () => {
  const location = useLocation();
  const dash = location.pathname;
  const router = useNavigate();
  const { Panel } = Collapse;

  ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);
  ChartJS.register(ArcElement, Tooltip, Legend);
  const data = {
    labels: [
      "Region 1",
      "Region 2",
      "Region 3",
      "Region 4",
      "Region 5",
      "Region 6",
      "Region 7",
      "Region 8",
      "Region 9",
      "Region 10",
    ],
    datasets: [
      {
        label: ["Number of Employees"],
        data: [12, 19, 3, 5, 2, 14, 2, 23, 6, 14],
        backgroundColor: "#261362",
        borderColor: "black",
        borderWidth: 1,
      },
      // {
      //   label: "362",
      //   data: [, 70],
      //   backgroundColor: "#261362",
      //   borderColor: "black",
      //   borderWidth: 1,
      // },
    ],
  };

  const data1 = {
    labels: ["Acive", "In-Active", "Pending"],
    datasets: [
      {
        // label: ["1", "2", "3", "4", "5"],
        data: [8, 9, 3],
        backgroundColor: ["aqua", "orange", "red"],
      },
      // {
      //   label: "362",
      //   data: [, 70],
      //   backgroundColor: "#261362",
      //   borderColor: "black",
      //   borderWidth: 1,
      // },
    ],
  };
  const options = {};
  const options1 = {};
  return (
    <div className="">
      <Nav />
      <div className="relative flex justify-between items-center mt-[2rem] ml-[5rem]">
        <div
          onClick={() => {
            if (location.pathname[1] === "E") {
              router("/EDashboard");
            } else {
              router("/Dashboard");
            }
          }}
          className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
        >
          <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
        </div>
        <h2 className="font-bold sm:text-[1.8rem] sm:ml-0 ml-[2rem] text-[1.2rem]">
          Admin Panel
        </h2>
      </div>
      <div className="mt-[3rem]">
        <Collapse accordion>
          <Panel header="Actions" key="1">
            <div className="mt-[4rem] flex justify-center text-[white]">
              <div className="flex justify-center items-center flex-col pb-[3rem]">
                <div className="flex">
                  <div
                    className={`grid lg:grid-cols-6 lg:gap-x-3 gap-y-5 grid-cols-2 gap-x-3`}
                  >
                    <div className={`cursor-pointer `}>
                      <div className="relative overflow-hidden font-bold  shadow-2xl text-[1rem] sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] rounded-2xl">
                        <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                          <div className="flex justify-between">
                            <div className=" flex items-center">
                              <div className="ml-3">
                                <p className="text-[1rem] font-bold text-black">
                                  Employees
                                </p>
                                <div className="flex items-center">
                                  <img
                                    src={effect}
                                    className="scale-[0.7]"
                                    alt=""
                                  />
                                  <p className="text-[0.9rem] font-normal text-black">
                                    OAP
                                  </p>
                                </div>
                              </div>
                              <p className="h-[1.5rem] right-2 absolute bg-[#F7542E] text-white text-[0.7rem] font-medium rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                                Active
                              </p>
                            </div>
                          </div>
                          <div className="overflow-hidden rounded-2xl h-[9rem]">
                            <img
                              className="rounded-2xl w-full h-[9rem]"
                              src={employeee}
                              alt=""
                            />
                          </div>
                          <div className="bg-[#57D38C]/[0.9]  rounded-2xl p-2 mt-2">
                            <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                              View the list of all of the employees that are
                              registered with MHSSS
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cursor-pointer">
                      <div className="relative overflow-hidden font-bold  shadow-2xl text-[1rem] sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] rounded-2xl">
                        <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                          <div className="flex justify-between">
                            <div className=" flex items-center">
                              <div className="ml-3">
                                <p className="text-[1rem] font-bold text-black">
                                  Pensioners
                                </p>
                                <div className="flex items-center">
                                  <img
                                    src={effect}
                                    className="scale-[0.7]"
                                    alt=""
                                  />
                                  <p className="text-[0.9rem] font-normal text-black">
                                    OAP
                                  </p>
                                </div>
                              </div>
                              <p className="h-[1.5rem] right-2 absolute bg-[#F7542E] text-white text-[0.7rem] font-medium rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                                Active
                              </p>
                            </div>
                          </div>
                          <div className="overflow-hidden rounded-2xl h-[9rem]">
                            <img
                              className="rounded-2xl w-full h-[9rem]"
                              src={reals}
                              alt=""
                            />
                          </div>
                          <div className="bg-[#57D38C]/[0.9]  rounded-2xl p-2 mt-2">
                            <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                              View the list of all of the pensioners that are
                              registered with MHSSS
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => alert("Coming Soon")}
                    >
                      <div className="overflow-hidden relative font-bold text-[1rem]  flex justify-center items-center  shadow-2xl  sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] rounded-2xl">
                        <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                          <div className="flex justify-between">
                            <div className=" flex items-center">
                              <div className="ml-3">
                                <p className="text-[1rem] font-bold text-black">
                                  Merchants
                                </p>
                                <div className="flex items-center ">
                                  <img
                                    src={effect}
                                    className="scale-[0.7]"
                                    alt=""
                                  />
                                  <p className="text-[0.9rem] font-normal text-black">
                                    OAP
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium mt-3 rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                              Active
                            </p>
                          </div>
                          <div className="overflow-hidden rounded-2xl h-[8rem] flex justify-center">
                            <img
                              className="rounded-2xl object-cover w-[13rem] h-[8rem]"
                              src={real2}
                              alt=""
                            />
                          </div>
                          <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2 mt-2">
                            <p className="text-[0.71rem] font-medium">
                              View merchants near you for receiving your
                              benefits, pay bills and purchase grocery
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => alert("Coming Soon")}
                    >
                      <div className="overflow-hidden relative font-bold text-[1rem] shadow-2xl  sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] rounded-2xl flex justify-center items-center">
                        <div className="sm:p-4 p-2 w-full h-full   relative  rounded-2xl flex flex-col justify-between">
                          <div className="flex justify-between ">
                            <div className=" flex items-center">
                              {/* <div className="w-[2.5rem] h-[2.5rem]">
                              <img className="object-cover" src={gro} alt="" />
                            </div> */}
                              <div className="ml-2">
                                <div className="flex justify-between items-center">
                                  <p className="text-[1rem] font-bold text-black">
                                    General
                                  </p>
                                  <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                                    Active
                                  </p>
                                </div>

                                <p className="text-[1rem] font-bold text-black w-[10rem]">
                                  Register Office
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="overflow-hidden flex justify-center items-center rounded-2xl h-[9rem]">
                            <img
                              className="rounded-2xl w-[8.5rem] h-[8.5rem] mb-3"
                              src={gro}
                              alt=""
                            />
                          </div>
                          <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2 mt-2">
                            <p className="text-[0.71rem] font-medium">
                              Updated records for deceased members of the Social
                              Programs
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        alert("Coming Soon");
                      }}
                    >
                      <div className="overflow-hidden relative font-bold text-[1rem] flex justify-center items-center shadow-2xl sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] mb-5 rounded-2xl">
                        <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                          <div className="flex justify-between">
                            <div className=" flex items-center">
                              <div className="ml-3">
                                <p className="text-[1rem] font-bold text-black">
                                  OAP Money
                                </p>
                                <div className="flex items-center ">
                                  <img
                                    src={effect}
                                    className="scale-[0.7]"
                                    alt=""
                                  />
                                  <p className="text-[0.9rem] font-normal text-black">
                                    OAP
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium mt-3 rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                              Active
                            </p>
                          </div>
                          <div className="overflow-hidden  rounded-2xl h-[13rem] mt-3">
                            <img
                              className="rounded-2xl w-full mr-[10rem]"
                              src={oapm}
                              alt=""
                            />
                          </div>
                          <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2">
                            <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                              Use your QR Code for purchases. The history will
                              be recorded in your profile
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        alert("Coming Soon");
                      }}
                    >
                      <div className="overflow-hidden relative font-bold text-[1rem] flex justify-center items-center shadow-2xl sm:w-[13rem] sm:h-[22rem] w-[10rem] h-[18rem] mb-5 rounded-2xl">
                        <div className="sm:p-4 p-2 w-full h-full  absolute top-0 left-0 rounded-2xl flex flex-col justify-between">
                          <div className="flex justify-between">
                            <div className=" flex items-center">
                              <div className="ml-3">
                                <p className="text-[1rem] font-bold text-black">
                                  Events
                                </p>
                                <div className="flex items-center ">
                                  <img
                                    src={effect}
                                    className="scale-[0.7]"
                                    alt=""
                                  />
                                  <p className="text-[0.9rem] font-normal text-black">
                                    OAP
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="h-[1.5rem] bg-[#F7542E] text-white text-[0.7rem] font-medium mt-3 rounded-lg sm:inline-block hidden pt-1 pb-1 pl-4 pr-4 ">
                              Active
                            </p>
                          </div>
                          <div className="overflow-hidden  rounded-2xl h-[13rem] mt-3">
                            <img
                              className="rounded-2xl w-full mr-[10rem]"
                              src={eve}
                              alt=""
                            />
                          </div>
                          <div className="bg-[#57D38C]/[0.9] rounded-2xl p-2">
                            <p className="sm:text-[0.8rem] text-[0.7rem] font-medium">
                              The announments will be for public or private
                              (Ministry) for scheduling events and distributions
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className="flex mt-[2rem] ">
        <div className="w-[62%] ml-8 text-[70%] mb-[1rem]">
          <PageE />
        </div>
        <div className="w-[32%] h-[20rem] ml-4 mr-4">
          <Line
            style={{ marginBottom: "1rem" }}
            data={data}
            options={options}
          ></Line>
          <Pie
            style={{ marginLeft: "5rem", marginBottom: "3rem" }}
            data={data1}
            options={options1}
          ></Pie>
        </div>
      </div>
      <div className="fixed bottom-[8%] right-[3%] bg-[#25D366] w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
        <img className=" w-[3.5rem]" src={qwqw} alt="" />
      </div>
    </div>
  );
};

export default AdminPanel;
