import {
  isLoggedReducer,
  veriReducer,
  veri2Reducer,
  wowReducer,
  wowxReducer,
  tokenReducer,
  refreshTokenReducer,
  benId,
  benIdReducer,
  loginTokenReducer,
  nameReducer,
  universalCheckReducer,
  appQuestionsReducer,
  editReducer,
  placeReducer,
  userDataReducer,
  dataReducer,
  popDataReducer,
  data1Reducer,
  data2Reducer,
  data3Reducer,
  profileDataReducer,
  allBenReducer,
  userDataReducer1,
  benDoneReducer,
  camReducer,
  idDoneReducer,
  pensionerNameReducer,
  pensionerIdReducer,
  addPensionerReducer,
  imgReducer,
  applicationAnswersReducer,
  whichViewReducer,
  fromAwaitedReducer,
  penIdReducer,
} from "./wow";
import {
  authStateReducer,
  placePopReducer,
  pol1Reducer,
  polNameReducer,
  polReducer,
  polStatusReducer,
  polUserReducer,
  popupReducer,
  resetReducer,
} from "./popup";
import {
  rcurrentReducer,
  icurrentReducer,
  bicurrentReducer,
  brcurrentReducer,
  acurrentReducer,
} from "./currents";
import {
  optionReducer,
  option2Reducer,
  gidFilterReducer,
  phoneFilterReducer,
  emailFilterReducer,
  universalPenReducer,
} from "./option";
import { combineReducers } from "redux";
import {
  appUrlReducer,
  question1Reducer,
  question2Reducer,
  question3Reducer,
  question4Reducer,
  question5Reducer,
  question6Reducer,
  question7Reducer,
  question8Reducer,
  question9Reducer,
  questionaReducer,
  questionbReducer,
  questioncReducer,
  questiondReducer,
  questioneReducer,
  questionxReducer,
  questionyReducer,
  questionzReducer,
} from "./application";

const allReducers = combineReducers({
  wowt: wowReducer,
  wowx: wowxReducer,
  rcurrent: rcurrentReducer,
  icurrent: icurrentReducer,
  brcurrent: brcurrentReducer,
  bicurrent: bicurrentReducer,
  acurrent: acurrentReducer,
  popup: popupReducer,
  option: optionReducer,
  option2: option2Reducer,
  isLogged: isLoggedReducer,
  veri: veriReducer,
  veri2: veri2Reducer,
  token: tokenReducer,
  benId: benIdReducer,
  refreshToken: refreshTokenReducer,
  dataBen: dataReducer,
  dataUser: userDataReducer,
  loginToken: loginTokenReducer,
  gidFilter: gidFilterReducer,
  phoneFilter: phoneFilterReducer,
  emailFilter: emailFilterReducer,
  profileData: profileDataReducer,
  universalPenId: universalPenReducer,
  namePerson: nameReducer,
  imagePerson: imgReducer,
  universalCheck: universalCheckReducer,
  popData: popDataReducer,
  appQuestions: appQuestionsReducer,
  data1: data1Reducer,
  data2: data2Reducer,
  data3: data3Reducer,
  applicationAnswers: applicationAnswersReducer,
  editFlag: editReducer,
  reset: resetReducer,
  polName: polNameReducer,
  pol: polReducer,
  polId: polUserReducer,
  polStatus: polStatusReducer,
  Question1: question1Reducer,
  Question2: question2Reducer,
  Question3: question3Reducer,
  Question4: question4Reducer,
  Question5: question5Reducer,
  Question6: question6Reducer,
  Questionx: questionxReducer,
  Questiony: questionyReducer,
  Question9: question9Reducer,
  Questiona: questionaReducer,
  Questionb: questionbReducer,
  Questionc: questioncReducer,
  Questiond: questiondReducer,
  Questione: questioneReducer,
  Questionz: questionzReducer,
  appUrl: appUrlReducer,
  place: placeReducer,
  placePop: placePopReducer,
  authState: authStateReducer,
  addPensionerData: addPensionerReducer,
  allBenId: allBenReducer,
  whichView: whichViewReducer,
  idDone: idDoneReducer,
  userData: userDataReducer1,
  benDone: benDoneReducer,
  fromAwaited: fromAwaitedReducer,
  pol1: pol1Reducer,
  penId: penIdReducer,
  pensionerName: pensionerNameReducer,
  pensionerId: pensionerIdReducer,
  cam: camReducer,
});

export default allReducers;
