import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import eye from "../../../imgs/eyee.png";
import across from "../../../imgs/across.png";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";

let check = "";
const SecondR = () => {
  const [prompt, setPrompt] = useState("");
  const location = useLocation();
  const dash = location.pathname;
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [email, setEmail] = useState("");
  const [gid, setGid] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const option = useSelector((state) => state.option);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const nextHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let body;

    if (option === "Pensioner") {
      if (email && prompt === "") {
        body = {
          role: option.toLowerCase(),
          gid: gid,
          email: email,
          password: password,
        };
        check = "email";
      } else if (prompt && email === "") {
        const wow = parsePhoneNumber(prompt);
        let countryCode = wow.countryCallingCode;
        let number = wow.nationalNumber;
        body = {
          role: option.toLowerCase(),
          gid: gid,
          countryCode: `+${countryCode}`,
          phoneNo: number,
          password: password,
        };
        check = "prompt";
      } else if (email && prompt) {
        const wow = parsePhoneNumber(prompt);
        let countryCode = wow.countryCallingCode;
        let number = wow.nationalNumber;
        body = {
          role: option.toLowerCase(),
          gid: gid,
          countryCode: `+${countryCode}`,
          phoneNo: number,
          email: email,
          password: password,
        };
        check = "email & prompt";
      } else {
        body = {
          role: option.toLowerCase(),
          gid: gid,
          password: password,
        };
        check = "gid";
      }
      if (dash === "/EDashboard/addPensioner") {
        delete body.role;
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/auth/pensioner-signup`,
            {
              user: body,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            console.log(data.data, "first");

            dispatch({ type: "popDataSet", num: data.data.user });
            // dispatch({ type: "addPensionerSet", num: data.data });
            dispatch({
              type: "tokenSet",
              num: `${data.data.tokens.access.token}`,
            });
            setIsLoading(false);
            dispatch({ type: "IINCREMENT" });
          })
          .catch((err) => {
            console.log(err);
            setErr(err.response.data.message);
            setShow(true);
            setIsLoading(false);
          });
      } else {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/signup`, body)
          .then((data) => {
            console.log(data.data, "first");
            dispatch({ type: "popDataSet", num: data.data.user });
            dispatch({
              type: "tokenSet",
              num: `${data.data.tokens.access.token}`,
            });
            setIsLoading(false);
            if (check === "email") {
              console.log("1");
              dispatch({ type: "veri" });
            } else if (check === "prompt") {
              console.log("2");
              dispatch({ type: "veri2" });
            } else if (check === "email & prompt") {
              console.log("3");
              dispatch({ type: "checkSet", num: "both" });
              dispatch({ type: "veri2" });
            } else if (check === "gid") {
              console.log("4");
              dispatch({ type: "IINCREMENT" });
            }
          })
          .catch((err) => {
            console.log(err);
            setErr(err.response.data.message);
            setShow(true);
            setIsLoading(false);
          });
      }
    } else {
      if (prompt) {
        const wow = parsePhoneNumber(prompt);
        let countryCode = wow.countryCallingCode;
        let number = wow.nationalNumber;
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/signup`, {
            role: option.toLowerCase(),
            countryCode: `+${countryCode}`,
            phoneNo: number,
            email: email,
            password: password,
          })
          .then((data) => {
            console.log(data.data, "first");
            dispatch({ type: "popDataSet", num: data.data.user });
            dispatch({
              type: "tokenSet",
              num: `${data.data.tokens.access.token}`,
            });
            dispatch({ type: "veri2" });
            dispatch({ type: "checkSet", num: "both" });
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      } else if (prompt === "") {
        setErr("please enter a valid phone number");
        setShow(true);
      }
    }
  };

  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };
  const passShowHandler = () => {
    const wow = document.getElementById("Pass11");
    if (wow.type === "password") {
      wow.type = "text";
    } else {
      wow.type = "password";
    }
  };
  return (
    <div>
      <form>
        <div className="flex justify-center items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div>
              <div className="mb-4">
                <label
                  htmlFor="input5"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  {option === "Pensioner" ? "Phone" : "Phone *"}
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="GY"
                  value={prompt}
                  onChange={handleChange(setPrompt, "tt")}
                  international
                  countryCallingCodeEditable={false}
                  required={option === "Employee" ? true : false}
                  className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                />
              </div>
              <div
                className={`${
                  option === "Employee" ? "hidden" : ""
                } flex flex-col`}
              >
                <label
                  htmlFor="input2"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  GID *
                </label>
                <input
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  type="text"
                  name="input2"
                  onChange={handleChange(setGid)}
                  value={gid}
                  placeholder="GID"
                  id=""
                />
              </div>
              <div className="flex flex-col mb-4">
                <label
                  htmlFor="input1"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  {option === "Pensioner" ? "Email" : "Email *"}
                </label>
                <input
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
                  type="text"
                  placeholder="Email"
                  onChange={handleChange(setEmail)}
                  value={email}
                  name="input1"
                  id=""
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <label
                  htmlFor="input4"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Password
                </label>
                <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                  <input
                    type="password"
                    placeholder="Password"
                    id="Pass11"
                    onChange={handleChange(setPassword)}
                    value={password}
                    required
                  ></input>
                  <img
                    className="w-[1.6rem] cursor-pointer"
                    onClick={passShowHandler}
                    src={eye}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className={`${
                show ? "" : "hidden"
              } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
            >
              <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
                <div
                  className="absolute top-[-11%] left-[42%]"
                  onClick={() => setShow(false)}
                >
                  <img src={across} alt="" />
                </div>
                <p className="text-center mt-[3rem] mb-5">{err}</p>
                <button
                  onClick={() => setShow(false)}
                  type="button"
                  className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex sm:justify-end justify-center sm:w-[85%]">
          <button
            onClick={nextHandler}
            className="relative justify-center flex cursor-pointer mt-10 bg-[#57D38C] pt-2 pb-2 pl-[2rem] pr-[2rem] text-white rounded-xl"
          >
            <Spin
              style={{
                display: `${isLoading ? "" : "none"}`,
                marginRight: "1rem",
              }}
              indicator={antIcon}
            />
            <span>Next</span>
            <div
              className={`w-[100%] h-[100%] ${
                isLoading ? "" : "hidden"
              } absolute top-0 bg-slate-400 opacity-40 rounded-2xl`}
            ></div>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SecondR;
