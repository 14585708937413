import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import back5 from "../../imgs/back5.png";
import { Tabs } from "antd";
import Page from "../Dash/Listing/page";
import { Input } from "antd";
import PageP from "./pageP";
import EmpRegisterPol from "./empRegisterPol";
import EmpRegisterPol1 from "./empRegisterPol1";
import { useNavigate } from "react-router-dom";

const Pol = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const router = useNavigate();
  const items = [
    {
      key: "1",
      label: `All`,
      children: <PageP />,
    },
    {
      key: "2",
      label: `Active`,
      children: <PageP />,
    },
    {
      key: "3",
      label: `Pending`,
      children: <PageP />,
    },
  ];
  return (
    <div className="sm:p-10 sm:pl-[5rem] sm:pr-[5rem]">
      <div>
        <div className="relative flex justify-between items-center sm:m-0 mt-4 ml-8">
          <div
            onClick={() => router("/EDashboard")}
            className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[-6%]"
          >
            <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold sm:text-[1.8rem] text-[1.2rem]">
            POL Verification
          </h2>
        </div>
        {/* <div className="flex w-full justify-between mt-[3rem] mb-8">
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Total Beneficiaries
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">8</p>
            </div>
            <div>
              <img className="w-[2.1rem] mt-4" src={e2} alt="" />
            </div>
          </div>
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Active Beneficiaries
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">5</p>
            </div>
            <div>
              <img className="w-[2.2rem] mt-4" src={e3} alt="" />
            </div>
          </div>
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Inactive Beneficiaries
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">4</p>
            </div>
            <div>
              <img className="w-[2rem] mt-5" src={e1} alt="" />
            </div>
          </div>
          <div className="w-[13rem] h-[4.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
            <div>
              <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                Pension
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">$600</p>
            </div>
            <div>
              <img className="w-[2.5rem] mt-4" src={e4} alt="" />
            </div>
          </div>
        </div> */}
      </div>
      <div className="relative sm:mt-0 mt-[4rem]">
        <Tabs defaultActiveKey="1" items={items} />
        <div className="absolute sm:top-1 sm:right-0 sm:p-0 pl-4 pr-4 top-[-2.5rem] w-[100%] sm:w-[55%] flex justify-between">
          <Input
            size="large"
            placeholder="Search by name"
            // prefix={<SearchOutlined />}
            className="Search"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Pol;
