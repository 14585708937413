import { Steps } from "antd";

// import First from "./stepsBI/FisrtBI";
import Second from "./stepsBI/SecondBI";
import Third from "./stepsBI/ThirdBI";
import Fourth from "./stepsBI/FourthBI";
import FirstI from "../Identification/stepsI/FirstI";
import SecondI from "../Identification/stepsI/SecondI";
import ThirdI from "../Identification/stepsI/ThirdI";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FifthB from "./stepsBR/FifthBR";
const BeneficiaryI = () => {
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const bicurrent = useSelector((state) => state.bicurrent);
  const Show = [FirstI, SecondI, ThirdI, FifthB];
  const Dis = Show[bicurrent];
  const steps = [
    {
      title: "",
      content: "First-content",
    },
    {
      title: "",
      content: "Second-content",
    },
    {
      title: "",
      content: "Last-content",
    },
    {
      title: "",
      content: "Last-content",
    },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const nextHandler = (check) => {
    if (check === "n") {
      if (bicurrent < 3) {
        dispatch({ type: "BIINCREMENT" });
      } else if (bicurrent === 3) {
        if (dash === "/Dashboard/BeneficiaryQuestions") {
          console.log("none");
        } else {
          dispatch({ type: "SET", num: 3 });
          dispatch({ type: "POP" });
        }
      }
    } else {
      if (bicurrent > 0) {
        dispatch({ type: "BIDECREMENT" });
      }
    }
  };
  return (
    <div>
      <div>
        <Dis />
      </div>
    </div>
  );
};

export default BeneficiaryI;
